import React, {Component} from 'react';
import {Dialog, DialogContentText, Theme} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = ((theme: Theme) => ({
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}));

export type CreateTagModalProps = {
    classes:any,
    isOpen: boolean,
    toggleModal: any,
    submit: (tagId: string, tagName: string, guideName: string, sampleQuestion: string) => any
};

export type CreateTagModalState = {
    values: any
};

class CreateTagModal extends Component<CreateTagModalProps, CreateTagModalState> {

    constructor(props: CreateTagModalProps) {
        super(props);
        this.state = {
            values: {}
        }
    }

    handleChange(event: any): void {
        this.setState({
            values: {...this.state.values, [event.target.id]: event.target.value }
        });
    }

    async submitTag(): Promise<void> {
        await this.props.submit(this.state.values.tagId, this.state.values.tagName, this.state.values.guideName, this.state.values.sampleQuestion);
        this.resetDialog();
    }

    resetDialog() {
        this.setState({ values: {} });
    }

    isFormValid(): boolean {
        return this.state.values.tagId && this.state.values.tagId !== ""
            && this.state.values.tagName && this.state.values.tagName !== "" && this.state.values.tagName.match(/^sb:\S.*?$/)
            && this.state.values.guideName && this.state.values.guideName !== ""
            && this.state.values.sampleQuestion && this.state.values.sampleQuestion !== "";
    }

    render() {
        return (
            <Dialog open={this.props.isOpen}
                onClose={this.props.toggleModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Opret ny tag</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Når der oprettes et nyt tag kræves det at der angives et tag id, et tag navn og et eksempel spørgsmål/svar hvori dette tag bruges.
                    </DialogContentText>
                    <TextField
                        required fullWidth
                        id="tagId"
                        className={this.props.classes.textField}
                        onChange={this.handleChange.bind(this)}
                        label="Tag Id"
                        variant="outlined"
                    />
                    <TextField
                        required fullWidth
                        id="tagName"
                        className={this.props.classes.textField}
                        onChange={this.handleChange.bind(this)}
                        label="Navn på selvbetjening.nu tag"
                        variant="outlined"
                        helperText={<span>Angiv altid navn på tagget i formattet <b><i>sb:kategori:navn</i></b>. Navnet skal være unikt.</span>}
                    />
                    <TextField
                        required fullWidth
                        id="guideName"
                        className={this.props.classes.textField}
                        onChange={this.handleChange.bind(this)}
                        label="Navn på selvbetjening.nu guide"
                        variant="outlined"
                    />
                    <TextField
                        required fullWidth
                        id="sampleQuestion"
                        className={this.props.classes.textField}
                        onChange={this.handleChange.bind(this)}
                        label="Spørgsmål eller svar der beskriver tag indholdet"
                        multiline
                        rows={3}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.toggleModal} color="secondary" autoFocus>Annuller</Button>
                    <Button
                        disabled={!this.isFormValid()}
                        onClick={this.submitTag.bind(this)}
                        color="primary">Gem</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CreateTagModal);