import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Theme} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Divider, Grid, Typography} from '@material-ui/core';
import {withStyles} from "@material-ui/styles";
import {options} from './chart';

const styles: any = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  category: {
    textAfter: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

export type CategoriesHitCountProps = {
  categories: any,
  classes: any
};
export type CategoriesHitCountState = {
  chartOptions: any,
  categories: {title: string, value: string, color:string }[]
}

export class CategoriesHitCount extends Component<CategoriesHitCountProps, CategoriesHitCountState> {

  private reference: any;

  constructor(props: CategoriesHitCountProps) {
    super(props);

    this.state = {
      chartOptions: options,
      categories: []
    }
  }

  componentDidMount(): void {
    this.populateCategoriesArray();

    if(this.reference && this.reference.hasOwnProperty("update"))
      this.reference.update();
  }

  componentDidUpdate(prevProps: Readonly<CategoriesHitCountProps>, prevState: Readonly<CategoriesHitCountState>, snapshot?: any): void {
    if(prevProps.categories !== this.props.categories) {
      this.populateCategoriesArray();

      if(this.reference && this.reference.hasOwnProperty("update"))
        this.reference.update();
    }
  }


  populateCategoriesArray(): any {
    const categoryLabels: { title: string, value: string, color: string }[] = [];

    const data = this.props.categories.data;
    if(data && data.labels && Array.isArray(data.labels)
        && data.datasets && data.datasets.length > 0){

      const total = data.total;

      data.labels.forEach((value: any, index: number) => {
        const dataValue = data.datasets[0].data[index];
        let valuePercentage: number = 0;

        if (total && total > 0)
          valuePercentage = (dataValue / total) * 100;

        categoryLabels.push({
          title:  value,
          value: valuePercentage.toFixed(1).toString(),
          color: data.datasets[0].backgroundColor[index],
        });
      });
    }

    this.setState({ categories: categoryLabels });
  }

  render (){
      return (
          <div className={this.props.classes.root}>
            <Card>
              <CardHeader title="Samtale start kategori" />
              <Divider />
              <CardContent>
                <div className={this.props.classes.chartContainer}>
                  <Doughnut data={this.props.categories.data}
                    options={this.state.chartOptions}
                  />
                </div>
              </CardContent>
              <CardContent>
                <Grid container>
                  {this.state.categories.map((category, i) => (
                      <Grid item
                          key={this.state.categories.indexOf(category)}
                          className={this.props.classes.category}
                            lg={3} sm={6} xl={3} xs={6}>
                        <Typography variant="h4">{category.title}:  </Typography>
                        <Typography
                            style={{ color: category.color}}
                            variant="h4">
                          {category.value}%
                        </Typography>
                      </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </div>
  ) }
}

export default withStyles(styles)(CategoriesHitCount);
