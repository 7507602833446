import React, {Component} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Theme} from '@material-ui/core/styles';
import {
    Card,
    CardActions,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import {StatusBullet} from "../../../../components";
import {withStyles} from "@material-ui/styles";

const styles = ((theme: Theme) => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    padding: theme.spacing(0),
    alignItems: 'center',
  },
  title: {
    fontWeight: 700
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  table: {
    maxHeight: '400px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
}));

export type StatusListProps = {
  municipalities: any[],
  classes: any
};

export type StatusListState = {
  rowsPerPage: number,
  page: number
};

class StatusList extends Component<StatusListProps,StatusListState> {

  constructor(props: StatusListProps) {
    super(props);

    this.state = {
      rowsPerPage: 5,
      page: 0
    }
  }

  handlePageChange(event: any, page: number){
    this.setState({ ...this.state, page: page })
  }

  handleRowsPerPageChange(event: any){
    this.setState({ page: 0, rowsPerPage: event.target.value })
  }

  render() {
    return (
        <Card className={this.props.classes.root}>
          <CardContent className={this.props.classes.content}>
            <Grid container spacing={0}>
              <Grid item lg={12}  md={12} xl={12} xs={12}>
                <PerfectScrollbar>
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Drift status</TableCell>
                          <TableCell>Kommune</TableCell>
                          <TableCell>Varighed</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.municipalities.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(item => (
                            <TableRow key={item.id}>
                              <TableCell>
                                <div className={this.props.classes.statusContainer}>
                                  <StatusBullet className={this.props.classes.status}
                                      color={!item.mode || item.mode.ttl === -2 ? "success" : "danger"} size="sm" />
                                  {!item.mode || item.mode.ttl === -2 ? "Aktiv" : "Inaktiv"}
                                </div>
                              </TableCell>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.mode ? `${(item.mode.ttl/60).toFixed(0)} min tilbage` : "" }</TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={this.props.classes.actions}>
            <TablePagination
                labelRowsPerPage="Rækker pr. side"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}` }
                component="div"
                count={this.props.municipalities.length}
                onChangePage={this.handlePageChange.bind(this)}
                onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50, 75]}
            />
          </CardActions>
        </Card>
    );
  }
}

export default withStyles(styles)(StatusList);
