import { config } from "../config/Config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { browserHistory } from "../App";

export class BaseAPIService {

    private static readonly BASE_URL = `${config.backend.scheme}://${config.backend.host}`;

    private handleForbiddenOrUnauthorized() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("profile");
        browserHistory.push("/sign-in");
    }

    public async request(options: AxiosRequestConfig, skipRedirectOnUnAuth?: boolean): Promise<any> {

        if (!options.url || !options.url.startsWith('/'))
            throw new Error("The url must be set like '/api/dialog'");

        options.url = BaseAPIService.BASE_URL + options.url;

        if (options.headers)
            options.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
        else
            options.headers = { Authorization: `Bearer ${localStorage.getItem("accessToken")}` };

        try {
            const response: AxiosResponse = await axios(options);

            return response.data;
        }
        catch (err) {
            const e = err as any;
            if (e?.response?.status === 401 || e?.response?.status === 403) {
                if (!skipRedirectOnUnAuth)
                    return this.handleForbiddenOrUnauthorized();
                else
                    return e.response.data;
            }
            else
                throw e;
        }
    }

}