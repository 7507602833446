import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { Avatar, Card, CardContent, Grid, Theme, Typography } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';

const styles: any = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

export type RatingsAverageProps = {
  avg: number,
  classes: any,
  title?: string,
};

export class RatingsAverage extends Component<RatingsAverageProps, any>{

  render() {
    return (
      <div className={this.props.classes.root}>
        <Card className={clsx(this.props.classes.root)} >
          <CardContent>
            <Grid container justify="space-between" wrap="nowrap">
              <Grid item>
                <Typography
                  className={this.props.classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2" >
                  {(this.props.title || "GENNEMSNIT RATING").toUpperCase()}
                </Typography>
                <Typography variant="h3">{this.props.avg !== 0 ? this.props.avg.toFixed(2) : "N/A"}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={this.props.classes.avatar}>
                  <GradeIcon className={this.props.classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(RatingsAverage);
