import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./WDStats.module.scss";
import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import MunicipalitySelection from "./components/MunicipalitySelection/MunicipalitySelection";
import { StatisticsAPIService } from "../../service/StatisticsAPIService";
import { IWatsonDiscoveryStats } from "../../service/interfaces/IWatsonDiscoveryStats";
import { MetricCard } from "../../components";
import {
  MouseTwoTone,
  SearchTwoTone,
  TrendingUpTwoTone,
} from "@material-ui/icons";
import { WatsonDiscoverySearchesTable } from "./components/WatsonDiscovery/WatsonDiscoverySearchesTable";
import { WatsonDiscoveryQueryStatisticsTable } from "./components/WatsonDiscovery/WatsonDiscoveryQueryStatisticsTable";
import { useMunicipalities } from "../../hooks/useMunicipalities";
import { WatsonDiscoveryUrlStatisticsTable } from "./components/WatsonDiscovery/WatsonDiscoveryUrlStatisticsTable";
import { PageState } from "../../helpers/PageState";

const STATE_KEY = "wd-stats";

export function WatsonDiscoveryStats() {
  const selectionRef = useRef<typeof MunicipalitySelection>(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState(0);
  const [dateRange, setDateRange] = useState({
    period: "thisMonth",
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(),
  });
  const [stats, setStats] = useState<IWatsonDiscoveryStats>();
  const [isFetching, setIsFetching] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const statsApi = useMemo(() => new StatisticsAPIService(), []);

  const { municipalities } = useMunicipalities();

  const refresh = useCallback(async () => {
    setIsFetching(true);

    const stats = await statsApi.getWDStats({
      periodStart: dateRange.start,
      periodEnd: dateRange.end,
      municipalityCode: selectedMunicipality,
    });
    setStats(stats);

    setIsFetching(false);
  }, [statsApi, dateRange, selectedMunicipality]);

  useEffect(() => {
    if (PageState.hasState(STATE_KEY)) {
      const state = PageState.get(STATE_KEY);
      setDateRange(state.dateRange);
      setSelectedMunicipality(state.selectedMunicipality);
      (selectionRef.current as any).setValues(
        state.selectedMunicipality,
        state.dateRange.start,
        state.dateRange.end,
        state.dateRange.period
      );
      setInitialized(true);
    } else {
      refresh();
      setInitialized(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!initialized) return;
    PageState.save(STATE_KEY, {
      selectedMunicipality,
      dateRange,
    });
    refresh();
  }, [selectedMunicipality, dateRange, refresh, initialized]);

  return (
    <div className={styles.root}>
      <div className={styles.breadcrumb}>
        <Breadcrumbs aria-label="bredcrumbs">
          <Typography color="textPrimary">
            Watson Discovery statistik
          </Typography>
        </Breadcrumbs>
      </div>

      <Grid container spacing={4} style={{ marginBottom: 16 }}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <MunicipalitySelection
            ref={selectionRef}
            isRefreshEnabled={selectedMunicipality !== 0}
            onMunicipalitySelected={(m) => setSelectedMunicipality(m)}
            onDateRangeSelected={(start, end, period) =>
              setDateRange({
                period,
                start,
                end,
              })
            }
            hideSubjects
            onSubjectSelected={() => {}}
            onRefresh={() => refresh()}
            municipalities={municipalities ?? []}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {(!stats || isFetching) && (
          <>
            <Grid item lg={4}>
              <MetricCard text="Samlet antal søgninger" icon={SearchTwoTone} />
            </Grid>
            <Grid item lg={4}>
              <MetricCard text="Samlet antal kliks" icon={MouseTwoTone} />
            </Grid>
            <Grid item lg={4}>
              <MetricCard text="Konverteringsrate" icon={TrendingUpTwoTone} />
            </Grid>
          </>
        )}
        {!isFetching && stats && (
          <>
            <Grid item lg={4}>
              <MetricCard
                text="Samlet antal søgninger"
                value={stats.searches}
                icon={SearchTwoTone}
                data={stats.data.map((d) => ({ x: d.datetime, y: d.searches }))}
              />
            </Grid>
            <Grid item lg={4}>
              <MetricCard
                text="Samlet antal kliks"
                value={stats.clicks}
                icon={MouseTwoTone}
                data={stats.data.map((d) => ({ x: d.datetime, y: d.clicks }))}
              />
            </Grid>
            <Grid item lg={4}>
              <MetricCard
                text="Konverteringsrate"
                value={`${(stats.conversionRate * 100).toFixed(0)}%`}
                icon={TrendingUpTwoTone}
                data={stats.data.map((d) => ({
                  x: d.datetime,
                  y: d.conversionRate * 100,
                }))}
              />
            </Grid>
          </>
        )}
        <Grid item lg={12}>
          <WatsonDiscoverySearchesTable
            municipalityCode={selectedMunicipality}
            periodStart={dateRange.start}
            periodEnd={dateRange.end}
          />
        </Grid>

        <Grid item lg={12}>
          <WatsonDiscoveryQueryStatisticsTable
            municipalityCode={selectedMunicipality}
            periodStart={dateRange.start}
            periodEnd={dateRange.end}
          />
        </Grid>

        <Grid item lg={12}>
          <WatsonDiscoveryUrlStatisticsTable
            municipalityCode={selectedMunicipality}
            periodStart={dateRange.start}
            periodEnd={dateRange.end}
          />
        </Grid>
      </Grid>
    </div>
  );
}
