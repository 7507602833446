import {colors} from '@material-ui/core';
import {PaletteOptions} from "@material-ui/core/styles/createPalette";

const white = '#FFFFFF';
const black = '#000000';

const palette: PaletteOptions = {
  common:{
    black: black,
    white: white,
  },
  primary: {
    contrastText: white,
    dark: "#004661",
    main: "#02718F",
    light: "#4da0bf"
  },
  secondary: {
    contrastText: white,
    dark: "#004661",
    main: "#02718F",
    light: "#4da0bf"
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    disabled: colors.grey[900],
    hint: colors.grey[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};

export const paletteExtension: any = {
  icon: colors.blueGrey[600]
};

export default palette;