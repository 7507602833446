import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/da";
import {Button, CardActions, Divider, Grid, Theme} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {Sanitize} from "../../../../../../../../helpers/utils";
import MenuBookIcon from '@material-ui/icons/MenuBookRounded';
import {User} from "../../../../../../../../helpers/profile";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    cardHeader: {
        backgroundColor: theme.palette.white
    },
    cardContent: {
        backgroundColor: theme.palette.white,
        height: "50vh",
        marginTop: "5px",
        marginBottom: "5px"
    },
    iconCustomAnswer: {
        fontSize: 80,
        opacity: 0.8,
        color: theme.palette.text.primary
    },
}));

export type CustomTextCardProps = {
    isEditing: boolean,
    onEditText: any,
    onDeleteText: any,
    tag: any,
    classes: any
};

export type CustomTextCardState = {

};

class CustomTextCard extends Component<CustomTextCardProps, CustomTextCardState> {

    constructor(props: CustomTextCardProps) {
        super(props);

        this.state = {

        }
    }

    async componentDidMount(): Promise<void> {
    }

    render() {
        return (
            <Card>
                <CardHeader className={this.props.classes.cardHeader} title={
                    <span>Tilpasset tekst</span>
                }
                action={
                        this.props.tag.correctedValue !== "" && this.props.tag.correctedValue ?
                            <Typography variant="body2" color="primary">Aktiv</Typography>
                            :
                            <Typography variant="body2">Inaktiv</Typography>
                }
                />
                <Divider/>
                <CardContent className={this.props.classes.cardContent} style={{ overflowY: "auto" }}>
                    {
                        this.props.tag.correctedValue !== "" && this.props.tag.correctedValue ?
                            <Typography
                                style={{ whiteSpace: "pre-wrap"}}
                                variant="body1"
                                dangerouslySetInnerHTML={{__html: Sanitize(this.props.tag.correctedValue) || ""}} />
                            :
                            <Grid container direction="column" alignItems="center" justify="center" style={{ height: "100%"}}>
                                <Grid item>
                                    <MenuBookIcon className={this.props.classes.iconCustomAnswer} />
                                </Grid>
                                <Grid item>
                                    Svaret fra Selvbetjening.nu anvendes
                                </Grid>
                            </Grid>
                    }
                </CardContent>
                <Divider/>
                {
                    User.isAdmin() &&
                    <CardActions className={this.props.classes.cardActions}>
                    {
                        !this.props.isEditing &&
                        <Button color="primary" variant="text"
                                onClick={this.props.onEditText.bind(this)}>
                            Tilpas svar
                        </Button>
                    }
                    {
                        this.props.tag.correctedValue !== "" && this.props.tag.correctedValue &&
                        <Button color="secondary" variant="text"
                                onClick={this.props.onDeleteText.bind(this)}>
                            Slet tilpasset svar
                        </Button>
                    }
                    </CardActions>
                }
            </Card>
        );
    }

}

export default withStyles(styles)(CustomTextCard)
