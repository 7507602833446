import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Card, CardContent, CardHeader, CardMedia, Divider, Theme, Typography} from '@material-ui/core';
import {Link} from "react-router-dom";

const styles: any = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  iconCard: {
    display:'flex',
    justifyContent:'center'
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

export type DashboardRedirectCardProps = {
  classes: any,
  title: string,
  description: string,
  url: string,
  color: string,
  image?: string,
  icon?: any
};
export type DashboardRedirectCardState = {};

class DashboardRedirectCard extends Component<DashboardRedirectCardProps, DashboardRedirectCardState>{

  constructor(props: DashboardRedirectCardProps) {
    super(props);

    this.state = {};
  }

  renderCard(){
    return (
        <Link to={this.props.url} >
          <Card className={this.props.classes.root}>
            <CardHeader title={this.props.title} />
             {
               this.props.image &&
               <CardMedia
                  component="img"
                  className={this.props.classes.media}
                  image={this.props.image}/>
             }
            {
              this.props.icon &&
              <CardContent className={this.props.classes.iconCard}>
                  {this.props.icon}
              </CardContent>
            }
            <Divider/>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.props.description}
              </Typography>
            </CardContent>
          </Card>
        </Link>
    );
  }

  render() {
    return (
    <div className={this.props.classes.root}>
      {this.renderCard()}
    </div>
    );
  }
}

export default withStyles(styles)(DashboardRedirectCard);
