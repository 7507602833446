import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import "moment/locale/da";
import {Button, Divider, List, ListItem, ListItemText, Theme} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {User} from "../../../../../../../../helpers/profile";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    cardHeader: {
        backgroundColor: theme.palette.white
    },
    cardContent: {
        backgroundColor: theme.palette.white
    },
    editCardContent: {
        backgroundColor: theme.palette.white,
        height: "50vh",
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    predefinedCardContent: {
        backgroundColor: theme.palette.white,
        height: "50vh",
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    iconCustomAnswer: {
        fontSize: 80,
        opacity: 0.8,
        color: theme.palette.text.primary
    },
    sectionText: {
        marginLeft: "5px"
    },
    dangerBtn: {
        color: theme.palette.error.main
    },
    actionBtnMargin: {
        marginRight: "10px"
    }
}));

export type TagDetailsProps = {
    onAlreadyRequestedClick: () => any,
    onDeleteClick: () => any,
    onActivationClick: () => any
    tag: any,
    classes: any
};

export type TagDetailsState = {};

class TagDetails extends Component<TagDetailsProps, TagDetailsState> {

    constructor(props: TagDetailsProps) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    getActions () {
        if (this.props.tag && this.props.tag.isActive === true)
            return (<Button className={this.props.classes.actionBtnMargin} variant="outlined" color="primary" onClick={this.props.onActivationClick.bind(this)}>Deaktiver</Button>);
        else if (this.props.tag && this.props.tag.isActive === false && this.props.tag.isRequested === true)
            return (<Button className={this.props.classes.actionBtnMargin} variant="outlined" color="primary" onClick={this.props.onActivationClick.bind(this)}>Aktiver</Button>);

        return (<Button className={this.props.classes.actionBtnMargin} variant="outlined" color="primary" onClick={this.props.onAlreadyRequestedClick.bind(this)}>Allerede anmodet</Button>);
    }

    getDeleteAction() {
        if (this.props.tag && this.props.tag.isActive === false)
            return (<Button variant="outlined" className={this.props.classes.dangerBtn} onClick={this.props.onDeleteClick.bind(this)}>Slet</Button>);
    }

    render() {
        return (
            <Card className={this.props.classes.card}>
                <CardHeader className={this.props.classes.cardHeader}
                            title="Tag detajler"
                            action={<div>
                                {
                                    User.isGlobalAdmin() &&
                                    <div>
                                        { this.getActions() }
                                        { this.getDeleteAction() }
                                    </div>
                                }
                            </div>} >

                </CardHeader>
                <Divider/>
                <CardContent className={this.props.classes.cardContent}>
                    <List>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.name ? this.props.tag.name : "-"}
                                primary="Navn"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.tagId ? this.props.tag.tagId : "-"}
                                primary="ID"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.guideName ? this.props.tag.guideName : "-"}
                                primary="Guide navn"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.createdAt ? moment(this.props.tag.createdAt).format('dddd DD. MMMM yyyy') + " " + moment(this.props.tag.createdAt).format('HH:mm:ss') : "-"}
                                primary="Oprettet den"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.sampleQuestion ? this.props.tag.sampleQuestion : "-"}
                                primary="Spørgsmål/Svar"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.isActive ? "Ja" : "Nej"}
                                primary="Aktiveret"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.isRequested ? "Ja" : "Nej"}
                                primary="Anmodet"
                            />
                        </ListItem>

                    </List>
                </CardContent>
            </Card>
        );
    }

}

export default withStyles(styles)(TagDetails)
