import {Grid, Popover, Theme} from "@material-ui/core";
import React, {Component} from "react";
import {AvatarIcon} from "../../../../../../assets/icons/AvatarIcon";
import moment from "moment";
import TimestampBotBlob from "../BotBlob/TimestampBotBlob";
import {withStyles} from "@material-ui/styles";
import ReactJson from "react-json-view";
import Typography from "@material-ui/core/Typography";

const styles: any = ((theme: Theme) => ({
    mssageContainer: {
        maxWidth: '600px'
    },
    popover: {
       // pointerEvents: 'none',
    },
    paper: {
        maxWidth: '60%',
        maxHeight: '80%',
        padding: theme.spacing(1),
    },
    kleFormFs3Field: {
        margin: theme.spacing(2)
    },
    jsonView: {
        margin: theme.spacing(2)
    }
}));

export type BotMessageProps = {
    blobs: any[],
    kleFormFs3Field?: string,
    waDebug?: any,
    timestamp: any,
    classes?: any
}

export type BotMessageState = {
    anchorEl: any,
    isPopoverOpen: boolean
};

class BotMessage extends Component<BotMessageProps, BotMessageState> {

    constructor(props: BotMessageProps) {
        super(props);

        this.state = {
            anchorEl: undefined,
            isPopoverOpen: false
        };
    }

    handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: undefined });
    };

    render() {
        return (
            <div>
                <Grid container direction="row" spacing={1} xs={12} style={{ flexWrap: "nowrap" }}>
                    <Grid item>
                        <AvatarIcon/>
                    </Grid>
                    <Grid item className={this.props.classes.mssageContainer}>
                        <Grid item xs={12} onDoubleClick={this.handlePopoverOpen.bind(this)}
                              >
                            {this.props.blobs.map((item, index) => {
                                return(
                                    <span key={index}>{item}</span>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <TimestampBotBlob timestamp={moment(this.props.timestamp).format("HH:mm")}/>
                        </Grid>
                    </Grid>
                </Grid>
                {this.props.waDebug !== undefined ?
                <Popover
                    id="mouse-over-popover"
                    className={this.props.classes.popover}
                    classes={{
                        paper: this.props.classes.paper,
                    }}
                    open={this.state.anchorEl ? true : false}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={this.handlePopoverClose.bind(this)}>
                    {this.props.kleFormFs3Field &&
                        <Typography className={this.props.classes.kleFormFs3Field}
                                    variant="body1">Detekteret tag: {this.props.kleFormFs3Field}</Typography>
                    }
                    <div className={this.props.classes.jsonView}>
                        <ReactJson src={this.props.waDebug ? this.props.waDebug : {}} />
                    </div>
                </Popover>
                    : ""
                }
            </div>
        );
    }
}

export default withStyles(styles)(BotMessage)
