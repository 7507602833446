import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Card, CardHeader, Divider, Grid, Theme} from '@material-ui/core';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import SubjectItemTable from "./components/SubjectItemTable";
import {StatisticsAPIService} from "../../../../../../service/StatisticsAPIService";
import {Log} from "../../../../../../common/log";
import InfoSvgButton from "../../../../../../components/InfoButton";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    }
}));

export type SubjectItemDetailsProps = { location: any, match: any, classes: any };

export type SubjectItemDetailsState = {
    isLoading: boolean,
    municipalityId: number,
    begin: Date,
    end: Date,
    subjectItems: any[],
    totalSubjects: number
};

class SubjectItemDetails extends Component<SubjectItemDetailsProps, SubjectItemDetailsState> {

    private service: StatisticsAPIService = new StatisticsAPIService();

    constructor(props: SubjectItemDetailsProps) {
        super(props);

        this.state = {
            isLoading: true,
            subjectItems: [],
            totalSubjects: 0,
            municipalityId: -1,
            begin: new Date(),
            end: new Date()
        }
    }

    async componentDidMount(): Promise<void> {
        await this.setupVarsFromQueryParams();
        await this.fetchData();
    }

    async setupVarsFromQueryParams(): Promise<void> {
        const query = new URLSearchParams(this.props.location.search);
        let id = -1;
        try {
            id = parseInt(query.get("id") || "-1");
        }
        catch (e) {
            Log.debug(`Failed parsing id as number`);
        }

        let begin = new Date();
        try {
            begin =  new Date(parseInt(query.get("begin") || ""));
        }
        catch (e) {
            Log.debug(`Failed parsing begin as date`);
        }

        let end = new Date();
        try {
            end = new Date(parseInt(query.get("end") || ""));
        }
        catch (e) {
            Log.debug(`Failed parsing end as date`);
        }

        await this.setState({...this.state,
            municipalityId: id,
            begin: begin,
            end: end
        });
    }

    async fetchData(): Promise<void> {

        if(!this.state.isLoading){
            this.setState({ isLoading: true });
        }

        await Promise.all([
            this.fetchSubjectItems()
        ]);

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async fetchSubjectItems(): Promise<void> {

        try {
            const subject = this.props.match.params.subject;
            const res = await this.service.getSubjectItems(this.state.municipalityId, subject, this.state.begin, this.state.end);

            if (res && res.items) {
                this.setState({ ...this.state, totalSubjects: res.count || 0, subjectItems: res.items || [] });
            }
        } catch (e) {
            Log.error(`Failed loading subject items ${e}`);
        }
    }

    getSubjectName(): string {
        const subject = this.props.match.params.subject;
        return subject && subject.length > 0 ? subject.charAt(0).toUpperCase() +  subject.slice(1) : subject;
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <RouterLink color="inherit" to="/stats" >
                            <Typography color="textPrimary">Statistik</Typography>
                        </RouterLink>
                        <Typography color="textPrimary">Samtale område</Typography>
                        <Typography color="textPrimary">{this.getSubjectName()}</Typography>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer}/>
                </div>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Card>
                            <CardHeader
                                subheader={`Samtaler i området ${this.props.match.params.subject?.toLowerCase()}`}
                                title={<span>Detaljeret oversigt over procentfordelingen<InfoSvgButton title={"Detajleret oversigt over procentfordelingen"}
                                 content={<Typography>Detaljeoversigten for procentfordelingen inden for et område giver dig mulighed for at få et overblik over, hvordan den procentvisefordeling af underområder er inden for et givent område.<br/>
                                     I kolonnen "Underområde" kan du se hvilke underområder der er blevet trigget under det givne område, som du er trykket ind på. "Antal" dækker over hvor mange gange det givne underområde er blevet trigget. "Procentfordeling" angiver den procentvise fordeling mellem alle underområderne der er repræsenteret på listen.<br/>
                                     <b>Eksempel med pas:</b><br/>
                                     Underområdet kunne være "Bestil pas". "Antal" vil her blive påvirket af, hvor mange borgere der ønsker at bestille pas gennem chatbotten. "Procentfordeling" vil vise, hvor stor en procentdel af borgere der forsøger med en pasbestilling fremfor et andet underemne under pas - dette kunne eksempelvis være "Leveringstid for pas" eller "Pris for pas"</Typography>}/></span>
                                }
                            />
                            <Divider/>
                            <SubjectItemTable
                                isLoading={this.state.isLoading}
                                totalSubjects={this.state.totalSubjects}
                                subjectItems={this.state.subjectItems} />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(SubjectItemDetails)
