import React, {Component} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from '@material-ui/styles';
import validate from 'validate.js';
import {chartjs} from './helpers';
import theme from './theme';
import validators from './common/validators';
import Routes from './Routes';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

const Chart = require('react-chartjs-2').Chart;

export const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    );
  }
}
