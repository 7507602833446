import React, {Component} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {MunicipalitySelection} from "./components";
import './Thumbnail.css';
import {DialogListProps} from "../DialogList/DialogList";
import {withStyles} from "@material-ui/styles";
import {APIService} from "../../service/APIService";
import {config} from "../../config/Config";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Log} from "../../common/log";

const styles: any = ((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: 'medium none'
  }
}));

export type TrainingProps = { classes: any };

export type TrainingState = {
  bubbleClass: string,
  presentationMode: number,
  presentationMessage: string,
  chatbotUrl: string,
  thumbnailStyle: string,
  isFrameHidden: boolean,
  isChatVisible: boolean,
  municipalityCode: number,
  category: string,
  municipalities: [],
  botWidth: string
};

class Training extends Component<TrainingProps,TrainingState> {

  private service: APIService = new APIService();
  private timeoutRef: any;
  private isFirstTime: boolean = true;

  constructor(props: DialogListProps) {
    super(props);

    this.state = {
      bubbleClass: "",
      presentationMode: 3,
      presentationMessage: "Skal du have hjælp, så spørg mig!",
      chatbotUrl: `${config.chatbot.scheme}://${config.chatbot.host}`,
      isChatVisible: false,
      isFrameHidden: true,
      municipalityCode: 0,
      category: '',
      thumbnailStyle: "",
      municipalities: [],
      botWidth: ''
    };
  }

  async componentDidMount(): Promise<void> {

    try {
      const municipalities = await this.service.getMunicipalities(100, 0, true, true);
      this.setState({ ...this.state, municipalities: municipalities });
    }
    catch (e) {
      Log.error(`Failed loading dialogs`);
    }
  }

  handleStep(isChatVisible: boolean) {
    let update = { ...this.state, isChatVisible: isChatVisible };
    if (isChatVisible && this.isTextBubbleEnabled()) {
      update.bubbleClass = this.state.presentationMode === 2 ? "text-bubble-in-fast" : "text-bubble-in";
      this.isFirstTime =true;
    }
    this.setState(update)
  }

  handleReset() {
    this.hideChatbot();
  }

  async handleMunicipalityChange(municipalityCode: number) {
    this.setState({ ...this.state, municipalityCode: municipalityCode, isChatVisible: false });
    await this.fetchPresentationConfig(municipalityCode);
    this.resetBubbleState();
  }

  handleCategoryChange(category: string) {
    this.setState({ ...this.state, category: category === "" ? '' : '&category=' + category });
  }

  async fetchPresentationConfig(municipalityCode: number) {
      try {
        const config = await this.service.getMunicipalityPresentationConfig(municipalityCode);
        if (config && config.presentationMessages && config.presentationMode !== undefined) {
          await this.setState({
            presentationMode: config.presentationMode,
            presentationMessage: config.presentationMessages[Math.floor(Math.random() * config.presentationMessages.length)]
          });
        }
      }
      catch {
        Log.error(`Failed loading presentation for municipality ${municipalityCode}`);
      }
  }

  showChatbot (): void {
    this.clearBubbleTimer();

    const iframe: any = document.getElementById("innerFrame");

    if (iframe) {
      iframe.contentWindow.postMessage("initialize", "*");

      const shadow: any = document.getElementById("chatbot-container-shadow");
      const container: any = document.getElementById("chatbot-container");
      const x: any = document.getElementById("x");

      container?.classList.remove("round");
      container?.classList.add("square");
      container?.classList.remove("jump");

      x?.classList.remove("hidden");

      shadow?.classList.add("hidden");

      this.setState({ ...this.state, bubbleClass: "hidden", isFrameHidden: false });
    }
  }

  hideChatbot (): void {
    this.clearBubbleTimer();

    const shadow: any = document.getElementById("chatbot-container-shadow");
    const container: any = document.getElementById("chatbot-container");
    const x: any = document.getElementById("x");

    container?.classList.remove("square");
    container?.classList.add("round");

    if (this.isJumpEnabled()) {
      container?.classList.add("jump");
    }

    x?.classList.add("hidden");
    shadow?.classList.remove("hidden");

    this.setState({ ...this.state, bubbleClass: "hidden", isFrameHidden: true });
  }

  toggleChatbot (event: any): void {

    event = event || window.event;
    event.stopPropagation();

    const container: any = document.getElementById("chatbot-container");

    if (container && container.classList.contains("round")){
      this.showChatbot();
    }
    else {
      this.hideChatbot();
    }
  }

  clearBubbleTimer() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = undefined;
    }
  }

  resetBubbleState() {
    if (this.isTextBubbleEnabled())
      this.setState({ bubbleClass: this.state.presentationMode === 2 ? "text-bubble-in-fast" : "text-bubble-in" });
  }

  isJumpEnabled(): boolean {
    return this.state.presentationMode === 1 || this.state.presentationMode === 3;
  }

  isTextBubbleEnabled(): boolean {
    return this.state.presentationMode === 2 || this.state.presentationMode === 3;
  }

  renderMuni = () => {

    if (this.isTextBubbleEnabled() && !this.timeoutRef && this.state.isChatVisible && this.isFirstTime && this.state.isFrameHidden) {
      this.isFirstTime = false;
      this.timeoutRef = setTimeout(() => {
          if (this.state.isFrameHidden) {
            this.setState({ bubbleClass: "text-bubble-out" });
          }
      }, 13000);
    }

    return(
        <div>
          {
            this.isTextBubbleEnabled() &&
            <div id="chatbot-bubble"
                 className={"text-bubble " + this.state.bubbleClass}>{this.state.presentationMessage}</div>
          }
          <div id="chatbot-container-shadow" className="round" />
          <div id="chatbot-container" className={this.isJumpEnabled() ? "round jump" : "round"} style={{width: this.state.botWidth}} onClick={this.toggleChatbot.bind(this)}>
            <div id="x" className="hidden" onClick={this.toggleChatbot.bind(this)}></div>
            <div id="iframe" className={this.state.isFrameHidden ? "hidden" : ""}>
              <iframe id="innerFrame" title="chatbot" className={this.props.classes.iframe} frameBorder="0"
                      seamless
                      src={this.state.chatbotUrl + "?municipalityCode=" + this.state.municipalityCode + this.state.category}/>
            </div>
          </div>
        </div>
    )
  };

  render(){

    const botStyle: string = `#chatbot-container.round:before { background-image: url("${this.state.chatbotUrl}/images/avatar/customization/${this.state.municipalityCode}_noshadow.svg"); }`;

    return (
        <div className={this.props.classes.root}>
          <div className={this.props.classes.row}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">Visning</Typography>
            </Breadcrumbs>
            <span className={this.props.classes.spacer}/>
          </div>
          <Grid
              container
              spacing={4}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <MunicipalitySelection municipalities={this.state.municipalities}
                                     onStepChange={this.handleStep.bind(this)}
                                     onReset={this.handleReset.bind(this)}
                                     onMunicipalityChange={this.handleMunicipalityChange.bind(this) }
                                     onCategoryChange={this.handleCategoryChange.bind(this)}/>
            </Grid>
          </Grid>
          <style type="text/css">
            {botStyle}
          </style>
          {
            this.state.isChatVisible ? this.renderMuni() : ""
          }
        </div>
    );
  }

}

export default withStyles(styles)(Training)
