import React, { Component } from 'react';
import moment from 'moment';
import "moment/locale/da";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    Tooltip,
    Typography
} from '@material-ui/core';
import ForumIcon from '@material-ui/icons/ForumRounded';
import SaveIcon from '@material-ui/icons/SaveRounded';
import IconButton from "@material-ui/core/IconButton";
import SaveAltIcon from '@material-ui/icons/SaveAltRounded';
import BookmarksIcon from '@material-ui/icons/BookmarksRounded';
import { Link as RouterLink } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getInitials } from "../../../../helpers/utils";
import CircularProgress from "@material-ui/core/CircularProgress";

moment.locale('da'); // it is required to select default locale manually

const styles: any = ((theme: Theme) => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1050
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    actionCol: {},
    tableHeaderButton: {
        height: "20px",
        width: "20px",
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        float: "right"
    }
}));

export type DialogsTableProps = {
    ref: any,
    isLoading: boolean,
    getSearchParams: any,
    onSelectBookmark: (params: any) => void
    onExport: any,
    onRowsPerPageChange: any,
    onPageChange: any,
    dialogs: any[],
    total: number,
    classes: any
};

export type DialogsTableState = {
    rowsPerPage: number,
    page: number,
    isSearchBookmarkOpen: boolean,
    isSearchSaveOpen: boolean,
    searchSaveName: string,
    values: any
};


class DialogsTable extends Component<DialogsTableProps, DialogsTableState> {

    constructor(props: DialogsTableProps) {
        super(props);

        this.state = {
            rowsPerPage: 10,
            page: 0,
            isSearchSaveOpen: false,
            isSearchBookmarkOpen: false,
            searchSaveName: "",
            values: {
                dialogName: "",
                dialogNameStored: -1
            }
        };
    }

    handleSaveDialogOpen() {
        this.setState({ isSearchSaveOpen: true });
    }

    handleChange(event: any) {
        this.setState({ values: { ...this.state.values, [event.target.name]: event.target.value } })
    }

    handleSaveDialog() {

        let storedSearch: any = localStorage.getItem("dialogSearches");

        if (!storedSearch) {
            storedSearch = {
                queries: []
            }
        }
        else {
            storedSearch = JSON.parse(storedSearch);
        }

        // Get params
        storedSearch.queries.push({
            name: this.state.values.dialogName,
            time: Date.now(),
            params: this.props.getSearchParams()
        });

        localStorage.setItem("dialogSearches", JSON.stringify(storedSearch));

        this.setState({ values: { ...this.state.values, dialogName: "" } });

        this.handleSaveDialogClose();
    }

    handleSaveDialogClose() {
        this.setState({ isSearchSaveOpen: false });
    }

    handleBookmarkDialogOpen() {
        this.setState({ isSearchBookmarkOpen: true });
    }

    handleBookmarkDialogClose() {
        this.setState({ isSearchBookmarkOpen: false });
    }

    handleBookmarkDialogSelect() {

        if (this.state.values.dialogNameStored === -1) {
            this.setState({ isSearchBookmarkOpen: false });
            return;
        }

        let storedSearch: any = localStorage.getItem("dialogSearches");

        if (!storedSearch) {
            this.setState({ isSearchBookmarkOpen: false });
            return;
        }
        storedSearch = JSON.parse(storedSearch);

        if (!storedSearch || !storedSearch.queries || !Array.isArray(storedSearch.queries)) {
            this.setState({ isSearchBookmarkOpen: false });
            return;
        }

        this.props.onSelectBookmark(storedSearch.queries[this.state.values.dialogNameStored].params);
        this.setState({ isSearchBookmarkOpen: false });
    }

    handleExport() {
        this.props.onExport();
    }

    changePage(page: number, rowsPerPage: number) {
        this.setState({ rowsPerPage: rowsPerPage, page: page });
    }

    handlePageChange(event: any, page: any) {
        this.setState({ page: page });
        this.props.onPageChange(page, this.state.rowsPerPage);
    }

    handleRowsPerPageChange(event: any) {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
        this.props.onRowsPerPageChange(0, event.target.value);
    }

    getStoredSearchQueries() {

        let storedSearch: any = localStorage.getItem("dialogSearches");

        if (!storedSearch)
            return;

        storedSearch = JSON.parse(storedSearch);

        if (!storedSearch || !storedSearch.queries || !Array.isArray(storedSearch.queries))
            return;

        return storedSearch.queries.slice(0, storedSearch.queries.length).map((query: any, index: number) => {
            return <MenuItem key={index} value={index}>{query.name} - {moment(query.time).format('HH:mm DD/MM/YYYY')}</MenuItem>
        });
    }

    render() {
        return (
            <div>
                <Card>
                    <CardContent className={this.props.classes.content}>
                        <PerfectScrollbar>
                            <div className={this.props.classes.inner}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                <Tooltip title="Eksporter side">
                                                    <IconButton disabled={this.props.total === 0} aria-label="eksport side"
                                                        color="primary"
                                                        onClick={this.handleExport.bind(this)}
                                                        className={this.props.classes.tableHeaderButton}>
                                                        <SaveAltIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Find gemt søgning">
                                                    <IconButton aria-label="find gemt søgning" color="primary"
                                                        onClick={this.handleBookmarkDialogOpen.bind(this)}
                                                        className={this.props.classes.tableHeaderButton}>
                                                        <BookmarksIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Gem søgning">
                                                    <IconButton aria-label="gem søgning" color="primary"
                                                        onClick={this.handleSaveDialogOpen.bind(this)}
                                                        className={this.props.classes.tableHeaderButton}>
                                                        <SaveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Kommune</TableCell>
                                            <TableCell>Samtale id</TableCell>
                                            <TableCell>Antal beskeder totalt</TableCell>
                                            <TableCell>Antal borger beskeder</TableCell>
                                            <TableCell>Tidspunkt</TableCell>
                                            <TableCell>Callback</TableCell>
                                            <TableCell className={this.props.classes.actionCol}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!this.props.isLoading ? this.props.dialogs.slice(0, this.state.rowsPerPage).map((dialog: any) => (
                                            <TableRow
                                                className={this.props.classes.tableRow}
                                                hover
                                                key={dialog.sessionId}>
                                                <TableCell>
                                                    <div className={this.props.classes.nameContainer}>
                                                        <Avatar
                                                            className={this.props.classes.avatar}
                                                            src={dialog.avatarUrl}>
                                                            {getInitials(dialog.municipality.name)}
                                                        </Avatar>
                                                        <Typography
                                                            variant="body1">{dialog.municipality.name}</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell>{dialog.sessionId}</TableCell>
                                                <TableCell>
                                                    {dialog.msgCount}
                                                </TableCell>
                                                <TableCell>
                                                    {dialog.msgCountUser}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(dialog.startTime).format('HH:mm:ss DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell>
                                                    {dialog.states && dialog.states.puzzelState > 0 ? 'Ja' : 'Nej'}
                                                </TableCell>
                                                <TableCell>
                                                    <RouterLink to={"/dialogs/" + dialog.sessionId}>
                                                        <IconButton color="secondary" aria-label="se dialog"
                                                            style={{ float: "right" }}>
                                                            <ForumIcon />
                                                        </IconButton>
                                                    </RouterLink>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow><TableCell colSpan={12} align={"center"}><CircularProgress /></TableCell></TableRow>}
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    </CardContent>
                    <CardActions className={this.props.classes.actions}>
                        <TablePagination
                            labelRowsPerPage="Rækker pr. side"
                            labelDisplayedRows={({ from, to, count }) => `${from > count ? count : from}-${to} af ${count}`}
                            component="div"
                            count={this.props.total}
                            onChangePage={this.handlePageChange.bind(this)}
                            onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                            page={this.state.page}
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                        />
                    </CardActions>
                </Card>
                <Dialog
                    open={this.state.isSearchSaveOpen}
                    onClose={this.handleSaveDialogClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Gem søgning"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Indtast et navn som den nuværende søgning skal gemmes under.
                        </DialogContentText>
                        <TextField required fullWidth
                            name="dialogName"
                            value={this.state.values.dialogName}
                            onChange={this.handleChange.bind(this)}
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Navn på søgning"
                            variant="outlined" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSaveDialogClose.bind(this)} color="primary" autoFocus>
                            Annuller
                        </Button>
                        <Button
                            disabled={!this.state.values.dialogName || this.state.values.dialogName.trim() === ""}
                            onClick={this.handleSaveDialog.bind(this)} color="secondary">
                            Gem
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isSearchBookmarkOpen}
                    onClose={this.handleBookmarkDialogClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Vælg gemt søgning"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Vælg en gemt søgning som skal anvendes.
                        </DialogContentText>
                        <TextField required fullWidth
                            name="dialogNameStored"
                            value={this.state.values.dialogNameStored}
                            onChange={this.handleChange.bind(this)}
                            className={this.props.classes.textField}
                            select
                            rows={4}
                            label="Vælg gemt søgning"
                            variant="outlined">
                            <MenuItem disabled key={-1} value={-1}>Vælg</MenuItem>
                            {this.getStoredSearchQueries()}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleBookmarkDialogClose.bind(this)} color="primary" autoFocus>
                            Annuller
                        </Button>
                        <Button disabled={this.state.values.dialogNameStored === -1} onClick={this.handleBookmarkDialogSelect.bind(this)} color="secondary">
                            Vælg
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(DialogsTable);
