import React, { Component } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withStyles } from "@material-ui/styles";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import ForumIcon from "@material-ui/icons/ForumRounded";
import IconButton from "@material-ui/core/IconButton";
import { Link as RouterLink } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getInitials } from "../../../../helpers/utils";
import DeleteIcon from "@material-ui/icons/Delete";

const styles: any = (theme: Theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  actionCol: {
    width: "120px",
  },
});

export type FeedbackTableProps = {
  total: number;
  onPageChange: any;
  onRowsPerPageChange: any;
  isLoading: boolean;
  ref: any;
  classes: any;
  ratings: any[];
  onDelete: (context: any) => Promise<any>;
};

export type FeedbackTableState = {
  page: number;
  rowsPerPage: number;
  selectedFeedback: any;
  deleteModalOpen: boolean;
};

class FeedbackTable extends Component<FeedbackTableProps, FeedbackTableState> {
  constructor(props: FeedbackTableProps) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      selectedFeedback: {},
      deleteModalOpen: false,
    };
  }

  handleDeleteModalOpen(feedback: any): void {
    this.setState({ selectedFeedback: feedback, deleteModalOpen: true });
  }

  handleDeleteModalClose(): void {
    this.setState({ deleteModalOpen: false });
  }

  async handleDelete() {
    this.handleDeleteModalClose();
    await this.props.onDelete(this.state.selectedFeedback);
  }

  changePage(page: number, rowsPerPage: number) {
    this.setState({ rowsPerPage: rowsPerPage, page: page });
  }

  handlePageChange(event: any, page: any) {
    this.setState({ page: page });
    this.props.onPageChange(page, this.state.rowsPerPage);
  }

  handleRowsPerPageChange(event: any) {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
    this.props.onRowsPerPageChange(0, event.target.value);
  }

  render() {
    return (
      <div>
        <Card className={this.props.classes.root}>
          <CardContent className={this.props.classes.content}>
            <PerfectScrollbar>
              <div className={this.props.classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Kommune</TableCell>
                      <TableCell>Tidspunkt</TableCell>
                      <TableCell>Rating</TableCell>
                      <TableCell>Kommentar</TableCell>
                      <TableCell>Scope</TableCell>
                      <TableCell
                        className={this.props.classes.actionCol}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!this.props.isLoading ? (
                      this.props.ratings
                        .slice(0, this.state.rowsPerPage)
                        .map((feedback: any) => (
                          <TableRow
                            className={this.props.classes.tableRow}
                            hover
                            key={feedback.sessionId}
                          >
                            <TableCell>
                              <div className={this.props.classes.nameContainer}>
                                <Avatar
                                  className={this.props.classes.avatar}
                                  src={feedback.avatarUrl}
                                >
                                  {getInitials(feedback.log.municipality.name)}
                                </Avatar>
                                <Typography variant="body1">
                                  {feedback.log.municipality.name}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell>
                              {moment(feedback.log.startTime).format(
                                "hh:mm:ss DD/MM/YYYY"
                              )}
                            </TableCell>
                            <TableCell>{feedback.ratingScore}</TableCell>
                            <TableCell>{feedback.ratingComment}</TableCell>
                            <TableCell>
                              {feedback.outOfScope ? (
                                <Chip
                                  size="small"
                                  label="Udenfor scope"
                                  color="primary"
                                />
                              ) : (
                                <Chip size="small" label="Indenfor scope" />
                              )}
                            </TableCell>
                            <TableCell>
                              <Box display="flex">
                                <RouterLink
                                  to={"/dialogs/" + feedback.sessionId}
                                >
                                  <IconButton
                                    color="secondary"
                                    aria-label="se dialog"
                                  >
                                    <ForumIcon />
                                  </IconButton>
                                </RouterLink>
                                <IconButton
                                  color="secondary"
                                  aria-label="slet dialog"
                                  onClick={() =>
                                    this.handleDeleteModalOpen(
                                      feedback.sessionId
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align={"center"}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={this.props.classes.actions}>
            <TablePagination
              labelRowsPerPage="Rækker pr. side"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} af ${count}`
              }
              component="div"
              count={this.props.total}
              onChangePage={this.handlePageChange.bind(this)}
              onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
            />
          </CardActions>
        </Card>
        <Dialog
          open={this.state.deleteModalOpen}
          onClose={this.handleDeleteModalClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Bekræft sletning af feedback"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på du ønsker at slette denne feedback permanent
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDeleteModalClose.bind(this)}
              color="primary"
              autoFocus
            >
              Annuller
            </Button>
            <Button onClick={this.handleDelete.bind(this)} color="secondary">
              Slet
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(FeedbackTable);
