import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Avatar, Divider, List, ListItem, ListItemText, Theme, Typography} from '@material-ui/core';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import QuestionAnswerRoundedIcon from "@material-ui/icons/QuestionAnswerRounded";
import {ActionCard} from "../index";

const styles: any = ((theme: Theme) => ({
  supportListItem: {
    cursor: "pointer"
  },
  supportAvatar: {
    backgroundColor: theme.palette.primary.main
  },
  supportTitle: {
    fontWeight: 500,
    fontSize: "14px"
  },
}));

export type SupportProps = {
  classes: any
};

export type SupportState = {};

class Support extends Component<SupportProps, SupportState>{

  constructor(props: SupportProps) {
    super(props);

    this.state = {};
  }

  openLink(link: string) {
    window.open(link, "_blank");
  }

  render() {
    return (
        <ActionCard
            title={"Chatbot support"}
            description={"Gennem denne chatbot support kontakter du chatbot teamet direkte. Klik på den af de nedenstående knapper, som din henvendelse vedrører."}
            content={
            <div>
              <List dense={false}>
                <ListItem className={this.props.classes.supportListItem}
                          onClick={() => this.openLink("https://munikommunesupport.hipporello.net/desk/form/69d0e37f0c5b4534b22071a3fbc84653/c6c82dfd8a4b4ad19e9c5095b96e48bd")}>
                  <ListItemAvatar>
                    <Avatar variant="rounded" className={this.props.classes.supportAvatar}>
                      <QuestionAnswerRoundedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                      primary={
                        <Typography variant="subtitle1" className={this.props.classes.supportTitle}>
                          Anmodning om oprettelse af nyt område i Muni
                        </Typography>
                      }
                      secondary="Her kan en kommune anmode om oprettelse af nye områder direkte til chatbot teamet. Dette kan f.eks. være hvis en kommune oplever mange borgerhenvendelser til et område, som endnu ikke er tilgængeligt i Muni."
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem className={this.props.classes.supportListItem}
                          onClick={() => this.openLink("https://munikommunesupport.hipporello.net/desk/form/25cc60f3f56d429d867fbe85fc4272fe/f46909beb8aa4d95a57585cce0db97a9")}>
                  <ListItemAvatar>
                    <Avatar variant="rounded" className={this.props.classes.supportAvatar}>
                      <QuestionAnswerRoundedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                      primary={
                        <Typography variant="subtitle1" className={this.props.classes.supportTitle}>
                          Indberetning af fejl eller udbedring af dialog
                        </Typography>
                      }
                      secondary="Her kan en kommune indberette fejl direkte til chatbot teamet. Dette kan både være fejl i dialogsvarene eller ønske om udbedring af svarene."
                  />
                </ListItem>
              </List>
            </div>
          }
        />
    );
  }
}

export default withStyles(styles)(Support);
