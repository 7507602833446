import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Avatar, Button, CircularProgress, Grid, Hidden, TextField, Theme, Typography} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {AuthService} from "../../service/AuthService";
import {browserHistory} from "../../App";
import validate from "validate.js";
import {setState} from "../../common/promisify";
import {Log} from "../../common/log";

const schema: any = {
  email: {
    presence: { allowEmpty: false, message: 'Email skal udfyldes' },
    email: {
      message: "Indtast en gyldig email",
    },
    length: {
      maximum: 200
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'Adgangskode skal udfyldes' },
    length: {
      minimum: 8,
      maximum: 128,
      message: "En adgangskode er minimum 8 karakter lang"
    }
  },
};

const styles: any = ((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  largeCircle: {
    alignSelf: 'center',
    backgroundColor: theme.palette.background.default,
    width: theme.spacing(49),
    height: theme.spacing(49),
  },
  mediumCircle: {
    alignSelf: 'center',
    backgroundColor: theme.palette.background.default,
    width: theme.spacing(26),
    height: theme.spacing(26),
  },
  mediumIcon: {
    width: theme.spacing(48),
    height: theme.spacing(48),
  },
  smallIcon: {
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBodyIconHeader: {
    paddingTop: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  signInButtonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

export type SignInProps = {
  classes?: any,
  history: any
};

export type SignInState = {
  isLoading: boolean,
  alert: any,
  isAlertLoginFailedOpen: boolean,
  formState: {
    isValid: boolean,
    values: any,
    touched: any,
    errors: any
  }
};

class SignIn extends Component<SignInProps, SignInState> {

  private service: AuthService = new AuthService();

  constructor(props: SignInProps) {
    super(props);

    this.state = {
      isLoading: false,
      alert: "",
      isAlertLoginFailedOpen: false,
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      }
    };
  }


  doValidationEffect() {
    const errors = validate(this.state.formState.values, schema,{ fullMessages: false });

    this.setState({
      formState: {
        ...this.state.formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }
    });
  }

  async handleChange(event: any){
    event.persist();

    await setState(this, {
      formState: {
        ...this.state.formState,
        values: {
          ...this.state.formState.values,
          [event.target.name]:
              event.target.type === 'checkbox'
                  ? event.target.checked
                  : event.target.value
        },
        touched: {
          ...this.state.formState.touched,
          [event.target.name]: true
        }
      }
    });
    this.doValidationEffect();
  }

  async handleSignIn(event: any) {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.service.signIn(this.state.formState.values.email, this.state.formState.values.password);
      const profile = await this.service.getUserProfile();
      localStorage.setItem("profile", JSON.stringify(profile));

      let lastPage: string = sessionStorage.getItem("lastPage") || "";
      if(lastPage && lastPage !== "") {
        browserHistory.push(lastPage);
      }
      else{
        browserHistory.push('/dashboard');
      }
    }
    catch (e) {
      this.setState({ isAlertLoginFailedOpen: true });
      Log.info("SignIn failed!");
    }

    setTimeout(()=> this.setState({ isLoading: false }), 500);
  }


  hasError(field: any) {
    return this.state.formState.touched[field] && this.state.formState.errors[field] ? true : false;
  }


  render() {
  return (
      <div className={this.props.classes.root}>
        <Grid className={this.props.classes.grid} container>
          <Grid container
                spacing={0}
                alignItems="center"
                justify="center" className={this.props.classes.quoteContainer} lg={5}>
            <div className={this.props.classes.quote}>
              <div className={this.props.classes.quoteInner}>
                <Avatar className={this.props.classes.largeCircle}>
                  <img alt="avatar" src="/images/avatars/avatar_icon_blue.svg" className={this.props.classes.mediumIcon}/>
                </Avatar>
              </div>
            </div>
          </Grid>
          <Grid className={this.props.classes.content} item xs={12} lg={7}>
            <div className={this.props.classes.content}>
              <div className={this.props.classes.contentBody}>
                <form
                    className={this.props.classes.form}
                    onSubmit={this.handleSignIn.bind(this)}>
                  <div className={this.props.classes.contentBodyIconHeader}>
                    <Hidden lgUp>
                      <Avatar className={this.props.classes.mediumCircle}>
                        <img alt="avatar" src="/images/avatars/avatar_icon_blue.svg" className={this.props.classes.smallIcon}/>
                      </Avatar>
                    </Hidden>
                  </div>
                  <Typography
                      className={this.props.classes.title}
                      variant="h2">
                    Log på
                  </Typography>
                  <Typography
                      color="textSecondary"
                      gutterBottom>
                    Administrationsmodul
                  </Typography>
                  <TextField
                      className={this.props.classes.textField}
                      error={this.hasError('email')}
                      fullWidth
                      helperText={
                        this.hasError('email') ? this.state.formState.errors.email[0] : null
                      }
                      label="Email adresse"
                      name="email"
                      onChange={this.handleChange.bind(this)}
                      type="text"
                      value={this.state.formState.values.email || ''}
                      variant="outlined"/>
                  <TextField
                      className={this.props.classes.textField}
                      error={this.hasError('password')}
                      fullWidth
                      helperText={
                        this.hasError('password') ? this.state.formState.errors.password[0] : null
                      }
                      label="Adgangskode"
                      name="password"
                      onChange={this.handleChange.bind(this)}
                      type="password"
                      value={this.state.formState.values.password || ''}
                      variant="outlined"/>
                  <div className={this.props.classes.wrapper}>
                    <Button
                        className={this.props.classes.signInButton}
                        color="primary"
                        disabled={!this.state.formState.isValid || this.state.isLoading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained">
                      Log på
                    </Button>
                    {this.state.isLoading && <CircularProgress size={24} className={this.props.classes.signInButtonProgress} />}
                  </div>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
        <Snackbar open={this.state.isAlertLoginFailedOpen} autoHideDuration={10000}
                  anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  onClose={() => this.setState({ isAlertLoginFailedOpen: false })}>
          <Alert severity="error">
            Log på fejlet pga. forkert brugernavn eller adgangskode.
          </Alert>
        </Snackbar>
      </div>
  );
}
}

export default withStyles(styles)(SignIn);
