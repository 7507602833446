import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Theme} from '@material-ui/core';

import {Password, Roles} from './components';
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    }
}));

export type SettingsProps = { classes: any };

export type SettingsState = { isAlertOpen: boolean, alert: any };


class Settings extends Component<SettingsProps, SettingsState> {

    constructor(props: SettingsProps) {
        super(props);
        this.state = {
            isAlertOpen: false,
            alert: undefined
        }
    }

    handleSave(success: boolean, text: string) {
        this.setState({ isAlertOpen: true, alert:
                <Alert severity={success ? "success" : "error"}>{text}</Alert>
        });
    }
    render(){
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="textPrimary">Indstillinger</Typography>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer}/>
                </div>
                <Grid container spacing={4}>
                    <Grid item md={7} xs={12}>
                        <Password onSave={this.handleSave.bind(this)} />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Roles />
                    </Grid>
                </Grid>
                <Snackbar open={this.state.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                          onClose={() => this.setState({ isAlertOpen: false })}>
                    {this.state.alert}
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(styles)(Settings);

