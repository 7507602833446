import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Checkbox, Grid, IconButton, Theme, Tooltip } from "@material-ui/core";
import { SearchInput } from "../../../../components";
import TextField from "@material-ui/core/TextField";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/da";
import MomentUtils from "@date-io/moment";
import { DateRange, FilterList } from "@material-ui/icons";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import { StatisticsAPIService } from "../../../../service/StatisticsAPIService";

moment.locale("da"); // it is required to select default locale manually

const styles = (theme: Theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  paper: {
    borderRadius: "4px",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexBasis: 420,
  },
  dateTimePicker: {
    marginRight: "8px",
  },
});

export type DialogsToolbarProps = {
  onFilterParamsChange: (filter: any) => void;
  filter: {
    searchText: string;
    municipalityCode: number;
    hasCallback: number;
    sortBy: number;
    timeRangeStart: Date;
    timeRangeEnd: Date;
    fromSender: number;
    subject: string;
  };
  municipalities: any[];
  onSearch: any;
  classes: any;
};

export type DialogsToolbarState = {
  isDateRangeEnabled: boolean;
  isFilterEnabled: boolean;
  lastSearch: string;
  subjects: any[];
};

class DialogsToolbar extends Component<
  DialogsToolbarProps,
  DialogsToolbarState
> {
  constructor(props: DialogsToolbarProps) {
    super(props);

    this.state = {
      isDateRangeEnabled: false,
      isFilterEnabled: false,
      lastSearch: "",
      subjects: [],
    };
  }

  componentDidMount(): void {
    this.getSubjects(this.props.filter.subject);
  }

  search(query: string) {
    this.setState({ ...this.state, lastSearch: query });
    this.props.onSearch();
  }

  maybeSearch(query: string) {
    if (query === this.state.lastSearch) return;
    this.search(query);
  }

  async handleSearchTextChange(event: any) {
    this.props.onFilterParamsChange({
      ...this.props.filter,
      searchText: event.target.value,
    });
  }

  async getSubjects(municipalityCode: string) {
    const municipality: any[] = this.props.municipalities.filter(
      (el: any) => `${el.code}` === municipalityCode
    );
    const municipalityId =
      municipality && municipality.length > 0 ? municipality[0].id : -1;
    this.setState({ ...this.state, subjects: [] });
    const api = new StatisticsAPIService();
    const data = await api.getSubjects(
      municipalityId,
      new Date(2019, 1, 1),
      new Date()
    );
    this.setState({
      ...this.state,
      subjects: data.subjects.map((s: any) => s.subject).sort(),
    });
  }

  handleDateRangeEnabledChange(event: any) {
    this.setState({ isDateRangeEnabled: event.target.checked });
    this.handleRefreshClick();
  }

  handleFilterEnabledChange(event: any) {
    this.setState({ isFilterEnabled: event.target.checked });
    this.handleRefreshClick();
  }

  handleFilterChange(event: any): void {
    const name = event.target.name;
    const value = event.target.value;
    this.props.onFilterParamsChange({
      ...this.props.filter,
      [name]: value,
    });
    if (name === "municipalityCode") {
      this.getSubjects(value);
    }
    this.handleRefreshClick();
  }

  handleDateFilterChange(name: string, date: any): void {
    this.props.onFilterParamsChange({
      ...this.props.filter,
      [name]: new Date(Date.parse(date)),
    });
    this.handleRefreshClick();
  }

  handleRefreshClick() {
    // Ensure we trigger after this event loop
    setTimeout(() => {
      this.search(this.props.filter.searchText);
    }, 0);
  }

  renderDateRange() {
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="da">
          <DateTimePicker
            name="timeRangeStart"
            className={this.props.classes.dateTimePicker}
            autoOk
            ampm={false}
            value={this.props.filter.timeRangeStart}
            disableFuture
            onChange={(date) =>
              this.handleDateFilterChange("timeRangeStart", date)
            }
            cancelLabel="Annuller"
            label="Start periode"
          />
          <DateTimePicker
            name="timeRangeEnd"
            className={this.props.classes.dateTimePicker}
            autoOk
            ampm={false}
            value={this.props.filter.timeRangeEnd}
            disableFuture
            onChange={(date) =>
              this.handleDateFilterChange("timeRangeEnd", date)
            }
            cancelLabel="Annuller"
            label="Slut periode"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }

  renderMunicipalityFilter() {
    return (
      <Grid item lg={3} md={4} sm={6} xl={2} xs={12}>
        <TextField
          fullWidth
          inputProps={{ "aria-label": "Without label" }}
          label="Kommune"
          margin="dense"
          name="municipalityCode"
          onChange={this.handleFilterChange.bind(this)}
          placeholder=""
          select
          SelectProps={{ native: true }}
          value={this.props.filter.municipalityCode}
          variant="outlined"
        >
          <option key={0} value="0">
            Alle
          </option>
          {this.props.municipalities
            .slice(0, this.props.municipalities.length)
            .map((municipality) => {
              return (
                <option key={municipality.id} value={municipality.code}>
                  {municipality.name}
                </option>
              );
            })}
        </TextField>
      </Grid>
    );
  }

  renderSubjectFilter() {
    return (
      <Grid item lg={3} md={4} sm={6} xl={2} xs={12}>
        <TextField
          fullWidth
          select
          label="Område"
          margin="dense"
          name="subject"
          SelectProps={{ native: true }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={this.handleFilterChange.bind(this)}
          value={this.props.filter.subject}
          variant="outlined"
        >
          <option key={0} value={""}>
            Alle
          </option>
          {this.state.subjects.map((subject) => (
            <option key={subject} value={subject}>
              {subject}
            </option>
          ))}
        </TextField>
      </Grid>
    );
  }

  renderMessageTypeFilter() {
    return (
      <Grid item lg={3} md={4} sm={6} xl={2} xs={12}>
        <TextField
          fullWidth
          inputProps={{ "aria-label": "Without label" }}
          label="Søg fritekst for"
          margin="dense"
          name="fromSender"
          onChange={this.handleFilterChange.bind(this)}
          placeholder=""
          select
          SelectProps={{ native: true }}
          value={this.props.filter.fromSender}
          variant="outlined"
        >
          <option key={0} value="0">
            Alle beskeder
          </option>
          <option key={2} value="1">
            Kun beskeder fra borger
          </option>
          <option key={1} value="2">
            Kun beskeder fra bot
          </option>
        </TextField>
      </Grid>
    );
  }

  renderCallbackFilter() {
    return (
      <Grid item lg={3} md={4} sm={6} xl={2} xs={12}>
        <TextField
          select
          fullWidth
          name="hasCallback"
          inputProps={{ "aria-label": "Without label" }}
          label="Callback"
          margin="dense"
          onChange={this.handleFilterChange.bind(this)}
          placeholder=""
          SelectProps={{ native: true }}
          value={this.props.filter.hasCallback}
          variant="outlined"
        >
          <option key={0} value="0">
            Alle
          </option>
          <option key={1} value="1">
            Med callback
          </option>
          <option key={2} value="2">
            Uden callback
          </option>
        </TextField>
      </Grid>
    );
  }

  renderSortFilter() {
    return (
      <Grid item lg={3} md={4} sm={6} xl={2} xs={12}>
        <TextField
          fullWidth
          inputProps={{ "aria-label": "Without label" }}
          label="Sorter efter"
          margin="dense"
          name="sortBy"
          onChange={this.handleFilterChange.bind(this)}
          placeholder=""
          select
          SelectProps={{ native: true }}
          value={this.props.filter.sortBy}
          variant="outlined"
        >
          <option key={0} value="1">
            Tidspunkt (Nyeste)
          </option>
          <option key={1} value="0">
            Tidspunkt (Ældste)
          </option>
          <option key={2} value="3">
            Antal beskeder totalt (Faldende)
          </option>
          <option key={3} value="2">
            Antal beskeder totalt (Stigende)
          </option>
          <option key={4} value="5">
            Antal borger beskeder (Faldende)
          </option>
          <option key={5} value="4">
            Antal borger beskeder (Stigende)
          </option>
        </TextField>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <div className={this.props.classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Samtaler</Typography>
          </Breadcrumbs>
          <span className={this.props.classes.spacer} />
        </div>
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xl={12}
            xs={12}
            style={{ marginBottom: 12 }}
          >
            <div className={this.props.classes.row}>
              <SearchInput
                className={this.props.classes.searchInput}
                content={
                  <div>
                    <Tooltip title="Genindlæs">
                      <IconButton
                        aria-label="refresh"
                        color="primary"
                        onClick={this.handleRefreshClick.bind(this)}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Vis dato filter">
                      <Checkbox
                        checkedIcon={<DateRange />}
                        icon={<DateRange />}
                        onChange={this.handleDateRangeEnabledChange.bind(this)}
                      />
                    </Tooltip>
                    <Tooltip title="Vis flere filtre">
                      <Checkbox
                        checkedIcon={<FilterList />}
                        icon={<FilterList />}
                        onChange={this.handleFilterEnabledChange.bind(this)}
                      />
                    </Tooltip>
                  </div>
                }
                onChange={this.handleSearchTextChange.bind(this)}
                onBlur={(event) => this.maybeSearch(event.target.value)}
                placeholder="Søg i samtale"
                value={this.props.filter.searchText}
              />
            </div>
          </Grid>
          {this.state.isDateRangeEnabled && this.renderDateRange()}
          {this.state.isFilterEnabled && this.renderMunicipalityFilter()}
          {this.state.isFilterEnabled && this.renderSubjectFilter()}
          {this.state.isFilterEnabled && this.renderMessageTypeFilter()}
          {this.state.isFilterEnabled && this.renderCallbackFilter()}
          {this.state.isFilterEnabled && this.renderSortFilter()}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(DialogsToolbar);
