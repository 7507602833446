import React, {Component} from 'react';
import {Dialog, DialogContentText, Theme} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = ((theme: Theme) => ({
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}));

export type StartJobModalProps = {
    classes:any,
    isOpen: boolean,
    toggleModal: any,
    submit: () => any
};

export type StartJobModalState = { };

class StartJobModal extends Component<StartJobModalProps, StartJobModalState> {

    constructor(props: StartJobModalProps) {
        super(props);
        this.state = {}
    }

    async submit(): Promise<void> {
        await this.props.submit();
    }

    render() {
        return (
            <Dialog open={this.props.isOpen}
                onClose={this.props.toggleModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Start synkronisering med Selvbetjening.nu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Er du sikker på at du ønsker at starte et nyt job som foretager synkronisering med Selvbetjening.nu?
                        <br/><br/>
                        <i>Bemærk når processen startes kan det tage op til 5 minutter før det er gennemført og herefter skal siden genindlæses før resultatet af kørslen vil fremgå af listen.</i>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.toggleModal} color="secondary">Annuller</Button>
                    <Button
                        onClick={this.submit.bind(this)}
                        color="primary">Start</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(StartJobModal);