import React, { Component } from "react";
import { Theme } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import { setState } from "../../../../common/promisify";
import { User } from "../../../../helpers/profile";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateRange, SaveAlt } from "@material-ui/icons";
import moment from "moment";
import { StatisticsAPIService } from "../../../../service/StatisticsAPIService";

const styles = (theme: Theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  dateTimePicker: {
    marginTop: "8px",
    marginRight: "8px",
  },
});

export type MunicipalitySelectionProps = {
  ref: any;
  municipalities: any[];
  classes: any;
  onMunicipalitySelected: (municipalityCode: number) => void;
  onSubjectSelected: (subject: string) => void;
  onDateRangeSelected: (
    timeRangeStart: Date,
    timeRangeEnd: Date,
    period: TimePeriod
  ) => void;
  onRefresh: () => void;
  onExport?: () => void;
  isRefreshEnabled: boolean;
  hideSubjects?: boolean;
};
export type TimePeriod =
  | "custom"
  | "thisDay"
  | "thisWeek"
  | "thisMonth"
  | "thisYear"
  | "prevDay"
  | "prevWeek"
  | "prevMonth"
  | "prevYear";
export type MunicipalitySelectionState = {
  values: {
    municipality: number;
    timeRangeStart: Date;
    timeRangeEnd: Date;
    period: TimePeriod;
    subject: string;
  };
  isDateRangeEnabled: boolean;
  subjects: any[];
};

class MunicipalitySelection extends Component<
  MunicipalitySelectionProps,
  MunicipalitySelectionState
> {
  constructor(props: MunicipalitySelectionProps) {
    super(props);

    const currentDate = new Date();
    this.state = {
      isDateRangeEnabled: false,
      values: {
        municipality: 0,
        subject: "",
        timeRangeStart: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ),
        timeRangeEnd: currentDate,
        period: "thisMonth",
      },
      subjects: [],
    };
  }

  async getSubjects(municipalityCode: string) {
    const municipality: any[] = this.props.municipalities.filter(
      (el: any) => `${el.code}` === municipalityCode
    );
    const municipalityId =
      municipality && municipality.length > 0 ? municipality[0].id : -1;
    this.setState({ ...this.state, subjects: [] });
    const api = new StatisticsAPIService();
    const data = await api.getSubjects(
      municipalityId,
      new Date(2019, 1, 1),
      new Date()
    );
    this.setState({
      ...this.state,
      subjects: data.subjects.map((s: any) => s.subject).sort(),
    });
  }

  async handleChange(event: any) {
    const name = event.target.name;
    const value = event.target.value;
    await setState(this, { values: { ...this.state.values, [name]: value } });
    if (name === "municipality") {
      if (!this.props.hideSubjects) this.getSubjects(value);
      this.props.onMunicipalitySelected(value);
    } else if (name === "subject") {
      this.props.onSubjectSelected(value);
    }
  }

  handleChangePeriod(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const timePeriods: Record<TimePeriod, { start: Date; end: Date }> = {
      custom: {
        start: new Date(this.state.values.timeRangeStart),
        end: new Date(this.state.values.timeRangeEnd),
      },
      thisDay: {
        start: moment().startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      },
      thisMonth: {
        start: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
      },
      thisWeek: {
        start: moment().startOf("week").toDate(),
        end: moment().endOf("week").toDate(),
      },
      thisYear: {
        start: moment().startOf("year").toDate(),
        end: moment().endOf("year").toDate(),
      },
      prevDay: {
        start: moment().subtract(1, "day").startOf("day").toDate(),
        end: moment().subtract(1, "day").endOf("day").toDate(),
      },
      prevMonth: {
        start: moment().subtract(1, "month").startOf("month").toDate(),
        end: moment().subtract(1, "month").endOf("month").toDate(),
      },
      prevWeek: {
        start: moment().subtract(1, "week").startOf("week").toDate(),
        end: moment().subtract(1, "week").endOf("week").toDate(),
      },
      prevYear: {
        start: moment().subtract(1, "year").startOf("year").toDate(),
        end: moment().subtract(1, "year").endOf("year").toDate(),
      },
    };
    const selectedPeriod = event.target.value as TimePeriod;
    const timePeriod = timePeriods[selectedPeriod];
    this.setState(
      {
        ...this.state,
        isDateRangeEnabled:
          selectedPeriod === "custom" ? true : this.state.isDateRangeEnabled,
        values: {
          ...this.state.values,
          timeRangeStart: timePeriod.start,
          timeRangeEnd: new Date(
            Math.max(
              Math.min(new Date().getTime(), timePeriod.end.getTime()),
              0
            )
          ), // Compute the maxmin -> we allow max this date
          period: event.target.value as TimePeriod,
        },
      },
      () => {
        this.props.onDateRangeSelected(
          this.state.values.timeRangeStart,
          this.state.values.timeRangeEnd,
          this.state.values.period
        );
      }
    );
  }

  handleRefreshClick() {
    this.props.onRefresh();
  }

  handleDateRangeEnabledChange(event: any) {
    this.setState({ isDateRangeEnabled: event.target.checked });
  }

  async handleDateFilterChange(name: string, date: any) {
    this.setState(
      {
        values: {
          ...this.state.values,
          [name]: new Date(Date.parse(date)),
          period: "custom",
        },
      },
      () => {
        this.props.onDateRangeSelected(
          this.state.values.timeRangeStart,
          this.state.values.timeRangeEnd,
          this.state.values.period
        );
      }
    );
  }

  setValues(
    municipality: number,
    timeStart: Date,
    timeEnd: Date,
    period: TimePeriod
  ) {
    this.setState({
      values: {
        ...this.state.values,
        municipality: municipality,
        timeRangeStart: timeStart,
        timeRangeEnd: timeEnd,
        period: period || "custom",
      },
    });
  }

  renderDateRange() {
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="da">
          <DateTimePicker
            name="timeRangeStart"
            className={this.props.classes.dateTimePicker}
            autoOk
            ampm={false}
            value={this.state.values.timeRangeStart}
            disableFuture
            onChange={(date) =>
              this.handleDateFilterChange("timeRangeStart", date)
            }
            cancelLabel="Annuller"
            label="Start periode"
          />
          <DateTimePicker
            name="timeRangeEnd"
            className={this.props.classes.dateTimePicker}
            autoOk
            ampm={false}
            value={this.state.values.timeRangeEnd}
            disableFuture
            onChange={(date) =>
              this.handleDateFilterChange("timeRangeEnd", date)
            }
            cancelLabel="Annuller"
            label="Slut periode"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <Card>
          <CardContent>
            <Grid container justify="space-between" direction="column">
              <Grid item>
                <Typography
                  className={this.props.classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  VÆLG KOMMUNE
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  minWidth: "300px",
                  minHeight: "60px",
                }}
              >
                <TextField
                  select
                  style={{ minWidth: "250px" }}
                  label="Kommune"
                  margin="dense"
                  name="municipality"
                  onChange={this.handleChange.bind(this)}
                  required
                  SelectProps={{ native: true }}
                  value={this.state.values.municipality}
                  variant="outlined"
                >
                  <option key={0} value={0} disabled>
                    Vælg
                  </option>
                  {User.isGlobalAdmin() && (
                    <option key={1} value={-1}>
                      Alle
                    </option>
                  )}
                  {this.props.municipalities.map((municipality: any) => (
                    <option key={municipality.code} value={municipality.code}>
                      {municipality.name}
                    </option>
                  ))}
                </TextField>

                <TextField
                  select
                  style={{ minWidth: "250px", marginLeft: "8px" }}
                  label="Periode"
                  margin="dense"
                  name="timePeriod"
                  SelectProps={{ native: true }}
                  variant="outlined"
                  onChange={this.handleChangePeriod.bind(this)}
                  value={this.state.values.period}
                >
                  <option value="thisDay">I dag</option>
                  <option value="prevDay">I går</option>
                  <option value="thisWeek">Denne uge</option>
                  <option value="prevWeek">Sidste uge</option>
                  <option value="thisMonth">Denne måned</option>
                  <option value="prevMonth">Sidste måned</option>
                  <option value="thisYear">Dette år</option>
                  <option value="prevYear">Sidste år</option>
                  <option value="custom">Valgfri</option>
                </TextField>

                {!this.props.hideSubjects && (
                  <TextField
                    select
                    style={{ minWidth: "250px", marginLeft: "8px" }}
                    label="Område"
                    margin="dense"
                    name="subject"
                    SelectProps={{ native: true }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleChange.bind(this)}
                    value={this.state.values.subject}
                  >
                    <option key={0} value={""}>
                      Alle
                    </option>
                    {this.state.subjects.map((subject) => (
                      <option key={subject} value={subject}>
                        {subject}
                      </option>
                    ))}
                  </TextField>
                )}

                <Tooltip title="Søg">
                  <IconButton
                    aria-label="refresh"
                    color="primary"
                    disabled={!this.props.isRefreshEnabled}
                    onClick={this.handleRefreshClick.bind(this)}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Vis dato filter">
                  <Checkbox
                    checkedIcon={<DateRange />}
                    icon={<DateRange />}
                    checked={this.state.isDateRangeEnabled}
                    onChange={this.handleDateRangeEnabledChange.bind(this)}
                  />
                </Tooltip>
                {this.props.onExport && (
                  <Box
                    paddingLeft="8px"
                    marginLeft="8px"
                    borderLeft="1px solid #eee"
                    display="inline-block"
                  >
                    <Tooltip title="Eksporter til excel">
                      <IconButton
                        aria-label="export"
                        color="primary"
                        onClick={this.props.onExport}
                      >
                        <SaveAlt />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {this.state.isDateRangeEnabled && this.renderDateRange()}
      </div>
    );
  }
}

export default withStyles(styles)(MunicipalitySelection);
