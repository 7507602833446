import React, {Component} from 'react';
import {
    CardActions,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme
} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import "moment/locale/da";
import CircularProgress from "@material-ui/core/CircularProgress";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {},
    actionCol: {
        width: "5%"
    },
    actions: {
        justifyContent: 'flex-end'
    },
    button: {
      marginLeft: "4px"
    },

}));

export type SubjectItemTableProps = {
    isLoading: boolean,
    classes: any,
    subjectItems: any[],
    totalSubjects: number,
};

export type SubjectItemTableState = {
    rowsPerPage: number,
    page: number,
};

class SubjectItemTable extends Component<SubjectItemTableProps, SubjectItemTableState> {

    constructor(props: SubjectItemTableProps) {
        super(props);

        this.state = {
            rowsPerPage: 10,
            page: 0
        };
    }

    changePage(page: number, rowsPerPage: number) {
        this.setState({ rowsPerPage: rowsPerPage, page: page });
    }

    handlePageChange(event: any, page: any) {
        this.setState({ page: page });
    }

    handleRowsPerPageChange(event: any) {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    }

    getPartInTotalAsPct(count: string, total: number) {

        let countAsNum;
        try {
            countAsNum = parseInt(count, 10);
        }
        catch (e) {
            countAsNum = 0;
        }

        if (total === 0)
            return 0;

        const pctOfTotal = ((countAsNum / total) * 100);
        return pctOfTotal % 1 !== 0 ? pctOfTotal.toFixed(2) : pctOfTotal;
    }

    render(): any {
        return (
                <div>
                    <CardContent>
                            <PerfectScrollbar>
                                <div className={this.props.classes.inner}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Underområder</TableCell>
                                                <TableCell>Antal</TableCell>
                                                <TableCell>Procentfordeling</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                !this.props.isLoading ? this.props.subjectItems.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(item => (
                                                <TableRow
                                                    className={this.props.classes.tableRow}
                                                    hover
                                                    key={item.tagId}>
                                                    <TableCell>{item.subject}</TableCell>
                                                    <TableCell>{item.count}</TableCell>
                                                    <TableCell>{this.getPartInTotalAsPct(item.count, this.props.totalSubjects) + " %"}</TableCell>
                                                </TableRow>
                                            )) : <TableRow><TableCell colSpan={12} align={"center"}><CircularProgress/></TableCell></TableRow> }
                                        </TableBody>
                                    </Table>
                                </div>
                            </PerfectScrollbar>
                    </CardContent>
                    <CardActions className={this.props.classes.actions}>
                        <TablePagination
                            labelRowsPerPage="Rækker pr. side"
                            labelDisplayedRows={({from, to, count}) => `${from > count ? count : from}-${to} af ${count}`}
                            component="div"
                            count={this.props.subjectItems.length}
                            onChangePage={this.handlePageChange.bind(this)}
                            onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                            page={this.state.page}
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                        />
                    </CardActions>
            </div>
        );
    }

}

export default withStyles(styles)(SubjectItemTable)

