import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Avatar, Card, CardContent, Grid, Typography} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/DoneRounded';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: theme.spacing(7),
    width: theme.spacing(7)
  },
  avatarIcon: {
    height: theme.spacing(6.5),
    width: theme.spacing(6.5)
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const OperationStatus = (props: OperationStatusprops) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              SYSTEM STATUS
            </Typography>
            <Typography variant="h3">OK</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar} src="">
              <DoneIcon/>
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// OperationStatus.propTypes = {
//   className: PropTypes.string
// };

type OperationStatusprops = {
  className?: string,
};

export default OperationStatus;
