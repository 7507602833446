import React, {Component} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Theme} from '@material-ui/core';
import {AuthService} from "../../../../service/AuthService";
import {withStyles} from "@material-ui/styles";
import {setState} from "../../../../common/promisify";

const styles = ((theme: Theme) => ({
    root: {},
    textfield: {
        display: 'block',
        width: '40ch',
    }
}));

export type PasswordProps = { onSave: any, classes: any };

export type PasswordState = {
    isSaveDisabled: boolean,
    password: string,
    confirm: string,
    isValidPassword: boolean,
    isMatching: boolean
};

class Password extends Component<PasswordProps, PasswordState> {

    private service = new AuthService();
    private validationRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{8,9999})");

    constructor(props: PasswordProps) {
        super(props);
        this.state = {
            isMatching: false,
            isValidPassword: false,
            isSaveDisabled: true,
            password: '',
            confirm: ''
        }
    }

    getPasswordHelpText() {

        if (this.state.isValidPassword || this.state.password === "") {
            return "";
        }

        return "Adgangskoden skal være minimum 8 karakter lang og indeholde mindst én af hver type [A-Z, a-z & 0-9]";
    }

    getConfirmHelpText() {

        if (this.state.isMatching || this.state.confirm === "") {
            return "";
        }

        return "Bekræft den nye adgangskode";
    }

    async handlePasswordChange(event: any) {
        await setState(this, {password: event.target.value});

        if (this.state.password && this.validationRegex.test(this.state.password))
            await setState(this,{isValidPassword: true});
        else
            await setState(this,{isValidPassword: false});

        this.updateSaveButton();
    }

    async handleConfirmChange(event: any) {
        await setState(this,{
            confirm: event.target.value,
            isMatching: this.state.password === event.target.value
        });

        this.updateSaveButton();
    }

    updateSaveButton() {
        this.setState({ ...this.state, isSaveDisabled: !(this.state.isMatching && this.state.isValidPassword)});
    }

    async onSaveClicked() {

        if (this.state.password && this.state.confirm && this.state.password === this.state.confirm) {
            try {
                await this.service.changePassword(this.state.password);
                this.props.onSave(true, "Din adgangskode er nu ændret");

                this.setState({
                    isMatching: false,
                    isValidPassword: false,
                    isSaveDisabled: true,
                    password: '',
                    confirm: ''
                });
            } catch (e) {
                this.props.onSave(false, "Der opstod en fejl under ændringen af din adgangskode.");
            }
        }
    }

    render() {
        return (
            <Card>
                <form>
                    <CardHeader  title="Konto" subheader="Opdater adgangskode" />
                    <Divider/>
                    <CardContent>
                        <TextField
                            fullWidth
                            className={this.props.classes.textfield}
                            label="Ny adgangskode"
                            name="password"
                            onChange={this.handlePasswordChange.bind(this)}
                            type="password"
                            value={this.state.password}
                            helperText={this.getPasswordHelpText()}
                            error={this.getPasswordHelpText() === "" ? false : true}
                            variant="outlined"
                        />
                        <TextField
                            disabled={!this.state.isValidPassword}
                            fullWidth
                            className={this.props.classes.textfield}
                            label="Bekræft adgangskode"
                            name="confirm"
                            onChange={this.handleConfirmChange.bind(this)}
                            style={{marginTop: '1rem'}}
                            type="password"
                            value={this.state.confirm}
                            helperText={this.getConfirmHelpText()}
                            error={this.getConfirmHelpText() === "" ? false : true}
                            variant="outlined"
                        />
                    </CardContent>
                    <Divider/>
                    <CardActions style={{ float: "right"}}>
                        <Button color="primary" variant="outlined" onClick={this.onSaveClicked.bind(this)}
                                disabled={this.state.isSaveDisabled}>
                            Opdater
                        </Button>
                    </CardActions>
                </form>
            </Card>
        );
    }
}


export default withStyles(styles)(Password);
