import palette from '../../../../../theme/palette';

const pal: any = palette;

export const data = {
  labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],
  datasets: [
    {
      label: 'This year',
      backgroundColor: pal.primary.main,
      data: [18, 5, 19, 27, 29, 19, 20]
    }
  ]
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: pal.divider,
    backgroundColor: pal.common.white,
    titleFontColor: pal.text.primary,
    bodyFontColor: pal.text.secondary,
    footerFontColor: pal.text.secondary,
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Tid'
        },
        offset: true,
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'DD/MM/YYYY',
          displayFormats: {
            month: 'MMMM YYYY',
            day: 'Do MMM',
            quarter: 'WW',
            week: 'WW',
            millisecond: 'HH:mm:ss.SSS',
            second: 'HH:mm:ss',
            minute: 'HH:mm',
            hour: 'HH'
          }
        },
        ticks: {
          fontColor: pal.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        bounds: 'data',
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: pal.text.secondary,
          beginAtZero: true,
          min: 0,
          precision: 0,

        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: pal.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: pal.divider
        }
      }
    ]
  }
};
