import React, { Component, Ref, RefObject } from "react";
import { withStyles } from "@material-ui/styles";

import { FeedbackTable, FeedbackToolbar } from "./components";
import { APIService, GetAllRatingsQuery } from "../../service/APIService";
import { setState } from "../../common/promisify";
import { Log } from "../../common/log";
import { PageState } from "../../helpers/PageState";

const styles: any = (theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
});

export type FeedbackListProps = { classes: any };

export type FeedbackListState = {
  isLoading: boolean;
  municipalities: any[];
  ratings: any[];
  totalRows: number;
  currentPage: number;
  currentLimit: number;
  filter: {
    searchText: string;
    municipalityCode: number;
    sortBy: number;
    timeRangeStart: Date;
    timeRangeEnd: Date;
    scope: "all" | "inScope" | "outOfScope";
  };
};

class FeedbackList extends Component<FeedbackListProps, FeedbackListState> {
  private tableRef: Ref<any>;
  private readonly pageKey: string = "feedback_list";
  private service: APIService = new APIService();

  constructor(props: FeedbackListProps) {
    super(props);

    this.tableRef = React.createRef();

    const currentDate = new Date();

    this.state = {
      filter: {
        searchText: "",
        municipalityCode: 0,
        sortBy: 1,
        timeRangeStart: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ),
        timeRangeEnd: currentDate,
        scope: "all",
      },
      isLoading: true,
      ratings: [],
      municipalities: [],
      totalRows: 0,
      currentPage: 0,
      currentLimit: 10,
    };
  }

  async componentDidMount(): Promise<void> {
    if (PageState.hasState(this.pageKey)) {
      const state = PageState.get(this.pageKey);
      if (this.isPageStateValid(state)) await this.setPageState(state);
    }

    await this.fetchRatings(this.state.currentLimit, this.state.currentPage);
    await this.fetchMunicipalities();
  }

  componentWillUnmount() {
    PageState.save(this.pageKey, {
      ...this.state.filter,
      totalRows: this.state.totalRows,
      currentPage: this.state.currentPage,
      currentLimit: this.state.currentLimit,
    });
  }

  buildSearchQuery(limit: number, page: number): GetAllRatingsQuery {
    let skip;
    if (page === 0 || limit === 0) skip = 0;
    else {
      if (limit * page < this.state.totalRows - 1) {
        skip = limit * page;
      } else {
        skip = this.state.totalRows - 1;
      }
    }

    return {
      limit: limit,
      skip: skip,
      isDetailed: true,
      searchText: this.state.filter.searchText,
      municipalityCode: this.state.filter.municipalityCode,
      sortBy: this.state.filter.sortBy,
      timeRangeStart: this.state.filter.timeRangeStart,
      timeRangeEnd: this.state.filter.timeRangeEnd,
      scope: this.state.filter.scope,
    };
  }

  async fetchRatings(limit: number, page: number): Promise<void> {
    if (!this.state.isLoading) this.setState({ isLoading: true });

    try {
      const query = this.buildSearchQuery(limit, page);
      const res = await this.service.getDialogRatings(query);

      if (
        res &&
        res.ratings !== undefined &&
        Array.isArray(res.ratings) &&
        res.total !== undefined
      )
        this.setState({
          ratings: res.ratings || [],
          totalRows: res.total || 0,
        });
    } catch (e) {
      Log.error(`Failed loading ratings`);
    }

    setTimeout(() => this.setState({ isLoading: false }), 500);
  }

  async fetchMunicipalities(): Promise<void> {
    try {
      const res = await this.service.getMunicipalities(100, 0, true, false);

      if (res && Array.isArray(res)) {
        this.setState({ municipalities: res });
      }
    } catch (e) {
      Log.error(`Failed loading dialogs`);
    }
  }

  onFilterParamsChange(filter: any) {
    this.setState({ filter: { ...filter } });
  }

  async onPageChange(page: number, rowsPerPage: number): Promise<void> {
    await setState(this, {
      currentPage: page,
      currentLimit: rowsPerPage,
    });
    await this.fetchRatings(rowsPerPage, page);
  }

  async onSearch() {
    await this.fetchRatings(this.state.currentLimit, this.state.currentPage);
  }

  isPageStateValid(state: any): boolean {
    return (
      state &&
      state.searchText !== undefined &&
      state.municipalityCode !== undefined &&
      state.sortBy !== undefined &&
      state.timeRangeStart !== undefined &&
      state.timeRangeEnd !== undefined &&
      state.totalRows !== undefined &&
      state.currentPage !== undefined &&
      state.currentLimit !== undefined
    );
  }

  async setPageState(state: any): Promise<any> {
    await this.setState({
      ...this.state,
      filter: {
        searchText: state.searchText,
        municipalityCode: state.municipalityCode,
        sortBy: state.sortBy,
        timeRangeStart: state.timeRangeStart,
        timeRangeEnd: state.timeRangeEnd,
        scope: state.scope || "all",
      },
      totalRows: state.totalRows,
      currentPage: state.currentPage,
      currentLimit: state.currentLimit,
    });

    if (this.tableRef)
      (this.tableRef as RefObject<any>).current.changePage(
        state.currentPage,
        state.currentLimit
      );
  }

  async handleFeedbackDelete(sessionId: any) {
    try {
      const res = await this.service.patchDialogRatingComment(sessionId, {
        comment: "Indeholder persondata",
      });
      if (res) {
        await this.fetchRatings(
          this.state.currentLimit,
          this.state.currentPage
        );
      }
    } catch (e) {
      Log.log("Failed deleting rating comment ", e);
    }
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <FeedbackToolbar
          onFilterParamsChange={this.onFilterParamsChange.bind(this)}
          municipalities={this.state.municipalities}
          filter={this.state.filter}
          onSearch={this.onSearch.bind(this)}
        />
        <div className={this.props.classes.content}>
          <FeedbackTable
            ref={this.tableRef}
            ratings={this.state.ratings}
            total={this.state.totalRows}
            onPageChange={this.onPageChange.bind(this)}
            onRowsPerPageChange={this.onPageChange.bind(this)}
            isLoading={this.state.isLoading}
            onDelete={this.handleFeedbackDelete.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FeedbackList);
