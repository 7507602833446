import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Theme } from '@material-ui/core';
import { ActionCard, Support } from './components';
import { APIService } from "../../service/APIService";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import { Log } from "../../common/log";
import { config } from "../../config/Config";
import ExtensionIcon from '@material-ui/icons/Extension';
import LoadingIndicator from "../../components/LoadingIndicator";
import { Search } from '@material-ui/icons';

const styles = ((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  iconSizes: {
    fontSize: 140,
    color: theme.palette.primary.main
  },
  supportListItem: {
    cursor: "pointer"
  },
  supportAvatar: {
    backgroundColor: theme.palette.primary.main
  },
  supportTitle: {
    fontWeight: 500,
    fontSize: "14px"
  },
}));

export type MunicipalityDetailsProps = { match: any, classes: any; };

export type MunicipalityDetailsState = {
  isLoading: boolean,
  municipality: any;
};

class MunicipalityDetails extends Component<MunicipalityDetailsProps, MunicipalityDetailsState> {

  private service: APIService = new APIService();

  constructor(props: MunicipalityDetailsProps) {
    super(props);

    this.state = {
      isLoading: true,
      municipality: {}
    };
  }

  async componentDidMount(): Promise<void> {
    await this.fetchMunicipality();
  }

  async fetchMunicipality(): Promise<void> {

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    try {
      const res = await this.service.getMunicipality(this.props.match.params.code);

      if (res) {
        this.setState({ municipality: res });
      }
    } catch (e) {
      Log.error(`Failed loading municipality ${e}`);
    }

    setTimeout(() => this.setState({ isLoading: false }), 500);
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <RouterLink color="inherit" to="/municipalities" >
              <Typography color="textPrimary">Kommuner</Typography>
            </RouterLink>
            <Typography color="textPrimary">{this.state.municipality.name}</Typography>
          </Breadcrumbs>
          <span className={this.props.classes.spacer} />
        </div>
        <LoadingIndicator isLoading={this.state.isLoading} />
        {
          !this.state.isLoading &&
          <Grid container spacing={4}>
            {
              // User.isAdmin() &&
              <Grid item lg={5} md={6} xl={4} xs={12}>
                <ActionCard
                  title={"Chatbot konfiguration"}
                  url={`/municipalities/${this.props.match.params.code}/chatbot`}
                  image={`${config.chatbot.scheme}://${config.chatbot.host}/images/avatar/customization/${this.state.municipality.code}_noshadow.svg`}
                  description={"Her kan en kommune ændre i kontekst variabler."} />
              </Grid>
            }
            <Grid item lg={5} md={6} xl={4} xs={12}>
              <ActionCard title={"Selvbetjening.nu konfiguration"}
                url={`/municipalities/${this.props.match.params.code}/sb`}
                icon={<ExtensionIcon className={this.props.classes.iconSizes} />}
                description={"Guides fra selvbetjening.nu kan overskrives via dette menupunkt."} />
            </Grid>
            <Grid item lg={5} md={6} xl={4} xs={12}>
              <ActionCard title={"Front desk konfiguration"}
                url={`/municipalities/${this.props.match.params.code}/front-desk`}
                icon={<ExtensionIcon className={this.props.classes.iconSizes} />}
                description={"Køer fra Front Desk kan aktiveres via dette menupunkt."} />
            </Grid>
            <Grid item lg={5} md={6} xl={4} xs={12}>
              <ActionCard title={"Watson Discovery konfiguration"}
                url={`/municipalities/${this.props.match.params.code}/watson-discovery`}
                icon={<Search className={this.props.classes.iconSizes} />}
                description={"Konfigurer Watson Discovery, tilknyt kollektion og ændre crawling af URL'er"} />
            </Grid>
            <Grid item lg={5} md={6} xl={4} xs={12}>
              <Support />
            </Grid>
          </Grid>
        }
      </div>
    );
  }
}

export default withStyles(styles)(MunicipalityDetails);
