import React, { Component } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import InfoSvgButton from "../../../../../../components/InfoButton";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardRounded";
import moment from "moment";
import "moment/locale/da";
import CircularProgress from "@material-ui/core/CircularProgress";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
  root: {},
  actionCol: {
    width: "5%"
  },
  actions: {
    justifyContent: 'flex-end'
  },
  alertHeader: {
    fontSize: "1.25rem",
    fontWeight: 500
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: "4px"
  },

}));

export type GuidesProps = {
  isLoading: boolean,
  onClick: (tag: any) => void,
  classes: any,
  guides: any[]
};

export type GuidesState = {
  rowsPerPage: number,
  page: number,
};

class Guides extends Component<GuidesProps, GuidesState> {

  constructor(props: GuidesProps) {
    super(props);

    this.state = {
      rowsPerPage: 5,
      page: 0
    };
  }

  changePage(page: number, rowsPerPage: number) {
    this.setState({ rowsPerPage: rowsPerPage, page: page });
  }

  handlePageChange(event: any, page: any) {
    this.setState({ page: page });
  }

  handleRowsPerPageChange(event: any) {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  }

  render(): any {
    return (
      <Card>
        <CardHeader
          subheader="Aktive guides fra Selvbetjening.nu"
          title={<span>Selvbetjening guides <InfoSvgButton title={"Selvbetjening.nu"}
            content={
              <Typography>Selvbetjening guides hentes hver nat fra Selvbetjening.nu systemet. Såfremt der er behov for at tilpasse teksten kan dette gøres manuelt. <br /><br /> Vær opmærksom på at så snart tekst ændres manuelt vil ændringerne fra Selvbetjening.nu ikke automatisk blive anvendt. Det er derfor nødvendigt løbende at opdatere den tilpasset tekst.</Typography>} />
          </span>
          }
        />
        <Divider />
        <CardContent>

          <PerfectScrollbar>
            <div className={this.props.classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tag id</TableCell>
                    <TableCell>Tag navn</TableCell>
                    <TableCell>Guide navn</TableCell>
                    <TableCell>Sidst opdateret</TableCell>
                    <TableCell>Rettet manuelt </TableCell>
                    <TableCell>Rettet tidspunkt</TableCell>
                    <TableCell>Rettet af bruger</TableCell>
                    <TableCell className={this.props.classes.actionCol}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    !this.props.isLoading ? this.props.guides.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(item => (
                      <TableRow
                        className={this.props.classes.tableRow}
                        hover
                        key={item.tagId}>
                        <TableCell>{item.tagId}</TableCell>
                        <TableCell>{item.tag}</TableCell>
                        <TableCell>{item.guideName}</TableCell>
                        <TableCell>{item.lastUpdate ? moment(item.lastUpdate).format('dddd DD. MMMM yyyy') + " " + moment(item.lastUpdate).format('HH:mm:ss') : "-"}</TableCell>
                        <TableCell>{item.correctedValue !== "" && item.correctedValue ? "Ja" : "Nej"}</TableCell>
                        <TableCell>{item.correctedAt ? moment(item.correctedAt).format('dddd DD. MMMM yyyy') + " " + moment(item.correctedAt).format('HH:mm:ss') : "-"}</TableCell>
                        <TableCell>{item.correctedBy ? item.correctedBy : "-"}</TableCell>
                        <TableCell>
                          <IconButton aria-label="rediger"
                            color="primary"
                            onClick={() => this.props.onClick(item)}>
                            <ArrowForwardIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )) : <TableRow><TableCell colSpan={12} align={"center"}><CircularProgress /></TableCell></TableRow>}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={this.props.classes.actions}>
          <TablePagination
            labelRowsPerPage="Rækker pr. side"
            labelDisplayedRows={({ from, to, count }) => `${from > count ? count : from}-${to} af ${count}`}
            component="div"
            count={this.props.guides.length}
            onChangePage={this.handlePageChange.bind(this)}
            onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          />
        </CardActions>
      </Card>
    );
  }

}

export default withStyles(styles)(Guides)

