import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Theme} from "@material-ui/core";
import VideoBotBlob from "./components/BotBlob/VideoBotBlob";
import ImageBotBlob from "./components/BotBlob/ImageBotBlob";
import LinkBotBlob from "./components/BotBlob/LinkBotBlob/LinkBotBlob";
import ExpandableBotBlob from "./components/BotBlob/ExpandableBotBlob";
import {getTextForElement} from "../../../../helpers/utils";
import OptionsBotBlob from "./components/BotBlob/OptionsBotBlob";
import TextBotBlob from "./components/BotBlob/TextBotBlob";
import BotMessage from "./components/BotMessage/BotMessage";
import MessageContainer from "./components/MessageContainer";
import UserBlob from "./components/UserBlob";
import '../../../../assets/scss/BlobStyles.scss';
import {User} from "../../../../helpers/profile";

const styles: any = ((theme: Theme) => ({
    scrollableLog: {
        width: '100vw',
        overflowX: 'hidden',
        overflowY: 'hidden'
    }
}));

export type DialogConversationProps = {
    messages: any[],
    classes: any
};

export type DialogConversationState = { dialog: any[] };


class DialogConversation extends Component<DialogConversationProps, DialogConversationState> {

    constructor(props: DialogConversationProps) {
        super(props);

        this.state = {
          dialog: []
        };
    }

    getDialog(){

        let dialog = [];
        let isFirst = true;
        let rawFromPrevious: any = {};
        let previousSequnce = -1;
        const isAdmin = User.isAdmin();
        for(let item of this.props.messages){


            if(item.isUser) {
                previousSequnce = item.sequence;
                rawFromPrevious = { waDebug: item.msgRaw, kleFormFs3Field: item.kleFormFs3Field };
                if(item.msg && item.msg.length > 0 && item.msg[0].text &&
                    (item.msg[0].text !== "" || (item.msg[0].text !== "Velkomst" && isFirst)))
                    dialog.push(<MessageContainer blobs={[ this.getUserMessage(item) ]}/>);
            }
            else {
                if(item.sequence !== (previousSequnce+1))
                    rawFromPrevious = {};

                dialog.push(<MessageContainer blobs={this.getBotMessage(item.msg, item.time, isAdmin ? rawFromPrevious.waDebug : undefined, rawFromPrevious.kleFormFs3Field)}/>);
                rawFromPrevious = {};
            }

            if(isFirst)
                isFirst = false;
        }


        return dialog.map((item: any, index: number) => {
            return (<span key={index}>{item}</span>)
        })
    }

    getUserMessage(item: any){

        if(item.msg && item.msg.length > 0){
            return <UserBlob text={item.msg[0].text} timestamp={item.time}/>
        }

    }

    getBotMessage(mgmElements: any, timestamp: string, msgRaw: any, kleFormFs3Field: string): any[] {
        let botBlobs: any = [];

        for (let x of mgmElements) {

            switch (x.responseType) {
                case "option":
                    botBlobs.push(<OptionsBotBlob text={getTextForElement(x.title, x.description)} options={x.options}/>);
                    break;
                case "pause":
                    break;
                case "text":
                    botBlobs.push(<TextBotBlob text={x.text}/>,);
                    break;
                case "link":
                    botBlobs.push(<LinkBotBlob text={getTextForElement(x.title, x.description)} source={x.src}/>);
                    break;
                case "image":
                    botBlobs.push(<ImageBotBlob text={x.title} descr={x.description} source={x.src}/>);
                    break;
                case "video":
                    botBlobs.push(<VideoBotBlob text={x.title} source={x.src} descr={x.description}/>);
                    break;
                case "expander":
                    botBlobs.push(<ExpandableBotBlob expandables={x.expandables} textBefore={x.textBefore} textAfter={x.textAfter}/>);
                    break;
                default:
                    break;
            }
        }

        return [ <BotMessage blobs={botBlobs} timestamp={timestamp} waDebug={msgRaw} kleFormFs3Field={kleFormFs3Field}/> ];
    }

    render() {
        return (
            <Grid container className={"chatLog"}>
                <div className={this.props.classes.scrollableLog}>
                    {this.getDialog()}
                </div>
            </Grid>
        );
    }
}

export default withStyles(styles)(DialogConversation);
