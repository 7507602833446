import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Checkbox, Grid, IconButton, Theme, Tooltip } from "@material-ui/core";
import { SearchInput } from "../../../../components";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import { DateRange, FilterList } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import InfoSvgButton from "../../../../components/InfoButton/InfoSvgButton";
import { FeedbackListState } from "../../FeedbackList";

const styles: any = (theme: Theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  dateTimePicker: {
    marginRight: "8px",
  },
});

export type FeedbackToolbarProps = {
  classes: any;
  filter: FeedbackListState["filter"];
  municipalities: any[];
  onFilterParamsChange: (filter: any) => void;
  onSearch: any;
};

export type FeedbackToolbarState = {
  isFilterEnabled: boolean;
  isDateRangeEnabled: boolean;
  lastSearch: string;
};

class FeedbackToolbar extends Component<
  FeedbackToolbarProps,
  FeedbackToolbarState
> {
  constructor(props: FeedbackToolbarProps) {
    super(props);

    this.state = {
      isFilterEnabled: false,
      isDateRangeEnabled: false,
      lastSearch: "",
    };
  }

  search(query: string) {
    this.setState({ ...this.state, lastSearch: query });
    this.props.onSearch();
  }

  maybeSearch(query: string) {
    if (query === this.state.lastSearch) return;
    this.search(query);
  }

  async handleSearchTextChange(event: any) {
    this.props.onFilterParamsChange({
      ...this.props.filter,
      searchText: event.target.value,
    });
  }

  handleFilterEnabledChange(event: any) {
    this.setState({ isFilterEnabled: event.target.checked });
  }

  handleFilterChange(event: any): void {
    this.props.onFilterParamsChange({
      ...this.props.filter,
      [event.target.name]: event.target.value,
    });
    this.handleRefreshClick();
  }

  handleRefreshClick() {
    // Ensure we trigger after this event loop
    setTimeout(() => {
      this.search(this.props.filter.searchText);
    }, 0);
  }

  handleDateFilterChange(name: string, date: any): void {
    this.props.onFilterParamsChange({
      ...this.props.filter,
      [name]: new Date(Date.parse(date)),
    });
    this.handleRefreshClick();
  }

  handleDateRangeEnabledChange(event: any) {
    this.setState({ isDateRangeEnabled: event.target.checked });
  }

  renderMunicipalityFilter() {
    return (
      <Grid item lg={3} md={3} sm={6} xl={2} xs={12}>
        <TextField
          fullWidth
          inputProps={{ "aria-label": "Without label" }}
          label="Kommune"
          margin="dense"
          name="municipalityCode"
          onChange={this.handleFilterChange.bind(this)}
          placeholder=""
          select
          SelectProps={{ native: true }}
          value={this.props.filter.municipalityCode}
        >
          <option key={0} value="0">
            Alle
          </option>
          {this.props.municipalities
            .slice(0, this.props.municipalities.length)
            .map((municipality) => {
              return (
                <option key={municipality.id} value={municipality.code}>
                  {municipality.name}
                </option>
              );
            })}
        </TextField>
      </Grid>
    );
  }

  renderScopeFilter() {
    return (
      <Grid item lg={3} md={3} sm={6} xl={2} xs={12}>
        <TextField select fullWidth
          name="scope"
          inputProps={{ "aria-label": "Without label" }}
          label="Scope"
          margin="dense"
          onChange={this.handleFilterChange.bind(this)}
          placeholder=""
          SelectProps={{ native: true }}
          value={this.props.filter.scope}
        >
          <option key={0} value="all">Alle</option>
          <option key={1} value="inScope">Indenfor scope</option>
          <option key={2} value="outOfScope">Udenfor scope</option>
        </TextField>
      </Grid>
    );
  }

  renderSortFilter() {
    return (
      <Grid item lg={3} md={3} sm={6} xl={2} xs={12}>
        <TextField
          select
          fullWidth
          name="sortBy"
          inputProps={{ "aria-label": "Without label" }}
          label="Sorter efter"
          margin="dense"
          onChange={this.handleFilterChange.bind(this)}
          placeholder=""
          SelectProps={{ native: true }}
          value={this.props.filter.sortBy}
        >
          <option key={0} value="1">
            Tidspunkt (Nyeste)
          </option>
          <option key={1} value="0">
            Tidspunkt (Ældste)
          </option>
          <option key={2} value="3">
            Score (Faldende)
          </option>
          <option key={3} value="2">
            Score (Stigende)
          </option>
        </TextField>
      </Grid>
    );
  }

  renderDateRange() {
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="da">
          <DateTimePicker
            name="timeRangeStart"
            className={this.props.classes.dateTimePicker}
            autoOk
            ampm={false}
            value={this.props.filter.timeRangeStart}
            disableFuture
            onChange={(date) =>
              this.handleDateFilterChange("timeRangeStart", date)
            }
            cancelLabel="Annuller"
            label="Start periode"
          />
          <DateTimePicker
            name="timeRangeEnd"
            className={this.props.classes.dateTimePicker}
            autoOk
            ampm={false}
            value={this.props.filter.timeRangeEnd}
            disableFuture
            onChange={(date) =>
              this.handleDateFilterChange("timeRangeEnd", date)
            }
            cancelLabel="Annuller"
            label="Slut periode"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <div className={this.props.classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Borger feedback</Typography>
          </Breadcrumbs>
          <span className={this.props.classes.spacer} />
        </div>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <div className={this.props.classes.row}>
              <SearchInput
                className={this.props.classes.searchInput}
                content={
                  <div>
                    <Tooltip title="Genindlæs">
                      <IconButton
                        aria-label="refresh"
                        color="primary"
                        onClick={this.handleRefreshClick.bind(this)}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Vis dato filter">
                      <Checkbox
                        checkedIcon={<DateRange />}
                        icon={<DateRange />}
                        onChange={this.handleDateRangeEnabledChange.bind(this)}
                      />
                    </Tooltip>
                    <Tooltip title="Vis flere filtre">
                      <Checkbox
                        checkedIcon={<FilterList />}
                        icon={<FilterList />}
                        onChange={this.handleFilterEnabledChange.bind(this)}
                      />
                    </Tooltip>
                  </div>
                }
                onChange={this.handleSearchTextChange.bind(this)}
                onBlur={(event) => this.maybeSearch(event.target.value)}
                placeholder="Søg feedback"
                value={this.props.filter.searchText}
              />
              <div>
                <InfoSvgButton
                  title={"Feedback og scope"}
                  content={
                    <Typography>
                      Borgere bliver fra tid til anden spurgt om at give
                      feedback til Muni. Når det sker, vil du kunne se den
                      feedback de giver på listen her.
                      <br />
                      <br />
                      Vær opmærksom på at markere feedback som{" "}
                      <strong>
                        <i>udenfor scope</i>
                      </strong>{" "}
                      hvis det falder uden for Muni's funktionalitet. Du kan til
                      enhver tid se om en feedback er{" "}
                      <strong>
                        <i>udenfor scope</i>
                      </strong>{" "}
                      i listen under "scope".
                      <br />
                      <br />
                      For at ændre scope for feedback, skal du ind i samtalen.
                      Under sektionen for feedback vil du kunne markere dit
                      scope.
                    </Typography>
                  }
                />
              </div>
            </div>
          </Grid>
          {this.state.isDateRangeEnabled && this.renderDateRange()}
          {this.state.isFilterEnabled && this.renderMunicipalityFilter()}
          {this.state.isFilterEnabled && this.renderScopeFilter()}
          {this.state.isFilterEnabled && this.renderSortFilter()}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(FeedbackToolbar);
