import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import DialogConversation from "./components/DialogConversation";
import DialogToolbar from "./components/DialogToolbar";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/da";
import {
  Box,
  Button,
  CardActions,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Theme,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { APIService } from "../../service/APIService";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import MailIcon from "@material-ui/icons/MailRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import CallIcon from "@material-ui/icons/Call";
import LoadingIndicator from "../../components/LoadingIndicator";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { User } from "../../helpers/profile";
import { Log } from "../../common/log";
import { OutOfScopeType } from "./OutOfScopeType";
import { SadHeadIcon } from "./components/HeadIcons/SadHeadIcon";
import { MediumHeadIcon } from "./components/HeadIcons/MediumHeadIcon";
import { HappyHeadIcon } from "./components/HeadIcons/HappyHeadIcon";

moment.locale("da"); // it is required to select default locale manually

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  cardHeader: {
    backgroundColor: theme.palette.white,
  },
  cardContent: {
    backgroundColor: theme.palette.white,
    height: "60vh",
    overflow: "auto",
  },
  feedbackCard: {
    marginTop: theme.spacing(3),
  },
  cardFeedbackContent: {
    backgroundColor: theme.palette.white,
    overflow: "auto",
  },
  chipSuccess: {
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  chipError: {
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.error.light,
  },
  iconDeleted: {
    fontSize: 80,
    opacity: 0.8,
    color: theme.palette.text.primary,
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
});

export type DialogDetailsProps = { match: any; classes: any };

export type DialogDetailsState = {
  isLoading: boolean;
  deleteModalOpen: boolean;
  dialog: any;
  alert: {
    isAlertOpen: boolean;
    alertMessage: string;
    alertType: "success" | "error" | "info" | "warning";
  };
};

class DialogDetails extends Component<DialogDetailsProps, DialogDetailsState> {
  private service: APIService = new APIService();

  constructor(props: DialogDetailsProps) {
    super(props);

    this.state = {
      isLoading: true,
      deleteModalOpen: false,
      dialog: {
        messages: [],
        states: {},
      },
      alert: {
        isAlertOpen: false,
        alertMessage: "",
        alertType: "success",
      },
    };
  }

  handleDeleteModalOpen(): void {
    this.setState({ deleteModalOpen: true });
  }

  handleDeleteModalClose(): void {
    this.setState({ deleteModalOpen: false });
  }

  async handleDeleteDialog() {
    this.handleDeleteModalClose();
    try {
      await this.service.deleteDialog(
        this.state.dialog.municipality.code,
        this.state.dialog.sessionId,
        this.state.dialog.customerId
      );
      this.setState({
        alert: {
          alertType: "success",
          alertMessage: "Samtalen er slettet permanent i systemet",
          isAlertOpen: true,
        },
      });
      await this.fetchDialog();
    } catch (e) {
      Log.error("Failed deleting the dialog", e);
      this.setState({
        alert: {
          alertType: "error",
          alertMessage: "Der opstod en fejl under sletning af samtalen",
          isAlertOpen: true,
        },
      });
      this.handleDeleteModalClose();
      await this.fetchDialog();
    }
  }

  async componentDidMount(): Promise<void> {
    let lastPage = sessionStorage.getItem("lastPage");
    if (!lastPage && lastPage !== "") {
      sessionStorage.setItem("lastPage", window.location.pathname);
    } else {
      sessionStorage.removeItem("lastPage");
    }
    await this.fetchDialog();
  }

  async fetchDialog(): Promise<void> {
    if (!this.state.isLoading) this.setState({ isLoading: true });

    try {
      const res = await this.service.getDialog(this.props.match.params.id);
      if (res) {
        this.setState({ dialog: res });
      }
    } catch (e) {
      Log.error(`Failed loading dialog ${e}`);
    }
    setTimeout(() => this.setState({ isLoading: false }), 500);
  }

  // Util for setting scope on state
  setOutOfScope = (outOfScope: boolean, loading: boolean = false) => {
    this.setState({
      ...this.state,
      dialog: {
        ...this.state.dialog,
        rating: {
          ...this.state.dialog.rating,
          outOfScope: outOfScope ? 1 : 0,
          loading,
        },
      },
    });
  };

  async markOutOfScope(flag: boolean): Promise<void> {
    const old = this.state.dialog.rating.outOfScope || false;

    try {
      this.setOutOfScope(flag, true);
      await this.service.patchDialogRating(this.state.dialog.sessionId, {
        outOfScope: flag,
      });
      this.setOutOfScope(flag, false);
    } catch (e) {
      this.setOutOfScope(old, false);
      Log.error(`Failed to mark rating out of scope`);
    }
  }

  getChips() {
    if (!this.state.dialog.states) {
      return;
    }

    let deleteChip: any = "";
    if (this.state.dialog.states.deleteState === 1) {
      deleteChip = (
        <Chip
          className={this.props.classes.chipSuccess}
          icon={<DeleteIcon style={{ color: "black" }} />}
          label="Sletning anmodet"
        />
      );
    } else if (this.state.dialog.states.deleteState === 3) {
      deleteChip = (
        <Chip
          className={this.props.classes.chipError}
          icon={<DeleteIcon style={{ color: "black" }} />}
          label="Sletning fejlet"
          color="primary"
        />
      );
    }

    let mailChip: any = "";
    if (this.state.dialog.states.mailState === 1) {
      mailChip = (
        <Chip
          className={this.props.classes.chipSuccess}
          icon={<MailIcon />}
          label="Mail anmodet"
        />
      );
    } else if (this.state.dialog.states.mailState === 2) {
      mailChip = (
        <Chip
          className={this.props.classes.chipSuccess}
          icon={<MailIcon />}
          label="Mail afsendt"
          color="primary"
        />
      );
    } else if (this.state.dialog.states.mailState === 3) {
      mailChip = (
        <Chip
          className={this.props.classes.chipError}
          icon={<MailIcon style={{ color: "black" }} />}
          label="Mail afsendelse fejlet"
        />
      );
    }

    let callbackChip: any = "";
    if (this.state.dialog.states.puzzelState === 1) {
      callbackChip = (
        <Chip
          className={this.props.classes.chipSuccess}
          icon={<CallIcon />}
          label="Callback foretaget"
          color="primary"
        />
      );
    } else if (this.state.dialog.states.puzzelState === 2) {
      callbackChip = (
        <Chip
          className={this.props.classes.chipError}
          icon={<CallIcon style={{ color: "black" }} />}
          label="Callback afsendelse fejlet"
        />
      );
    }

    return (
      <div>
        {mailChip}
        {callbackChip}
        {deleteChip}
      </div>
    );
  }

  getActions() {
    if (
      this.state.dialog.states &&
      this.state.dialog.states.deleteState === 2
    ) {
      return;
    }

    return (
      <Button
        color="primary"
        variant="text"
        onClick={this.handleDeleteModalOpen.bind(this)}
      >
        Slet samtale
      </Button>
    );
  }

  async handleFeedbackDelete() {
    try {
      await this.service.patchDialogRatingComment(this.state.dialog.sessionId, {
        comment: "*** Indeholder persondata ***",
      });
      await this.fetchDialog();
    } catch (e) {
      Log.log("Failed deleting rating comment ", e);
    }
  }

  getFeedback() {
    if (!this.state.dialog.rating) {
      return;
    }

    return (
      <Card
        className={
          this.props.classes.card +
          " " +
          this.props.classes.content +
          " " +
          this.props.classes.feedbackCard
        }
      >
        <CardHeader
          className={this.props.classes.cardHeader}
          avatar={
            <Typography gutterBottom variant="h3">
              Feedback
            </Typography>
          }
          action={
            <Typography variant="body2">
              <span>
                Scope:{" "}
                {this.state.dialog.rating.outOfScope === OutOfScopeType.IN_SCOPE
                  ? "Indenfor scope"
                  : this.state.dialog.rating.outOfScope === OutOfScopeType.AGENT
                  ? "Udenfor scope (markeret af agent)"
                  : "Udenfor scope"}
              </span>
              <br />
              <span>Tag: {this.state.dialog.rating.tag}</span>
            </Typography>
          }
        ></CardHeader>
        <Divider />
        <CardContent className={this.props.classes.cardFeedbackContent}>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <SadHeadIcon mute={this.state.dialog.rating.ratingScore !== 1} />
              <Box marginRight={1} />
              <MediumHeadIcon
                mute={this.state.dialog.rating.ratingScore !== 2}
              />
              <Box marginRight={1} />
              <HappyHeadIcon
                mute={this.state.dialog.rating.ratingScore !== 3}
              />
            </Box>
          </Box>
          <Typography variant="body1">
            {this.state.dialog.rating.ratingComment
              ? this.state.dialog.rating.ratingComment
              : ""}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={this.props.classes.cardActions}>
          {User.isGlobalAdmin() && (
            <FormControlLabel
              style={{ marginLeft: "0px" }}
              labelPlacement="end"
              label="Udenfor scope"
              control={
                <Switch
                  checked={!!this.state.dialog.rating.outOfScope || false}
                  onChange={(event, checked) => this.markOutOfScope(checked)}
                  name="outOfScope"
                  color="primary"
                  disabled={this.state.dialog.rating.loading}
                />
              }
            />
          )}
          {User.isGlobalAdmin() && (
            <Button
              endIcon={<DeleteIcon />}
              style={{ marginLeft: "auto" }}
              color="secondary"
              aria-label="slet dialog"
              onClick={() =>
                window.confirm(
                  "Du er ved at fjerne feedback kommentaren. Er du sikker på at du vil fortsætte?"
                ) && this.handleFeedbackDelete()
              }
            >
              Fjern sensitiv information
            </Button>
          )}
        </CardActions>
      </Card>
    );
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <DialogToolbar msgId={this.props.match.params.id} />
        {this.state.isLoading && (
          <div className={this.props.classes.content}>
            <LoadingIndicator isLoading={this.state.isLoading} />
          </div>
        )}
        {!this.state.isLoading && (
          <div className={this.props.classes.content}>
            <Card className={this.props.classes.card}>
              <CardHeader
                className={this.props.classes.cardHeader}
                avatar={
                  <div>
                    <Typography gutterBottom variant="h2">
                      Samtale
                    </Typography>
                    <Typography variant="body1">
                      {this.state.dialog &&
                      this.state.dialog.municipality &&
                      this.state.dialog.municipality.name
                        ? this.state.dialog.municipality.name
                        : ""}
                    </Typography>
                    <Typography
                      className={this.props.classes.dateText}
                      variant="body1"
                    >
                      {this.state.dialog && this.state.dialog.startTime
                        ? moment(this.state.dialog.startTime).format(
                            "dddd DD. MMMM yyyy"
                          )
                        : ""}
                    </Typography>
                    <Typography
                      className={this.props.classes.dateText}
                      variant="body1"
                    >
                      {this.state.dialog && this.state.dialog.startTime
                        ? "kl. " +
                          moment(this.state.dialog.startTime).format("HH:mm:ss")
                        : ""}
                    </Typography>
                  </div>
                }
                action={
                  <div>
                    <Typography variant="body2">
                      {this.state.dialog && this.state.dialog.customerId
                        ? "WA ID:  " + this.state.dialog.customerId
                        : ""}
                    </Typography>
                    <Typography variant="body2">
                      {this.state.dialog && this.state.dialog.sessionId
                        ? "Samtale ID: " + this.state.dialog.sessionId
                        : ""}
                    </Typography>
                  </div>
                }
              ></CardHeader>
              <Divider />
              <CardContent className={this.props.classes.cardContent}>
                {this.state.dialog.states &&
                this.state.dialog.states.deleteState === 2 ? (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <DeleteIcon className={this.props.classes.iconDeleted} />
                    </Grid>
                    <Grid item>Samtalen er slettet i systemet</Grid>
                  </Grid>
                ) : (
                  <DialogConversation messages={this.state.dialog.messages} />
                )}
              </CardContent>
              <Divider />
              {
                <CardActions className={this.props.classes.cardActions}>
                  {User.isAdmin() && this.getActions()}
                  {User.isAdmin() && (
                    <Divider orientation="vertical" flexItem />
                  )}
                  {this.getChips()}
                </CardActions>
              }
            </Card>
            {this.getFeedback()}
          </div>
        )}
        <Dialog
          open={this.state.deleteModalOpen}
          onClose={this.handleDeleteModalClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Bekræft sletning af samtalen"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på du ønsker at slette denne samtale permanent? Efter
              samtalen slettes vil den ikke være tilgængelig i Watson Assistant
              eller i resten af systemet
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDeleteModalClose.bind(this)}
              color="primary"
              autoFocus
            >
              Annuller
            </Button>
            <Button
              className={this.props.classes.deleteBtn}
              onClick={this.handleDeleteDialog.bind(this)}
              color="inherit"
            >
              Slet
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.alert.isAlertOpen}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() =>
            this.setState({
              alert: { ...this.state.alert, isAlertOpen: false },
            })
          }
        >
          <Alert severity={this.state.alert.alertType}>
            {this.state.alert.alertMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(DialogDetails);
