import React, {Component} from 'react';
import {Grid, Theme} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import moment from "moment";

const styles = ((theme: Theme) => ({

}));

export type UserBlobProps = { text: string, timestamp: any, classes: any };

export type UserBlobState = {  };

class UserBlob extends Component<UserBlobProps,UserBlobState> {

    render(){
        return (
            <Grid container direction="row-reverse">
                <Grid item xs={12} className="userBlob">
                    <div className="userBlobPaper">{this.props.text}</div>
                </Grid>
                <Grid item>
                    <div className="userTimestamp">{moment(this.props.timestamp).format("HH:mm")}</div>
                </Grid>
            </Grid>

        );
    }
}

export default withStyles(styles)(UserBlob);