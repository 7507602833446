import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Theme } from '@material-ui/core';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import { User } from "../../helpers/profile";
import ActionCard from "../../components/ActionCard";
import ExtensionIcon from "@material-ui/icons/Extension";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    iconSizes: {
        fontSize: 140,
        color: theme.palette.primary.main
    }
}));

export type IntegrationsProps = { match: any, classes: any };

export type IntegrationsState = {};

class Integrations extends Component<IntegrationsProps, IntegrationsState> {

    constructor(props: IntegrationsProps) {
        super(props);

        this.state = {}
    }

    async componentDidMount(): Promise<void> { }

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <RouterLink color="inherit" to="/municipalities" >
                            <Typography color="textPrimary">Integrationer</Typography>
                        </RouterLink>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer} />
                </div>
                <Grid container spacing={4}>
                    {
                        User.isGlobalAdmin() &&
                        <>
                            <Grid item lg={5} md={6} xl={4} xs={12}>
                                <ActionCard
                                    title={"Selvbetjening.nu"}
                                    url={`/integration/sb`}
                                    icon={<ExtensionIcon className={this.props.classes.iconSizes} />}
                                    description={"Her kan du få et overblik over tidligere synkroniseringer til Selvbetjening.nu's database eller manuelt starte en ny synkronisering."} />
                            </Grid>
                            <Grid item lg={5} md={6} xl={4} xs={12}>
                                <ActionCard
                                    title={"Front Desk"}
                                    url={`/integration/front-desk`}
                                    icon={<ExtensionIcon className={this.props.classes.iconSizes} />}
                                    description={"Her kan du se kommuner som er tilkoblet Front Desk, samt tilkoble nye kommuner ved brug af en API-nøgle."} />
                            </Grid>
                        </>
                    }
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Integrations);
