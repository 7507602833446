import React, { Component } from 'react';
import moment from 'moment';
import "moment/locale/da";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
} from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import { Log } from "../../../../common/log";

moment.locale('da'); // it is required to select default locale manually

const styles: any = ((theme: Theme) => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    }
}));

export type SubjectStatsProps = {
    municipalityId: number,
    begin: string,
    end: string,
    subjects: any[],
    classes: any
}

export type SubjectStatsState = {
    rowsPerPage: any,
    page: number
}

class SubjectStats extends Component<SubjectStatsProps, SubjectStatsState>{

    constructor(props: SubjectStatsProps) {
        super(props);

        this.state = {
            rowsPerPage: 10,
            page: 0
        };
    }

    getDateStrAsNumberStr(dateStr: string): string {
        try {
            const date = new Date(Date.parse(dateStr)).getTime();
            return `${date}`;
        }
        catch (e) {
            Log.error(`Failed parsing date string to number ${dateStr}`);
        }
        return `0`;
    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader title="Procentvis fordeling af områder" />
                    <Divider />
                    <CardContent>
                        <PerfectScrollbar>
                            <div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Område</TableCell>
                                            {/* <TableCell>Antal</TableCell> */}
                                            <TableCell>Procentfordeling</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.subjects.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((item, index) => (
                                            <TableRow
                                                className={this.props.classes.tableRow}
                                                hover
                                                key={`${item.subject}-${item.subject_count}-${index}`}>
                                                <TableCell>
                                                    {item.subject && item.subject.length > 0 ? item.subject.charAt(0).toUpperCase() + item.subject.slice(1) : item.subject}
                                                </TableCell>
                                                {/* <TableCell>
                                                    {item.subject_count}
                                                </TableCell> */}
                                                <TableCell>
                                                    {item.percent}
                                                </TableCell>
                                                <TableCell>
                                                    <RouterLink to={`/stats/subject/${item.subject}?id=${this.props.municipalityId}&begin=${this.getDateStrAsNumberStr(this.props.begin)}&end=${this.getDateStrAsNumberStr(this.props.end)}`}>
                                                        <IconButton color="secondary" aria-label={`Se detaljer for ${item.subject}`}
                                                            style={{ float: "right" }}>
                                                            <ArrowForwardIcon />
                                                        </IconButton>
                                                    </RouterLink>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    </CardContent>
                    <CardActions className={this.props.classes.actions}>
                        <TablePagination
                            labelRowsPerPage="Rækker pr. side"
                            labelDisplayedRows={({ from, to, count }) => `${from > count ? count : from}-${to} af ${count}`}
                            component="div"
                            count={this.props.subjects.length}
                            onChangePage={this.handlePageChange.bind(this)}
                            onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                            page={this.state.page}
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                        />
                    </CardActions>
                </Card>
            </div>
        );
    }

    handlePageChange(event: any, page: any) {
        this.setState({ page: page });
    }

    handleRowsPerPageChange(event: any) {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    }
}

export default withStyles(styles)(SubjectStats);