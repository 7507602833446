import React, { Component } from "react";
import { Theme } from "@material-ui/core/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/DashboardRounded";
import ChatIcon from "@material-ui/icons/ChatRounded";
import PlaceIcon from "@material-ui/icons/PlaceRounded";
import TextFieldsIcon from "@material-ui/icons/TextFieldsRounded";
import BarChartIcon from "@material-ui/icons/BarChartRounded";
import SettingsIcon from "@material-ui/icons/SettingsRounded";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDownRounded";
import ExtensionIcon from "@material-ui/icons/ExtensionRounded";
import BuildIcon from "@material-ui/icons/PowerRounded";
import { Profile, SidebarNav } from "./components";
import { withStyles } from "@material-ui/styles";
import { User } from "../../../../helpers/profile";
import PageviewIcon from "@material-ui/icons/Pageview";

const styles: any = (theme: Theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
});

export type SidebarProps = {
  open: boolean;
  variant: "permanent" | "persistent" | "temporary" | undefined;
  onClose: any;

  classes: any;
};

export type SidebarState = { pages: any[] };

class Sidebar extends Component<SidebarProps, SidebarState> {
  constructor(props: SidebarProps) {
    super(props);

    this.state = {
      pages: [],
    };
  }

  componentDidMount(): void {
    this.loadRoles();
  }

  loadRoles() {
    if (User.isAdmin()) {
      this.setState({ pages: this.getAdminNavs() });
    } else if (User.isAgent()) {
      this.setState({ pages: this.getAgentNavs() });
    }
  }

  getAdminNavs() {
    const items = [
      {
        title: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
      },
      {
        title: "Kommuner",
        href: "/municipalities",
        icon: <PlaceIcon />,
      },
      {
        title: "Samtaler",
        href: "/dialogs",
        icon: <ChatIcon />,
      },
      {
        title: "Borger feedback",
        href: "/ratings",
        icon: <ThumbsUpDownIcon />,
      },
      {
        title: "Test din egen chatbot",
        href: "/train",
        icon: <TextFieldsIcon />,
      },
      {
        title: "Statistik",
        href: "/stats",
        icon: <BarChartIcon />,
      },
    ];

    if (User.isGlobalAdmin()) {
      items.push(
        {
          title: "Watson Discovery",
          href: "/wd/stats",
          icon: <PageviewIcon />,
        },
        {
          title: "Integrationer",
          href: "/integration",
          icon: <ExtensionIcon />,
        },
        {
          title: "Drift",
          href: "/status",
          icon: <BuildIcon />,
        }
      );
    }

    items.push({
      title: "Indstillinger",
      href: "/settings",
      icon: <SettingsIcon />,
    });

    return items;
  }

  getAgentNavs() {
    return [
      {
        title: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
      },
      {
        title: "Kommuner",
        href: "/municipalities",
        icon: <PlaceIcon />,
      },
      {
        title: "Samtaler",
        href: "/dialogs",
        icon: <ChatIcon />,
      },
      {
        title: "Borger feedback",
        href: "/ratings",
        icon: <ThumbsUpDownIcon />,
      },
      {
        title: "Test din egen chatbot",
        href: "/train",
        icon: <TextFieldsIcon />,
      },
      {
        title: "Statistik",
        href: "/stats",
        icon: <BarChartIcon />,
      },
      {
        title: "Indstillinger",
        href: "/settings",
        icon: <SettingsIcon />,
      },
    ];
  }

  render() {
    return (
      <Drawer
        anchor="left"
        classes={{ paper: this.props.classes.drawer }}
        onClose={this.props.onClose}
        open={this.props.open}
        variant={this.props.variant}
      >
        <div>
          <Profile />
          <Divider className={this.props.classes.divider} />
          <SidebarNav
            className={this.props.classes.nav}
            pages={this.state.pages}
          />
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(Sidebar);
