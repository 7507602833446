import React from "react";
import { HeadIconProps } from "./SadHeadIcon";

export const MediumHeadIcon: React.FC<HeadIconProps> = ({ mute, active }) => (
  <div
    className={`medium-head-icon ${mute ? "mute " : ""} ${
      active ? "active" : ""
    }`}
  >
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" />
      <g opacity="1" transform="translate(-4.5 -4.5)">
        <path
          d="M23.8906 23.4062C23.8906 24.5664 22.9238 25.4688 21.8281 25.4688C20.668 25.4688 19.7656 24.5664 19.7656 23.4062C19.7656 22.3105 20.668 21.3438 21.8281 21.3438C22.9238 21.3438 23.8906 22.3105 23.8906 23.4062Z"
          fill="#333333"
        />
        <path
          d="M30.0781 23.4062C30.0781 22.3105 30.9805 21.3438 32.1406 21.3438C33.2363 21.3438 34.2031 22.3105 34.2031 23.4062C34.2031 24.5664 33.2363 25.4688 32.1406 25.4688C30.9805 25.4688 30.0781 24.5664 30.0781 23.4062Z"
          fill="#333333"
        />
        <path
          d="M20.7969 31.6562H33.1719C33.6875 31.6562 34.2031 32.1719 34.2031 32.6875C34.2031 33.2676 33.6875 33.7188 33.1719 33.7188H20.7969C20.2168 33.7188 19.7656 33.2676 19.7656 32.6875C19.7656 32.1719 20.2168 31.6562 20.7969 31.6562Z"
          fill="#333333"
        />
      </g>
    </svg>
  </div>
);
