import React, {Component} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme
} from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import {withStyles} from "@material-ui/styles";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";

const styles = ((theme: Theme) => ({
    root: {}
}));

export type AssistantListProps = {
    isLoading: boolean,
    assistants: any,
    classes: any
};

export type AssistantListState = { values: any };

class AssistantList extends Component<AssistantListProps, AssistantListState> {

    constructor(props: AssistantListProps) {
        super(props);

        this.state = {
            values: {}
        };
    }

    handleChange(event: any) {
        this.setState({
            ...this.state.values,
            [event.target.name]: event.target.value
        });
    }

    render(): any {
        return (
            <Card>
                <form autoComplete="off" noValidate>
                    <CardHeader
                        subheader="Definerede assistenter"
                        title="Assistenter"
                    />
                    <Divider/>
                    <CardContent>
                      <LoadingIndicator isLoading={this.props.isLoading}/>
                        {
                            !this.props.isLoading &&
                            <PerfectScrollbar>
                                <div className={this.props.classes.inner}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Navn</TableCell>
                                                <TableCell>Beskrivelse</TableCell>
                                                <TableCell>Aktiv</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>WA Assistent Id</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.props.assistants.slice(0, this.props.assistants.length).map((assistant: any) => (
                                                <TableRow className={this.props.classes.tableRow}
                                                          hover key={assistant.id}>
                                                    <TableCell>{assistant.assistant.name}</TableCell>
                                                    <TableCell>{assistant.assistant.description}</TableCell>
                                                    <TableCell>{assistant.isActive ? "Aktiv" : "Passiv"}</TableCell>
                                                    <TableCell>{assistant.isMainAssistant ? "General" : "Specifik"}</TableCell>
                                                    <TableCell>{assistant.assistant.assistantId}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </PerfectScrollbar>
                        }
                    </CardContent>
                </form>
            </Card>);
    }
}


export default withStyles(styles)(AssistantList)

