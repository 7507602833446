import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import InfoSvgButton from "../../../../components/InfoButton";
import { FrontDeskDetailsModal } from "./FrontDeskDetailsModal";
import { APIService } from "../../../../service/APIService";
import { Log } from "../../../../common/log";
import { Alert } from "@material-ui/lab";

export const FrontDeskTable: React.FC = () => {
  const [addIntegration, setAddIntegration] = useState(false);
  const [integrations, setIntegrations] = useState<any[] | undefined>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const service = useMemo(() => new APIService(), []);

  const [stateCounter, setStateCounter] = useState(0);
  const [deleteError, setDeleteError] = useState("");

  useEffect(() => {
    let isSubscribed = true;

    service
      .getFrontDeskIntegrations()
      .then((integrations) => isSubscribed && setIntegrations(integrations))
      .catch((err) => Log.error(`Failed to get integrations %o`, err));

    return () => {
      isSubscribed = false;
    };
  }, [service, stateCounter]);

  const deleteIntegration = async (integration: { id: string }) => {
    if (!integrations) return;
    let index = integrations.indexOf(integration);
    try {
      setIntegrations(integrations.filter((i) => i.id !== integration.id));
      await service.deleteFrontDeskIntegration(integration.id);
      setAddIntegration(false);
      setStateCounter((s) => s + 1); // Force a refetch
    } catch (err) {
      Log.error(`Failed to delete integration: %o`, err);
      setIntegrations(Array.from(integrations).splice(index, 0, integration));
      setDeleteError("Kunne ikke slette integrationen. Prøv venligst igen.");
    }
  };

  const toggleIntegration = async (integration: any, isActive: boolean) => {
    if (!integrations) return;
    let index = integrations.indexOf(integration);
    try {
      const changed = [...integrations];
      changed[index] = { ...integration, isActive };
      setIntegrations(changed);
      await service.toggleFrontDeskIntegration(integration.id, isActive);
    } catch (err) {
      alert(`Kunne ikke ${isActive ? "aktivere" : "deaktivere"} integration. Prøv igen.`);
      setIntegrations(integrations); // Reset integrations if we fail
    }
  }

  return (
    <Card>
      <Snackbar
        open={!!deleteError}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setDeleteError("")}
      >
        <Alert severity="error">{deleteError}</Alert>
      </Snackbar>
      <CardHeader
        subheader="Her kan du få et overblik over Front Desk integrationer"
        title={
          <span>
            Front Desk integrationer
            <InfoSvgButton
              title={"Selvbetjening.nu synkronisering"}
              content={
                <Typography>
                  Med Front Desk integrationsmuligheden kan du sammenkoble en
                  kommunekode med en Front Desk API-nøgle. På den måde kan du
                  bygge flows, der muliggør tidsbestilling direkte til Front
                  Desk.
                </Typography>
              }
            />
          </span>
        }
        action={
          <Button
            onClick={() => setAddIntegration(true)}
            startIcon={<AddIcon />}
            color="primary"
            variant="outlined"
          >
            Opret integration
          </Button>
        }
      />
      <FrontDeskDetailsModal
        isOpen={addIntegration}
        onClose={() => {
          setAddIntegration(false);
          setStateCounter((s) => s + 1); // Force a refetch
        }}
      />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kommunekode</TableCell>
                  <TableCell>Kommune</TableCell>
                  <TableCell>API-nøgle</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell style={{ width: 80 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {integrations ? (
                  integrations.length > 0 ? (
                    integrations
                      .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      .map((integration) => (
                        <TableRow key={integration.id}>
                          <TableCell>{integration.municipality.code}</TableCell>
                          <TableCell>{integration.municipality.name}</TableCell>
                          <TableCell>**** **** **** **** ****</TableCell>
                          <TableCell>
                            {integration.isActive ? (
                              <Chip
                                onClick={() => {
                                  if (window.confirm("Er du sikker på at du vil deaktivere integrationen?")) {
                                    toggleIntegration(integration, false);
                                  }
                                }}
                                color="secondary"
                                label="Aktiv"
                                size="small"
                              />
                            ) : (
                              <Chip
                                onClick={() => {
                                  if (window.confirm("Er du sikker på at du vil aktivere integrationen?")) {
                                    toggleIntegration(integration, true);
                                  }
                                }}
                                label="Deaktiveret"
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell style={{ width: 80 }}>
                            <IconButton>
                              <DeleteIcon
                                onClick={() => deleteIntegration(integration)}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align={"center"}>
                        Ingen integrationer oprettet endnu
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align={"center"}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>
        <TablePagination
          style={{ marginLeft: "auto" }}
          labelRowsPerPage="Rækker pr. side"
          labelDisplayedRows={({ from, to, count }) =>
            `${from > count ? count : from}-${to} af ${count}`
          }
          component="div"
          count={integrations?.length || 0}
          onChangePage={(event, page) => setPage(page)}
          onChangeRowsPerPage={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        />
      </CardActions>
    </Card>
  );
};
