import React, {Component} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {Avatar, Typography} from '@material-ui/core';
import {withStyles} from "@material-ui/styles";
import {getInitials} from "../../../../../../helpers/utils";
import {User} from "../../../../../../helpers/profile";
import {Log} from "../../../../../../common/log";

const styles: any = ((theme: any) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content',
        marginTop: theme.spacing(2)
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1)
    }
}));

export type ProfileProps = { className?: any, classes: any };

export type ProfileState = { user: any };

class Profile extends Component<ProfileProps, ProfileState> {

    constructor(props: ProfileProps) {
        super(props);

        this.state = {
            user: {
                name: "",
                email: ""
            }
        }
    }

    async componentDidMount(): Promise<void> {

        if(this.state.user.name === "" || this.state.user.email === "")
            await this.fetchProfile();
    }

    async fetchProfile(): Promise<void> {
        try {
            const profile = User.getProfile();

            if (profile) {
                this.setState({ user: profile });
            }
        } catch (e) {
            Log.error(`Failed loading profile ${e}`);
        }
    }

    getAvatar(): string {

    let avatar = "";
    let names = this.state.user.name.split(" ");

    for(let word of names) {
      avatar += getInitials(word);
    }

    return avatar;
  }

  getDisplayName(): string {

    let displayName = "";
    let names = this.state.user.name.split(" ");

    for(let word of names) {
      displayName += getInitials(word) + word.slice(1, word.length) + " ";
    }

    return displayName;
  }


    render() {
        return (
            <div className={clsx(this.props.classes.root, this.props.className)}>
                <Avatar
                    alt="Person"
                    className={this.props.classes.avatar}
                    component={RouterLink}
                    src={getInitials(this.props.classes.name)}
                    to="/settings">{this.getAvatar()}</Avatar>
                <Typography
                    className={this.props.classes.name}
                    variant="h5">
                    {this.getDisplayName()}
                </Typography>
                <Typography variant="body2">{this.state.user.email}</Typography>
            </div>
        );
    }
}


export default withStyles(styles)(Profile)
