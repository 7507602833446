import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: any) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        borderRadius: "4px"
    },
}));


const InfoModal = (props: InfoModalProps) => {

    // eslint-disable-next-line
    const { className, content, style, title,handleClose, ...rest } = props;
    const [modalStyle] = React.useState(getModalStyle);

    const classes: any = useStyles();

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="info-modal-title">{props.title}</h2>
            <br/>
            {props.content}
            <br/>
            <Button variant="outlined" color={"primary"} onClick={props.handleClose}>Ok</Button>
        </div>
    );

    return (
        <div>
            <Modal
                open={props.isOpen}
                onClose={props.handleClose}
                aria-labelledby="info-modal-title"
                aria-describedby="info-modal-description">
                <div>
                    {body}
                </div>
            </Modal>
        </div>
    );
};

type InfoModalProps = {
    className: string,
    style?: any,
    title: string,
    content: any,
    isOpen: boolean,
    handleClose: any
}

export default InfoModal;
