import {
  Box,
  Card,
  CardActions,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { APIService } from "../../../../service/APIService";
import { IWatsonDiscoverySearchStat } from "../../../../service/interfaces/IWatsonDiscoveryStats";
import { useMunicipalities } from "../../../../hooks/useMunicipalities";

export interface WatsonDiscoveryQueryStatisticsTableProps {
  collectionId?: string;
  municipalityCode: string | number;
  periodStart?: Date;
  periodEnd?: Date;
}

export const WatsonDiscoveryQueryStatisticsTable: React.FC<
  WatsonDiscoveryQueryStatisticsTableProps
> = ({ collectionId, municipalityCode, periodEnd, periodStart }) => {
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const apiService = useMemo(() => new APIService(), []);
  const { municipalities } = useMunicipalities();

  const [searches, setSearches] = useState<IWatsonDiscoverySearchStat[] | null>(
    null
  );

  useEffect(() => {
    if (municipalityCode === undefined || municipalityCode === null) return;
    let isSub = true;
    setSearches(null);
    async function fetch() {
      const searches = await apiService.getWatsonDiscoverySearchStats({
        municipalityCode,
        collectionId,
        periodEnd,
        periodStart,
      });
      if (isSub) setSearches(searches as any);
    }
    fetch();
    return () => {
      isSub = false;
    };
  }, [apiService, collectionId, municipalityCode, periodStart, periodEnd]);

  return (
    <Card>
      <CardHeader title="Grupperet efter søgning" />
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Kommune</TableCell>
            <TableCell>Søgning</TableCell>
            <TableCell>Antal søgninger</TableCell>
            <TableCell>Antal klik</TableCell>
            <TableCell>Konvertering</TableCell>
            <TableCell style={{ width: 40 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!searches && (
            <TableRow>
              <TableCell colSpan={6}>
                <LoadingIndicator isLoading={true} />
              </TableCell>
            </TableRow>
          )}
          {searches &&
            searches
              .slice(
                pagination.page * pagination.rowsPerPage,
                (pagination.page + 1) * pagination.rowsPerPage
              )
              .map((s, i) => (
                <Row
                  key={`${s.query}+${i}+${s.municipalityCode}+${pagination.page}`}
                  stat={s}
                  municipalities={municipalities}
                />
              ))}
        </TableBody>
      </Table>
      <CardActions>
        <TablePagination
          style={{ marginLeft: "auto" }}
          labelRowsPerPage="Rækker pr. side"
          labelDisplayedRows={({ from, to, count }) =>
            `${from > count ? count : from}-${to} af ${count}`
          }
          component="div"
          count={searches?.length || 0}
          onChangePage={(e, page) => setPagination((p) => ({ ...p, page }))}
          onChangeRowsPerPage={(e) =>
            setPagination((p) => ({ ...p, rowsPerPage: e.target.value as any }))
          }
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        />
      </CardActions>
    </Card>
  );
};

const Row = ({
  stat,
  municipalities,
}: {
  stat: IWatsonDiscoverySearchStat;
  municipalities?: any[];
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell style={{ borderBottom: "none" }}>
          {stat.municipalityCode} (
          {municipalities?.find((m) => m.code === stat.municipalityCode)?.name})
        </TableCell>
        <TableCell style={{ borderBottom: "none" }}>{stat.query}</TableCell>
        <TableCell style={{ borderBottom: "none" }}>{stat.searches}</TableCell>
        <TableCell style={{ borderBottom: "none" }}>{stat.clicks}</TableCell>
        <TableCell style={{ borderBottom: "none" }}>
          {(stat.conversion * 100).toFixed(0)}%
        </TableCell>
        <TableCell style={{ borderBottom: "none" }}>
          <Box display="flex" alignItems={"center"} justifyContent={"flex-end"}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen((o) => !o)}
            >
              <KeyboardArrowUp
                style={{
                  rotate: open ? "180deg" : undefined,
                  transition: "rotate 200ms ease-in-out",
                }}
              />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#fafafa",
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card style={{ marginTop: 8, marginBottom: 16 }}>
              <Box padding={2} paddingBottom={1}>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography variant="overline">URL</Typography>
                  <Typography variant="overline">
                    Antal gange klikket
                  </Typography>
                </Box>
                {stat.urlClicks?.map((result) => (
                  <Box
                    key={result.url}
                    display="flex"
                    justifyContent="space-between"
                    borderTop="1px solid rgba(0,0,0,0.1)"
                    paddingY={1}
                  >
                    <Typography variant="body1">{result.url}</Typography>
                    <Typography variant="body1">{result.count}</Typography>
                  </Box>
                ))}
              </Box>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
