import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Theme } from '@material-ui/core';

import { APIService } from "../../../../service/APIService";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import { Log } from "../../../../common/log";
import Guides from "./components/Guides";
import { browserHistory } from "../../../../App";
import Tags from "./components/Tags";
import { User } from "../../../../helpers/profile";

const styles = ((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  }
}));

export type SbIntegrationProps = { match: any, classes: any };

export type SbIntegrationState = {
  isLoading: boolean,
  municipality: any,
  guides: any[],
  tags: any[]
};

class SbIntegration extends Component<SbIntegrationProps, SbIntegrationState> {

  private service: APIService = new APIService();

  constructor(props: SbIntegrationProps) {
    super(props);

    this.state = {
      isLoading: true,
      municipality: {
        assistants: [],
        assistantContexts: [],
        chatCategories: []
      },
      guides: [],
      tags: []
    }
  }

  async componentDidMount(): Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    await Promise.all([
      this.fetchMunicipality(),
      this.fetchGuides(),
      this.fetchTags()
    ]);

    setTimeout(() => this.setState({ isLoading: false }), 500);
  }

  async fetchMunicipality(): Promise<void> {

    try {
      const res = await this.service.getMunicipality(this.props.match.params.code, true);

      if (res) {
        this.setState({ municipality: res });
      }
    } catch (e) {
      Log.error(`Failed loading municipality ${e}`);
    }

  }

  sortByTagId = (list: any[]): any[] => {
    return list.sort((a: any, b: any) => {
      try {
        let aT: string[] = a.tagId.split(".");
        let bT: string[] = b.tagId.split(".");
        return (parseInt(aT[0]) - parseInt(bT[0])) || (parseInt(aT[1]) - parseInt(bT[1]));
      } catch (err) {
        // Fallback if something fails (which it should not if tagId is defined)
        return 0;
      }
    });
  }

  async fetchGuides(): Promise<void> {

    try {
      const res = await this.service.getAllGuides(this.props.match.params.code);

      if (res) {
        this.setState({ guides: this.sortByTagId(res) });
      }
    } catch (e) {
      Log.error(`Failed loading guides ${e}`);
    }

  }

  async fetchTags(): Promise<void> {

    try {
      const res = await this.service.getAllTags();

      if (res) {
        this.setState({ tags: this.sortByTagId(res) });
      }
    } catch (e) {
      Log.error(`Failed loading tags ${e}`);
    }

  }

  showGuideDetails(item: any): void {
    browserHistory.push(`/municipalities/${this.state.municipality.code}/sb/guide/${item.tagId}`);
  }

  showTagDetails(item: any): void {
    browserHistory.push(`/municipalities/${this.state.municipality.code}/sb/tag/${item.tagId}`);
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <RouterLink color="inherit" to="/municipalities" >
              <Typography color="textPrimary">Kommuner</Typography>
            </RouterLink>
            <RouterLink color="inherit" to={`/municipalities/${this.state.municipality.code}`}>
              <Typography color="textPrimary">{this.state.municipality.name}</Typography>
            </RouterLink>
            <Typography color="textPrimary">Selvbetjening.nu konfiguration</Typography>
          </Breadcrumbs>
          <span className={this.props.classes.spacer} />
        </div>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Guides
              isLoading={this.state.isLoading}
              onClick={this.showGuideDetails.bind(this)}
              guides={this.state.guides}
            />
          </Grid>
          {
            User.isGlobalAdmin() &&
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Tags
                isLoading={this.state.isLoading}
                tags={this.state.tags}
                onClick={this.showTagDetails.bind(this)}
                onRefresh={this.fetchData.bind(this)}
              />
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SbIntegration)
