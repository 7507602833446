import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Theme} from '@material-ui/core';

import {AssistantList, ContextVariables, MunicipalityProfile, PresentationSettings} from './components';
import {APIService, PresentationConfig} from "../../../../service/APIService";
import Categories from "./components/Categories";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Log} from "../../../../common/log";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    }
}));

export type AssistantSettingsProps = { match: any, classes: any };

export type AssistantSettingsState = {
    isLoading: boolean,
    isEdit: boolean,
    alert: {
      isAlertOpen: boolean,
        alertMessage: string,
        alertType: "success" | "error" | "info" | "warning",
    },
    presentation: any,
    municipality: any };

class AssistantSettings extends Component<AssistantSettingsProps, AssistantSettingsState> {

    private service: APIService = new APIService();

    constructor(props: AssistantSettingsProps) {
        super(props);

        this.state = {
            isLoading: true,
            isEdit: false,
            alert: {
                isAlertOpen: false,
                alertMessage: "",
                alertType: "success"
            },
            presentation: {},
            municipality: {
              assistants: [],
              assistantContexts: [],
              chatCategories: []
            }
        }
    }

    async componentDidMount(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {

        if (!this.state.isLoading){
            this.setState({ isLoading: true });
        }

        await Promise.all([
            this.fetchMunicipality(),
            this.fetchPresentationConfig()
        ]);

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async fetchMunicipality(): Promise<void> {

        try {
            const res = await this.service.getMunicipality(this.props.match.params.code);

            if (res) {
                this.setState({ municipality: res });
            }
        } catch (e) {
            Log.error(`Failed loading municipality ${e}`);
        }

    }

    async fetchPresentationConfig(): Promise<void> {

        try {
            const res = await this.service.getMunicipalityPresentationConfig(this.props.match.params.code);

            if (res) {
                this.setState({ presentation: res });
            }
        } catch (e) {
            Log.error(`Failed loading presentation config ${e}`);
        }

    }

    async handleDeleteCategory(category: any, isGlobal: boolean){
        try {
            await this.service.deleteMunicipalityCategory(this.state.municipality.id, category.id, isGlobal);
            this.setState({
                alert: {
                    isAlertOpen: true,
                    alertMessage: `Kategorien med navnet '${category.tagName}' er slettet`,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            this.setState({
                alert: {
                    isAlertOpen: true,
                    alertMessage: `Der skete en fejl under sletningen af kategorien med navnet '${category.tagName}'`,
                    alertType: "error"
                }
            });
            Log.log("Failed deleting categroy ", e);
        }
        await this.fetchData();
    }

    async handleCreateCategory(category: any){
        Log.log("category:", category);
        try {
            await this.service.createMunicipalityCategory(this.state.municipality.id, category);
            this.setState({
                alert: {
                    alertMessage: `Kategorien med navnet '${category.tagName}' er oprettet`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under oprettelsen af kategorien med navnet '${category.tagName}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
            Log.log("Failed deleting categroy ", e);
        }
        await this.fetchData();
    }

    async handleDeleteContext(context: any){
        try {
            await this.service.deleteMunicipalityContext(this.state.municipality.id, context.id);
            this.setState({
                alert: {
                    alertMessage: `Kontekst variablen med navnet '${context.key}' er slettet`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under sletningen af kontekst variablen med navnet '${context.key}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
            Log.log("Failed deleting context ", e);
        }
        await this.fetchData();
    }

    async handleCreateContext(context: any){
        Log.log("context:", context);
        try {
            await this.service.createMunicipalityContext(this.state.municipality.id, context);
            this.setState({
                alert: {
                    alertMessage: `Kontekst variablen med navnet '${context.key}' er oprettet`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under oprettelsen af kontekst variablen med navnet '${context.key}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
            Log.log("Failed deleting context ", e);
        }
        await this.fetchData();
    }

    async handleEditContext(context: any, savedContext: any){
        Log.log("context:", context);
        try {
            await this.service.deleteMunicipalityContext(this.state.municipality.id, context.id);
            await this.service.createMunicipalityContext(this.state.municipality.id, savedContext);
            this.setState({
                alert: {
                    alertMessage: `Kontekst variablen med navnet '${context.key}' er redigeret`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under redigeringen af kontekst variablen med navnet '${context.key}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
            Log.log("Failed editing context ", e);
        }
        await this.fetchData();
    }

    async handleEditCategory(category: any, savedCategory: any, isGlobal: boolean){
        Log.log("context:", category);
        try {
            await this.service.deleteMunicipalityCategory(this.state.municipality.id, category.id, isGlobal);
            await this.service.createMunicipalityCategory(this.state.municipality.id, savedCategory);
            this.setState({
                alert: {
                    alertMessage: `Kontekst variablen med navnet '${category.key}' er redigeret`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under redigeringen af kontekst variablen med navnet '${category.key}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
            Log.log("Failed editing context ", e);
        }
        await this.fetchData();
    }

    async handlePresentationSettingSave(config: PresentationConfig) {
        Log.log("presentation config:", config);
        try {
            await this.service.updateMunicipalityPresentationConfig(this.state.municipality.code, config);
            this.setState({
                alert: {
                    alertMessage: `Præsentationsindstillingerne er nu opdateret`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under opdateringen af præsentationsindstillingerne`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
            Log.log("Failed deleting context ", e);
        }
        await this.fetchData();
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <RouterLink color="inherit" to="/municipalities" >
                            <Typography color="textPrimary">Kommuner</Typography>
                        </RouterLink>
                        <RouterLink color="inherit" to={`/municipalities/${this.state.municipality.code}`} >
                            <Typography color="textPrimary">{this.state.municipality.name}</Typography>
                        </RouterLink>
                        <Typography color="textPrimary">Chatbot konfiguration</Typography>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer}/>
                </div>
                <Grid container spacing={4}>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                        <MunicipalityProfile
                            isLoading={this.state.isLoading}
                            municipality={this.state.municipality}/>
                    </Grid>
                    <Grid item lg={8} md={6} xl={8} xs={12}>
                        <AssistantList
                            isLoading={this.state.isLoading}
                            assistants={this.state.municipality.assistants}/>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                        <PresentationSettings
                            onSave={this.handlePresentationSettingSave.bind(this)}
                            isLoading={this.state.isLoading}
                            config={this.state.presentation}
                        />
                    </Grid>
                    <Grid item lg={6} md={12} xl={6} xs={12}>
                        <ContextVariables
                            isLoading={this.state.isLoading}
                            contexts={this.state.municipality.assistantContexts}
                            onCreate={this.handleCreateContext.bind(this)}
                            onDelete={this.handleDeleteContext.bind(this)}
                            onEdit={this.handleEditContext.bind(this)}
                        />
                    </Grid>
                    <Grid item lg={6} md={12} xl={6} xs={12}>
                        <Categories
                            isLoading={this.state.isLoading}
                            categories={this.state.municipality.chatCategories}
                            onCreate={this.handleCreateCategory.bind(this)}
                            onEdit={this.handleEditCategory.bind(this)}
                            onDelete={this.handleDeleteCategory.bind(this)}
                        />
                    </Grid>
                </Grid>
                <Snackbar open={this.state.alert.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={() => this.setState({alert: { ...this.state.alert, isAlertOpen: false }})}>
                    <Alert severity={this.state.alert.alertType}>
                        {this.state.alert.alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(styles)(AssistantSettings)
