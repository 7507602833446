import React, {Component} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Breadcrumbs, Grid, Typography} from '@material-ui/core';
import {DashboardRedirectCard} from "./components";
import ChatIcon from '@material-ui/icons/ChatRounded';
import PlaceIcon from '@material-ui/icons/PlaceRounded';
import TextFieldsIcon from '@material-ui/icons/TextFieldsRounded';
import BarChartIcon from '@material-ui/icons/BarChartRounded';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDownRounded';
import ExtensionIcon from '@material-ui/icons/ExtensionRounded';
import BuildIcon from '@material-ui/icons/PowerRounded';
import {withStyles} from "@material-ui/styles";
import {ProfileProps} from "../../layouts/Main/components/Sidebar/components/Profile/Profile";
import {User} from "../../helpers/profile";
import {setState} from "../../common/promisify";

const styles = ((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  iconSizes: {
    fontSize: 140,
    color: theme.palette.primary.main
  }
}));

export type DashboardProps = { classes: any };

export type DashboardState = {
  isAdmin: boolean,
  isAgent: boolean,
  cards: any[]
};

class Dashboard extends Component<DashboardProps, DashboardState> {

  constructor(props: ProfileProps) {
    super(props);

    this.state = {
      isAdmin: false,
      isAgent: false,
      cards: []
    }
  }

  async componentDidMount(): Promise<void> {
    await setState(this, { isAdmin: User.isAdmin(), isAgent: User.isAgent() });

    let cards: any[] = [];

    if (this.state.isAdmin)
      cards = this.getAdminCards();
    else if (this.state.isAgent)
      cards = this.getAgentCards();

    this.setState({ cards: cards })
  }

  getAdminCards() {

    const cards = [
      {
        title: 'Kommuner',
        url: '/municipalities',
        description: 'Rediger chatbot indstillinger for de kommuner din bruger er tilknyttet.',
        color: '',
        icon: <PlaceIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Samtaler',
        url: '/dialogs',
        description: 'Find en given samtale eller søg i listen over samtaler med chatbotten.',
        color: '',
        icon: <ChatIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Borger feedback',
        url: '/ratings',
        description: 'Se feedback og ratings fra borgere som har bedømt kommunens chatbot.',
        color: '',
        icon: <ThumbsUpDownIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Test din egen chatbot',
        url: '/train',
        description: 'Afprøv chatbottens udseende og indlæsning af kategori.',
        color: '',
        icon: <TextFieldsIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Statistik',
        url: '/stats',
        description: 'Se statistik over samtaler.',
        color: '',
        icon: <BarChartIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Drift',
        url: '/status',
        description: 'Se tilgængeligheden af chatbotten.',
        color: '',
        icon: <BuildIcon className={this.props.classes.iconSizes} />
      },
    ];

    if (User.isGlobalAdmin()) {
      cards.push(
        {
          title: 'Integrationer',
          url: '/integration',
          description: 'Se integrationer der indgår i chatbotten.',
          color: '',
          icon: <ExtensionIcon className={this.props.classes.iconSizes} />
        }
      );
    }

    cards.push(
      {
        title: 'Indstillinger',
        url: '/settings',
        description: 'Administrér dine brugerindstilinger.',
        color: '',
        icon: <SettingsIcon className={this.props.classes.iconSizes} />
      }
    );

    return cards;
  }

  getAgentCards() {
    return [
      {
        title: 'Kommuner',
        url: '/municipalities',
        description: 'Rediger chatbot indstillinger for de kommuner din bruger er tilknyttet.',
        color: '',
        icon: <PlaceIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Borger feedback',
        url: '/ratings',
        description: 'Se feedback og ratings fra borgere som har bedømt kommunens chatbot.',
        color: '',
        icon: <ThumbsUpDownIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Test din egen chatbot',
        url: '/train',
        description: 'Afprøv chatbottens udseende og indlæsning af kategori.',
        color: '',
        icon: <TextFieldsIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Samtaler',
        url: '/dialogs',
        description: 'Find en given samtale eller søg i listen over samtaler med chatbotten.',
        color: '',
        icon: <ChatIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Statistik',
        url: '/stats',
        description: 'Se statistik over samtaler.',
        color: '',
        icon: <BarChartIcon className={this.props.classes.iconSizes} />
      },
      {
        title: 'Indstillinger',
        url: '/settings',
        description: 'Administrér dine brugerindstilinger.',
        color: '',
        icon: <SettingsIcon className={this.props.classes.iconSizes} />
      }
    ];
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Dashboard</Typography>
          </Breadcrumbs>
          <span className={this.props.classes.spacer} />
        </div>
        <Grid container spacing={4}>
          {this.state.cards.map((card: any) => (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <DashboardRedirectCard
                title={card.title}
                description={card.description}
                url={card.url}
                image={card.image}
                icon={card.icon}
                color={card.color}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

}

export default withStyles(styles)(Dashboard);

