import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Card, CardContent, CardHeader, CardMedia, Divider, Theme, Typography} from '@material-ui/core';
import {Link} from "react-router-dom";

const styles: any = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  iconCard: {
    display:'flex',
    justifyContent:'center',
    height: "200px",
    objectFit: "fill"
  },
  media: {
    height: "180px",
    objectFit: "fill"
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

export type ActionCardProps = {
  classes: any,
  title: string,
  description: string,
  url?: string,
  image?: string,
  icon?: any,
  content?: any
};
export type ActionCardState = {};

class ActionCard extends Component<ActionCardProps, ActionCardState>{

  constructor(props: ActionCardProps) {
    super(props);

    this.state = {};
  }

  getContent() {
    return (
        <Card className={this.props.classes.root}>
          <CardHeader title={this.props.title} />
          <Divider/>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {this.props.description}
            </Typography>
            {
              this.props.content &&
              <div>{ this.props.content }</div>
            }
          </CardContent>
          {
            this.props.image &&
            <CardMedia
                component="img"
                className={this.props.classes.media}
                image={this.props.image}/>
          }
          {
            this.props.icon &&
            <CardContent className={this.props.classes.iconCard}>
              {this.props.icon}
            </CardContent>
          }
        </Card>
    );
  }

  render() {
    return (
        <div className={this.props.classes.root}>
          {this.props.url &&
            <Link to={this.props.url}>
              { this.getContent() }
            </Link>
          }
          { !this.props.url && this.getContent() }
        </div>
    );
  }
}

export default withStyles(styles)(ActionCard);
