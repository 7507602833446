import React, {useState} from 'react';
import clsx from 'clsx';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core';

import {Footer, Sidebar, Topbar} from './components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = (props: MainProps) => {
  const { children } = props;

  const classes: any = useStyles();
  const theme: any = useTheme();
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })} >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'} />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

type MainProps = {
  children: any,
};

export default Main;
