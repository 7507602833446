import { useState, useMemo, useEffect } from "react";
import { APIService } from "../service/APIService";

export function useMunicipalities() {
  const [municipalities, setMunicipalities] = useState<any[]>();
  const [isFetching, setIsFetching] = useState(false);
  const api = useMemo(() => new APIService(), []);

  useEffect(() => {
    setIsFetching(true);
    let isSub = true;
    api.getMunicipalities(100, 0, true, false).then((res) => {
      if (isSub) {
        setIsFetching(false);
        setMunicipalities(res);
      }
    });
    return () => {
      isSub = false;
    };
  }, [api]);

  return {
    municipalities,
    isFetching,
  };
}
