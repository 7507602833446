import React, { Component, Ref, RefObject } from "react";
import { withStyles } from "@material-ui/styles";
import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import DialogsCount from "./components/DialogsCount";
import { StatisticsAPIService } from "../../service/StatisticsAPIService";
import { APIService } from "../../service/APIService";
import MunicipalitySelection from "./components/MunicipalitySelection";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  StatisticsResponseObject,
  TimeResolution,
} from "../../common/statistics";
import { generateColorsForPie } from "./components/UsersByDevice/UsersByDevice";
import { setState } from "../../common/promisify";
import { Log } from "../../common/log";
import { PageState } from "../../helpers/PageState";
import { TimePeriod } from "./components/MunicipalitySelection/MunicipalitySelection";
import {
  CallbacksCount,
  RatingsAverage,
  RatingsCount,
  SubjectStats,
  UsersByDevice,
} from "./components";
import { DialogsStatistics } from "./components/Statistics";
import { DialogsByTime } from "./components/DialogsByTime";

const styles: any = (theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  cards: {
    padding: theme.spacing(2),
  },
  iframe: {
    width: "100%",
    minHeight: 640,
    border: 0,
  },
});

export type StatsProps = { classes: any };
export type StatsState = {
  isMunicipalitiesLoading: boolean;
  isStatsLoading: number;
  municipalities: [];
  platforms: any;
  categories: any;
  selectedMunicipality: number;
  selectedMunicipalityId: number;
  selectedSubject: string;
  timeStart: Date;
  timeEnd: Date;
  period: TimePeriod;
  stats: {
    callbackCount: number;
    dialogsCount: number;
    ratingCount: number;
    ratingCountOutOfScope: number;
    ratingCountInScope: number;
    ratingAvg: number;
    ratingAvgInScope: number;
    ratingAvgOutOfScope: number;
  };
  conversations: {
    filter: {
      date: Date;
      resolution: TimeResolution;
    };
    dialogs: any;
  };
  dialogsByTime: any;
  subjectStats: {
    subjects: any[];
    totalRows: number;
  };
};

class Stats extends Component<StatsProps, StatsState> {
  private selectionRef: Ref<any>;
  private tableRef: Ref<any>;
  private readonly pageKey: string = "stats";
  private apiService: APIService = new APIService();
  private statsService: StatisticsAPIService = new StatisticsAPIService();

  constructor(props: StatsProps) {
    super(props);

    this.selectionRef = React.createRef();
    this.tableRef = React.createRef();

    const currentDate = new Date();
    this.state = {
      isMunicipalitiesLoading: true,
      isStatsLoading: 0,
      municipalities: [],
      selectedMunicipality: 0,
      selectedMunicipalityId: -1,
      selectedSubject: "",
      timeStart: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      timeEnd: currentDate,
      period: "thisMonth",
      stats: {
        callbackCount: 0,
        dialogsCount: 0,
        ratingCount: 0,
        ratingAvg: 0,
        ratingCountOutOfScope: 0,
        ratingCountInScope: 0,
        ratingAvgInScope: 0,
        ratingAvgOutOfScope: 0,
      },
      conversations: {
        filter: {
          date: currentDate,
          resolution: "day",
        },
        dialogs: {
          data: {
            labels: [],
            datasets: [
              {
                label: "Antal Samtaler",
                backgroundColor: "rgba(40, 159, 117, 1)",
                data: [],
              },
            ],
          },
        },
      },
      dialogsByTime: {
        data: {
          datasets: [],
          labels: [],
          total: 0,
        },
      },
      platforms: {
        data: {
          datasets: [],
          labels: [],
          total: 0,
        },
      },
      categories: {
        data: {
          datasets: [],
          labels: [],
          total: 0,
        },
      },
      subjectStats: {
        subjects: [],
        totalRows: 0,
      },
    };
  }

  async componentDidMount(): Promise<void> {
    if (PageState.hasState(this.pageKey)) {
      const state = PageState.get(this.pageKey);
      if (this.isPageStateValid(state)) await this.setPageState(state);
    }

    await this.fetchMunicipalities();
  }

  componentWillUnmount() {
    PageState.save(this.pageKey, {
      selectedMunicipality: this.state.selectedMunicipality,
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      period: this.state.period,
    });
  }

  async fetchMunicipalities() {
    try {
      const municipalities = await this.apiService.getMunicipalities(
        100,
        0,
        true,
        false
      );
      this.setState({ municipalities: municipalities });
    } catch (e) {
      Log.error(`Failed fetching municipalities with reason ${e}`);
    }
  }

  async loadStats() {
    if (!this.state.isStatsLoading)
      if (this.state.isStatsLoading !== 1) this.setState({ isStatsLoading: 1 });

    await Promise.all([
      this.fetchCallbacksCount(
        this.state.selectedMunicipality,
        this.state.timeStart,
        this.state.timeEnd,
        this.state.selectedSubject
      ),
      this.fetchDialogsCount(
        this.state.selectedMunicipality,
        this.state.timeStart,
        this.state.timeEnd,
        this.state.selectedSubject
      ),
      this.fetchRatingsAverage(
        this.state.selectedMunicipality,
        this.state.timeStart,
        this.state.timeEnd,
        this.state.selectedSubject
      ),
      this.fetchDialogStatistics(
        this.state.selectedMunicipality,
        this.state.timeStart,
        this.state.timeEnd,
        this.state.selectedSubject
      ),
      this.fetchPlatforms(
        this.state.selectedMunicipality,
        this.state.timeStart,
        this.state.timeEnd,
        this.state.selectedSubject
      ),
      this.fetchDialogsByTime(
        this.state.selectedMunicipality,
        this.state.timeStart,
        this.state.timeEnd,
        this.state.selectedSubject
      ),
      this.fetchSubjects(
        `${this.state.selectedMunicipality}`,
        this.state.timeStart,
        this.state.timeEnd
      ),
    ]);

    setTimeout(() => this.setState({ isStatsLoading: 2 }), 500);
  }

  async fetchCallbacksCount(
    municipalityCode: number,
    begin?: Date,
    end?: Date,
    subject?: string
  ) {
    try {
      const count: number = await this.statsService.getCount(
        "callback-requested",
        municipalityCode,
        begin,
        end,
        subject
      );
      this.setState({ stats: { ...this.state.stats, callbackCount: count } });
    } catch (e) {
      Log.error(`Failed fetching callback count with reason ${e}`);
    }
  }

  async fetchDialogsCount(
    municipalityCode: number,
    begin?: Date,
    end?: Date,
    subject?: string
  ) {
    try {
      const count: number = await this.statsService.getCount(
        "dialogs-total",
        municipalityCode,
        begin,
        end,
        subject
      );
      this.setState({ stats: { ...this.state.stats, dialogsCount: count } });
    } catch (e) {
      Log.error(`Failed fetching dialogs count with reason ${e}`);
    }
  }

  async fetchRatingsAverage(
    municipalityCode: number,
    begin?: Date,
    end?: Date,
    subject?: string
  ) {
    try {
      let res: any = await this.statsService.getCount(
        "ratings-count-and-avg",
        municipalityCode,
        begin,
        end,
        subject
      );

      this.setState({
        stats: {
          ...this.state.stats,
          ratingAvg: res.avg || 0,
          ratingCount: res.count || 0,
          ratingCountOutOfScope: res.countOutOfScope || 0,
          ratingCountInScope: res.countInScope || 0,
          ratingAvgInScope: res.avgInScope || 0,
          ratingAvgOutOfScope: res.avgOutOfScope || 0,
        },
      });
    } catch (e) {
      Log.error(`Failed fetching average of ratings with reason ${e}`);
    }
  }

  async fetchDialogStatistics(
    municipalityCode: number,
    start: Date,
    end: Date,
    subject?: string
  ) {
    try {
      const dialogStatData: StatisticsResponseObject =
        await this.statsService.getDialogStats(
          municipalityCode,
          new Date(start),
          new Date(end),
          this.state.conversations.filter.resolution,
          subject
        );

      const dialogs: any = {
        resolution: dialogStatData.resolution,
        data: {
          datasets: [
            {
              label: "Antal Samtaler",
              backgroundColor: "rgba(40, 159, 117, 1)",
              borderColor: "rgba(55, 237, 173, 1)",
              data: dialogStatData.data,
              categoryPercentage: 1,
              barPercentage: 1,
              maxBarThickness: 15,
              spanGaps: true,
              fill: false,
              pointRadius: 6,
            },
          ],
          labels: [],
        },
      };

      await setState(this, {
        conversations: { ...this.state.conversations, dialogs: dialogs },
      });
    } catch (e) {
      Log.error("Failed loading dialog stats with reason: ", e);
    }
  }

  async fetchPlatforms(
    municipalityCode: number,
    begin: Date,
    end: Date,
    subject?: string
  ): Promise<void> {
    try {
      const platforms = await this.statsService.getUsersByDeviceStats(
        municipalityCode,
        begin,
        end,
        subject
      );

      if (
        platforms &&
        platforms.datasets &&
        Array.isArray(platforms.datasets) &&
        platforms.datasets.length > 0
      ) {
        const statDataColors = generateColorsForPie(platforms.datasets[0].data);
        platforms.datasets[0].backgroundColor = statDataColors;
        await setState(this, {
          platforms: {
            data: {
              datasets: platforms.datasets,
              labels: platforms.labels,
              total: platforms.total,
            },
          },
        });
      }
    } catch (e) {
      Log.error("Failed loading platform stats with reason: ", e);
    }
  }

  async fetchCategories(
    municipalityCode: number,
    begin?: Date,
    end?: Date
  ): Promise<void> {
    try {
      const categories = await this.statsService.getCategoriesHitStats(
        municipalityCode,
        begin,
        end
      );

      if (
        categories &&
        categories.datasets &&
        Array.isArray(categories.datasets) &&
        categories.datasets.length > 0
      ) {
        const statDataColors = generateColorsForPie(
          categories.datasets[0].data
        );
        categories.datasets[0].backgroundColor = statDataColors;
        await setState(this, {
          categories: {
            data: {
              datasets: categories.datasets,
              labels: categories.labels,
              total: categories.total,
            },
          },
        });
      }
    } catch (e) {
      Log.error("Failed loading platform stats with reason: ", e);
    }
  }

  async fetchSubjects(
    selectedMunicipality: string,
    begin: Date,
    end: Date
  ): Promise<void> {
    try {
      const municipality: any[] = this.state.municipalities.filter(
        (el: any) => `${el.code}` === selectedMunicipality
      );
      const municipalityId =
        municipality && municipality.length > 0 ? municipality[0].id : -1;

      await this.setState({
        ...this.state,
        selectedMunicipalityId: municipalityId,
      });
      const res = await this.statsService.getSubjects(
        municipalityId,
        begin,
        end
      );

      if (
        res &&
        res.subjects !== undefined &&
        Array.isArray(res.subjects) &&
        res.total !== undefined
      ) {
        this.setState({
          subjectStats: {
            subjects: res.subjects || [],
            totalRows: res.total || 0,
          },
        });
      }

      let total = 0;

      this.state.subjectStats.subjects.forEach((value: any, index: number) => {
        total += parseInt(value.subject_count);
      });

      this.state.subjectStats.subjects.forEach((value: any, index: number) => {
        value.percent = `${Math.round((value.subject_count / total) * 100)}%`;
      });
    } catch (e) {
      Log.error(`Failed loading subjects`);
    }
  }

  async fetchDialogsByTime(
    municipalityCode: number,
    begin: Date,
    end: Date,
    subject?: string
  ) {
    try {
      const res = await this.statsService.getDialogsByTime(
        municipalityCode,
        begin,
        end,
        subject
      );
      const times = new Array(24).fill(0).map((_, i) => i);
      const dialogsByTime = {
        data: {
          labels: times,
          datasets: [
            {
              label: "Antal samtaler i timen",
              backgroundColor: "rgba(40, 159, 117, 1)",
              borderColor: "rgba(55, 237, 173, 1)",
              data: times.map(
                (t) => res?.data.find((d: any) => d.x === t) ?? { x: t, y: 0 }
              ),
            },
          ],
        },
      };
      await setState(this, {
        ...this.state,
        dialogsByTime,
      });
      // await setState(this, { categories: { data: { datasets: categories.datasets, labels: categories.labels, total: categories.total } } });
    } catch (e) {
      Log.error("Failed loading dialogs by time");
    }
  }

  async handleRefresh() {
    await this.loadStats();
  }

  async handleExport() {
    try {
      const res = await this.statsService.exportToXLSX({
        periodStart: this.state.timeStart,
        periodEnd: this.state.timeEnd,
        municipalityCode: this.state.selectedMunicipality,
        subject: this.state.selectedSubject,
      });
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "statistik.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      Log.log(`Failed eksporting the dialogs with reason ${e}`);
    }
  }

  async handleMunicipalityChanged(code: number) {
    await setState(this, { selectedMunicipality: code });
    await this.handleRefresh();
  }

  async handleDateRangeChanged(
    timeStart: Date,
    timeEnd: Date,
    period: TimePeriod
  ) {
    await setState(this, {
      timeStart: timeStart,
      timeEnd: timeEnd,
      period,
      conversations: {
        ...this.state.conversations,
        filter: {
          ...this.state.conversations.filter,
          date: timeStart,
          resolution: this.computeTimeResolution(timeStart, timeEnd),
        },
      },
    });
    await this.handleRefresh();
  }

  private async handleSubjectChanged(subject: string) {
    await setState(this, {
      selectedSubject: subject,
    });
    await this.handleRefresh();
  }

  private computeTimeResolution(start: Date, end: Date): TimeResolution {
    // Compute time resolution
    const distanceInDays =
      (new Date(end).getTime() - new Date(start).getTime()) /
      (1000 * 60 * 60 * 24);
    const resolution: TimeResolution =
      distanceInDays <= 1 / 24
        ? "minute"
        : distanceInDays <= 1
        ? "hour"
        : distanceInDays <= 65
        ? "day"
        : distanceInDays <= 120
        ? "week"
        : "month";

    return resolution;
  }

  isPageStateValid(state: any): boolean {
    return (
      state &&
      state.selectedMunicipality !== undefined &&
      state.timeStart !== undefined &&
      state.timeEnd !== undefined
    );
  }

  async setPageState(state: any): Promise<any> {
    await setState(this, {
      ...this.state,
      selectedMunicipality: state.selectedMunicipality,
      timeStart: new Date(state.timeStart),
      timeEnd: new Date(state.timeEnd),
      period: state.period,
    });
    await this.handleDateRangeChanged(
      this.state.timeStart,
      this.state.timeEnd,
      state.period
    );
    if (this.selectionRef)
      (this.selectionRef as RefObject<any>).current.setValues(
        this.state.selectedMunicipality,
        this.state.timeStart,
        this.state.timeEnd,
        this.state.period
      );
    this.handleRefresh();
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Statistik</Typography>
          </Breadcrumbs>
          <span className={this.props.classes.spacer} />
        </div>
        <Grid container spacing={4}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <MunicipalitySelection
              ref={this.selectionRef}
              isRefreshEnabled={this.state.selectedMunicipality !== 0}
              onMunicipalitySelected={this.handleMunicipalityChanged.bind(this)}
              onDateRangeSelected={this.handleDateRangeChanged.bind(this)}
              onSubjectSelected={this.handleSubjectChanged.bind(this)}
              onRefresh={this.handleRefresh.bind(this)}
              municipalities={this.state.municipalities}
              onExport={this.handleExport.bind(this)}
            />
          </Grid>
          {this.state.isStatsLoading === 1 && (
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <LoadingIndicator isLoading={this.state.isStatsLoading === 1} />
            </Grid>
          )}
          {this.state.isStatsLoading === 2 && (
            <Grid container spacing={4} className={this.props.classes.cards}>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <DialogsCount count={this.state.stats.dialogsCount} />
              </Grid>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <CallbacksCount count={this.state.stats.callbackCount} />
              </Grid>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <RatingsCount count={this.state.stats.ratingCount} />
              </Grid>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <RatingsAverage avg={this.state.stats.ratingAvg} />
              </Grid>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <RatingsCount
                  count={this.state.stats.ratingCountOutOfScope}
                  title="Antal ratings uden for scope"
                />
              </Grid>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <RatingsAverage
                  avg={this.state.stats.ratingAvgOutOfScope}
                  title="Gennemsnit rating uden for scope"
                />
              </Grid>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <RatingsCount
                  count={this.state.stats.ratingCountInScope}
                  title="Antal ratings inden for scope"
                />
              </Grid>
              <Grid item sm={6} lg={3} xl={3} xs={12}>
                <RatingsAverage
                  avg={this.state.stats.ratingAvgInScope}
                  title="Gennemsnit rating inden for scope"
                />
              </Grid>
              <Grid item lg={12} md={12} xl={6} xs={12}>
                <DialogsStatistics
                  dialogs={this.state.conversations.dialogs}
                  resolution={this.state.conversations.filter.resolution}
                  timeRange={{
                    start: this.state.timeStart,
                    end: this.state.timeEnd,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={12} xl={3} xs={12}>
                <DialogsByTime times={this.state.dialogsByTime} />
              </Grid>
              <Grid item lg={6} md={12} xl={3} xs={12}>
                <UsersByDevice platforms={this.state.platforms} />
              </Grid>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <SubjectStats
                  municipalityId={this.state.selectedMunicipalityId}
                  begin={this.state.timeStart as unknown as string}
                  end={this.state.timeEnd as unknown as string}
                  subjects={this.state.subjectStats.subjects}
                />
              </Grid>

              {/*<Grid item lg={12} md={12} xl={4} xs={12}>
                    <CategoriesHitCount categories={this.state.categories}/>
                  </Grid>*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Stats);
