
export class User {

    private static agentRegex = new RegExp("Agent.*?");
    private static administratorRegex = new RegExp("Administrator.*?");

    public static getProfile(): any {
        const storedProfile = localStorage.getItem("profile");

        if (!storedProfile) {
            return undefined;
        }

        try{
            const profile = JSON.parse(storedProfile);
            return profile;
        }
        catch (e) {
            return undefined;
        }
    }

    public static getRoles(): any[] {

        const storedProfile = localStorage.getItem("profile");

        if(!storedProfile)
            return [];

        try{
            const profile = JSON.parse(storedProfile);
            if(!profile || !profile.roles || profile.role <= 0)
                return [];

            return profile.roles;
        }
        catch (e) {
            return [];
        }
    }

    public static isGlobalAdmin(): boolean {
        return User.isRole(new RegExp("^Administrator$|^Administrator-IBM$"));
    }

    public static isAdmin(): boolean {
        return User.isRole(User.administratorRegex);
    }

    public static isAgent(): boolean {
        return User.isRole(User.agentRegex);
    }

    public static isRole(regex: any): boolean {
        for( let role of User.getRoles()) {
            if(role.roleRights && regex.test(role.roleRights.roleType)){
                return true;
            }
        }
        return false;
    }

}