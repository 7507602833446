import React, {Component} from 'react'
import {Button} from "@material-ui/core";

export type OptionItemProps = {
    classes?: any,
    option: {
        label: string,
        value: string
    }
};

export type OptionItemState = {};

export class OptionItem extends Component<OptionItemProps, OptionItemState> {

    render() {
        return (
            <Button className="botBlobOption" variant={"text"} size={"medium"} key={this.props.option.label}>
                {this.props.option.label ? this.props.option.label.trim() : ""}
            </Button>
        );
    }
}