import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  card: {
    flexBasis: '100%'
  },
  cardActions: {
    justifyContent: "flex-end"
  }
}));

const DialogToolbar = (props: DialogToolbarProps) => {
  const { msgId } = props;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.row}>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink color="inherit" to="/dialogs" >
            <Typography color="textPrimary">Samtaler</Typography>
          </RouterLink>
          <Typography color="textPrimary">{msgId}</Typography>
        </Breadcrumbs>
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

type DialogToolbarProps = {
  msgId: string,
  className?: string,
};

export default DialogToolbar;
