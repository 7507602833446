import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Button, Grid, Theme} from '@material-ui/core';
import {APIService} from "../../../../../../service/APIService";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import {Log} from "../../../../../../common/log";
import LoadingIndicator from "../../../../../../components/LoadingIndicator";
import TagDetails from "./components/TagDetails";
import SbTextCard from "./components/SbTextCard";
import GuideEditor from "./components/GuideEditor/GuideEditor";
import CustomTextCard from "./components/CustomTextCard";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    deleteConfirmBtn: {
        color: theme.palette.error.main
    }
}));

export type SbGuideDetailsProps = { match: any, classes: any };

export type SbGuideDetailsState = {
    isLoading: boolean,
    isEditing: boolean,
    alert: {
      isAlertOpen: boolean,
        alertMessage: string,
        alertType: "success" | "error" | "info" | "warning",
    },
    deleteModalOpen: boolean,
    municipality: any,
    tag: any
};

class SbGuideDetails extends Component<SbGuideDetailsProps, SbGuideDetailsState> {

    private service: APIService = new APIService();

    constructor(props: SbGuideDetailsProps) {
        super(props);

        this.state = {
            isLoading: true,
            isEditing: false,
            deleteModalOpen: false,
            alert: {
                isAlertOpen: false,
                alertMessage: "",
                alertType: "success"
            },
            municipality: {},
            tag: {}
        }
    }

    async componentDidMount(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {

        if(!this.state.isLoading){
            this.setState({ isLoading: true });
        }

        await Promise.all([
            this.fetchMunicipality(),
            this.fetchTag()
        ]);

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async fetchMunicipality(): Promise<void> {

        try {
            const res = await this.service.getMunicipality(this.props.match.params.code, true);

            if (res) {
                this.setState({ municipality: res });
            }
        } catch (e) {
            Log.error(`Failed loading municipality ${e}`);
        }

    }

    async fetchTag(): Promise<void> {

        try {
            const res = await this.service.getGuide(this.props.match.params.tagId, this.props.match.params.code);

            if (res) {
                this.setState({ tag: res });
            }
        } catch (e) {
            Log.error(`Failed loading tags ${e}`);
        }

    }

    async handleSave(text: string) {

        this.setState({ isEditing: false, isLoading: true });

        if (!text || text.length === 0) {
            this.setState({ alert: {
                    alertMessage: `Den tilpasset tekst må ikke være tom. Prøv igen`,
                    isAlertOpen: true, alertType: "error"
                }
            });
            return;
        }

        try {
            await this.service.updateCorrectedGuide(this.props.match.params.tagId, this.props.match.params.code, text);
            this.setState({ alert: {
                    alertMessage: `Teksten er nu tilpasset`,
                    isAlertOpen: true, alertType: "success"
                }
            });
        }
        catch (e) {
            Log.error(`Failed saving new value for tag`);
            this.setState({ alert: {
                    alertMessage: `Der skete en fejl under tilpasning af teksten`,
                    isAlertOpen: true, alertType: "error"
                }
            });
        }

        await this.fetchTag();

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async handleDeleteCorrectedTag() {

        this.handleDeleteModalClose();
        this.setState({ isLoading: true });

        try {
            await this.service.deleteCorrectedGuide(this.props.match.params.tagId, this.props.match.params.code);
            this.setState({ alert: {
                    alertMessage: `Den tilpasset tekst er slettet. Teksten anvendes direkte fra Selvbetjening.nu`,
                    isAlertOpen: true, alertType: "success"
                }
            });
        }
        catch (e) {
            Log.error(`Failed deleting corrected value for tag`);
            this.setState({ alert: {
                    alertMessage: `Der skete en fejl under sletning af den tilpasset tekst`,
                    isAlertOpen: true, alertType: "error"
                }
            });
        }

        await this.fetchTag();

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    handleDeleteModalClose(): void {
        this.setState({ deleteModalOpen: false });
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <RouterLink color="inherit" to="/municipalities" >
                            <Typography color="textPrimary">Kommuner</Typography>
                        </RouterLink>
                        <RouterLink color="inherit" to={`/municipalities/${this.props.match.params.code}`}>
                            <Typography color="textPrimary">{this.state.municipality.name}</Typography>
                        </RouterLink>
                        <RouterLink color="inherit" to={`/municipalities/${this.props.match.params.code}/sb`}>
                            <Typography color="textPrimary">Selvbetjening.nu konfiguration</Typography>
                        </RouterLink>
                        <Typography color="textPrimary">{this.props.match.params.tagId}</Typography>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer}/>
                </div>
                <LoadingIndicator isLoading={this.state.isLoading} />
                {
                    !this.state.isLoading &&
                    <div>
                        <Grid container spacing={4}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <TagDetails tag={this.state.tag}/>
                            </Grid>
                            <Grid item lg={6} sm={6} xl={6} xs={12}>
                                <SbTextCard tag={this.state.tag} />
                            </Grid>
                            <Grid item lg={6} sm={6} xl={6} xs={12}>
                                <CustomTextCard
                                    isEditing={this.state.isEditing}
                                    tag={this.state.tag}
                                    onEditText={() => { this.setState({...this.state, isEditing: true }); }}
                                    onDeleteText={() => this.setState({ deleteModalOpen: true }) }
                                />
                            </Grid>
                        </Grid>
                    </div>
                }
                <GuideEditor
                    isEditing={this.state.isEditing}
                    onClose={() => this.setState({ isEditing: false })}
                    onSave={this.handleSave.bind(this)}
                    tag={this.state.tag ? (!this.state.tag.correctedValue || this.state.tag.correctedValue === "" ? this.state.tag.tagValue : this.state.tag.correctedValue) : "" }
                />
                <Snackbar open={this.state.alert.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={() => this.setState({alert: { ...this.state.alert, isAlertOpen: false }})}>
                    <Alert severity={this.state.alert.alertType}>
                        {this.state.alert.alertMessage}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={this.state.deleteModalOpen}
                    onClose={() => this.handleDeleteModalClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Bekræft sletning</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på du ønsker at slette den tilpasset tekst? Værd opmærksom på at det betyder svaret fra Selvbetjening.nu anvendes istedet.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteModalClose.bind(this)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleDeleteCorrectedTag.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(SbGuideDetails)
