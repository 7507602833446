export const config = {
    isTest: (process.env.REACT_APP_ENV_MODE === "test" ? true : false),
    isProduction: (process.env.REACT_APP_ENV_MODE === "prod" ? true : false),
    isDev: (process.env.REACT_APP_ENV_MODE === "dev" ? true : false),
    backend: {
        scheme: process.env.REACT_APP_BACKEND_SCHEME || "https",
        host: process.env.REACT_APP_BACKEND_HOST
    },
    chatbot: {
        scheme: process.env.REACT_APP_CHATBOT_SCHEME || "https",
        host: process.env.REACT_APP_CHATBOT_HOST || ""
    }
};