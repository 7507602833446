import React, {Component} from "react";
import {CircularProgress, Grid} from "@material-ui/core";

export type LoadingIndicatorProps = {
    isLoading: boolean
};

export default class LoadingIndicator extends Component<LoadingIndicatorProps> {

    render(): any {
        return (
            <div>
            {
                this.props.isLoading ?
                <Grid container direction="column" alignItems="center" justify="center" style={{ height: "100%", minHeight: "20px" }}>
                    <Grid item>
                        <CircularProgress style={{ justifyContent: 'center' }}/>
                    </Grid>
                </Grid>
                    : null
            }
            </div>
        );
    }

}