import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Theme,
    Typography
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const styles = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

export type MunicipalitySelectionProps = {
  municipalities: any[],
  onStepChange: any,
  onReset: any,
  onMunicipalityChange: any,
  onCategoryChange: any,
  classes: any
};

export type MunicipalitySelectionState = { activeStep: number, municipality: any, values: any };

class MunicipalitySelection extends Component<MunicipalitySelectionProps, MunicipalitySelectionState> {

  constructor(props: MunicipalitySelectionProps) {
    super(props);

    this.state = {
      activeStep: 0,
      values: {
        category: '',
        municipality: ''
      },
      municipality: {
        chatCategories: []
      }
    };
  }

  async handleMunicipalityChange(event: any) {

    this.setState({
        values: {
          category: '',
          [event.target.name]: event.target.value
        }
    });

    let selectedMunicipality = undefined;

    for (let municipality of this.props.municipalities) {
      if (municipality.id === parseInt(event.target.value)) {
        selectedMunicipality = municipality;
        break;
      }
    }

    if (!selectedMunicipality)
      return;

    await this.setState({ municipality: selectedMunicipality });
    await this.props.onMunicipalityChange(selectedMunicipality.code);
    this.handleNext();
  }

    handleCategoryChange = (event: any) => {
      this.setState({
          ...this.state,
        values: {
          ...this.state.values,
          [event.target.name]: event.target.value
        }
      });
      this.props.onCategoryChange(event.target.value);
      this.handleNext();
    };

    getStepCategory(){

      if(this.state.activeStep < 1 || !this.state.municipality || !this.state.municipality.chatCategories)
        return;

      return <TextField
        fullWidth
        margin="dense"
        onChange={this.handleCategoryChange.bind(this)}
        placeholder=""
        required
        select
        SelectProps={{native: true}}
        value={this.state.values.category}
        variant="outlined"
      >
        <option
          disabled
          key={0}
          value=""
        >Vælg</option>
        <option
          key={1}
          value="none"
        >Ingen</option>
        {
          this.state.municipality.chatCategories.map((option: any, index: number) => (
            <option
              key={index+2}
              value={option.tagName}
            >
              {option.description}
            </option>
          ))
        }
      </TextField>
    }

    async handleNext() {
      await this.setState({ ...this.state, activeStep: this.state.activeStep + 1 });
      if(this.state.activeStep === 2)
        this.props.onStepChange(true);
    }

    handleBack() {
      this.setState({ ...this.state, activeStep: this.state.activeStep - 1 });
      this.props.onStepChange(false);
    }

    async handleReset() {
      await this.setState({
        activeStep: 0,
        values: {
          category: '',
          municipality: ''
        },
        municipality: {
          chatCategories: []
        }
      });
      await this.props.onReset();
      this.props.onStepChange(false);
    }

    render() {
      return (
        <Card>
          <CardContent>
            <Grid
              container
              spacing={4}
            >
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography
                  className={this.props.classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2">
                                KONFIGURER CHATBOT
                </Typography>
              </Grid>
              <Grid item lg={10} md={10} xl={10} xs={10}>
                <Typography variant="body1">
                  På denne side kan chatbotten vises som den vil fremgå på kommunens hjemmeside. Følg de tre nedenstående trin.
                </Typography>
              </Grid>
              <Grid item lg={10} md={10} xl={10} xs={10}>
                <Stepper
                  activeStep={this.state.activeStep}
                  orientation="vertical">
                  <Step key="1">
                    <StepLabel>Vælg kommune</StepLabel>
                    <StepContent>
                      <Grid container>
                        <Grid item lg={3} md={6} xl={3} xs={12}>
                          <TextField
                            fullWidth
                            inputProps={{ 'aria-label': 'Without label' }}
                            margin="dense"
                            onChange={this.handleMunicipalityChange.bind(this)}
                            placeholder=""
                            required
                            select
                            SelectProps={{native: true}}
                            value={this.state.values.municipality}
                            variant="outlined" >
                            <option disabled key={0} value="">Vælg</option>
                            {this.props.municipalities.map((municipality: any) => (
                              <option key={municipality.id} value={municipality.id}>
                                {municipality.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </StepContent>
                  </Step>
                  <Step key="2">
                    <StepLabel>Vælg kategori</StepLabel>
                    <StepContent>
                      <Grid container>
                        <Grid item lg={3} md={6} xl={3} xs={12}>
                          {this.getStepCategory()}
                        </Grid>
                      </Grid>
                      <div className={this.props.classes.actionsContainer}>
                        <div>
                          <Button
                            className={this.props.classes.button}
                            onClick={this.handleBack.bind(this)}>
                            Tilbage
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key="3">
                    <StepLabel>Prøv</StepLabel>
                    <StepContent>
                      <Grid container>
                        <Grid item lg={3} md={6} xl={3} xs={12}>
                          <Typography>Nu er assistenten klar til visning</Typography>
                          <Button onClick={this.handleReset.bind(this)}>
                            Start forfra
                          </Button>
                        </Grid>
                      </Grid>
                    </StepContent>
                  </Step>
                </Stepper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
}

export default withStyles(styles)(MunicipalitySelection);

