import React, { Component, Ref, RefObject } from "react";
import { withStyles } from "@material-ui/styles";

import { DialogsTable, DialogsToolbar } from "./components";
import { APIService, GetAllDialogsQuery } from "../../service/APIService";
import { setState } from "../../common/promisify";
import { Log } from "../../common/log";
import { PageState } from "../../helpers/PageState";

const styles: any = (theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
});

export type DialogListProps = { classes: any };

export type DialogListState = {
  isLoading: boolean;
  municipalities: any[];
  dialogs: any[];
  totalRows: number;
  currentPage: number;
  currentLimit: number;
  filter: {
    searchText: string;
    municipalityCode: number;
    hasCallback: number;
    sortBy: number;
    timeRangeStart: Date;
    timeRangeEnd: Date;
    fromSender: number;
    subject: string;
  };
};

class DialogList extends Component<DialogListProps, DialogListState> {
  private tableRef: Ref<any>;
  private readonly pageKey: string = "dialog_list";
  private service: APIService = new APIService();

  constructor(props: DialogListProps) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      filter: {
        searchText: "",
        municipalityCode: 0,
        hasCallback: 0,
        sortBy: 1,
        timeRangeStart: new Date(new Date().setHours(0, 0, 0, 0)),
        timeRangeEnd: new Date(),
        fromSender: 0,
        subject: "",
      },
      isLoading: true,
      municipalities: [],
      totalRows: 0,
      currentPage: 0,
      currentLimit: 10,
      dialogs: [],
    };
  }

  async componentDidMount(): Promise<void> {
    if (PageState.hasState(this.pageKey)) {
      const state = PageState.get(this.pageKey);
      if (this.isPageStateValid(state)) {
        await this.setPageState(state);
      }
    }

    await this.fetchDialogs(this.state.currentLimit, this.state.currentPage);
    await this.fetchMunicipalities();
  }

  componentWillUnmount() {
    PageState.save(this.pageKey, {
      ...this.state.filter,
      totalRows: this.state.totalRows,
      currentPage: this.state.currentPage,
      currentLimit: this.state.currentLimit,
    });
  }

  buildSearchQuery(limit: number, page: number): GetAllDialogsQuery {
    let skip;
    if (page === 0 || limit === 0) skip = 0;
    else {
      if (limit * page < this.state.totalRows - 1) {
        skip = limit * page;
      } else {
        skip = this.state.totalRows - 1;
      }
    }

    return {
      limit: limit,
      skip: skip,
      isDetailed: true,
      searchText: this.state.filter.searchText,
      municipalityCode: this.state.filter.municipalityCode,
      hasCallback: this.state.filter.hasCallback,
      sortBy: this.state.filter.sortBy,
      fromSender: this.state.filter.fromSender,
      timeRangeStart: this.state.filter.timeRangeStart,
      timeRangeEnd: this.state.filter.timeRangeEnd,
      subject: this.state.filter.subject,
    };
  }

  async fetchDialogs(limit: number, page: number): Promise<void> {
    if (!this.state.isLoading) this.setState({ isLoading: true });

    try {
      const query = this.buildSearchQuery(limit, page);
      const res = await this.service.getDialogs(query);

      if (
        res &&
        res.dialogs !== undefined &&
        Array.isArray(res.dialogs) &&
        res.total !== undefined
      ) {
        this.setState({
          dialogs: res.dialogs || [],
          totalRows: res.total || 0,
        });
      }
    } catch (e) {
      Log.error(`Failed loading dialogs`);
    }

    setTimeout(() => this.setState({ isLoading: false }), 500);
  }

  async fetchMunicipalities(): Promise<void> {
    try {
      const res = await this.service.getMunicipalities(100, 0, true, false);

      if (res && Array.isArray(res)) {
        this.setState({ municipalities: res });
      }
    } catch (e) {
      Log.error(`Failed loading dialogs`);
    }
  }

  onFilterParamsChange(filter: any) {
    this.setState({ filter: { ...filter } });
  }

  async onPageChange(page: number, rowsPerPage: number): Promise<void> {
    await setState(this, {
      currentPage: page,
      currentLimit: rowsPerPage,
    });
    await this.fetchDialogs(rowsPerPage, page);
  }

  async onSearch(event: any) {
    await this.fetchDialogs(this.state.currentLimit, this.state.currentPage);
  }

  async onExport() {
    const query = this.buildSearchQuery(
      this.state.currentLimit,
      this.state.currentPage
    );
    try {
      const res = await this.service.exportDialogs(query);
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "eksport.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      Log.log(`Failed eksporting the dialogs with reason ${e}`);
    }
  }

  async onUpdateFilter(filter: any) {
    if (filter) this.setState({ filter: filter });
  }

  getSearchParams() {
    return this.state.filter;
  }

  isPageStateValid(state: any): boolean {
    return (
      state &&
      state.searchText !== undefined &&
      state.municipalityCode !== undefined &&
      state.hasCallback !== undefined &&
      state.sortBy !== undefined &&
      state.timeRangeStart !== undefined &&
      state.timeRangeEnd !== undefined &&
      state.fromSender !== undefined &&
      state.totalRows !== undefined &&
      state.currentPage !== undefined &&
      state.currentLimit !== undefined
    );
  }

  async setPageState(state: any): Promise<any> {
    await this.setState({
      ...this.state,
      filter: {
        searchText: state.searchText,
        municipalityCode: state.municipalityCode,
        hasCallback: state.hasCallback,
        sortBy: state.sortBy,
        timeRangeStart: state.timeRangeStart,
        timeRangeEnd: state.timeRangeEnd,
        fromSender: state.fromSender,
        subject: state.subject,
      },
      totalRows: state.totalRows,
      currentPage: state.currentPage,
      currentLimit: state.currentLimit,
    });

    if (this.tableRef)
      (this.tableRef as RefObject<any>).current.changePage(
        state.currentPage,
        state.currentLimit
      );
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <DialogsToolbar
          filter={this.state.filter}
          municipalities={this.state.municipalities}
          onFilterParamsChange={this.onFilterParamsChange.bind(this)}
          onSearch={this.onSearch.bind(this)}
        />
        <div className={this.props.classes.content}>
          <DialogsTable
            ref={this.tableRef}
            isLoading={this.state.isLoading}
            dialogs={this.state.dialogs}
            total={this.state.totalRows}
            onSelectBookmark={this.onUpdateFilter.bind(this)}
            getSearchParams={this.getSearchParams.bind(this)}
            onExport={this.onExport.bind(this)}
            onPageChange={this.onPageChange.bind(this)}
            onRowsPerPageChange={this.onPageChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DialogList);
