import React from "react";
import { Redirect, Switch } from "react-router-dom";

import { RouteWithLayout } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  ContentOnly as ContentOnlyLayout,
} from "./layouts";

import {
  AssistantSettings as AssistantSettingsView,
  Dashboard as DashboardView,
  DialogDetails as DialogDetailsView,
  DialogList as DialogListView,
  FeedbackList as RatingListView,
  Integrations as IntegrationsView,
  MunicipalityDetails as MunicipalityDetailsView,
  MunicipalityList as MunicipalityListView,
  NotFound as NotFoundView,
  SbGuideDetails as SbIntegrationGuideDetailsView,
  SbIntegration as SbIntegrationView,
  SbIntegrationDetails as SbIntegrationDetailsView,
  FrontDeskIntegrationDetails as FrontDeskIntegrationDetailsView,
  DialogDetailsContentOnly as DialogDetailsContentOnlyView,
  SbTagDetails as SbIntegrationTagDetailsView,
  Settings as SettingsView,
  SignIn as SignInView,
  Stats as StatsView,
  Status as StatusView,
  SubjectItemDetails as StatsSubjectItemsView,
  Training as TrainingView,
} from "./views";
import { FrontDeskInstanceView } from "./views/MunicipalityDetails/components/FrontDesk";
import { WatsonDiscoveryIntegrationView } from "./views/MunicipalityDetails/components/WatsonDiscovery";
import { WatsonDiscoveryStats } from "./views/Stats/WDStats";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={MunicipalityListView}
        exact
        layout={MainLayout}
        path="/municipalities"
      />
      <RouteWithLayout
        component={MunicipalityDetailsView}
        exact
        layout={MainLayout}
        path="/municipalities/:code"
      />
      <RouteWithLayout
        component={AssistantSettingsView}
        exact
        layout={MainLayout}
        path="/municipalities/:code/chatbot"
      />
      <RouteWithLayout
        component={SbIntegrationView}
        exact
        layout={MainLayout}
        path="/municipalities/:code/sb"
      />
      <RouteWithLayout
        component={SbIntegrationGuideDetailsView}
        exact
        layout={MainLayout}
        path="/municipalities/:code/sb/guide/:tagId"
      />
      <RouteWithLayout
        component={SbIntegrationTagDetailsView}
        exact
        layout={MainLayout}
        path="/municipalities/:code/sb/tag/:tagId"
      />
      <RouteWithLayout
        component={FrontDeskInstanceView}
        exact
        layout={MainLayout}
        path="/municipalities/:code/front-desk"
      />
      <RouteWithLayout
        component={WatsonDiscoveryIntegrationView}
        exact
        layout={MainLayout}
        path="/municipalities/:code/watson-discovery"
      />
      <RouteWithLayout
        component={DialogListView}
        exact
        layout={MainLayout}
        path="/dialogs"
      />
      <RouteWithLayout
        component={DialogDetailsView}
        exact
        layout={MainLayout}
        path="/dialogs/:id"
      />
      <RouteWithLayout
        component={DialogDetailsContentOnlyView}
        exact
        layout={ContentOnlyLayout}
        path="/extern/dialogs/:id"
      />
      <RouteWithLayout
        component={RatingListView}
        exact
        layout={MainLayout}
        path="/ratings"
      />
      <RouteWithLayout
        component={TrainingView}
        exact
        layout={MainLayout}
        path="/train"
      />
      <RouteWithLayout
        component={StatsView}
        exact
        layout={MainLayout}
        path="/stats"
      />
      <RouteWithLayout
        component={StatsSubjectItemsView}
        exact
        layout={MainLayout}
        path="/stats/subject/:subject"
      />
      <RouteWithLayout
        component={WatsonDiscoveryStats}
        exact
        layout={MainLayout}
        path="/wd/stats"
      />
      <RouteWithLayout
        component={StatusView}
        exact
        layout={MainLayout}
        path="/status"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={IntegrationsView}
        exact
        layout={MainLayout}
        path="/integration"
      />
      <RouteWithLayout
        component={SbIntegrationDetailsView}
        exact
        layout={MainLayout}
        path="/integration/sb"
      />
      <RouteWithLayout
        component={FrontDeskIntegrationDetailsView}
        exact
        layout={MainLayout}
        path="/integration/front-desk"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
