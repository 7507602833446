import React, {Component} from 'react';
import '../../../../../../../assets/scss/BlobStyles.scss';
import Grid from '@material-ui/core/Grid';
import {Theme} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import {ExpandItem} from "./ExpandItem";

const styles = ((theme: Theme) => ({
    beforeAfterText: {
        padding: '10px 23px 10px 17px'
    }
}));

export type ExpandableBotBlobProps = {
    classes: any,
    expandables?: any[],
    textBefore?: string,
    textAfter?: string,
    handleClick?: any
};

export type ExpandableBlobBotState = {  };

class ExpandableBotBlob extends Component<ExpandableBotBlobProps, ExpandableBlobBotState> {

    render() {
        const expandArr = [];
        if(this.props.expandables){
            for(let item of this.props.expandables){
                expandArr.push(
                    <Grid key={item.text}>
                        <ExpandItem label={item.label} value={item.value}/>
                    </Grid>
                )
            }
        }

        return (
            <Grid container className={"botBlob"}>
                <Grid className="botBlobExpandPaper" item>
                    { this.props.textBefore ? <div className={this.props.classes.beforeAfterText}>{this.props.textBefore}</div>: ""}
                    {expandArr}
                    { this.props.textAfter ? <div className={this.props.classes.beforeAfterText}>{this.props.textAfter}</div> : ""}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ExpandableBotBlob);