import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Theme} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {MunicipalitySelection, StatusList} from "./components";
import LoadingIndicator from "../../components/LoadingIndicator";
import {APIService} from "../../service/APIService";
import {Log} from "../../common/log";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    }
}));

export type StatusProps = { classes: any };

export type StatusState = {
    isMunicipalitiesLoading: boolean,
    municipalities: any[],
    selectedMunicipality: number,
    isAlertOpen: boolean, alert: any
};

class Status extends Component<StatusProps, StatusState> {

    private apiService: APIService = new APIService();

    constructor(props: StatusProps) {
        super(props);
        this.state = {
            isMunicipalitiesLoading: true,
            municipalities: [],
            selectedMunicipality: 0,
            isAlertOpen: false,
            alert: undefined
        }
    }

    async componentDidMount(): Promise<void> {
        await this.fetchMunicipalities();
    }

    async fetchMunicipalities(){

        if (!this.state.isMunicipalitiesLoading)
            this.setState({ ...this.state, isMunicipalitiesLoading: true });

        try {
            const municipalities = await this.apiService.getMunicipalities(100, 0, true, false);

            const codes: number[] = municipalities.map( (m: any) => { return m.code });

            const maintenanceModes = await this.apiService.getMaintenanceMode(codes);

            for (let municipality of municipalities) {
                for (let mode of maintenanceModes) {

                    if (municipality.code === mode.code) {
                        municipality.mode = mode;
                        break;
                    }
                }
            }

            this.setState({ ...this.state, municipalities: municipalities });
        }
        catch (e) {
            Log.error(`Failed fetching municipalities with reason ${e}`);
        }

        setTimeout(()=> this.setState({ ...this.state, isMunicipalitiesLoading: false }), 500);
    }

    showModeAlert(success: boolean, text: string) {
        this.setState({ ...this.state, isAlertOpen: true, alert:
                <Alert severity={success ? "success" : "error"}>{text}</Alert>
        });
    }

    async onModeChange(municipalityCodes: number[], duration: number){

        try {
            await this.apiService.setMaintenanceMode(municipalityCodes, duration);
            this.showModeAlert(true, "Drift status er opdateret");
        }
        catch (e) {
            this.showModeAlert(false, "Der opstod en fejl under ændringen af drift status");
        }

        await this.fetchMunicipalities();
    }

    render(){
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="textPrimary">Drift</Typography>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer}/>
                </div>
                {
                    <LoadingIndicator isLoading={this.state.isMunicipalitiesLoading}/>
                }
                {
                    !this.state.isMunicipalitiesLoading &&
                    <Grid container spacing={4}>
                        <Grid item xl={5} xs={12}>
                            <MunicipalitySelection municipalities={this.state.municipalities} onModeChange={this.onModeChange.bind(this)} />
                        </Grid>
                        <Grid item xl={7} xs={12}>
                            <StatusList municipalities={this.state.municipalities} />
                        </Grid>
                    </Grid>
                }
                <Snackbar open={this.state.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                          onClose={() => this.setState({ ...this.state, isAlertOpen: false })}>
                    {this.state.alert}
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(styles)(Status);

