import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Button, Grid, Theme} from '@material-ui/core';
import {APIService} from "../../../../../../service/APIService";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import {Log} from "../../../../../../common/log";
import LoadingIndicator from "../../../../../../components/LoadingIndicator";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TagDetails from "./components/TagDetails";
import {browserHistory} from "../../../../../../App";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    deleteConfirmBtn: {
        color: theme.palette.error.main
    }
}));

export type SbGuideDetailsProps = { match: any, classes: any };

export type SbGuideDetailsState = {
    isLoading: boolean,
    isEditing: boolean,
    alert: {
      isAlertOpen: boolean,
        alertMessage: string,
        alertType: "success" | "error" | "info" | "warning",
    },
    isDeleteModalOpen: boolean,
    isRequestedModalOpen: boolean,
    isActivationModalOpen: boolean,
    municipality: any,
    tag: any
};

class SbTagDetails extends Component<SbGuideDetailsProps, SbGuideDetailsState> {

    private service: APIService = new APIService();

    constructor(props: SbGuideDetailsProps) {
        super(props);

        this.state = {
            isLoading: true,
            isEditing: false,
            isRequestedModalOpen: false,
            isDeleteModalOpen: false,
            isActivationModalOpen: false,
            alert: {
                isAlertOpen: false,
                alertMessage: "",
                alertType: "success"
            },
            municipality: {},
            tag: {}
        }
    }

    async componentDidMount(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {

        if(!this.state.isLoading){
            this.setState({ isLoading: true });
        }

        await Promise.all([
            this.fetchMunicipality(),
            this.fetchTag()
        ]);

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async fetchMunicipality(): Promise<void> {

        try {
            const res = await this.service.getMunicipality(this.props.match.params.code, true);

            if (res) {
                this.setState({ municipality: res });
            }
        } catch (e) {
            Log.error(`Failed loading municipality ${e}`);
        }

    }

    async fetchTag(): Promise<void> {

        try {
            const res = await this.service.getTag(this.props.match.params.tagId);

            if (res) {
                this.setState({ tag: res });
            }
        } catch (e) {
            Log.error(`Failed loading tags ${e}`);
        }

    }

    handleDeleteModalClose(): void {
        this.setState({ isDeleteModalOpen: false });
    }

    handleRequestedModalClose(): void {
        this.setState({ isRequestedModalOpen: false });
    }

    handleActivationModalClose(): void {
        this.setState({ isActivationModalOpen: false });
    }

    async handleTagDeletionButtonClick(): Promise<void> {
        await this.setState({ isLoading: true, isDeleteModalOpen: false });

        try {
            await this.service.deleteTag(this.state.tag.tagId);
            await this.setState({
                alert: {
                    alertMessage: `Tag med id '${this.state.tag.tagId}' er slettet`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
            setTimeout(() =>  browserHistory.push(`/municipalities/${this.state.municipality.code}/sb`), 2000 );

        } catch (e) {
            Log.error(`Failed deleting tag ${e}`);
            await this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under sletning af tag med id '${this.state.tag.tagId}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
            await this.fetchData();
        }
    }

    async handleActivationButtonClick(): Promise<void> {
        await this.setState({ isLoading: true, isActivationModalOpen: false });

        try {
            const activeState = !this.state.tag.isActive;
            await this.service.updateTagActive(this.state.tag.tagId, activeState);

            await this.setState({
                alert: {
                    alertMessage: `Tag med id '${this.state.tag.tagId}' er ${activeState ? "aktiveret" : "deaktiveret"}`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });

            await this.fetchData();
        } catch (e) {
            Log.error(`Failed updating tag state ${e}`);
            await this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under opdateringen af tag med id '${this.state.tag.tagId}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
        }
    }

    async handleMarkRequestButtonClick(): Promise<void> {
        await this.setState({ isLoading: true, isRequestedModalOpen: false });

        try {
            await this.service.updateTagRequested(this.state.tag.tagId, true);

            await this.setState({
                alert: {
                    alertMessage: `Tag med id '${this.state.tag.tagId}' er markeret som anmodet"}`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });

            await this.fetchData();
        } catch (e) {
            Log.error(`Failed updating tag state ${e}`);
            await this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under opdateringen af tag med id '${this.state.tag.tagId}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
        }
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <RouterLink color="inherit" to="/municipalities" >
                            <Typography color="textPrimary">Kommuner</Typography>
                        </RouterLink>
                        <RouterLink color="inherit" to={`/municipalities/${this.props.match.params.code}`}>
                            <Typography color="textPrimary">{this.state.municipality.name}</Typography>
                        </RouterLink>
                        <RouterLink color="inherit" to={`/municipalities/${this.props.match.params.code}/sb`}>
                            <Typography color="textPrimary">Selvbetjening.nu konfiguration</Typography>
                        </RouterLink>
                        <Typography color="textPrimary">{this.props.match.params.tagId}</Typography>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer}/>
                </div>
                <LoadingIndicator isLoading={this.state.isLoading} />
                {
                    !this.state.isLoading &&
                    <div>
                        <Grid container spacing={4}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <TagDetails
                                    onAlreadyRequestedClick={() => this.setState({...this.state, isRequestedModalOpen: true }) }
                                    onActivationClick={() => this.setState({...this.state, isActivationModalOpen: true }) }
                                    onDeleteClick={() => this.setState({...this.state, isDeleteModalOpen: true }) }
                                    tag={this.state.tag} />
                            </Grid>
                        </Grid>
                    </div>
                }

                <Snackbar open={this.state.alert.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={() => this.setState({alert: { ...this.state.alert, isAlertOpen: false }})}>
                    <Alert severity={this.state.alert.alertType}>
                        {this.state.alert.alertMessage}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={this.state.isRequestedModalOpen}
                    onClose={() => this.handleRequestedModalClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Bekræft markering af tag som anmodet</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på du ønsker at markere dette tag som anmodet? <br/><br/>Et tag bør kun markeres anmodet hvis anmodningen er foretaget externt og ikke via knappen "Anmod Selvbetjening om tags".
                            Foretages anmodningen via den omtalte knap bliver tagget automatisk markeret som anmodet.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRequestedModalClose.bind(this)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleMarkRequestButtonClick.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            Marker som anmodet
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isDeleteModalOpen}
                    onClose={() => this.handleDeleteModalClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Bekræft sletning af tag</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på du ønsker at slette dette tag? Værd opmærksom på at det betyder at tagget ikke længere synkroniseres med Selvbetjening.nu og kan derved ikke anvendes i Watson Assistant.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteModalClose.bind(this)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleTagDeletionButtonClick.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isActivationModalOpen}
                    onClose={() => this.handleActivationModalClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Bekræft {
                        this.state.tag.isActive ? "deaktivering" : "aktivering"
                    } af tag</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { this.state.tag.isActive ?
                                `Er du sikker på du ønsker at deaktivere dette tag? Det medfører at de tilhørende guides ikke kan anvendes via Watson Assistant. Derudover vil inaktive guides ikke blive synkroniseret med Selvbetjening.nu systemet.` :
                                `Er du sikker på du ønsker at aktivere dette tag? Et aktiveret tag synkroniseres med Selvbetjening.nu for alle kommuner og kan efter synkronisering anvendes i Watson Assistant.`
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleActivationModalClose.bind(this)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleActivationButtonClick.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            { this.state.tag.isActive ? "Deaktiver" : "Aktiver" }
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(SbTagDetails)
