import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: any) => ({
  root: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 600,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-0.05px",
  },
}));

const SearchInput = (props: SearchInputProps) => {
  const { className, value, onChange, content, style, onBlur, ...rest } = props;

  const classes: any = useStyles();

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {content}
    </Paper>
  );
};

type SearchInputProps = {
  className: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?:
    | React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  onKeyDown?: any;
  style?: any;
  placeholder: string;
  content?: any;
  value?: any;
};

export default SearchInput;
