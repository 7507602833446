import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Card, CardActions, CardContent, Divider, Grid, Theme, Typography} from '@material-ui/core';
import FaceIcon from '@material-ui/icons/FaceRounded';
import PublicIcon from '@material-ui/icons/PublicRounded';
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  }
}));

const MunicipalityCard = (props: any) => {
  const { className, municipality, ...rest } = props;

  const classes: any = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)} >
      <CardContent>
        <div className={classes.imageContainer}>
          <img
            alt="Logo"
            className={classes.image}
            src={`/images/municipality/${municipality.code}.png` }
          />
        </div>
        <Typography align="center" gutterBottom variant="h4" >
          {municipality.name}
        </Typography>
        <Typography align="center" variant="body1" >
          {municipality.description}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container justify="space-between">
          <Grid className={classes.statsItem} item >
            <FaceIcon className={classes.statsIcon} />
            <Typography display="inline" variant="body2">
              {municipality.code}
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => window.open(municipality.website)}>
              <PublicIcon className={classes.statsIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

MunicipalityCard.propTypes = {
  className: PropTypes.string,
  municipality: PropTypes.object.isRequired
};

export default MunicipalityCard;
