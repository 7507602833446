import React from "react";
import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import palette from '../../../../theme/palette';

const pal: any = palette;

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: pal.divider,
    backgroundColor: pal.common.white,
    titleFontColor: pal.text.primary,
    bodyFontColor: pal.text.secondary,
    footerFontColor: pal.text.secondary,
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Time på dag'
        },
        offset: true,
        ticks: {
          fontColor: pal.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        bounds: 'data',
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: pal.text.secondary,
          beginAtZero: true,
          min: 0,
          precision: 0,

        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: pal.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: pal.divider
        }
      }
    ]
  }
};

export function DialogsByTime({ times }: { times: { data: { labels: any[], datasets: any[]; }; }; }) {
  return <Card style={{ height: "100%" }}>
    <CardHeader title="Antal samtaler efter tid på dagen" />
    <Divider />
    <CardContent>
      <div style={{ height: "400px" }}>
        <Bar options={chartOptions} data={{
          labels: times.data.labels,
          datasets: times.data.datasets,
        }} />
      </div>
    </CardContent>
  </Card>;
}