import React, {Component} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme
} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";
import InfoSvgButton from "../../../../../../components/InfoButton";
import Typography from "@material-ui/core/Typography";
import {User} from "../../../../../../helpers/profile";

const styles = ((theme: Theme) => ({
    root: {},
    deleteCol: {
        width: "5%"
    },
    editCol: {
        width: "5%"
    },
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    deleteConfirmBtn: {
        color: theme.palette.error.main
    }
}));

export type CategoriesProps = {
    isLoading: boolean,
    onDelete: (category: any, isGlobal: boolean) => Promise<any>,
    onEdit: (category: any, savedCategory: any, isGlobal: boolean) => Promise<any>,
    onCreate: (category: any) => Promise<any>,
    classes: any,
    categories: any[]
};

export type CategoriesState = { deleteModalOpen: boolean, createModalOpen: boolean, warningModalOpen: boolean, isEdit: boolean, selectedCategory: any, values: any, page: number, rowsPerPage: number };

class Categories extends Component<CategoriesProps, CategoriesState> {

    constructor(props: CategoriesProps) {
        super(props);

        this.state = {
            selectedCategory: {},
            deleteModalOpen: false,
            createModalOpen: false,
            warningModalOpen: false,
            isEdit: false,
            values: {
            },
            page: 0,
            rowsPerPage: 10
        };
    }


    handleChange(event: any): void {
        this.setState({
            values: { ...this.state.values, [event.target.name]: event.target.value }
        });
    }

    setEditing(editValue: boolean): void {
        this.setState({ isEdit: editValue });
    }

    handleChecked(event: any): void {
        this.setState({
            values: { ...this.state.values, [event.target.name]: event.target.checked }
        });
    }

    handleDeleteModalOpen(category: any): void {
        this.setState({ selectedCategory: category, deleteModalOpen: true });
    }

    handleDeleteModalClose(): void {
        this.setState({ deleteModalOpen: false });
    }

    handleWarningModalOpen(test: boolean): void {
        this.setState({ warningModalOpen: test });
    }

    async handleDelete() {
        this.handleDeleteModalClose();
        await this.props.onDelete(this.state.selectedCategory, this.state.values.isGlobalDelete);
    }

    async handleCreate() {
        this.handleCreateModalOpenClose(false);
        await this.props.onCreate({
            tagName: this.state.values.categoryName,
            description: this.state.values.categoryDescription,
            trigger: this.state.values.categoryValue,
            isGlobal: this.state.values.isGlobal,
        });
    }

    handleEdit(category: any) {
        this.setState({selectedCategory: category, values: {categoryName: category.tagName, categoryValue: category.trigger, categoryDescription: category.description, isGlobal: category.isGlobal}})
        this.handleEditCategory();
    }

    handleEditCategory(): void {
        this.setEditing(true);
        this.handleCreateModalOpenClose(true);
    }

    async handleEditCreate() {
        this.handleWarningModalOpen(false);
        this.handleCreateModalOpenClose(false);
        this.props.onEdit(this.state.selectedCategory, {
            tagName: this.state.values.categoryName,
            description: this.state.values.categoryDescription,
            trigger: this.state.values.categoryValue,
            isGlobal: this.state.values.isGlobal,
        }, this.state.values.isGlobal);
    }

    handleCreateModalOpenClose(isOpen: boolean): void {
        this.setState({ createModalOpen: isOpen });
    }

    setPage(page: number) {
        this.setState({ page: page });
    }

    handleRowsPerPageChange(event: any) {
        this.setState({ rowsPerPage: event.target.value, page: 0 })
    }

    render(): any {
        return (
            <Card>
                <CardHeader
                    subheader="Vedligehold kategori variabler"
                    title={<span>Kategorier <InfoSvgButton title={"Om kategorier"} content={<Typography>Kategorier repræsenterer de kategorier som chatbotten har mulighed for at starte på. <b>Titel</b> er titlen på kategorien og <b>WA Værdi</b> er den besked som chatbotten skal modtage for at give det tilhørende svar der er defineret i Watson Assistant.</Typography>} /> </span>}
                    action={User.isAdmin() &&
                        <Button color="primary" size="small" variant="outlined"
                            onClick={() => {this.handleCreateModalOpenClose(true); this.setEditing(false)}}>
                            Opret kategori variabel
                            </Button>
                    }
                />
                <Divider />
                <CardContent>
                    <LoadingIndicator isLoading={this.props.isLoading} />
                    {
                        !this.props.isLoading &&
                        <PerfectScrollbar>
                            <div className={this.props.classes.inner}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Navn</TableCell>
                                            <TableCell>WA Værdi</TableCell>
                                            <TableCell>Beskrivelse</TableCell>
                                            <TableCell className={this.props.classes.deleteCol}></TableCell>
                                            <TableCell className={this.props.classes.editCol}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.categories.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map(category => (
                                            <TableRow
                                                className={this.props.classes.tableRow}
                                                hover
                                                key={category.id}>
                                                <TableCell>{category.tagName}</TableCell>
                                                <TableCell>{category.trigger}</TableCell>
                                                <TableCell>{category.description}</TableCell>
                                                <TableCell>
                                                    {
                                                        User.isGlobalAdmin() &&
                                                        <IconButton aria-label="rediger"
                                                                    onClick={() => this.handleEdit(category)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        User.isGlobalAdmin() &&
                                                        <IconButton aria-label="slet"
                                                            onClick={() => this.handleDeleteModalOpen(category)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    }
                </CardContent>
                <CardActions>
                    <TablePagination
                        labelRowsPerPage="Rækker pr. side"
                        labelDisplayedRows={({ from, to, count }) =>
                        `${from > count ? count : from}-${to} af ${count}`
                        }
                        component="div"
                        count={this.props.categories.length || 0}
                        onChangePage={(event, page) => this.setPage(page)}
                        onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                    />
                </CardActions>
                <Divider />
                <Dialog
                    open={this.state.deleteModalOpen}
                    onClose={() => this.handleDeleteModalClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Bekræft sletning af kategori</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på du ønsker at slette kategorien '{this.state.selectedCategory.tagName}'
                            permanent? Vær opmærksom på at det kan påvirke brugeroplevelsen.
                        </DialogContentText>
                        {
                            User.isGlobalAdmin() &&
                            <FormControlLabel
                                name="isGlobalDelete"
                                control={<Checkbox
                                    onChange={this.handleChecked.bind(this)}
                                    color="primary" />
                                }
                                label="Slet fra alle kommuner"
                                labelPlacement="end"
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteModalClose.bind(this)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.warningModalOpen}
                    onClose={() => this.handleWarningModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Du har valgt at ændre værdien for alle kommuner</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på du ønsker at ændre kategorien '{this.state.selectedCategory.tagName}'
                            permanent? Vær opmærksom på at det kan påvirke brugeroplevelsen.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleWarningModalOpen(false)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleEditCreate.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            Fortsæt
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.createModalOpen}
                    onClose={() => this.handleCreateModalOpenClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Opret ny kategori</DialogTitle>
                    <DialogContent>
                        <TextField required fullWidth
                            name="categoryName"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Navn"
                            value={this.state.isEdit ? this.state.values.categoryName : null}
                            onChange={this.handleChange.bind(this)}
                            variant="outlined"
                        />
                        <TextField required fullWidth
                            name="categoryValue"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Værdi i Watson Assistant"
                            value={this.state.isEdit ? this.state.values.categoryValue : null}
                            onChange={this.handleChange.bind(this)}
                            variant="outlined"
                        />
                        <TextField required fullWidth
                            name="categoryDescription"
                            id="outlined-multiline-static"
                            className={this.props.classes.textField}
                            label="Beskrivelse"
                            value={this.state.isEdit ? this.state.values.categoryDescription : null}
                            multiline
                            rows={4}
                            onChange={this.handleChange.bind(this)}
                            variant="outlined"
                        />
                        {
                            User.isGlobalAdmin() &&
                            <FormControlLabel
                                name="isGlobal"
                                control={<Checkbox
                                    onChange={this.handleChecked.bind(this)}
                                    color="primary" />
                                }
                                value= {this.state.isEdit ? this.state.values.isGlobal : null}
                                label="Tilføj til alle kommuner"
                                labelPlacement="end"
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleCreateModalOpenClose(false)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={(this.state.isEdit && this.state.values.isGlobal===true) ? () => this.handleWarningModalOpen(true) : this.state.isEdit ? this.handleEditCreate.bind(this) :  this.handleCreate.bind(this)} color="primary">
                            Gem
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        );
    }

}

export default withStyles(styles)(Categories)

