import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Theme} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Divider, Grid, Typography} from '@material-ui/core';
import {withStyles} from "@material-ui/styles";
import {colorArray, options} from './chart';

const styles: any = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAfter: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

export type UsersByDeviceProps = {
  platforms: any
  classes: any
};
export type UsersByDeviceState = {
  chartOptions: any,
  devices: { title: string, value: string, color:string }[]
}

export class UsersByDevice extends Component<UsersByDeviceProps, UsersByDeviceState> {

  private reference: any;

  constructor(props: UsersByDeviceProps) {
    super(props);

    this.state = {
      chartOptions: options,
      devices: []
    }
  }

  componentDidMount(): void {
    this.populateDevicesArray();

    if(this.reference && this.reference.hasOwnProperty("update"))
      this.reference.update();
  }

  componentDidUpdate(prevProps: Readonly<UsersByDeviceProps>, prevState: Readonly<UsersByDeviceState>, snapshot?: any): void {
    if(prevProps.platforms !== this.props.platforms) {
      this.populateDevicesArray();

      if(this.reference && this.reference.hasOwnProperty("update"))
        this.reference.update();
    }
  }

  populateDevicesArray(): any {
    const devicesResult: { title: string, value: string, color: string }[] = [];

    const data = this.props.platforms.data;
    if(data && data.labels && Array.isArray(data.labels)
        && data.datasets && data.datasets.length > 0){

      const total = data.total;

      data.labels.forEach((value: any, index: number) => {
        const dataValue = data.datasets[0].data[index];
        let valuePercentage: number = 0;

        if (total && total > 0)
          valuePercentage = (dataValue / total) * 100;

        devicesResult.push({
          title:  value,
          value: valuePercentage.toFixed(1).toString(),
          color: data.datasets[0].backgroundColor[index],
        });
      });
    }

    this.setState({ devices: devicesResult });
  }


  render (){
      return (
        <div className={this.props.classes.root}>
          <Card>
            <CardHeader title="Samtaler efter platform" />
            <Divider />
            <CardContent>
              <div className={this.props.classes.chartContainer}>
                <Doughnut ref = {(reference) => this.reference = reference}
                  data={this.props.platforms.data}
                  options={this.state.chartOptions}
                />
              </div>
            </CardContent>
            <CardContent>
              <Grid container>
                {this.state.devices.map((device, i) => (
                    <Grid item key={this.state.devices.indexOf(device)}
                        className={this.props.classes.device}
                          lg={3} sm={6} xl={3} xs={6} >
                      <Typography variant="h4">{device.title}:  </Typography>
                      <Typography
                          style={{ color: device.color}}
                          variant="h4" >
                        {device.value}%
                      </Typography>
                    </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </div>
    )
  }
}

export function generateColorsForPie(dataPoints: number[]): string[]{
  let colorsResult: string[] = [];
  dataPoints.forEach((value, index) => {
    colorsResult.push(colorArray[index]);
  });
  return colorsResult;
}

export default withStyles(styles)(UsersByDevice);
