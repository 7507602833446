import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import InfoSvgButton from "../../../../../components/InfoButton";
import { APIService } from "../../../../../service/APIService";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Log } from "../../../../../common/log";
import { AxiosError } from "axios";
import LoadingIndicator from "../../../../../components/LoadingIndicator";

export interface IFrontDeskLocationProps {
  municipalityCode: string;
}

export const FrontDeskLocations: React.FC<IFrontDeskLocationProps> = ({
  municipalityCode,
}) => {
  const [locations, setLocations] = useState<any[]>();
  const service = useMemo(() => new APIService(), []);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    let isSubscribed = true;

    service
      .getAllFrontDeskLocations({ municipalityCode })
      .then((res) => isSubscribed && setLocations(res))
      .catch((err: AxiosError) => {
        if (isSubscribed) {
          if (err.isAxiosError && err.response?.status === 404) {
            setError(
              'Du skal oprette en Front Desk integration under "Integrationer" før du kan konfigurere og vælge køer'
            );
          } else {
            setError(
              "Der skete en fejl ved indlæsning af Front Desk køer: " +
              JSON.stringify(err.response?.data)
            );
          }
        }
        Log.error(err);
      });

    return () => {
      isSubscribed = false;
    };
  }, [municipalityCode, service]);

  const updateLocation = async (
    location: any,
    changes: { displayName?: string; type?: string; isActive?: boolean; }
  ) => {
    if (!locations) return;
    let index = locations.indexOf(location);
    try {
      const changed = [...locations];
      changed[index] = { ...location, ...changes };
      setLocations(changed);
      await service.updateFrontDeskLocation(
        parseInt(municipalityCode),
        location.frontDeskName,
        { ...location, ...changes }
      );
    } catch (err) {
      setLocations(locations);
      alert(
        `Der skete en fejl da lokationen "${location.frontDeskName}" skulle opdateres. Prøv igen.`
      );
      Log.error(err);
    }
  };

  const changeDisplayName = (location: any) => {
    const displayName = window.prompt(
      "Angiv lokationens navn til visning i Muni"
    );
    if (displayName) {
      updateLocation(location, { ...location, displayName });
    }
  };

  return (
    <Card>
      <CardHeader
        subheader="Lokationer fra Front Desk"
        title={
          <span>
            Front Desk lokationer{" "}
            <InfoSvgButton
              title={"Lokationer"}
              content={
                <Typography>
                  Her ser du alle lokationer i Front Desk. Du kan deaktivere
                  enkelte lokationer og give dem et andet visningsnavn i Muni.
                  <br />
                  Anvend <i>Front desk lokation</i> som værdi i Watson
                  Assistant.
                </Typography>
              }
            />
          </span>
        }
      />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Front desk lokation</TableCell>
                  <TableCell>Navn til visning</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!locations && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <LoadingIndicator isLoading={true} />
                    </TableCell>
                  </TableRow>
                )}
                {error && (
                  <TableRow>
                    <TableCell colSpan={3}>{error}</TableCell>
                  </TableRow>
                )}
                {locations && locations.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      Der findes ingen lokationer i Front Desk for den givne
                      kommune
                    </TableCell>
                  </TableRow>
                )}
                {locations &&
                  locations
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((location) => (
                      <TableRow key={location.frontDeskName}>
                        <TableCell>{location.frontDeskName}</TableCell>
                        <TableCell>
                          {location.displayName ? (
                            <Chip
                              onClick={() => changeDisplayName(location)}
                              color="secondary"
                              label={location.displayName}
                              size="small"
                            />
                          ) : (
                            <Chip
                              onClick={() => changeDisplayName(location)}
                              label="Angiv navn"
                              size="small"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {location.isActive ? (
                            <Chip
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Er du sikker på at du vil deaktivere lokationen?"
                                  )
                                ) {
                                  updateLocation(location, {
                                    isActive: false,
                                  });
                                }
                              }}
                              color="secondary"
                              label="Aktiv"
                              size="small"
                            />
                          ) : (
                            <Chip
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Er du sikker på at du vil aktivere lokationen?"
                                  )
                                ) {
                                  updateLocation(location, { isActive: true });
                                }
                              }}
                              label="Deaktiveret"
                              size="small"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>
        <TablePagination
          style={{ marginLeft: "auto" }}
          labelRowsPerPage="Rækker pr. side"
          labelDisplayedRows={({ from, to, count }) =>
            `${from > count ? count : from}-${to} af ${count}`
          }
          component="div"
          count={locations?.length || 0}
          onChangePage={(event, page) => setPage(page)}
          onChangeRowsPerPage={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        />
      </CardActions>
    </Card>
  );
};
