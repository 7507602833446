import React, {Component} from "react";
import {withStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import axios, {AxiosRequestConfig} from "axios";
import Grid from "@material-ui/core/Grid";
import {DownloadIcon} from "../../../../../../../assets/icons/DownloadIcon";
import {getTextForElement} from "../../../../../../../helpers/utils";
import {Log} from "../../../../../../../common/log";

const styles = ((theme: Theme) => ({

}));

export type ImageBotBlobProps = {
    classes: any,
    text?: string;
    descr?: string,
    source?: string
};

export type ImageBotBlobState = {  };


class ImageBotBlob extends Component<ImageBotBlobProps, ImageBotBlobState> {

    async downloadImage(event: any){
        if (!this.props.source)
            return;

        let imageType = "image/png";
        let filename = this.props.source.split(/(\\|\/)/g).pop();

        if (this.props.source.indexOf(".jpg") > -1)
            imageType = "image/jpeg";
        else if (this.props.source.indexOf(".png") > -1)
            imageType = "image/png";
        else if (this.props.source.indexOf(".gif") > -1)
            imageType = "image/gif";
        else if (this.props.source.indexOf(".svg") > -1)
            imageType = "image/svg+xml";

        const options: AxiosRequestConfig = {
            headers: {
                'Content-Type': imageType,
                'Content-Disposition': 'attachment'
            }
        };

        try {
            const res = await axios.get(this.props.source, options);

            if(res && res.data) {
                const image = `data:${imageType};base64,${btoa(res.data)}`;

                const element = document.createElement('a');
                element.setAttribute('href', image);
                element.setAttribute('download', filename || "Billede.png");

                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        } catch (e) {
            Log.log(`Failed downloading image`, e);
            window.open(this.props.source, "_blank");
        }
    }

    render() {
        if ((this.props.text || this.props.descr ) && (this.props.text !== "" || this.props.descr !== "")) {
            return (
                <Grid container spacing={1} direction="row" style={{ flexWrap: "nowrap" }}>
                    <Grid item>
                        <img className={"botBlobImg responseImgWithText"} src={this.props.source} alt={getTextForElement(this.props.text, this.props.descr)}/>
                        <Grid className={"responseImgText"}>
                            <div className={"responseTextAlign"}>{getTextForElement(this.props.text, this.props.descr)}</div>
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div onClick={this.downloadImage.bind(this)}>
                            <DownloadIcon/>
                        </div>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container spacing={1} direction="row" style={{ flexWrap: "nowrap" }}>
                    <Grid item>
                        <img className={"botBlobImg responseImg"} src={this.props.source} alt={"Billede"}/>
                    </Grid>
                    <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div onClick={this.downloadImage.bind(this)}>
                            <DownloadIcon/>
                        </div>
                    </Grid>
                </Grid>
            );
        }
    }

}

export default withStyles(styles)(ImageBotBlob);