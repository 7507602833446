import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import "moment/locale/da";
import {Divider, List, ListItem, ListItemText, Theme} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    cardHeader: {
        backgroundColor: theme.palette.white
    },
    cardContent: {
        backgroundColor: theme.palette.white
    },
    editCardContent: {
        backgroundColor: theme.palette.white,
        height: "50vh",
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    predefinedCardContent: {
        backgroundColor: theme.palette.white,
        height: "50vh",
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    iconCustomAnswer: {
        fontSize: 80,
        opacity: 0.8,
        color: theme.palette.text.primary
    },
    sectionText: {
        marginLeft: "5px"
    }
}));

export type TagDetailsProps = {
    tag: any,
    classes: any
};

export type TagDetailsState = {
    isModalActive: boolean,
    isEditLoading: boolean,
    values: any
};

class TagDetails extends Component<TagDetailsProps, TagDetailsState> {

    constructor(props: TagDetailsProps) {
        super(props);

        this.state = {
            isModalActive: false,
            isEditLoading: false,
            values: {}

        };
    }

    toggleEditModal = () => {
        this.setState({
            isModalActive: !this.state.isModalActive
        })
    }

    handleChange = (event: any) => {
        this.setState({
            values: {...this.state.values, [event.target.id]: event.target.value }
        });
    }

    toggleActive = (event: any) => {
        this.setState({
            values: {...this.state.values, [event.target.id]: !this.state.values[event.target.id] }
        });
    }

    submitEdit = (validateObject: any) => {
        let updateObject:any = {
            name: this.props.tag.tag,
            tagId: this.props.tag.tagId,
            active: this.props.tag.isActive
        }

        for(let v in validateObject){
            if(validateObject[v].changed){
                updateObject[v] = this.state.values[v]
            }
        }
    }

    componentDidMount() {
        if(this.props.tag){
            this.setState({
                values:{
                    active: this.props.tag.isActive,
                    tagId: this.props.tag.tagId,
                    name: this.props.tag.tag
                }
            })
        }
    }

    render() {
        return (
            <Card className={this.props.classes.card}>
                <CardHeader className={this.props.classes.cardHeader} title={
                    <div>
                        <span>Guide detaljer</span>
                    </div>
                }>
                </CardHeader>
                <Divider/>
                <CardContent className={this.props.classes.cardContent}>
                    <List>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.guideName ? this.props.tag.guideName : "-"}
                                primary="Guide navn"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.tag ? this.props.tag.tag : "-"}
                                primary="Navn"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.tagId ? this.props.tag.tagId : "-"}
                                primary="ID"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.isActive ? "Ja" : "Nej"}
                                primary="Aktiv"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.correctedBy ? this.props.tag.correctedBy : "-"}
                                primary="Senest rettet af"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={this.props.tag && this.props.tag.correctedAt ? moment(this.props.tag.correctedAt).format('dddd DD. MMMM yyyy') + " " + moment(this.props.tag.correctedAt).format('HH:mm:ss') : "-"}
                                primary="Senest rettet tidspunkt"
                            />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        );
    }

}

export default withStyles(styles)(TagDetails)
