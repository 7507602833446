import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Footer, Topbar } from '../Main/components';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        }
    },
    shiftContent: {
        paddingLeft: 0
    },
    content: {
        height: '100%'
    }
}));

const ContentOnly = (props: Content_only_Props) => {
    const { children } = props;

    const classes: any = useStyles();
    const theme: any = useTheme();
    const isDesktop: boolean = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop
            })} >
            <Topbar onSidebarOpen={() => { }} />
            <div></div>
            <main className={classes.content}>
                {children}
                <Footer />
            </main>
        </div>
    );
};

type Content_only_Props = {
    children: any,
};

export default ContentOnly;
