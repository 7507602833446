import Grid from "@material-ui/core/Grid";
import React, {Component} from "react";
import {Theme} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import {getTextForElement} from "../../../../../../../helpers/utils";

const styles: any = ((theme: Theme) => ({
    video: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        objectFit: 'contain',
        width: '100%',
        maxWidth: '360px',
        border: 0
    },
    videoText: {
        width: '100%',
        maxWidth: '360px',
        float: 'left',
        paddingBottom: '10px',
        color: '#202020',
        fontSize: '12px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
    },
    videoTextAlign: {
        paddingLeft: '15px',
        paddingTop: '10px',
    }
}));

export type VideoBotBlobProps = {
    classes: any,
    text?: string,
    descr: string,
    source?: string
};

export type VideoBotBlobState = {};

class VideoBotBlob extends Component<VideoBotBlobProps, VideoBotBlobState> {

    render() {
        return (
            <Grid item xs={10}>
                <Grid className="botBlob" container>
                    <iframe title="video" src={this.props.source} className={this.props.classes.video}/>
                    <Grid className={this.props.classes.videoText} style={{ backgroundColor: 'var(--main-bg-bot_blob_color)' }}>
                        <div className={this.props.classes.videoTextAlign} style={{ color: `var(--main-bg-bot_blob_link_text_color)` }}>
                            {getTextForElement(this.props.text, this.props.descr)}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

}

export default withStyles(styles)(VideoBotBlob);