import Grid from "@material-ui/core/Grid";
import React, {Component} from "react";
import {Theme} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import {Sanitize} from "../../../../../../../helpers/utils";
import {OptionItem} from "./OptionItem";

const styles = ((theme: Theme) => ({

}));

export type OptionsBotBlobProps = {
    classes: any,
    text?: string,
    options?: any[]
};

export type OptionsBotBlobState = {  };

class OptionsBotBlob extends Component<OptionsBotBlobProps, OptionsBotBlobState> {

    render() {
        const optionsArr = [];
        if(this.props.options){
            for(let option of this.props.options){
                optionsArr.push(
                    <Grid item>
                        <OptionItem option={option} />
                    </Grid>
                )
            }
        }
        return(
            <Grid container className="botBlob">
                {
                    !this.props.text ? "" :
                        <Grid item xs={12}>
                            <div className="botBlobPaper" dangerouslySetInnerHTML={{__html: Sanitize(this.props.text) || ""}} ></div>
                        </Grid>
                }
                {optionsArr}
            </Grid>
        )
    }
}

export default withStyles(styles)(OptionsBotBlob);