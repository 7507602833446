import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Select,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { useMunicipality } from "../../useMunicipality";
import { APIService } from "../../../../service/APIService";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { DeleteOutline, Link } from "@material-ui/icons";
import { WatsonDiscoverySearchesTable } from "../../../Stats/components/WatsonDiscovery/WatsonDiscoverySearchesTable";
import { CustomCrawler } from "./components/CustomCrawler";

export interface WatsonDiscoveryIntegrationViewProps {}

export function WatsonDiscoveryIntegrationView(props: WatsonDiscoveryIntegrationViewProps) {
  const { params } = useRouteMatch<{ code: string }>();
  const municipality = useMunicipality(params.code);

  const service = useMemo(() => new APIService(), []);

  const [collections, setCollections] = useState<any[] | null>(null);
  const [attachingCollection, setAttachingCollection] = useState(false);

  const [allCollections, setAllCollections] = useState<any[] | null>(null);
  const [selectedCollection, setSelectedCollection] = useState("");

  const [detachingCollection, setDetachingCollection] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (attachingCollection) return;
    if (detachingCollection) return;
    let isSub = true;
    async function fetch() {
      const collections = await service.getWatsonDiscoveryCollectionsForMunicipality(params.code);
      const all = await service.getAllWatsonDiscoveryCollections();
      if (isSub) {
        setAllCollections(all.filter((c) => !collections.find((cc) => cc.collectionId === c.collection_id)));
        setCollections(
          collections.map((col) => {
            const wdCol = all.find((c) => c.collection_id === col.collectionId);
            return {
              ...col,
              name: wdCol?.name,
              projectName: wdCol?.project_name,
            };
          })
        );
      }
    }
    fetch();
    return () => {
      isSub = false;
    };
  }, [params.code, service, attachingCollection, detachingCollection]);

  const attachCollection = async (collectionId: string) => {
    try {
      setAttachingCollection(true);
      await service.createCollectionForMuni(params.code, collectionId);
      setCollections(null);
    } catch (err) {
      console.error(err);
    } finally {
      setAttachingCollection(false);
    }
  };

  return (
    <Box p={3}>
      <Box height="42px" display="flex" alignItems="center" mt={1}>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink color="inherit" to="/municipalities">
            <Typography color="textPrimary">Kommuner</Typography>
          </RouterLink>
          <RouterLink color="inherit" to={`/municipalities/${municipality.code}`}>
            <Typography color="textPrimary">{municipality.name}</Typography>
          </RouterLink>
          <Typography color="textPrimary">Watson Discovery konfiguration</Typography>
        </Breadcrumbs>
      </Box>
      <Box mb={2} mt={2}>
        <Typography variant="h4">Watson Discovery konfiguration</Typography>
      </Box>
      <Grid container spacing={4}>
        {!collections && (
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card>
              <CardContent>
                <Box paddingY={4}>
                  <LoadingIndicator isLoading />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
        {collections && allCollections && (
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card>
              <CardContent>
                <Box maxWidth="400px">
                  <Typography variant="h5">Forbind til Watson Discovery</Typography>
                  <Typography variant="body1">
                    Med Watson Discovery kan du tilbyde søgning af din hjemmeside, hvis Muni ikke kender svaret.
                  </Typography>
                  <Typography style={{ marginTop: "8px" }} variant="body1">
                    Du skal{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://eu-de.discovery.watson.cloud.ibm.com/v2/instances/crn%3Av1%3Abluemix%3Apublic%3Adiscovery%3Aeu-de%3Aa%2Fdb501d761aa44a728bcaeb47f406c4f3%3A6fcc6e69-382c-4c87-b314-323f3ba20438%3A%3A/projects/"
                    >
                      oprette en web-scraper i Watson Discovery
                    </a>{" "}
                    før du kan tilknytte den her.
                  </Typography>
                </Box>
                <Box mt={2} display="flex">
                  <Select
                    variant="outlined"
                    native
                    displayEmpty
                    style={{ minWidth: "240px", marginRight: "8px" }}
                    value={selectedCollection}
                    onChange={(e) => setSelectedCollection(e.target.value as string)}
                  >
                    <option value={""} hidden>
                      Vælg en kollektion
                    </option>
                    {allCollections &&
                      allCollections.map((col) => (
                        <option key={col.collection_id} value={col.collection_id}>
                          {col.name} ({col.project_name})
                        </option>
                      ))}
                  </Select>
                  <Button
                    variant="outlined"
                    style={{ flexShrink: 0 }}
                    disabled={!selectedCollection || attachingCollection}
                    onClick={() => selectedCollection && attachCollection(selectedCollection)}
                  >
                    Forbind til kollektion
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
        {collections && (
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Card>
              <CardContent>
                <CustomCrawler municipality={municipality}/>
              </CardContent>
            </Card>
          </Grid>
        )}
        {collections?.map((collection) => (
          <Grid item lg={12} md={12} xl={12} xs={12} key={collection.id}>
            <Card>
              <CardHeader title={collection.name} />
              <Divider />
              <CardContent>
                <Typography variant="body1">
                  Tilknyttet Watson Discovery kollektionen <strong>{collection.name}</strong> med ID{" "}
                  {collection.collectionId} i projektet <strong>{collection.projectName}</strong>.
                </Typography>
                <br />
                <Button
                  endIcon={<Link />}
                  variant="outlined"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://eu-de.discovery.watson.cloud.ibm.com/v2/instances/crn%3Av1%3Abluemix%3Apublic%3Adiscovery%3Aeu-de%3Aa%2Fdb501d761aa44a728bcaeb47f406c4f3%3A6fcc6e69-382c-4c87-b314-323f3ba20438%3A%3A/projects/${collection.projectId}/collections/${collection.collectionId}/activity`}
                  style={{ marginRight: 8 }}
                >
                  Konfigurer kollektionen i IBM Cloud
                </Button>
                <Button
                  endIcon={<DeleteOutline />}
                  variant="outlined"
                  onClick={() => setDetachingCollection(collection)}
                >
                  Fjern kollektion fra kommunen
                </Button>
              </CardContent>
              <WatsonDiscoverySearchesTable municipalityCode={params.code} collectionId={collection.collectionId} />
            </Card>
          </Grid>
        ))}
      </Grid>
      <DetachDialog
        open={!!detachingCollection}
        collection={detachingCollection}
        onClose={() => setDetachingCollection(undefined)}
        onDetach={() => {
          setCollections(null);
        }}
        code={params.code}
      />
    </Box>
  );
}

const DetachDialog = ({
  collection,
  open,
  onClose,
  onDetach,
  code,
}: {
  collection: any | undefined;
  open: boolean;
  onClose: () => void;
  onDetach: () => void;
  code: number | string;
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const service = useMemo(() => new APIService(), []);
  const detachCollection = async () => {
    setDeleting(true);
    try {
      await service.detachCollectionFromMuni(code, collection.id);
      onDetach();
      onClose();
    } finally {
      setDeleting(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} onExited={onClose}>
      <DialogTitle>Fjern kollektion fra kommunen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Er du sikker på at du vil fjerne kollektionen <strong>{collection?.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} disabled={deleting}>
          Annuller
        </Button>
        <Button onClick={() => detachCollection()} color="secondary" disabled={deleting}>
          Fjern kollektion
        </Button>
      </DialogActions>
    </Dialog>
  );
};
