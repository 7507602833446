import { Breadcrumbs, Typography, Grid, Box } from "@material-ui/core";
import React from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { useMunicipality } from "../../useMunicipality";
import { FrontDeskLocations } from "./components/FrontDeskLocations";
import { FrontDeskQueues } from "./components/FrontDeskQueues";

export const FrontDeskInstanceView = () => {

  const { params } = useRouteMatch<{ code: string; }>();
  const municipality = useMunicipality(params.code);

  return (
    <Box p={3}>
      <Box height="42px" display="flex" alignItems="center" mt={1}>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink color="inherit" to="/municipalities">
            <Typography color="textPrimary">Kommuner</Typography>
          </RouterLink>
          <RouterLink
            color="inherit"
            to={`/municipalities/${municipality.code}`}
          >
            <Typography color="textPrimary">{municipality.name}</Typography>
          </RouterLink>
          <Typography color="textPrimary">Front Desk konfiguration</Typography>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <FrontDeskQueues municipalityCode={params.code} />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <FrontDeskLocations municipalityCode={params.code} />
        </Grid>
      </Grid>
    </Box>
  );
};
