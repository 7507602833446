import React from "react";
import { HeadIconProps } from "./SadHeadIcon";

export const HappyHeadIcon: React.FC<HeadIconProps> = ({ mute, active }) => (
  <div
    className={`happy-head-icon ${mute ? "mute " : ""} ${
      active ? "active" : ""
    }`}
  >
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" />
      <g opacity="1" transform="translate(-4.5 -4.5)">
        <path
          d="M32.7852 31.0117C31.3028 32.752 29.1758 33.7188 26.9844 33.7188C24.7285 33.7188 22.6016 32.752 21.1192 31.0117C20.7969 30.5605 20.1524 30.5605 19.7012 30.8828C19.25 31.2695 19.1856 31.9141 19.5723 32.3652C21.377 34.5566 24.084 35.7812 26.9844 35.7812C29.8203 35.7812 32.5274 34.5566 34.3321 32.3652C34.7188 31.9141 34.6543 31.2695 34.2031 30.8828C33.8164 30.5605 33.1074 30.5605 32.7852 31.0117ZM21.8281 25.4688C22.9238 25.4688 23.8906 24.5664 23.8906 23.4062C23.8906 22.3105 22.9238 21.3438 21.8281 21.3438C20.668 21.3438 19.7656 22.3105 19.7656 23.4062C19.7656 24.5664 20.668 25.4688 21.8281 25.4688ZM32.1406 25.4688C33.2363 25.4688 34.2031 24.5664 34.2031 23.4062C34.2031 22.3105 33.2363 21.3438 32.1406 21.3438C30.9805 21.3438 30.0781 22.3105 30.0781 23.4062C30.0781 24.5664 30.9805 25.4688 32.1406 25.4688Z"
          fill="#333333"
        />
      </g>
    </svg>
  </div>
);
