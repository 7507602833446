import palette from '../../../../theme/palette';

const pal: any = palette;

export const options = {
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: pal.divider,
    backgroundColor: 'rgb(255, 255, 255)',
    titleFontColor: pal.text.primary,
    bodyFontColor: pal.text.secondary,
    footerFontColor: pal.text.secondary
  }
};


export const deviceTypes = {

}


export const colorArray = [
  'rgb(56, 142, 60)',
  'rgb(71, 90, 255)',
  'rgb(255, 71, 212)',
  'rgb(255, 71, 71)',
  'rgb(71, 255, 255)',
  'rgb(255, 120, 71)',
  'rgb(255, 169, 71)', 
  'rgb(255, 218, 71)',

  'rgb(167, 245, 66)',

  'rgb(158, 66, 245)',
  'rgb(66, 176, 245)',
  'rgb(15,114,78)',
  'rgb(115, 135, 81)',
  'rgb(81, 92, 135)',
  'rgb(135, 81, 130)',
  'rgb(135, 81, 98)',
  'rgb(63,23,121)',
  'rgb(148,151,13)',
  'rgb(177,71,55)',
  'rgb(86,82,227)',
  'rgb(227,117,117)',
]
