import {
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { APIService } from "../../../../service/APIService";
import { IWatsonDiscoverySearch } from "../../../../service/interfaces/IWatsonDiscoveryStats";
import moment from "moment";
import { useMunicipalities } from "../../../../hooks/useMunicipalities";
import { SearchInput } from "../../../../components";

export interface WatsonDiscoverySearchesTableProps {
  collectionId?: string;
  municipalityCode: string | number;
  periodStart?: Date;
  periodEnd?: Date;
}

export const WatsonDiscoverySearchesTable: React.FC<
  WatsonDiscoverySearchesTableProps
> = ({ collectionId, municipalityCode, periodStart, periodEnd }) => {
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 5,
  });

  const { municipalities } = useMunicipalities();

  const apiService = useMemo(() => new APIService(), []);

  const [searches, setSearches] = useState<IWatsonDiscoverySearch[] | null>(
    null
  );

  const [filter, setFilter] = useState("");

  const filteredSearches = useMemo(
    () =>
      searches?.filter(
        (s) =>
          !filter ||
          (s.metadata?.query &&
            typeof s.metadata?.query === "string" &&
            (s.metadata?.query as string)
              .toLowerCase()
              .includes(filter.toLowerCase()))
      ),
    [searches, filter]
  );

  useEffect(() => {
    if (municipalityCode === undefined || municipalityCode === null) return;
    let isSub = true;
    setSearches(null);
    async function fetch() {
      const searches = await apiService.getWatsonDiscoverySearchesForCollection(
        { collectionId, municipalityCode, periodStart, periodEnd }
      );
      if (isSub) setSearches(searches);
    }
    fetch();
    return () => {
      isSub = false;
    };
  }, [apiService, collectionId, municipalityCode, periodStart, periodEnd]);

  const obfuscate = async (query: string) => {
    const newQuery = prompt(
      `Du kan fjerne teksten "${query}" fra alle søgninger.\n\nHvad skal der stå i stedet?`
    );
    if (!newQuery) return;
    try {
      await apiService.obfuscateWatsonDiscoverySearch(query, newQuery);
      setSearches(
        searches!.map((s) => ({
          ...s,
          metadata: {
            ...s.metadata,
            query: s.metadata.query === query ? newQuery : s.metadata.query,
          },
        }))
      );
    } catch (err) {
      alert(`Der skete en fejl: ${err}`);
    }
  };

  return (
    <Card>
      <CardHeader
        title="Søgninger"
        action={
          <SearchInput
            className=""
            placeholder="Filtrer søgninger"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: 320 }}
          />
        }
      />
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Dato</TableCell>
            <TableCell>Kommune</TableCell>
            <TableCell>Søgning</TableCell>
            <TableCell style={{ width: 40 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!searches && (
            <TableRow>
              <TableCell colSpan={5}>
                <LoadingIndicator isLoading={true} />
              </TableCell>
            </TableRow>
          )}
          {filteredSearches &&
            filteredSearches
              .slice(
                pagination.page * pagination.rowsPerPage,
                (pagination.page + 1) * pagination.rowsPerPage
              )
              .map((s, i) => (
                <TableRow key={s.id}>
                  <TableCell>{moment(s.datetime).format("LLL")}</TableCell>
                  <TableCell>
                    {s.municipalityCode} (
                    {
                      municipalities?.find((m) => m.code === s.municipalityCode)
                        ?.name
                    }
                    )
                  </TableCell>
                  <TableCell>{s.metadata.query}</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      aria-label="slet dialog"
                      style={{ marginTop: -6, marginBottom: -6 }}
                      onClick={() => {
                        obfuscate(s.metadata.query as string);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <CardActions>
        <TablePagination
          style={{ marginLeft: "auto" }}
          labelRowsPerPage="Rækker pr. side"
          labelDisplayedRows={({ from, to, count }) =>
            `${from > count ? count : from}-${to} af ${count}`
          }
          component="div"
          count={searches?.length || 0}
          onChangePage={(e, page) => setPagination((p) => ({ ...p, page }))}
          onChangeRowsPerPage={(e) =>
            setPagination((p) => ({ ...p, rowsPerPage: e.target.value as any }))
          }
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        />
      </CardActions>
    </Card>
  );
};
