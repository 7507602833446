import React, { Component } from "react";
import { Card, CardContent, CardHeader, Divider, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { options } from './chart';
import { Line } from "react-chartjs-2";
import { TimeResolution } from "../../../../../common/statistics";

const styles: any = ((theme: Theme) => ({
  root: {
    padding: theme.spacing(0)
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  content: {
    marginTop: theme.spacing(0)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

export type DialogsStatisticsProps = {
  timeRange: {
    start: Date,
    end: Date,
  },
  resolution: TimeResolution,
  dialogs: any,
  classes: any;
};

export type DialogsStatisticsState = {
  chartOptions: any;
};

class DialogsStatistics extends Component<DialogsStatisticsProps, DialogsStatisticsState> {

  private reference: any;

  constructor(props: DialogsStatisticsProps) {
    super(props);

    this.state = {
      chartOptions: options,
    };
  }

  componentDidMount(): void {
    this.formatTimeResolutionForChartOptions(this.props.resolution);
    if (this.reference && this.reference.hasOwnProperty("update"))
      this.reference.update();
  }

  componentDidUpdate(prevProps: Readonly<DialogsStatisticsProps>, prevState: Readonly<DialogsStatisticsState>, snapshot?: any): void {
    if (prevProps.dialogs !== this.props.dialogs) {
      this.formatTimeResolutionForChartOptions(this.props.resolution);
      if (this.reference && this.reference.hasOwnProperty("update"))
        this.reference.update();
    }
  }

  formatTimeResolutionForChartOptions(resolution: TimeResolution) {
    let options = JSON.parse(JSON.stringify(this.state.chartOptions));
    switch (this.props.resolution) {
      case "minute":
      case "hour": {
        options.scales.xAxes[0].time.unit = "hour"; // Hourly
        options.scales.xAxes[0].time.tooltipFormat = "Do MMM YYYY HH:mm";
        options.scales.xAxes[0].scaleLabel.labelString = "Klokkeslæt";
        options.tooltips.callbacks = {
          title: function (tooltipItems: { xLabel: string; }[], data: any) {
            return tooltipItems[0].xLabel;
          }
        };

        break;
      }
      case "day": {
        options.scales.xAxes[0].time.unit = "day"; // Days
        options.scales.xAxes[0].time.tooltipFormat = "DD/MM/YYYY";
        options.scales.xAxes[0].scaleLabel.labelString = "Dato";
        options.tooltips.callbacks = {
          title: function (tooltipItems: { xLabel: string; }[], data: any) {
            return tooltipItems[0].xLabel;
          }
        };

        break;
      }
      case "week": {
        options.scales.xAxes[0].time.unit = "week"; // Round to nearest week
        options.scales.xAxes[0].time.tooltipFormat = "WW YYYY";
        options.scales.xAxes[0].scaleLabel.labelString = "Uge nr";
        options.tooltips.callbacks = {
          title: function (tooltipItems: { xLabel: string; }[], data: any) {
            return 'Uge ' + tooltipItems[0].xLabel;
          }
        };

        break;
      }
      case "quarter":
      case "year":
      case "month": {
        options.scales.xAxes[0].time.unit = "month"; // Round to nearest month
        options.scales.xAxes[0].time.tooltipFormat = "MMMM YYYY";
        options.scales.xAxes[0].scaleLabel.labelString = "Måned";
        options.tooltips.callbacks = {
          title: function (tooltipItems: { xLabel: string; }[], data: any) {
            return tooltipItems[0].xLabel;
          }
        };

        break;
      }
      default:
        break;
    }
    this.setState({ chartOptions: options });
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Card>
          <CardHeader title="Antal samtaler"
          />
          <Divider />
          <CardContent>
            <div className={this.props.classes.chartContainer}>
              <Line ref={(reference) => this.reference = reference}
                data={this.props.dialogs.data}
                options={this.state.chartOptions}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

}

export default withStyles(styles)(DialogsStatistics);
