import React from 'react';
import { Route } from 'react-router-dom';

const RouteWithLayout = (props: RouteWithLayoutprops) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

type RouteWithLayoutprops = {
  layout: any
  component: any,
  path: string,
  exact: any,
}
export default RouteWithLayout;
