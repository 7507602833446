import Grid from "@material-ui/core/Grid";
import React, {Component} from "react";
import {withStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";
import {Sanitize} from "../../../../../../../helpers/utils";

const styles = ((theme: Theme) => ({

}));

export type TextBotBlobProps = { classes: any, text: string };

export type TextBotBlobState = {  };

class TextBotBlob extends Component<TextBotBlobProps, TextBotBlobState> {

    render(){
        return (
            <Grid container className={"botBlob"}>
                <Grid item xs={12}>
                    <div className="botBlobPaper" dangerouslySetInnerHTML={{__html: Sanitize(this.props.text) || ""}} ></div>
                </Grid>
            </Grid>
        );
    }

}

export default withStyles(styles)(TextBotBlob);