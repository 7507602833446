import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
import { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Log } from "../../../../../common/log";
import { SearchInput } from "../../../../../components";
import InfoSvgButton from "../../../../../components/InfoButton";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import { APIService } from "../../../../../service/APIService";
import { FrontDeskQueueModal } from "./FrontDeskQueueModal";
import { IFrontDeskQueue } from "./IFrontDeskQueue";

export interface IFrontDeskQueuesProps {
  municipalityCode: string;
}

export const FrontDeskQueues: React.FC<IFrontDeskQueuesProps> = ({
  municipalityCode,
}) => {
  const [queues, setQueues] = useState<IFrontDeskQueue[]>();

  const [query, setQuery] = useState("");

  const [editQueue, setEditQueue] = useState<IFrontDeskQueue | undefined>(
    undefined
  );

  const service = useMemo(() => new APIService(), []);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [error, setError] = useState("");

  useEffect(() => {
    let isSubscribed = true;

    service
      .getAllFrontDeskQueues({ municipalityCode })
      .then((res) => isSubscribed && setQueues(res))
      .catch((err: AxiosError) => {
        if (isSubscribed) {
          if (err.isAxiosError && err.response?.status === 404) {
            setError(
              'Du skal oprette en Front Desk integration under "Integrationer" før du kan konfigurere og vælge køer'
            );
          } else {
            setError(
              "Der skete en fejl ved indlæsning af Front Desk køer: " +
              JSON.stringify(err.response?.data)
            );
          }
        }
        Log.error(err);
      });

    return () => {
      isSubscribed = false;
    };
  }, [municipalityCode, service]);

  const updateQueue = async (
    queue: IFrontDeskQueue,
    changes: Partial<IFrontDeskQueue>
  ) => {
    if (!queues) return;
    let index = queues.indexOf(queue);
    try {
      const changed = [...queues];
      changed[index] = {
        ...queue,
        ...changes,
      };
      setQueues(changed);
      await service.updateFrontDeskQueue(
        parseInt(municipalityCode),
        queue.frontDeskId,
        { ...queue, ...changes }
      );
    } catch (err) {
      setQueues(queues);
      alert(
        `Der skete en fejl da "${queue.name}" skulle opdateres. Prøv igen.`
      );
      Log.error(err);
    }
  };

  const filteredQueues = useMemo(() => {
    return queues?.filter(
      (q) => JSON.stringify(q).toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }, [queues, query]);

  return (
    <Card>
      <CardHeader
        subheader="Aktive køer fra Front Desk"
        title={
          <span>
            Front Desk køer{" "}
            <InfoSvgButton
              title={"Front desk køer"}
              content={
                <Typography>
                  Du kan her se alle de Front Desk køer som er aktive og kan
                  anvendes i chatbotten. Du kan tilføje eller fjerne køer efter
                  behov for den specifikke kommune.
                </Typography>
              }
            />
          </span>
        }
        action={
          <Box minWidth={"300px"} marginRight={1} marginTop={1}>
            <SearchInput
              className=""
              placeholder="Søg efter køer"
              value={query}
              onChange={(e: any) => setQuery(e.target.value)}
            />
          </Box>
        }
      />
      <FrontDeskQueueModal
        queue={editQueue}
        onClose={() => setEditQueue(undefined)}
        onUpdateQueue={updateQueue}
      />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Front desk ID</TableCell>
                  <TableCell>Front desk navn</TableCell>
                  <TableCell>Navn til visning</TableCell>
                  <TableCell>Køens type</TableCell>
                  <TableCell>Lokationer</TableCell>
                  <TableCell>Vis antal dage fra nu</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!queues && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <LoadingIndicator isLoading={true} />
                    </TableCell>
                  </TableRow>
                )}
                {error && (
                  <TableRow>
                    <TableCell colSpan={8}>{error}</TableCell>
                  </TableRow>
                )}
                {queues && queues.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      Der findes ingen køer i Front Desk for den givne kommune
                    </TableCell>
                  </TableRow>
                )}
                {filteredQueues &&
                  filteredQueues
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((queue) => (
                      <TableRow key={queue.frontDeskId}>
                        <TableCell>{queue.frontDeskId}</TableCell>
                        <TableCell>{queue.name}</TableCell>
                        <TableCell>{queue.displayName || "-"}</TableCell>
                        <TableCell>{queue.type || "-"}</TableCell>
                        <TableCell>{queue.locations.join(", ")}</TableCell>
                        <TableCell>{queue.lookAhead} dage</TableCell>
                        <TableCell>
                          {queue.isActive ? (
                            <Chip
                              color="secondary"
                              label="Aktiv"
                              size="small"
                            />
                          ) : (
                            <Chip label="Deaktiveret" size="small" />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="inherit"
                            onClick={() => setEditQueue(queue)}
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>
        <TablePagination
          style={{ marginLeft: "auto" }}
          labelRowsPerPage="Rækker pr. side"
          labelDisplayedRows={({ from, to, count }) =>
            `${from > count ? count : from}-${to} af ${count}`
          }
          component="div"
          count={filteredQueues?.length || 0}
          onChangePage={(event, page) => setPage(page)}
          onChangeRowsPerPage={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setPage(0);
          }}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        />
      </CardActions>
    </Card>
  );
};
