import { useState, useCallback, useEffect, useMemo } from "react";
import { Log } from "../../common/log";
import { APIService } from "../../service/APIService";

export function useMunicipality(code: string) {
  const service = useMemo(() => new APIService(), []);

  const [municipality, setMunicipality] = useState<any>({});
  const fetchMunicipality = useCallback(
    async (code: string) => {
      try {
        const res = await service.getMunicipality(code, true);
        if (res) {
          setMunicipality(res);
        }
      } catch (e) {
        Log.error(`Failed loading municipality ${e}`);
      }
    },
    [service]
  );

  useEffect(() => {
    fetchMunicipality(code);
  }, [code, fetchMunicipality]);

  return municipality;
}