import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { APIService } from "../../../../../service/APIService";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import { Add, Delete, Edit, Save } from "@material-ui/icons";

interface StartUrl {
  id: number,
  municipalityId: number,
  startUrl: string,
  depth: number,
  selector: string
}

export function CustomCrawler(props: {municipality: any}) {
  // State management
  const {id} = props.municipality
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedUrl, setSelectedUrl] = useState<StartUrl | undefined>(undefined)
  const [startUrls, setStartUrls] = useState<Array<StartUrl>>([])
  const service = useMemo(() => new APIService(), []);
  useEffect(() => {
    loadUrls()
    // eslint-disable-next-line
  }, [id, service])

  // Functions
  const selectUrl = (url: StartUrl | null) => {
    if (url !== null) {
      setSelectedUrl(url)
    } else {
      setSelectedUrl({
        id: -1,
        depth: 0,
        municipalityId: id,
        selector: '',
        startUrl: ''
      })
    }
  }

  const updateUrl = (property: keyof StartUrl, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (selectedUrl === undefined) return;
    let value: string | number = event.target.value
    if (property === 'depth' && value.length > 0) {
      value = parseInt(value)
      if (Number.isNaN(value)) return;
    }
    const updatedUrl: Record<string, any> = Object.assign({}, selectedUrl)
    updatedUrl[property] = value
    setSelectedUrl(updatedUrl as StartUrl);
  }

  const saveUrl = async () => {
    if (selectedUrl === undefined) return;
    setLoading(true);
    await service.saveUrl(selectedUrl);
    setSelectedUrl(undefined);
    loadUrls()
  }

  const deleteUrl = async (id: number) => {
    setLoading(true);
    await service.deleteUrl(id);
    setSelectedUrl(undefined);
    loadUrls()
  }

  const loadUrls = async () => {
      setLoading(true)
      const result = await service.getUrlsForMunicipality(id)
      setStartUrls(result)
      setLoading(false)
    };

  // Render
  return (
  <Box>
    <Typography variant="h5">
      Custom Crawler Setup
    </Typography>
    <Box style={{display: 'flex', columnGap: '16px', paddingTop: '8px', paddingBottom: '8px'}}>
        <TextField onChange={(event) => updateUrl('startUrl', event)} helperText="Start URL" variant="outlined" disabled={selectedUrl === undefined} value={selectedUrl?.startUrl ?? ''}/> 
        <TextField onChange={(event) => updateUrl('selector', event)} helperText="Selector-tag" variant="outlined" disabled={selectedUrl === undefined} value={selectedUrl?.selector ?? ''}/>
        <TextField onChange={(event) => updateUrl('depth', event)} helperText="Dybde" variant="outlined" disabled={selectedUrl === undefined} value={selectedUrl?.depth ?? ''}/>
        <Button variant='contained' startIcon={<Save style={{color: '#02718F'}}/>} disabled={selectedUrl === undefined} onClick={() => saveUrl()}>
          Gem Ændringer
        </Button>
    </Box>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Start URL</TableCell>
            <TableCell>Selector-tag</TableCell>
            <TableCell>Dybde</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
        {!loading && startUrls && startUrls.map(url => {
          const isSelected = url.id === selectedUrl?.id
          return (
            <TableRow style={{backgroundColor: isSelected ? '#02718F' : '#FFFFFF'}}>
              <TableCell size="small" style={{color: isSelected ? '#FFFFFF' : '#000000'}}>{url.startUrl}</TableCell>
              <TableCell size="small" style={{color: isSelected ? '#FFFFFF' : '#000000'}}>{url.selector}</TableCell>
              <TableCell size="small" style={{color: isSelected ? '#FFFFFF' : '#000000'}}>{url.depth}</TableCell>
              <TableCell size="small" align="right" style={{color: isSelected ? '#FFFFFF' : '#000000'}}>
                <Box>
                  <IconButton onClick={() => selectUrl(url)}>
                    <Edit style={{color: isSelected ? '#FFFFFF' : '#02718F'}}/>
                  </IconButton>
                  <IconButton onClick={
                    // eslint-disable-next-line
                    () => confirm(`Er du sikker på du vil slette ${url.startUrl}`) ? deleteUrl(url.id) :''
                    }>
                    <Delete style={{color: isSelected ? '#FFFFFF' : '#02718F'}}/>
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          )
        })}
        {loading && <TableRow><TableCell colSpan={4}><LoadingIndicator isLoading={loading} /></TableCell></TableRow>}
        <TableRow style={{backgroundColor: selectedUrl?.id === -1 ? '#02718F' : '#FFFFFF'}}>
              <TableCell size="small" align="left" colSpan={4}>
                <Button variant='contained' startIcon={<Add style={{color: '#02718F'}} />} onClick={() => selectUrl(null)}>
                  Tilføj ny URL
                </Button>
              </TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
  )
}