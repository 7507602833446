import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/da";
import { Box, Chip, Divider, Grid, Theme } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { APIService } from "../../service/APIService";
import MailIcon from '@material-ui/icons/MailRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import CallIcon from '@material-ui/icons/Call';
import LoadingIndicator from "../../components/LoadingIndicator";
import { Log } from "../../common/log";
import DialogConversation from '../DialogDetails/components/DialogConversation';
import { SadHeadIcon } from '../DialogDetails/components/HeadIcons/SadHeadIcon';
import { MediumHeadIcon } from '../DialogDetails/components/HeadIcons/MediumHeadIcon';
import { HappyHeadIcon } from '../DialogDetails/components/HeadIcons/HappyHeadIcon';

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    cardHeader: {
        backgroundColor: theme.palette.white
    },
    cardContent: {
        backgroundColor: theme.palette.white,
        height: "60vh",
        overflow: 'auto'
    },
    feedbackCard: {
        marginTop: theme.spacing(3)
    },
    cardFeedbackContent: {
        backgroundColor: theme.palette.white,
        overflow: 'auto'
    },
    chipSuccess: {
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    chipError: {
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        backgroundColor: theme.palette.error.light
    },
    iconDeleted: {
        fontSize: 80,
        opacity: 0.8,
        color: theme.palette.text.primary
    },
    deleteBtn: {
        color: theme.palette.error.main
    }
}));

export type DialogDetailsProps = { match: any, classes: any, location: any };

export type DialogDetailsState = {
    isLoading: boolean,
    deleteModalOpen: boolean,
    dialog: any,
    alert: {
        isAlertOpen: boolean,
        alertMessage: string,
        alertType: "success" | "error" | "info" | "warning",
    },
};

class DialogDetailsContentOnly extends Component<DialogDetailsProps, DialogDetailsState> {

    private service: APIService = new APIService();

    constructor(props: DialogDetailsProps) {
        super(props);

        this.state = {
            isLoading: true,
            deleteModalOpen: false,
            dialog: {
                messages: [],
                states: {}
            },
            alert: {
                isAlertOpen: false,
                alertMessage: "",
                alertType: "success"
            },
        }
    }


    async componentDidMount(): Promise<void> {
        await this.fetchDialog();
    }

    async fetchDialog(): Promise<void> {

        if (!this.state.isLoading)
            this.setState({ isLoading: true });

        try {
            const search = new URLSearchParams(this.props.location.search);
            const token = search.get("token") as string;
            const res = await this.service.getExternalDialog(this.props.match.params.id, token);

            if (res) {
                this.setState({ dialog: res });
            }
        } catch (e) {
            Log.error(`Failed loading dialog ${e}`);
        }
        setTimeout(() => this.setState({ isLoading: false }), 500);
    }

    getChips() {

        if (!this.state.dialog.states) {
            return;
        }

        let deleteChip: any = "";
        if (this.state.dialog.states.deleteState === 1) {
            deleteChip = <Chip className={this.props.classes.chipSuccess}
                icon={<DeleteIcon style={{ color: "black" }} />}
                label="Sletning anmodet" />;
        }
        else if (this.state.dialog.states.deleteState === 3) {
            deleteChip = <Chip className={this.props.classes.chipError}
                icon={<DeleteIcon style={{ color: "black" }} />}
                label="Sletning fejlet"
                color="primary" />;
        }

        let mailChip: any = "";
        if (this.state.dialog.states.mailState === 1) {
            mailChip = <Chip className={this.props.classes.chipSuccess}
                icon={<MailIcon />}
                label="Mail anmodet" />;
        }
        else if (this.state.dialog.states.mailState === 2) {
            mailChip = <Chip className={this.props.classes.chipSuccess}
                icon={<MailIcon />}
                label="Mail afsendt"
                color="primary" />;
        }
        else if (this.state.dialog.states.mailState === 3) {
            mailChip = <Chip className={this.props.classes.chipError}
                icon={<MailIcon style={{ color: "black" }} />}
                label="Mail afsendelse fejlet" />;
        }

        let callbackChip: any = "";
        if (this.state.dialog.states.puzzelState === 1) {
            callbackChip = <Chip className={this.props.classes.chipSuccess}
                icon={<CallIcon />}
                label="Callback foretaget"
                color="primary" />;
        }
        else if (this.state.dialog.states.puzzelState === 2) {
            callbackChip = <Chip className={this.props.classes.chipError}
                icon={<CallIcon style={{ color: "black" }} />}
                label="Callback afsendelse fejlet" />;
        }

        return (
            <div>
                {mailChip}
                {callbackChip}
                {deleteChip}
            </div>
        );
    }

    getFeedback() {

        if (!this.state.dialog.rating) {
            return;
        }

        return (
            <Card className={this.props.classes.card + " " + this.props.classes.content + " " + this.props.classes.feedbackCard}>
                <CardHeader className={this.props.classes.cardHeader} avatar={
                    <Typography gutterBottom variant="h3">
                        Feedback
                    </Typography>
                }>
                </CardHeader>
                <Divider />
                <CardContent className={this.props.classes.cardFeedbackContent}>
                    <Box component="fieldset" mb={3} borderColor="transparent">
                        <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <SadHeadIcon mute={this.state.dialog.rating.ratingScore !== 1} />
                            <Box marginRight={1} />
                            <MediumHeadIcon mute={this.state.dialog.rating.ratingScore !== 2}/>
                            <Box marginRight={1} />
                            <HappyHeadIcon mute={this.state.dialog.rating.ratingScore !== 3}/>
                        </Box>
                    </Box>
                    <Typography variant="body1">
                        {
                            this.state.dialog.rating.ratingComment ? this.state.dialog.rating.ratingComment : ""
                        }
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                {
                    this.state.isLoading &&
                    <div className={this.props.classes.content}>
                        <LoadingIndicator isLoading={this.state.isLoading} />
                    </div>
                }
                {
                    !this.state.isLoading &&
                    <div className={this.props.classes.content}>
                        <Card className={this.props.classes.card}>
                            <CardHeader className={this.props.classes.cardHeader} avatar={
                                <div>
                                    <Typography
                                        gutterBottom
                                        variant="h2">
                                        Samtale
                                    </Typography>
                                    <Typography variant="body1">
                                        {
                                            this.state.dialog && this.state.dialog.municipality && this.state.dialog.municipality.name ? this.state.dialog.municipality.name : ""
                                        }
                                    </Typography>
                                    <Typography
                                        className={this.props.classes.dateText}
                                        variant="body1">
                                        {this.state.dialog && this.state.dialog.startTime ? moment(this.state.dialog.startTime).format('dddd DD. MMMM yyyy') : ""}
                                    </Typography>
                                    <Typography
                                        className={this.props.classes.dateText}
                                        variant="body1">
                                        {this.state.dialog && this.state.dialog.startTime ? "kl. " + moment(this.state.dialog.startTime).format('HH:mm:ss') : ""}
                                    </Typography>
                                </div>
                            } action={
                                <div>
                                    <Typography variant="body2">
                                        {this.state.dialog && this.state.dialog.customerId ? "WA ID:  " + this.state.dialog.customerId : ""}
                                    </Typography>
                                    <Typography variant="body2">
                                        {this.state.dialog && this.state.dialog.sessionId ? "Samtale ID: " + this.state.dialog.sessionId : ""}
                                    </Typography>
                                </div>
                            }>
                            </CardHeader>
                            <Divider />
                            <CardContent className={this.props.classes.cardContent}>
                                {
                                    this.state.dialog.states && this.state.dialog.states.deleteState === 2 ?

                                        <Grid container direction="column" alignItems="center" justify="center" style={{ height: "100%" }}>
                                            <Grid item>
                                                <DeleteIcon className={this.props.classes.iconDeleted} />
                                            </Grid>
                                            <Grid item>
                                                Samtalen er slettet i systemet
                                            </Grid>
                                        </Grid>
                                        :
                                        <DialogConversation messages={this.state.dialog.messages} />
                                }
                            </CardContent>
                            <Divider />
                        </Card>
                        {this.getFeedback()}
                    </div>
                }
            </div>
        )
    }
}

export default withStyles(styles)(DialogDetailsContentOnly)
