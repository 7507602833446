import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Theme} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";
import {MunicipalityCard, MunicipalityToolbar} from './components';
import {APIService} from "../../service/APIService";
import LoadingIndicator from "../../components/LoadingIndicator";
import {setState} from "../../common/promisify";
import Typography from "@material-ui/core/Typography";
import {Log} from "../../common/log";

const styles: any = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    text:{
        fontSize: "10px",
        color: "grey"
    }
}));

export type MunicipalityListProps = { classes: any };

export type MunicipalityListState = { municipalities: any[], isLoading: boolean };

class MunicipalityList extends Component<MunicipalityListProps, MunicipalityListState> {

    private service: APIService = new APIService();

    constructor(props: MunicipalityListProps) {
        super(props);

        this.state = {
            municipalities: [],
            isLoading: true
        };
    }

    async componentDidMount(): Promise<void> {
        await this.fetchMunicipalities();
    }

    async fetchMunicipalities(searchName?: string, sortNameAsc?: boolean):Promise<void> {

        if(!this.state.isLoading)
            await setState(this, { isLoading: true });

        let municipalities: any;
        try {
            if (!searchName)
                municipalities = await this.service.getMunicipalities(100, 0, sortNameAsc, false);
            else
                municipalities = await this.service.getMunicipalities(100, 0, sortNameAsc, false, searchName);

            await setState(this, () => ({
                municipalities: municipalities,
                })
            );
        }
        catch (e) {
            Log.error(`Failed loading municipalities`);
        }

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async handleSearch(searchName: string, sortNameAsc: boolean): Promise<void> {
        await this.fetchMunicipalities(searchName, sortNameAsc);
    }

    getMunicipalityCards() {

        if(!this.state.municipalities)
            return;

        return this.state.municipalities.map((municipality: any) => (
            <Grid item xs={12} md={6} lg={4} xl={3}
                  key={municipality.id}>
                <RouterLink to={`/municipalities/${municipality.code}`}>
                    <MunicipalityCard municipality={municipality}/>
                </RouterLink>
            </Grid>
        ));
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <Typography variant="body2">På denne side kan du få et overblik over de kommuner du har adgang til. Klik på den enkelte kommune for at redigere og se detaljer.</Typography>
                <MunicipalityToolbar onSearch={this.handleSearch.bind(this)}/>
                <div className={this.props.classes.content}>
                    <LoadingIndicator isLoading={this.state.isLoading}/>
                    <Grid container spacing={3}>
                        {
                           !this.state.isLoading && this.getMunicipalityCards()
                        }
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(MunicipalityList);
