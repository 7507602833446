import {Grid} from "@material-ui/core";
import React, {Component} from "react";

export type MessageContainerProps = {
    blobs: any[],
}

export default class MessageContainer extends Component<MessageContainerProps> {

    render(){
        return (
            <Grid container direction="column">
                <Grid item xs={2}></Grid>
                {this.props.blobs}
            </Grid>
        );
    }

}