import React, {Component} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Theme,
} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import InfoSvgButton from "../../../../../../components/InfoButton";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/da";
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import {User} from "../../../../../../helpers/profile";
import {Log} from "../../../../../../common/log";
import theme from "../../../../../../theme";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    actionCol: {
        width: "5%"
    },
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
      marginLeft: "4px"
    },
    status: {
        marginRight: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        width: '90%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

export type DownloadMissingContentProps = {
    isLoading: boolean,
    classes: any,
    municipalities: any[],
    onDownload: (municipalityCodes: number[]) => Promise<void>
};

export type DownloadMissingContentState = {
    isDownloading: boolean,
    values: any,
    alert: {
        isAlertOpen: boolean,
        alertMessage: string,
        alertType: "success" | "error" | "info" | "warning",
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class DownloadMissingContent extends Component<DownloadMissingContentProps, DownloadMissingContentState> {

    constructor(props: DownloadMissingContentProps) {
        super(props);
        this.state = {
            isDownloading: false,
            values: {
                isAllMunicipalities: true,
                municipalities: []
            },
            alert: {
                isAlertOpen: false,
                alertMessage: "",
                alertType: "success"
            },
        };
    }

    getActions(): any {
        if(User.isGlobalAdmin()){
            return (
                <div style={{ display: "inline"}}>
                    {
                        !this.props.isLoading &&
                        <div style={{ display: "inline"}}>
                            <Button variant="outlined" color="primary"
                                    onClick={this.submit.bind(this)}
                                    disabled={this.state.isDownloading || (!this.state.values.isAllMunicipalities && this.state.values.municipalities.length === 0)}
                                    className={this.props.classes.button}>Download</Button>
                        </div>
                    }
                </div>

            );
        }
    }

    handleChange(event: any): void {
        this.setState({
            values: {...this.state.values, [event.target.id]: event.target.value }
        });
    }

    handleChangeCheck(event: any): void {
        this.setState({
            values: {...this.state.values, [event.target.name]: event.target.checked }
        });
    }

    handleChangeMunicipality(event: any): void {
        this.setState({
            values: {...this.state.values, municipalities: event.target.value }
        });
    }

    getStyles(name: string, personName: string[], theme: Theme) {
        return {
            fontWeight: theme.typography.fontWeightRegular
        };
    }

    async submit(): Promise<void> {
        await this.setState({ isDownloading: true });
        try {
            let municipalities = this.state.values.isAllMunicipalities === true ? [] : this.state.values.municipalities;
            let municipalityCodes: number[] = municipalities.map((item: any) => item.code);
            await this.props.onDownload(municipalityCodes);
            this.setState({
                isDownloading: false,
                alert: {
                    alertMessage: `Rapporten er downloadet`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            Log.error(`Failed downloading content %o`, e);
            this.setState({
                isDownloading: false,
                alert: {
                    alertMessage: `Der skete en fejl under download af rapporten`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
        }
    }

    render(): any {
        return (
            <Card>
                <CardHeader
                    subheader="Her kan du udtrække information om, hvilke kommuner der mangler at udfylde information brugt i chatbotten."
                    title={<span>Selvbetjening.nu tag-udtræk <InfoSvgButton title={"Selvbetjening.nu tag-udtræk"} content={
                            <Typography>I Selvbetjening.nu tag-udtræksfunktionen, vil det være muligt at lave udtræk over hvilke kommuner,
                                som mangler at udfylde information i de Selvbetjning.nu guides som er benyttet i chatbotten.
                                Udtrækket kan enten laves for en specifik kommune eller for alle kommuner.
                                Udtrækket vil blive genereret ud i en CSV-fil, som viser: Kommunekode, Tag-ID og Guide navn - for de valgte kommuner.
                            </Typography>}/></span>}
                />
                <Divider />
                <CardContent>
                        <PerfectScrollbar>
                            <div className={this.props.classes.inner}>
                                <FormControlLabel name="isAllMunicipalities" label="Alle kommuner" 
                                        control={<Checkbox
                                        checked={this.state.values.isAllMunicipalities}
                                        onChange={this.handleChangeCheck.bind(this)}
                                        color="primary"
                                    />} />
                                {
                                    !this.state.values.isAllMunicipalities && 
                                    <FormControl className={this.props.classes.formControl}>
                                        <InputLabel id="select-municipality-label">Vælg kommuner</InputLabel>
                                        <Select
                                            name="municipalities"
                                            labelId="select-municipality-label"
                                            id="select-mutiple-municipality-chip"
                                            multiple
                                            value={this.state.values.municipalities}
                                            onChange={this.handleChangeMunicipality.bind(this)}
                                            input={<Input id="select-multiple-chip" />}
                                            renderValue={(selected) => (
                                                <div className={this.props.classes.chips}>
                                                    {(selected as string[]).map((value: any) => (
                                                        <Chip key={value.code} label={value.name} className={this.props.classes.chip} />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps} >
                                            {this.props.municipalities.map((municipality) => (
                                                <MenuItem key={municipality} value={municipality} style={this.getStyles(municipality, this.state.values.municipalities, theme)}>
                                                    {municipality.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            </div>
                        </PerfectScrollbar>
                </CardContent>
                <Divider></Divider>
                <CardActions className={this.props.classes.actions}>
                    {this.getActions()}
                </CardActions>
                <Snackbar open={this.state.alert.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={() => this.setState({ alert: { ...this.state.alert, isAlertOpen: false }})}>
                    <Alert severity={this.state.alert.alertType}>
                        {this.state.alert.alertMessage}
                    </Alert>
                </Snackbar>
            </Card>
        );
    }

}

export default withStyles(styles as any)(DownloadMissingContent);

