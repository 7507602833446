import { BaseAPIService } from "./BaseAPIService";
import { StatisticsResponseObject, TimeResolution } from "../common/statistics";
import { AxiosRequestConfig } from "axios";
import { IWatsonDiscoveryStats } from "./interfaces/IWatsonDiscoveryStats";

export class StatisticsAPIService extends BaseAPIService {
  private static statsUrl: string = "/api/stat";

  public async getDialogStats(
    municipalityCode: number,
    periodStart: Date,
    periodEnd: Date,
    timeResolution: TimeResolution,
    subject?: string
  ): Promise<StatisticsResponseObject> {
    const startDate = new Date(periodStart.getTime());
    if (
      (timeResolution === "month" || timeResolution === "week") &&
      startDate.getHours() === 0
    ) {
      startDate.setHours(startDate.getHours() + 1);
    }

    const options: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      url: StatisticsAPIService.statsUrl + "/dialogs",
      method: "GET",
      params: {
        municipalityCode: municipalityCode,
        periodStart: startDate,
        periodEnd: periodEnd,
        timeResolution: timeResolution,
        subject,
      },
    };

    return await this.request(options);
  }

  public async getUsersByDeviceStats(
    municipalityCode: number,
    periodStart: Date,
    periodEnd: Date,
    subject?: string
  ) {
    const options: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      url: StatisticsAPIService.statsUrl + `/users-by-device`,
      method: "GET",
      params: {
        municipalityCode: municipalityCode,
        periodStart: periodStart,
        periodEnd: periodEnd,
        subject,
      },
    };

    return await this.request(options);
  }

  public async getCategoriesHitStats(
    municipalityCode: number,
    begin?: Date,
    end?: Date
  ) {
    const options: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      url: StatisticsAPIService.statsUrl + `/categories-hit`,
      method: "GET",
      params: {
        municipalityCode: municipalityCode,
      },
    };

    if (begin && end) {
      options.params.begin = begin;
      options.params.end = end;
    }

    return await this.request(options);
  }

  public async getDialogsByTime(
    municipalityCode: number,
    periodStart: Date,
    periodEnd: Date,
    subject?: string
  ) {
    const options: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      url: StatisticsAPIService.statsUrl + `/dialogs-by-time`,
      method: "GET",
      params: {
        municipalityCode: municipalityCode,
        begin: periodStart,
        end: periodEnd,
        subject,
      },
    };

    return await this.request(options);
  }

  public async getCount(
    urlSuffix: string,
    municipalityCode: number,
    begin?: Date,
    end?: Date,
    subject?: string
  ) {
    const options: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      url: StatisticsAPIService.statsUrl + "/" + urlSuffix,
      method: "GET",
      params: {
        municipalityCode: municipalityCode,
        subject,
      },
    };

    if (begin && end) {
      options.params.begin = begin;
      options.params.end = end;
    }

    return await this.request(options);
  }

  /**
   * Get all main subjects.
   */
  public async getSubjects(
    municipalityId: any,
    begin: Date,
    end: Date
  ): Promise<any> {
    const options: AxiosRequestConfig = {
      url: "/api/stat/subject",
      method: "GET",
      params: {
        municipalityId: municipalityId,
        begin: begin,
        end: end,
      },
    };

    return await this.request(options);
  }

  /**
   * Get all sub subject.
   */
  public async getSubjectItems(
    municipalityId: any,
    subject: string,
    begin: Date,
    end: Date
  ): Promise<any> {
    const options: AxiosRequestConfig = {
      url: "/api/stat/subject/item",
      method: "GET",
      params: {
        subject: subject,
        municipalityId: municipalityId,
        begin: begin,
        end: end,
      },
    };

    return await this.request(options);
  }

  public async getWDStats(params: {
    municipalityCode: number;
    subject?: string;
    periodStart: Date;
    periodEnd: Date;
  }): Promise<IWatsonDiscoveryStats> {
    const options: AxiosRequestConfig = {
      url: "/api/watson-discovery/stat",
      method: "GET",
      params: {
        ...params,
      },
    };
    return this.request(options);
  }

  public async exportToXLSX(params: {
    municipalityCode: number;
    subject?: string;
    periodStart: Date;
    periodEnd: Date;
  }): Promise<any> {
    const options: AxiosRequestConfig = {
      url: "/api/stat/xlsx",
      method: "GET",
      responseType: "blob",
      params: {
        ...params,
      },
    };

    return await this.request(options);
  }
}
