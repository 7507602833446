import React, {Component} from 'react';
import {
    Checkbox,
    Dialog,
    DialogContentText,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    Theme
} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = ((theme: Theme) => ({
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    input: {
        display: 'none',
    }
}));

export type RequestTagModalProps = {
    isOpen: boolean,
    toggleModal: any,
    classes:any,
    submit: any
    saveFileInMemory: any,
    tags: any,
    numberOfFiles: number
};

export type RequestTagModalState = {
    selectedTags: any,
    text: string
};

class RequestTagModal extends Component<RequestTagModalProps, RequestTagModalState> {

    constructor(props: RequestTagModalProps) {
        super(props);
        this.state = {
            selectedTags: {},
            text: ""
        }
    }

    componentDidMount() {
        this.setState({ selectedTags: {} });
    }

    onTextChange(e: any): void {
        this.setState({ text: e.target.value });
    }

    onGuideIdChange = (e:any) => {
        if (!e.target.name) {
            return;
        }

        const targetKey = e.target.name.split(',');

        if (targetKey[1] !== "guide")
            return;

        const tagId = targetKey[0];
        this.setState({ selectedTags: {
                ...this.state.selectedTags,
                [tagId]: {...this.state.selectedTags[tagId], value: e.target.value, changed: true }
            }
        });
    }

    onCheckboxChange = (e:any) => {
        if (!e.target.name) {
            return;
        }

        const targetKey = e.target.name.split(',');

        if(targetKey[1] !== "tagId")
            return;

        const tagId = targetKey[0];

        if (this.state.selectedTags.hasOwnProperty(tagId)) {
            this.setState({ selectedTags: {
                    ...this.state.selectedTags,
                    [tagId]: {...this.state.selectedTags[tagId], checked: !this.state.selectedTags[tagId].checked }
                }
            });
        }
        else {
            this.setState({ selectedTags: {
                    ...this.state.selectedTags,
                    [tagId]: {...this.state.selectedTags[tagId], checked: true }
                }
            });
        }
    }

    isCheckBoxChecked(tagId: string): boolean {
        return this.state.selectedTags && this.state.selectedTags.hasOwnProperty(tagId) ? this.state.selectedTags[tagId].checked : false;
    }

    isValidState(): boolean {

        // Check one or more boxes is checked
        let isValid = false;
        let counter = 0;
        for (let key in this.state.selectedTags) {
            if (this.state.selectedTags[key].checked && ( this.state.selectedTags[key].value === "" || !this.state.selectedTags[key].value )){
                isValid = false;
                break;
            }
            else if (this.state.selectedTags[key].checked && this.state.selectedTags[key].value && this.state.selectedTags[key].value !== "") {
                counter += 1;
                isValid = true;
            }
        }

        return isValid && counter > 0 && this.state.text.length > 0;
    }

    renderRadioButtons() {

        if (!this.props.tags)
            return;

        return (
            <List>
                {
                    this.props.tags.map((item: any) => {
                        if(item.isActive === false && item.isRequested  === false) {
                            return (
                                <ListItem key={item.tagId} role={undefined}>
                                    <ListItemIcon>
                                        <FormControlLabel
                                            name={`${item.tagId},tagId`}
                                            checked={this.isCheckBoxChecked(item.tagId)}
                                            control={<Checkbox/>}
                                            label={`${item.tagId}`}
                                            onClick={this.onCheckboxChange}/>
                                    </ListItemIcon>
                                    <ListItemSecondaryAction>
                                        <TextField
                                            name={`${item.tagId},guide`}
                                            label={`Indtast guide id for ${item.tagId}`}
                                            variant="outlined"
                                            className={this.isCheckBoxChecked(item.tagId) ? "" : this.props.classes.input}
                                            onChange={this.onGuideIdChange}
                                            error={this.state.selectedTags && this.state.selectedTags[item.tagId] && this.state.selectedTags[item.tagId].changed && this.state.selectedTags[item.tagId].guideId === ""}
                                            disabled={!this.isCheckBoxChecked(item.tagId)}/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        }

                        return "";
                    })
                }
            </List>
        );
    }

    submitRequest() {

        let tags = [];
        for (let key in this.state.selectedTags) {
            if (this.state.selectedTags[key].checked && this.state.selectedTags[key].value && this.state.selectedTags[key].value !== "") {
                tags.push({ tagId: key, guideId: this.state.selectedTags[key].value });
            }
        }

        this.props.submit(this.state.text, tags);
        this.setState({ selectedTags: {} });
    }

    async resetDialog() {
        await this.setState({ selectedTags: {} });
        this.props.toggleModal();
    }

    render() {
        return (
            <Dialog open={this.props.isOpen}
                    onClose={this.resetDialog.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Anmod Selvbetjening.nu om tags</DialogTitle>
                <FormControl style={{ width: "100%" }}>
                    <form onSubmit={this.props.submit} encType="multipart/form-data">
                        <DialogContent>
                            <DialogContentText>
                                Udfyld denne formular for at anmode Selvbetjening.nu om at oprette de valgte tags. Når formularen indsendes vil du modtage en mail hvori du er cc.
                            </DialogContentText>
                            <TextField required fullWidth
                                id="msg"
                                label="Skriv besked til selvbetjening.nu"
                                variant="outlined"
                                multiline
                                rows={6}
                                className={this.props.classes.textField}
                                onChange={this.onTextChange.bind(this)}
                            />
                        </DialogContent>
                        <DialogContent>
                            <input id="contained-button-file"
                                   onChange={this.props.saveFileInMemory}
                                   className={this.props.classes.input}
                                   multiple
                                   type="file"
                            />
                            <label htmlFor="contained-button-file" className={this.props.classes.textField}>
                                <Button variant="contained" color="primary" component="span">Vedhæft filer</Button>
                                <DialogContentText>
                                    {this.props.numberOfFiles} {this.props.numberOfFiles === 1 ? " fil" : " filer"}
                                </DialogContentText>
                            </label>
                            <DialogContentText>
                                Vælg de tags du ønsker anmodning om fra Selvbetjening.nu
                            </DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            {this.renderRadioButtons()}
                        </DialogContent>
                    </form>
                </FormControl>
                <DialogActions>
                    <Button onClick={this.resetDialog.bind(this)} color="secondary" autoFocus>Annuller</Button>
                    <Button
                        disabled={!this.isValidState()}
                        onClick={this.submitRequest.bind(this)}
                        color="primary">
                        Gem
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(RequestTagModal)