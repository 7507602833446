import React, {Component} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Tooltip
} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import InfoSvgButton from "../../../../../../components/InfoButton";
import RefreshIcon from "@material-ui/icons/RefreshRounded";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/da";
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {User} from "../../../../../../helpers/profile";
import StartJobModal from "../StartJobModal";
import {Log} from "../../../../../../common/log";
import {StatusBullet} from "../../../../../../components";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    actionCol: {
        width: "5%"
    },
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
      marginLeft: "4px"
    },
    status: {
        marginRight: theme.spacing(1)
    }
}));

export type ExecutionsProps = {
    isLoading: boolean,
    classes: any,
    executions: any[],
    jobStatus?: Date,
    onRefresh: () => void,
    onStartExecution: () => void
};

export type ExecutionsState = {
    isJobSubmitted: boolean,
    rowsPerPage: number,
    page: number,
    values: any,
    isJobStartModalOpen: boolean,
    isSnackbarOpen: boolean,
    alert: {
        isAlertOpen: boolean,
        alertMessage: string,
        alertType: "success" | "error" | "info" | "warning",
    }
};

class Executions extends Component<ExecutionsProps, ExecutionsState> {

    constructor(props: ExecutionsProps) {
        super(props);
        this.state = {
            isJobSubmitted: false,
            rowsPerPage: 5,
            page: 0,
            values: {},
            isJobStartModalOpen: false,
            isSnackbarOpen: false,
            alert: {
                isAlertOpen: false,
                alertMessage: "",
                alertType: "success"
            },
        };
    }

    changePage(page: number, rowsPerPage: number) {
        this.setState({ rowsPerPage: rowsPerPage, page: page });
    }

    handlePageChange(event: any, page: any) {
        this.setState({ page: page });
    }

    handleRowsPerPageChange(event: any) {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    }

    getActions(): any {
        if(User.isGlobalAdmin()){
            return (
                <div style={{ display: "inline"}}>
                    {
                        !this.props.isLoading &&
                        <div style={{ display: "inline"}}>
                            {
                                (this.state.isJobSubmitted || this.props.jobStatus) &&
                                <Typography variant="body2" style={{ display:  "inline" }}>
                                    {"Synkronisering påbegyndt " + moment(this.props.jobStatus || new Date()).format('dddd DD. MMMM yyyy') + " " + moment(this.props.jobStatus || new Date()).format('HH:mm:ss')}
                                </Typography>
                            }
                            <Button variant="outlined" color="primary"
                                    disabled={this.state.isJobSubmitted || this.props.jobStatus !== undefined}
                                    onClick={this.toggleConfirmModal.bind(this)}
                                    className={this.props.classes.button}>Start job</Button>
                        </div>
                    }
                    <Tooltip title="Genindlæs">
                        <IconButton
                            aria-label="refresh"
                            color="primary"
                            onClick={this.handleRefreshClick.bind(this)} >
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>
                </div>

            );
        }
    }

    handleChange(event: any): void {
        this.setState({
            values: {...this.state.values, [event.target.id]: event.target.value }
        });
    }

    toggleConfirmModal(): void {
        this.setState({...this.state, isJobStartModalOpen: !this.state.isJobStartModalOpen });
    }

    async submit(): Promise<void> {
        await this.setState({ isJobStartModalOpen: false });
        try {
            await this.props.onStartExecution();
            this.setState({
                isJobSubmitted: true,
                alert: {
                    alertMessage: `Synkroniseringen er started`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e) {
            Log.error(`Failed submitted job execution %o`, e);
            this.setState({
                isJobSubmitted: false,
                alert: {
                    alertMessage: `Der skete en fejl under start af synkroniseringen`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
        }
    }

    handleRefreshClick() {
        this.props.onRefresh();
    }

    render(): any {
        return (
            <Card>
                <CardHeader
                    subheader="Her kan du få et overblik over tidligere synkroniseringer til Selvbetjening.nu's database eller manuelt starte en ny synkronisering."
                    title={<span>Selvbetjening.nu synkronisering <InfoSvgButton title={"Selvbetjening.nu synkronisering"} content={
                            <Typography>I Selvbetjening.nu synkroniseringsfunktionen, vil det være muligt at foretage en manuel synkronisering til Selvbetjening.nu's database,
                                dette kunne være i tilfælde af at en tidligere synkronisering mislykkes eller ved akut brug for nyoprettet tags.
                                Synkroniseringen til Selvbetjening.nu's database, sker automatisk én gang i døgnet, så denne funktion gør det muligt manuelt at synkronisere uden for dette tidspunkt, hvis nødvendigt.
                            </Typography>}/></span>}
                    action={this.getActions()}
                />
                <Divider />
                <CardContent>
                        <PerfectScrollbar>
                            <div className={this.props.classes.inner}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Job nummer</TableCell>
                                            <TableCell>Tidspunkt</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            !this.props.isLoading ? this.props.executions.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(item => (
                                                <TableRow
                                                    className={this.props.classes.tableRow}
                                                    hover
                                                    key={item.tagId}>
                                                    <TableCell>#{item.id}</TableCell>
                                                    <TableCell>{item.request_at ? moment(item.request_at).format('dddd DD. MMMM yyyy') + " " + moment(item.request_at).format('HH:mm:ss') : "-"}</TableCell>
                                                    <TableCell>
                                                        {
                                                            item.response === "Passed" ?
                                                            <span><StatusBullet className={this.props.classes.status} color="success" size="sm"/> Gennemført</span> :
                                                            <span><StatusBullet className={this.props.classes.status} color="danger" size="sm"/> Fejlet</span>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )) : <TableRow><TableCell colSpan={12} align={"center"}><CircularProgress/></TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                </CardContent>
                <CardActions className={this.props.classes.actions}>
                    <TablePagination
                        labelRowsPerPage="Rækker pr. side"
                        labelDisplayedRows={({from, to, count}) => `${from > count ? count : from}-${to} af ${count}`}
                        component="div"
                        count={this.props.executions.length}
                        onChangePage={this.handlePageChange.bind(this)}
                        onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                    />
                </CardActions>
                <Snackbar open={this.state.alert.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={() => this.setState({ alert: { ...this.state.alert, isAlertOpen: false }})}>
                    <Alert severity={this.state.alert.alertType}>
                        {this.state.alert.alertMessage}
                    </Alert>
                </Snackbar>
                <StartJobModal
                    submit={this.submit.bind(this)}
                    isOpen={this.state.isJobStartModalOpen}
                    toggleModal={this.toggleConfirmModal.bind(this)}
                />
            </Card>
        );
    }

}

export default withStyles(styles)(Executions);

