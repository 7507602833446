import React, {Component} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme
} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import InfoSvgButton from "../../../../../../components/InfoButton";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardRounded";
import moment from "moment";
import "moment/locale/da";
import CreateTagModal from "../CreateTagModal";
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import RequestTagModal from "../RequestTagModal";
import {APIService} from "../../../../../../service/APIService";
import CircularProgress from "@material-ui/core/CircularProgress";
import {User} from "../../../../../../helpers/profile";
import {Log} from "../../../../../../common/log";

moment.locale('da'); // it is required to select default locale manually

const styles = ((theme: Theme) => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    actionCol: {
        width: "5%"
    },
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    button: {
      marginLeft: "4px"
    },

}));

export type TagsProps = {
    onRefresh: () => any,
    isLoading: boolean,
    onClick: (tag: any) => void,
    classes: any,
    tags: any[]
};

export type TagsState = {
    rowsPerPage: number,
    page: number,
    values: any,
    isRequestModalOpen: boolean,
    isCreateModalOpen: boolean,
    isSnackbarOpen: boolean,
    alert: {
        isAlertOpen: boolean,
        alertMessage: string,
        alertType: "success" | "error" | "info" | "warning",
    },
    numberOfFiles: number,
};

class Tags extends Component<TagsProps, TagsState> {

    private readonly api: APIService;

    constructor(props: TagsProps) {
        super(props);
        this.api = new APIService();
        this.state = {
            rowsPerPage: 5,
            page: 0,
            values: {},
            isRequestModalOpen: false,
            isCreateModalOpen: false,
            isSnackbarOpen: false,
            alert: {
                isAlertOpen: false,
                alertMessage: "",
                alertType: "success"
            },
            numberOfFiles: 0
        };
    }

    changePage(page: number, rowsPerPage: number) {
        this.setState({ rowsPerPage: rowsPerPage, page: page });
    }

    handlePageChange(event: any, page: any) {
        this.setState({ page: page });
    }

    handleRowsPerPageChange(event: any) {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    }

    getActions(): any {
        if(User.isGlobalAdmin()){
            return (
                <div style={{float: "right"}}>
                    <Button variant="outlined" color="primary" onClick={this.changeCreateModalState.bind(this)}>Opret tag</Button>
                    <Button variant="outlined" color="primary"
                            disabled={!this.isSbRequestPossible()}
                            onClick={this.changeRequestModalState.bind(this)}
                            className={this.props.classes.button}>Anmod selvbetjening.nu om tags</Button>
                </div>
            );
        }
    }

    changeCreateModalState(): void {
        this.setState({
            isCreateModalOpen: !this.state.isCreateModalOpen
        })
    }

    changeRequestModalState(): void {

        if (this.state.isRequestModalOpen)
            this.setState({ isRequestModalOpen: false, numberOfFiles: 0, values: {...this.state.values, files: [] } })
        else
            this.setState({ isRequestModalOpen: true })
    }

    async handleTagCreation (tagId: string, tagName: string, guideName: string, sampleQuestion: string): Promise<void> {
        this.setState({ isCreateModalOpen: false });

        try{
            await this.api.createTag(tagId, tagName, guideName, sampleQuestion);
            this.setState({
                alert: {
                    alertMessage: `Tag med id '${tagId}' er oprettet`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch (e){
            Log.error("Failed creating tag ", e);
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under oprettelse af tag id '${tagId}'`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
        }

        this.props.onRefresh();
    }

    async saveFileInMemory(e: any) {
        if(!e.target.files)
            return;

        await this.setState({
            numberOfFiles: e.target.files.length,
            values: {...this.state.values, files: e.target.files }
        });
    }

    async submitRequest(text: string, tags: any[]): Promise<void> {
        this.setState({ isRequestModalOpen: false });

        const filesToSend: any = this.state.values.files;

        const content = {
            msg: text,
            items: tags
        }

        const formData = new FormData();
        formData.set("content", JSON.stringify(content));

        if (filesToSend) {
            for(let file of filesToSend) {
                formData.append("files", file);
            }
        }

        try {
            await this.api.requestTagAnnotation(formData);
            this.setState({
                alert: {
                    alertMessage: `Anmodningen er afsendt og du vil snarest modtage en mail hvor du er cc'et`,
                    isAlertOpen: true,
                    alertType: "success"
                }
            });
        }
        catch(e){
            Log.error("Failed sending annotation request to SB " + e);
            this.setState({
                alert: {
                    alertMessage: `Der skete en fejl under din anmodning og den blev ikke gennemført`,
                    isAlertOpen: true,
                    alertType: "error"
                }
            });
        }

        this.props.onRefresh();
    }

    isSbRequestPossible() {
        if (!this.props.tags)
            return false;

        const candidates = this.props.tags.filter( (item) => {
            if (item.isActive === false && !item.isRequested)
                return item;
            return undefined;
        });

        return candidates.length > 0;
    }

    handleChange(event: any): void {
        this.setState({
            values: {...this.state.values, [event.target.id]: event.target.value }
        });
    }

    render(): any {
        return (
            <Card>
                <CardHeader
                    subheader="Understøttet tags fra Selvbetjening.nu"
                    title={<span>Selvbetjening tags <InfoSvgButton title={"Selvbetjening Tags"} content={
                            <Typography>Selvbetjening tags anvendes til at hente guides hver nat fra Selvbetjening.nu systemet. Disse guides kan herefter anvendes i Watson Assistant. Alle aktive tags bliver synkroniseret en gang i døgnet. <br/><br/>
                            Vær opmærksom på at et oprettet tag skal anmodes om at blive annoteret i guides til Selvbetjening.nu.
                                Anmodningen skal sendes via knappen "Anmod selvbetjening.nu om tags" og bekræftelsen skal modtages via mail før et tag bør aktiveres. Et tag der ikke er anmodet via Selvbetjening.nu kan ikke aktiveres.</Typography>}/></span>}
                    action={this.getActions()}
                />
                <Divider/>
                <CardContent>
                        <PerfectScrollbar>
                            <div className={this.props.classes.inner}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tag id</TableCell>
                                            <TableCell>Tag navn</TableCell>
                                            <TableCell>Guide navn</TableCell>
                                            <TableCell>Oprettet tidspunkt</TableCell>
                                            <TableCell>Aktiv</TableCell>
                                            <TableCell>Anmodet</TableCell>
                                            <TableCell className={this.props.classes.actionCol}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            !this.props.isLoading ? this.props.tags.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(item => (
                                                <TableRow
                                                    className={this.props.classes.tableRow}
                                                    hover
                                                    key={item.tagId}>
                                                    <TableCell>{item.tagId}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.guideName}</TableCell>
                                                    <TableCell>{item.createdAt ? moment(item.createdAt).format('dddd DD. MMMM yyyy') + " " + moment(item.createdAt).format('HH:mm:ss') : "-"}</TableCell>
                                                    <TableCell>{item.isActive ? "Ja" : "Nej"}</TableCell>
                                                    <TableCell>{item.isRequested ? "Ja" : "Nej"}</TableCell>
                                                    <TableCell>
                                                        <IconButton aria-label="rediger"
                                                                    color="primary"
                                                                    onClick={() => this.props.onClick(item)}>
                                                            <ArrowForwardIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )) : <TableRow><TableCell colSpan={12} align={"center"}><CircularProgress/></TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                </CardContent>
                <CardActions className={this.props.classes.actions}>
                    <TablePagination
                        labelRowsPerPage="Rækker pr. side"
                        labelDisplayedRows={({from, to, count}) => `${from > count ? count : from}-${to} af ${count}`}
                        component="div"
                        count={this.props.tags.length}
                        onChangePage={this.handlePageChange.bind(this)}
                        onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                    />
                </CardActions>
                <CreateTagModal isOpen={this.state.isCreateModalOpen}
                                submit={this.handleTagCreation.bind(this)}
                                toggleModal={() => this.setState({ isCreateModalOpen: !this.state.isCreateModalOpen })} />
                <RequestTagModal
                    numberOfFiles={this.state.numberOfFiles}
                    tags={this.props.tags}
                    saveFileInMemory={this.saveFileInMemory.bind(this)}
                    isOpen={this.state.isRequestModalOpen}
                    submit={this.submitRequest.bind(this)}
                    toggleModal={this.changeRequestModalState.bind(this)} />
                <Snackbar open={this.state.alert.isAlertOpen} autoHideDuration={10000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          onClose={() => this.setState({ alert: { ...this.state.alert, isAlertOpen: false }})}>
                    <Alert severity={this.state.alert.alertType}>
                        {this.state.alert.alertMessage}
                    </Alert>
                </Snackbar>
            </Card>
        );
    }

}

export default withStyles(styles)(Tags);

