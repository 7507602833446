import React, {Component} from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    FormControlLabel,
    MenuItem,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Theme
} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";
import Typography from "@material-ui/core/Typography";
import InfoSvgButton from "../../../../../../components/InfoButton";
import {StatusBullet} from "../../../../../../components";
import {User} from "../../../../../../helpers/profile";
import {PresentationConfig} from "../../../../../../service/APIService";

const styles = ((theme: Theme) => ({
    root: {},
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    deleteCol: {
        width: "5%"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    deleteConfirmBtn: {
        color: theme.palette.error.main
    },
    status: {
        marginRight: theme.spacing(1)
    },
}));

export type PresentationSettingsProps = {
    isLoading: boolean,
    onSave: (config: PresentationConfig) => Promise<any>,
    classes: any,
    config: any
};

export type PresentationSettingsState = {
    settings: any[],
    isEdit: boolean,
    isConfirmModalOpen: boolean,
    isAvatarModeModalOpen: boolean,
    isFeedbackDelayModalOpen: boolean,
    isFeedbackModeModalOpen: boolean,
    isTextBubblesModalOpen: boolean,
    values: any,
    infoModalOpen: boolean
};

class PresentationSettings extends Component<PresentationSettingsProps, PresentationSettingsState> {

    constructor(props: PresentationSettingsProps) {
        super(props);

        this.state = {
            isEdit: false,
            isConfirmModalOpen: false,
            isAvatarModeModalOpen: false,
            isFeedbackDelayModalOpen: false,
            isFeedbackModeModalOpen: false,
            isTextBubblesModalOpen: false,
            values: {
                isFeedbackEnabled: true,
                feedbackDelay: 25,
                textBubbles: ["", "", "", "", ""],
                avatarMode: 1
            },
            infoModalOpen: false,
            settings: [
                { id: 0, name: "Vurdering af samtale", description: "Her kan borgervurdering af chatbotten slås til eller fra." },
                { id: 1, name: "Tidsinterval for vurdering af samtale", description: "Her kan tidsintervallet ændres ift. hvor hurtigt vurderingsforespørgslen afsendes." },
                { id: 2, name: "Chatbot mode", description: "Her kan der ændres på parametre ift. hvorledes chatbotten opfører sig på hjemmesiden." },
                { id: 3, name: "Tekstindhold i talebobler", description: "Her kan indholdet i taleboblerne tilpasses." },
            ]
        };
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.config !== this.props.config) {
            this.setState({
                values: {
                    ...this.state.values,
                    isFeedbackEnabled: this.props.config.isFeedbackEnabled,
                    feedbackDelay: this.props.config.feedbackDelay,
                    textBubbles: this.props.config.presentationMessages,
                    avatarMode: this.props.config.presentationMode
                }
            });
        }
    }

    handleChange(event: any): void {
        this.setEditing();
        this.setState({
            values: { ...this.state.values, [event.target.name]: event.target.value }
        });
    }

    setEditing(): void {
        if (!this.state.isEdit)
            this.setState({ isEdit: true });
    }

    handleTextBubbleChange(event: any): void {

        let currentBubbles = this.state.values.textBubbles;

        if (!currentBubbles)
            currentBubbles = [];

        while (currentBubbles.length < 5) {
            currentBubbles.push("");
        }

        const index = event.target.name.substr(event.target.name.length - 1, 1);
        currentBubbles[index] = event.target.value;

        this.setEditing();
        this.setState({
            values: { ...this.state.values, textBubbles: currentBubbles }
        });
    }

    handleFeedbackModeModalOpenClose(isOpen: boolean): void {
        this.setState({ isFeedbackModeModalOpen: isOpen });
    }

    handleConfirmModalOpenClose(isOpen: boolean): void {
        this.setState({ isConfirmModalOpen: isOpen });
    }

    async handleSave() {
        this.setState({ isEdit: false });
        this.handleConfirmModalOpenClose(false);
        await this.props.onSave({
            isFeedbackEnabled: this.state.values.isFeedbackEnabled,
            feedbackDelay: parseInt(this.state.values.feedbackDelay),
            presentationMode: parseInt(this.state.values.avatarMode),
            presentationMessages: this.state.values.textBubbles

        });
    }

    handleSettingEdit(index: number) {
        switch (index) {
            case 0:
                this.handleFeedbackModeModalOpenClose(true);
                break;
            case 1:
                this.handleFeedbackDelayModalOpenClose(true);
                break;
            case 2:
                this.handleAvatarModeModalOpenClose(true);
                break;
            case 3:
                this.handleTextBubbleModalOpenClose(true);
                break;
            default:
                break;
        }
    }

    toggleInfoModal = (): void => {
        this.setState({
            infoModalOpen: !this.state.infoModalOpen
        })
    }

    handleTextBubbleModalOpenClose(isOpen: boolean): void {
        this.setState({ isTextBubblesModalOpen: isOpen });
    }

    handleFeedbackDelayModalOpenClose(isOpen: boolean): void {
        this.setState({ isFeedbackDelayModalOpen: isOpen });
    }

    handleAvatarModeModalOpenClose(isOpen: boolean): void {
        this.setState({ isAvatarModeModalOpen: isOpen });
    }

    handleFeedbackMode(e: any) {
        this.setEditing();
        this.setState({ values: { ...this.state.values, isFeedbackEnabled: e.target.checked } })
    }

    renderFeedbackDelay() {
        if (!this.state.values.feedbackDelay)
            return "25 sekunder";

        return `${this.state.values.feedbackDelay} sekunder`;
    }

    renderMode() {
        if (this.state.values.avatarMode === undefined || this.state.values.avatarMode === 1)
            return "Hop-animation";
        else if (this.state.values.avatarMode === 0)
            return "Ingen hop-animation eller taleboble";
        else if (this.state.values.avatarMode === 2)
            return "Taleboble";
        else if (this.state.values.avatarMode === 3)
            return "Hop-animation og taleboble";
    }

    restoreTextBubbles() {
        this.setState({
            isEdit: true, values: {
                ...this.state.values, textBubbles: [
                    "Klik på mig, så kan jeg hjælpe dig",
                    "Du kan spørge mig. Jeg er til for at hjælpe!",
                    "Skal du have hjælp, så spørg mig!",
                    "Vil du have hjælp, så klik her",
                    "Har du spørgsmål, så prøv mig!"
                ]
            }
        })
    }

    renderFeedbackMode() {
        return (
            <div>
                <StatusBullet className={this.props.classes.status}
                    color={this.state.values.isFeedbackEnabled ? "success" : "danger"} size="sm" />
                {this.state.values.isFeedbackEnabled ? "Aktiv" : "Inaktiv"}
            </div>
        );
    }

    renderTextBubbles() {
        if (!this.state.values.textBubbles || this.state.values.textBubbles.length === 0)
            return;

        return (
            <ul> { this.state.values.textBubbles.map((item: string) => {
                if (item === "")
                    return "";

                return <li>{item}</li>
            })}
            </ul>
        );
    }

    render(): any {
        return (
            <Card>
                <CardHeader
                    subheader="Mulighed for tilpasning af hvordan chatbotten optræder og interagere på kommunens hjemmeside."
                    title={<span>Præsentationsindstillinger<InfoSvgButton title={"Om præsentationsindstillinger"} content={<Typography>Præsentationsindstillingerne giver dig mulighed for at tilpasse forskellige parametre i chatbotten. <br /><br />Gennem "Vurdering af samtale" kan du slå borgervurdering fra, hvis du <u>ikke</u> ønsker at chatbotten skal bede borgere vurdere samtaler.
                        Desuden kan du, under "Tidsinterval for vurdering af samtale", tilpasse tidsintervallet ift. hvor hurtigt chatbotten beder borgere om vurdering.
                        Under "Chatbot mode" kan du tilpasse hvordan chatbotten opfører sig på hjemmesiden, her kan du skrue på parametre såsom: hop, taleboble, en kombination eller ønsket om en chatbot der gør ingen af delene. Til sidst er det muligt, under "Tekstindhold i talebobler", at tilpasse teksten i taleboblerne.</Typography>} /></span>}
                />
                <Divider />
                <CardContent>
                    <LoadingIndicator isLoading={this.props.isLoading} />
                    {
                        !this.props.isLoading &&
                        <PerfectScrollbar>
                            <div className={this.props.classes.inner}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Navn</TableCell>
                                            <TableCell>Beskrivelse</TableCell>
                                            <TableCell>Værdi</TableCell>
                                            <TableCell className={this.props.classes.deleteCol}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.settings.slice(0, this.state.settings.length).map(setting => (
                                            <TableRow
                                                className={this.props.classes.tableRow}
                                                hover
                                                key={setting.id}>
                                                <TableCell><b>{setting.name}</b></TableCell>
                                                <TableCell>{setting.description}</TableCell>
                                                <TableCell>
                                                    {setting.id === 0 && this.renderFeedbackMode()}
                                                    {setting.id === 1 && this.renderFeedbackDelay()}
                                                    {setting.id === 2 && this.renderMode()}
                                                    {setting.id === 3 && this.renderTextBubbles()}
                                                </TableCell>
                                                <TableCell>
                                                    {setting.id !== 0 && User.isAdmin() &&
                                                        <IconButton aria-label="rediger"
                                                            onClick={() => this.handleSettingEdit(setting.id)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                    {setting.id === 0 && User.isGlobalAdmin() &&
                                                        <IconButton aria-label="rediger"
                                                            onClick={() => this.handleSettingEdit(setting.id)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    }
                </CardContent>
                <Divider />
                {User.isAdmin() &&
                    <CardActions style={{ float: "right" }}>
                        <Button color="primary" variant="outlined" onClick={() => this.handleConfirmModalOpenClose(true)}
                            disabled={!this.state.isEdit}>
                            Gem ændringer
                    </Button>
                    </CardActions>
                }
                <Dialog
                    open={this.state.isConfirmModalOpen}
                    onClose={() => this.handleFeedbackModeModalOpenClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Bekræft opdatering</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på du ønsker at opdatere præsentationsindstillingerne?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleConfirmModalOpenClose(false)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleSave.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            Gem
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isFeedbackModeModalOpen}
                    onClose={() => this.handleFeedbackModeModalOpenClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className={this.props.classes.alertHeader} id="alert-dialog-title">Slå borgervurdering til eller fra.</DialogTitle>
                    <DialogContent>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.values.isFeedbackEnabled}
                                    onChange={this.handleFeedbackMode.bind(this)}
                                    name="feedbackMode"
                                    color="primary"
                                />
                            }
                            label={"Slå borgervurdering " + (this.state.values.isFeedbackEnabled ? "fra" : "til")}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleFeedbackModeModalOpenClose(false)} color="secondary" autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isAvatarModeModalOpen}
                    onClose={() => this.handleAvatarModeModalOpenClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className={this.props.classes.alertHeader} id="alert-dialog-title">Ændre chatbot mode</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Vælg dén chatbot mode du ønsker, der skal anvendes på hjemmesiden.
                        </DialogContentText>
                    </DialogContent>
                    <DialogContent>
                        <TextField required fullWidth
                            name="avatarMode"
                            id="outlined-select-flexible"
                            select
                            value={this.state.values.avatarMode}
                            className={this.props.classes.textField}
                            label="Vælg chatbot mode"
                            onChange={this.handleChange.bind(this)}
                            variant="outlined">
                            <MenuItem key="0" value={0}>
                                Ingen hop-animation eller taleboble
                            </MenuItem>
                            <MenuItem key="1" value={1}>
                                Hop-animation
                            </MenuItem>
                            <MenuItem key="2" value={2}>
                                Taleboble
                            </MenuItem>
                            <MenuItem key="3" value={3}>
                                Hop-animation og taleboble
                            </MenuItem>
                        </TextField>
                        <DialogContentText>
                            <i>
                                {this.state.values.avatarMode === 0 && `Dette chatbot mode betyder, at chatbotten hverken vil have hop-animation eller taleboble synlig på hjemmesiden.`}
                                {this.state.values.avatarMode === 1 && `Dette chatbot mode betyder, at chatbotten udelukkende vil have hop-animation synlig på hjemmesiden.`}
                                {this.state.values.avatarMode === 2 && `Dette chatbot mode betyder, at chatbotten udelukkende vil have en taleboble synlig på hjemmesiden.`}
                                {this.state.values.avatarMode === 3 && `Dette chatbot mode betyder, at chatbotten vil have både hop-animation og taleboble synlig på hjemmesiden.`}
                            </i>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleAvatarModeModalOpenClose(false)} color="secondary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isFeedbackDelayModalOpen}
                    onClose={() => this.handleFeedbackDelayModalOpenClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className={this.props.classes.alertHeader} id="alert-dialog-title">Ændring af tidsinterval</DialogTitle>
                    <DialogContent>
                        <TextField required fullWidth
                            value={this.state.values.feedbackDelay}
                            name="feedbackDelay"
                            type="number"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Tidsinterval for vurdering"
                            helperText="Indtast et tal mellem 5 og 300 (i sekunder)"
                            onChange={this.handleChange.bind(this)}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleFeedbackDelayModalOpenClose(false)} color="secondary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isTextBubblesModalOpen}
                    onClose={() => this.handleTextBubbleModalOpenClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className={this.props.classes.alertHeader} id="alert-dialog-title">Rediger talebobler</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Nedenstående liste indeholder de beskeder der vil blive vist i en taleboble med tilfældig rækkefølge.
                        </DialogContentText>
                        <TextField required fullWidth
                            value={this.state.values.textBubbles && this.state.values.textBubbles.length > 0 ? this.state.values.textBubbles[0] : ""}
                            name="textBubble0"
                            type="text"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Taleboble #1"
                            onChange={this.handleTextBubbleChange.bind(this)}
                            variant="outlined"
                        />
                        <TextField required fullWidth
                            value={this.state.values.textBubbles && this.state.values.textBubbles.length > 1 ? this.state.values.textBubbles[1] : ""}
                            name="textBubble1"
                            type="text"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Taleboble #2"
                            onChange={this.handleTextBubbleChange.bind(this)}
                            variant="outlined"
                        />
                        <TextField required fullWidth
                            value={this.state.values.textBubbles && this.state.values.textBubbles.length > 2 ? this.state.values.textBubbles[2] : ""}
                            name="textBubble2"
                            type="text"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Taleboble #3"
                            onChange={this.handleTextBubbleChange.bind(this)}
                            variant="outlined"
                        />
                        <TextField required fullWidth
                            value={this.state.values.textBubbles && this.state.values.textBubbles.length > 3 ? this.state.values.textBubbles[3] : ""}
                            name="textBubble3"
                            type="text"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Taleboble #4"
                            onChange={this.handleTextBubbleChange.bind(this)}
                            variant="outlined"
                        />
                        <TextField required fullWidth
                            value={this.state.values.textBubbles && this.state.values.textBubbles.length > 4 ? this.state.values.textBubbles[4] : ""}
                            name="textBubble4"
                            type="text"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Taleboble #5"
                            onChange={this.handleTextBubbleChange.bind(this)}
                            variant="outlined"
                        />
                        <Button onClick={this.restoreTextBubbles.bind(this)}>
                            Nulstil til standard
                        </Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleTextBubbleModalOpenClose(false)} color="secondary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card >
        );
    }

}

export default withStyles(styles)(PresentationSettings)

