import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import Typography from "@material-ui/core/Typography";
import {AppBar, Dialog, Divider, IconButton, List, ListItem, Slide, Theme, Toolbar, Tooltip} from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import {ContentState, convertFromHTML, convertToRaw} from 'draft-js'
import './Editor.css';
import {stateToHTML} from 'draft-js-export-html'
import CloseIcon from "@material-ui/icons/CloseRounded";
import SaveIcon from '@material-ui/icons/SaveRounded';
import {TransitionProps} from "@material-ui/core/transitions";

const styles = ((theme: Theme) => ({
    grow: {
        flexGrow: 1
    },
    title: {
        padding: theme.spacing(2),
    }
}));

export type GuideEditorProps = {
    isEditing: boolean,
    onClose: () => any,
    onSave: (value: string) => any,
    tag: any,
    classes: any
};

export type GuideEditorState = {};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class GuideEditor extends Component<GuideEditorProps, GuideEditorState> {

    private editorState: any;

    constructor(props: GuideEditorProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount(): Promise<void> {

    }

    onChange(state: any) {
        this.editorState = state;
    }

    onSave(val: any) {

        const options = {
            inlineStyles: {
                // Override default elements (`strong`, `em`).
                BOLD: {element: 'b'},
                ITALIC: {element: 'i'},
            },
            entityStyleFn: (entity: any) => {
                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'link') {
                    const data = entity.getData();
                    return {
                        element: 'a',
                        attributes: {
                            href: data.url,
                            target: '_blank'
                        },
                    };
                }
            }
        };
        let html = stateToHTML(this.editorState.getCurrentContent(), options);

        // Remove all styling tags
        html = html.replace(/<pre>|<\/pre>|<code>|<\/code>|<p>|<\/p>/g, "");
        html = html.replace(/<br>/g, "");

        this.props.onSave(html);
    }

    getDefaultValue(): string {

        let tag = `<pre>${this.props.tag}</pre>`;

        const htmlContent = convertFromHTML(tag);
        const state = ContentState.createFromBlockArray(htmlContent.contentBlocks, htmlContent.entityMap);

        const raw = convertToRaw(state);
        const content = JSON.stringify(raw);

        return content;
    }

    render() {
        return (
            <Dialog fullScreen open={this.props.isEditing} onClose={this.props.onClose.bind(this)} TransitionComponent={Transition}>
                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <Tooltip title="Fortryd">
                            <IconButton edge="start" color="inherit" onClick={this.props.onClose.bind(this)} aria-label="luk" autoFocus>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <div className={this.props.classes.grow} />
                        <Tooltip title="Gem">
                            <IconButton color="inherit" onClick={this.onSave.bind(this)} aria-label="gem" style={{ float: "right"}}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Typography
                    className={this.props.classes.title}
                    gutterBottom
                    variant="h2">
                    Tilpas svaret fra Selvbetjening.nu
                </Typography>
                <Divider />
                <List style={{ overflow: "scroll", paddingBottom: "10px"}}>
                    <ListItem>
                        <MUIRichTextEditor
                            inlineToolbar={true}
                            defaultValue={this.getDefaultValue()}
                            controls={[
                                "bold", "italic", "underline", "link", "bulletList", "undo", "redo"
                            ]}
                            label="Indtast tilpasset tekst..."
                            maxLength={5000}
                            onChange={this.onChange.bind(this)}
                        />
                    </ListItem>
                </List>
            </Dialog>
        );
    }

}

export default withStyles(styles)(GuideEditor)
