import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Grid, Theme} from '@material-ui/core';
import {APIService} from "../../../../service/APIService";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import Executions from "./components/Executions";
import {User} from "../../../../helpers/profile";
import {Log} from "../../../../common/log";
import DownloadMissingContent from './components/DownloadMissingContent';

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    }
}));

export type SbIntegrationProps = { match: any, classes: any };

export type SbIntegrationState = {
    isLoading: boolean,
    executions: any[],
    municipalities: any[],
    jobStatus?: Date
};

class SbIntegration extends Component<SbIntegrationProps, SbIntegrationState> {

    private service: APIService = new APIService();

    constructor(props: SbIntegrationProps) {
        super(props);

        this.state = {
            isLoading: true,
            executions: [],
            municipalities: [],
            jobStatus: undefined
        };

    }

    async componentDidMount(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(): Promise<void> {

        if(!this.state.isLoading){
            this.setState({ isLoading: true });
        }

        await Promise.all([ this.fetchJobHistory(), this.fetchMunicipalities() ]);

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async fetchJobHistory(): Promise<void> {

        try {
            const res = await this.service.getSbJobHistory();

            if (res) {
                this.setState({ executions: res.executions, jobStatus: res.jobStatus ? res.jobStatus.startedAt : undefined });
            }
        } catch (e) {
            Log.error(`Failed loading sb job status ${e}`);
        }

    }

    async fetchMunicipalities(): Promise<void> {

        try {
            const res = await this.service.getMunicipalities(100, 0, true, false);

            if (res) {
                this.setState({ municipalities: res });
            }
        } catch (e) {
            Log.error(`Failed loading municipalities ${e}`);
        }

    }

    async handleStartExecution() {
        if(!this.state.isLoading){
            this.setState({ isLoading: true });
        }

        await this.service.startSbJob();

        setTimeout(()=> this.setState({ isLoading: false }), 500);
    }

    async handleRefresh() {
        await this.fetchData();
    }

    async handleDownloadMissingContent(municipalityCodes: number[]) {
        const res = await this.service.downloadMissingSBContent(municipalityCodes);
        const url = window.URL.createObjectURL(new Blob([ res ],{ type: "text/csv" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'guides_med_mangler.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.row}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <RouterLink color="inherit" to="/integration" >
                            <Typography color="textPrimary">Integrationer</Typography>
                        </RouterLink>
                        <Typography color="textPrimary">Selvbetjening.nu</Typography>
                    </Breadcrumbs>
                    <span className={this.props.classes.spacer}/>
                </div>
                <Grid container spacing={4}>
                    {
                        User.isGlobalAdmin() &&
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Executions
                                onRefresh={this.handleRefresh.bind(this)}
                                onStartExecution={this.handleStartExecution.bind(this)}
                                isLoading={this.state.isLoading}
                                jobStatus={this.state.jobStatus}
                                executions={this.state.executions} />
                        </Grid>
                    }
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                        <DownloadMissingContent
                            onDownload={this.handleDownloadMissingContent.bind(this)}
                            isLoading={this.state.isLoading}
                            municipalities={this.state.municipalities} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(SbIntegration)
