import React, {Component} from 'react';
import {Card, CardContent, CardHeader, Divider, Theme} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import {withStyles} from "@material-ui/styles";
import {User} from "../../../../helpers/profile";
import {TreeItem, TreeView} from "@material-ui/lab";

const styles = ((theme: Theme) => ({
    root: {
        margin: theme.spacing(2)
    },
    textfield: {
        display: 'block',
        width: '40ch',
    }
}));

export type RolesProps = { classes: any };

export type RolesState = { roles: any };

class Roles extends Component<RolesProps, RolesState> {

    constructor(props: RolesProps) {
        super(props);

        this.state = {
            roles: this.getRoles()
        }
    }

    getRoles(){
        const roles = this.mapMunicipality2Role();
        return (
            <TreeView
                className={this.props.classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />} >
                {
                    Object.keys(roles).map((role: any) => {
                        return (
                            <TreeItem nodeId={role} label={role}>
                                {
                                    roles[role].map( (municipality: any) => {
                                        return (
                                            <TreeItem id={municipality.id} nodeId={`${municipality.id}`} label={municipality.name}>
                                                <TreeItem id={municipality.id + "1"} nodeId={municipality.id + "1"} label={"Kommune - [" + this.getLevel(municipality.municipalityLevel) + "]" } />
                                                <TreeItem id={municipality.id + "2"} nodeId={municipality.id + "2"} label={"Samtaler - [" + this.getLevel(municipality.dialogLevel) + "]" }  />
                                                <TreeItem id={municipality.id + "3"} nodeId={municipality.id + "3"} label={"Statistik - [" + this.getLevel(municipality.statisticLevel) + "]"} />
                                            </TreeItem>)
                                    })
                                }
                            </TreeItem>);
                    })
                }
            </TreeView>
        );
    }

    getLevel(level: string) {
        if(level === "W")
            return "Læs + Skriv";

        if(level === "R")
            return "Læs";

        return "Ingen"
    }

    mapMunicipality2Role(){

        const roles = User.getRoles();

        const roleForMunicipality: any = {};
        for (let role of roles) {
            if (role && role.roleRights) {

                if(!roleForMunicipality.hasOwnProperty(role.roleRights.roleType))
                    roleForMunicipality[role.roleRights.roleType] = [];

                roleForMunicipality[role.roleRights.roleType].push({
                    id: role.id,
                    name: role.municipality.name,
                    code: role.municipality.code,
                    statisticLevel: role.roleRights.statisticLevel,
                    dialogLevel: role.roleRights.dialogLevel,
                    municipalityLevel: role.roleRights.municipalityLevel
                });
            }
        }

        return roleForMunicipality;
    }

    render() {
        return (
            <Card>
                <CardHeader  title="Adgang" subheader="Mine roller" />
                <Divider/>
                <CardContent>
                    {this.state.roles}
                </CardContent>
            </Card>
        );
    }
}


export default withStyles(styles)(Roles);
