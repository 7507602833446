import React from "react";

import "./HeadIcons.scss"

export interface HeadIconProps {
  mute?: boolean;
  active?: boolean;
}

export const SadHeadIcon: React.FC<HeadIconProps> = ({ mute, active }) => (
  <div
    className={`sad-head-icon ${!active && mute ? "mute " : ""} ${active ? "active" : ""}`}
  >
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" />
      <g transform="translate(-4.5 -4.5)">
        <path
          d="M19.5723 34.1055C21.3769 31.9141 24.084 30.625 26.9844 30.625C29.8203 30.625 32.5273 31.9141 34.332 34.1055C34.7187 34.5566 34.6543 35.2012 34.2031 35.5879C33.7519 35.9746 33.1074 35.8457 32.7851 35.459C31.3027 33.7188 29.1758 32.752 26.9199 32.752C24.7285 32.752 22.6016 33.7188 21.1191 35.459C20.7969 35.9102 20.1523 35.9102 19.7012 35.5879C19.25 35.2012 19.1855 34.5566 19.5723 34.1055Z"
          fill="#333333"
        />
        <path
          d="M23.8906 23.4063C23.8906 24.5664 22.9238 25.4688 21.8281 25.4688C20.668 25.4688 19.7656 24.5664 19.7656 23.4063C19.7656 22.3105 20.668 21.3438 21.8281 21.3438C22.9238 21.3438 23.8906 22.3105 23.8906 23.4063Z"
          fill="#333333"
        />
        <path
          d="M34.2031 23.4063C34.2031 24.5664 33.2363 25.4688 32.1406 25.4688C30.9805 25.4688 30.0781 24.5664 30.0781 23.4063C30.0781 22.3105 30.9805 21.3438 32.1406 21.3438C33.2363 21.3438 34.2031 22.3105 34.2031 23.4063Z"
          fill="#333333"
        />
      </g>
    </svg>
  </div>
);
