import * as Log from 'loglevel';
import {config} from "../config/Config";

if(config.isProduction){
    Log.setLevel(Log.levels.SILENT);
}
else{
    Log.setLevel(Log.levels.TRACE);
}

export { Log };