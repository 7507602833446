import { AxiosRequestConfig } from "axios";
import { BaseAPIService } from "./BaseAPIService";
import { PresentationModeEnum } from "../../../server/src/model/presentation/PresentationModeEnum";
import { IFrontDeskQueue } from "../views/MunicipalityDetails/components/FrontDesk/components/IFrontDeskQueue";
import {
  IWatsonDiscoverySearch,
  IWatsonDiscoverySearchStat,
  IWatsonDiscoveryStartUrl,
  IWatsonDiscoveryUrlStat,
} from "./interfaces/IWatsonDiscoveryStats";

export class APIService extends BaseAPIService {
  /**
   * Export all dialogs.
   */
  public async exportDialogs(query: GetAllDialogsQuery): Promise<any> {
    const options: AxiosRequestConfig = {
      url: "/api/dialog/xlsx",
      method: "GET",
      responseType: "blob",
      params: {
        limit: query.limit || 100,
        skip: query.skip || 0,
        isDetailed: query.isDetailed || false,
      },
    };

    if (query.searchText && query.searchText.length > 0 && query.searchText.trim() !== "")
      options.params.searchText = query.searchText;

    if (query.timeRangeEnd && query.timeRangeStart) {
      options.params.timeRangeEnd = query.timeRangeEnd;
      options.params.timeRangeStart = query.timeRangeStart;
    }

    if (query.municipalityCode && query.municipalityCode >= 0 && query.municipalityCode <= 999)
      options.params.municipalityCode = query.municipalityCode;

    if (query.sortBy && query.sortBy >= 0 && query.sortBy <= 5) options.params.sortBy = query.sortBy;

    if (query.subject) options.params.subject = query.subject;

    if (query.fromSender && query.fromSender >= 0 && query.fromSender <= 2)
      options.params.fromSender = query.fromSender;

    if (query.hasCallback && query.hasCallback >= 0 && query.hasCallback <= 2)
      options.params.hasCallback = query.hasCallback;

    return await this.request(options);
  }

  /**
   * Get all dialogs.
   */
  public async getDialogs(query: GetAllDialogsQuery): Promise<any> {
    const options: AxiosRequestConfig = {
      url: "/api/dialog",
      method: "GET",
      params: {
        limit: query.limit || 100,
        skip: query.skip || 0,
        isDetailed: query.isDetailed || false,
      },
    };

    if (query.searchText && query.searchText.length > 0 && query.searchText.trim() !== "")
      options.params.searchText = query.searchText;

    if (query.timeRangeEnd && query.timeRangeStart) {
      options.params.timeRangeEnd = query.timeRangeEnd;
      options.params.timeRangeStart = query.timeRangeStart;
    }

    if (query.municipalityCode && query.municipalityCode >= 0 && query.municipalityCode <= 999)
      options.params.municipalityCode = query.municipalityCode;

    if (query.sortBy && query.sortBy >= 0 && query.sortBy <= 5) options.params.sortBy = query.sortBy;

    if (query.subject) options.params.subject = query.subject;

    if (query.fromSender && query.fromSender >= 0 && query.fromSender <= 2)
      options.params.fromSender = query.fromSender;

    if (query.hasCallback && query.hasCallback >= 0 && query.hasCallback <= 2)
      options.params.hasCallback = query.hasCallback;

    return await this.request(options);
  }

  /**
   * Get all dialogs.
   */
  public async getDialog(sessionId: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/dialog/${sessionId}`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Get all dialogs.
   */
  public async getExternalDialog(sessionId: string, token: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/external/dialog/${sessionId}`,
      params: { token: token },
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Delete a dialog.
   */
  public async deleteDialog(municipalityCode: number, sessionId: string, customerId: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/dialog/${municipalityCode}/${sessionId}/${customerId}`,
      method: "DELETE",
    };
    return await this.request(options);
  }

  /**
   * Get all dialog ratings.
   */
  public async getDialogRatings(query: GetAllRatingsQuery): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/dialog/rating`,
      method: "GET",
      params: {
        limit: query.limit || 100,
        skip: query.skip || 0,
        isDetailed: query.isDetailed || false,
      },
    };

    if (query.searchText && query.searchText.length > 0 && query.searchText.trim() !== "")
      options.params.searchText = query.searchText;

    if (query.municipalityCode && query.municipalityCode >= 0 && query.municipalityCode <= 999)
      options.params.municipalityCode = query.municipalityCode;

    if (query.timeRangeStart && query.timeRangeEnd) {
      options.params.timeRangeStart = query.timeRangeStart;
      options.params.timeRangeEnd = query.timeRangeEnd;
    }

    if (query.sortBy && query.sortBy >= 0 && query.sortBy <= 3) options.params.sortBy = query.sortBy;

    if (query.scope) options.params.scope = query.scope;

    return await this.request(options);
  }

  /**
   * Get a single dialog rating.
   * @param sessionId
   */
  public async getDialogRating(sessionId: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/dialog/${sessionId}/rating`,
      method: "GET",
      data: {},
    };
    return await this.request(options);
  }

  public async patchDialogRating(sessionId: string, rating: { outOfScope: boolean }): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/dialog/${sessionId}/rating`,
      method: "PATCH",
      data: rating,
    };
    return await this.request(options);
  }

  public async patchDialogRatingComment(sessionId: string, rating: { comment: string }): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/dialog/${sessionId}/rating`,
      method: "PATCH",
      data: rating,
    };
    console.log(options);
    return await this.request(options);
  }

  /**
   * Get all municipalities.
   */
  public async getMunicipalities(
    limit?: number,
    skip?: number,
    sortAsc?: boolean,
    isDetailed?: boolean,
    searchName?: string
  ): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/municipality`,
      method: "GET",
      params: {
        limit: limit || 100,
        skip: skip || 0,
        sortAsc: sortAsc === undefined ? true : sortAsc,
        isDetailed: isDetailed || false,
      },
    };

    if (!isDetailed && searchName) {
      options.params.searchName = searchName;
    }

    return await this.request(options);
  }

  /**
   * Get a single municipality.
   * @param municipalityCode
   * @param withoutRelations (optional)
   */
  public async getMunicipality(municipalityCode: string, withoutRelations?: boolean): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/municipality/${municipalityCode}${withoutRelations ? "?withoutRelations=true" : ""}`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Get a single municipality.
   * @param municipalityId
   * @param category
   */
  public async createMunicipalityCategory(municipalityId: string, category: any): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/municipality/${municipalityId}/category`,
      method: "POST",
      data: {
        ...category,
      },
    };
    return await this.request(options);
  }

  /**
   * Deletes a category for a municipality
   * @param municipalityId
   * @param categoryId
   */
  public async deleteMunicipalityCategory(
    municipalityId: string,
    categoryId: string,
    isGlobal?: boolean
  ): Promise<any> {
    const params = isGlobal ? "?isGlobal=true" : "";
    const options: AxiosRequestConfig = {
      url: `/api/municipality/${municipalityId}/category/${categoryId}${params}`,
      method: "DELETE",
    };
    return await this.request(options);
  }

  /**
   * Create a single municipality context var.
   * @param municipalityId
   * @param context
   */
  public async createMunicipalityContext(municipalityId: string, context: any): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/municipality/${municipalityId}/context`,
      method: "POST",
      data: {
        ...context,
      },
    };
    return await this.request(options);
  }

  /**
   * Delete a single municipality context var.
   * @param municipalityId
   * @param contextId
   */
  public async deleteMunicipalityContext(municipalityId: string, contextId: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/municipality/${municipalityId}/context/${contextId}`,
      method: "DELETE",
    };
    return await this.request(options);
  }

  /**
   * Set maintenance mode for given set of municipalities and a duration time
   * @param municipalityCodes
   * @param duration
   */
  public async setMaintenanceMode(municipalityCodes: number[], duration: number): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/maintenance`,
      method: "PUT",
      data: {
        municipalityCodes: municipalityCodes || [],
        duration: duration || 0,
      },
    };
    return await this.request(options);
  }

  /**
   * Get maintenance mode for given set of municipalities
   * @param municipalityCodes
   */
  public async getMaintenanceMode(municipalityCodes: number[]): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/maintenance`,
      method: "POST",
      data: {
        municipalityCodes: municipalityCodes || [],
      },
    };
    return await this.request(options);
  }

  /**
   * Gets all guides for a given municipality
   * @param municipalityCode
   */
  public async getAllGuides(municipalityCode: number): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/guide/${municipalityCode}`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Get guide for a given municipality and tag id
   * @param tagId
   * @param municipalityCode
   */
  public async getGuide(tagId: string, municipalityCode: number): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/guide/${municipalityCode}/${tagId}`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Updates a guide for a given municipality
   * @param tagId
   * @param municipalityCode
   * @param value
   */
  public async updateCorrectedGuide(tagId: string, municipalityCode: number, value: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/guide/${municipalityCode}/${tagId}`,
      method: "PUT",
      data: {
        correctedValue: value,
      },
    };
    return await this.request(options);
  }

  /**
   * Deletes a corrected value of a guide for a given municipality.
   * @param tagId
   * @param municipalityCode
   */
  public async deleteCorrectedGuide(tagId: string, municipalityCode: number): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/guide/${municipalityCode}/${tagId}`,
      method: "DELETE",
    };
    return await this.request(options);
  }

  /**
   * Gets all tags.
   */
  public async getAllTags(): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/tag`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Get specific tag by its id.
   * @param tagId
   */
  public async getTag(tagId: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/tag/${tagId}`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Creates a new tag
   * @param tagId
   * @param name
   * @param guideName
   * @param sampleQuestion
   */
  public async createTag(tagId: string, name: string, guideName: string, sampleQuestion: string) {
    const options: AxiosRequestConfig = {
      url: `/api/tag`,
      method: "POST",
      data: {
        tagId: tagId,
        name: name,
        guideName: guideName,
        sampleQuestion: sampleQuestion,
      },
    };
    return await this.request(options);
  }

  /**
   * Updates a tag for isActive parameter
   * @param tagId
   * @param isActive
   */
  public async updateTagActive(tagId: string, isActive: boolean) {
    const options: AxiosRequestConfig = {
      url: `/api/tag`,
      method: "PUT",
      data: {
        tagId: tagId,
        isActive: isActive,
      },
    };
    return await this.request(options);
  }

  /**
   * Updates a tag for isRequested parameter
   * @param tagId
   * @param isRequested
   */
  public async updateTagRequested(tagId: string, isRequested: boolean) {
    const options: AxiosRequestConfig = {
      url: `/api/tag`,
      method: "PUT",
      data: {
        tagId: tagId,
        isRequested: isRequested,
      },
    };
    return await this.request(options);
  }

  /**
   * Deletes a specific tag by its id.
   * @param tagId
   */
  public async deleteTag(tagId: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/tag/${tagId}`,
      method: "DELETE",
    };
    return await this.request(options);
  }

  /**
   * Sends a annotation request to Selvbetjening.nu
   * @param formData
   */
  public async requestTagAnnotation(formData: any) {
    const options: AxiosRequestConfig = {
      url: `/api/selvbetjening/request`,
      method: "POST",
      data: formData,
    };
    return await this.request(options);
  }

  /**
   * Gets a municipality's presentation config.
   * @param municipalityCode
   */
  public async getMunicipalityPresentationConfig(municipalityCode: number) {
    const options: AxiosRequestConfig = {
      url: `/api/municipality/${municipalityCode}/conf`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Updates a municipality's presentation config.
   * @param municipalityCode
   * @param config
   */
  public async updateMunicipalityPresentationConfig(municipalityCode: number, config: PresentationConfig) {
    const options: AxiosRequestConfig = {
      url: `/api/municipality/${municipalityCode}/conf`,
      method: "POST",
      data: {
        ...config,
      },
    };
    return await this.request(options);
  }

  /**
   * Starts a selvbetjening sync job.
   */
  public async startSbJob() {
    const options: AxiosRequestConfig = {
      url: `/api/queue/sb/job`,
      method: "POST",
      data: {
        options: {
          priority: 1,
          attempts: 1,
        },
        data: {
          municipalityIds: [
            190, 210, 223, 240, 260, 265, 316, 615, 657, 661, 665, 671, 706, 707, 710, 727, 730, 740, 741, 746, 751,
            756, 760, 766, 773, 779, 787, 791, 810, 813, 820, 840, 846, 849, 851, 860,
          ],
        },
      },
    };
    return await this.request(options);
  }

  /**
   * Get history of past selvbetjening sync jobs.
   */
  public async getSbJobHistory() {
    const options: AxiosRequestConfig = {
      url: `/api/integration/sb/history`,
      method: "GET",
    };
    return await this.request(options);
  }

  /**
   * Get rapport of missing sb content for municipalities.
   * @param municipalityCodes The municipalities to look for. An empty array means all.
   */
  public async downloadMissingSBContent(municipalityCodes: number[]) {
    const options: AxiosRequestConfig = {
      url: `/api/integration/sb/csv`,
      method: "GET",
      params: {
        municipalities: municipalityCodes,
      },
    };
    return await this.request(options);
  }

  public async getFrontDeskMunicipalities(apiKey: string) {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/get-municipalities`,
      method: "POST",
      data: {
        apiKey,
      },
    };
    return this.request(options);
  }

  /**
   * Get all Front Desk integrations
   */
  public async getFrontDeskIntegrations() {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/`,
      method: "GET",
    };
    return this.request(options);
  }

  /**
   * Create a Front Desk integration
   */
  public async createFrontDeskIntegration(data: { apiKey: string; municipalityId: number; frontDeskId: number }) {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/`,
      method: "POST",
      data,
    };
    return this.request(options);
  }

  /**
   * Delete a Front Desk integration
   */
  public async deleteFrontDeskIntegration(id: string) {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/${id}`,
      method: "DELETE",
    };
    return this.request(options);
  }

  public async toggleFrontDeskIntegration(id: string, isActive: boolean) {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/${id}`,
      method: "PUT",
      data: {
        isActive,
      },
    };
    return this.request(options);
  }

  /**
   * Get all queues from front desk - active or not.
   * @param municipalityCode
   */
  public async getAllFrontDeskQueues({ municipalityCode }: { municipalityCode: string }): Promise<any[]> {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/queues`,
      method: "GET",
      params: {
        municipalityCode,
      },
    };
    return this.request(options);
  }

  /**
   * Update a front desk queue with the api.
   * @returns
   */
  public async updateFrontDeskQueue(municipalityCode: number, frontDeskId: string, changes: Partial<IFrontDeskQueue>) {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/queues`,
      method: "PUT",
      data: {
        municipalityCode,
        frontDeskId,
        isActive: changes.isActive || false,
        displayName: changes.displayName || "",
        type: changes.type || "",
        locations: changes.locations,
        lookAhead: changes.lookAhead,
        message: changes.message,
      },
    };
    return this.request(options);
  }

  /**
   * Get all queues from front desk - active or not.
   * @param municipalityCode
   */
  public async getAllFrontDeskLocations({ municipalityCode }: { municipalityCode: string }): Promise<any[]> {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/locations`,
      method: "GET",
      params: {
        municipalityCode,
      },
    };
    return this.request(options);
  }

  /**
   * Update a front desk location with the api.
   * @returns
   */
  public async updateFrontDeskLocation(
    municipalityCode: number,
    frontDeskName: string,
    changes: { displayName?: string; isActive?: boolean }
  ) {
    const options: AxiosRequestConfig = {
      url: `/api/integration/front-desk/locations`,
      method: "PUT",
      data: {
        municipalityCode,
        frontDeskName,
        isActive: changes.isActive || false,
        displayName: changes.displayName || "",
      },
    };
    return this.request(options);
  }

  public async getAllWatsonDiscoveryCollections(): Promise<any[]> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    return this.request(options);
  }

  public async getWatsonDiscoveryCollectionsForMunicipality(municipalityCode: number | string): Promise<any[]> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/municipalities/${municipalityCode}/collections`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    return this.request(options);
  }

  public async getWatsonDiscoveryCollection(municipalityCode: string | number, collectionId: string) {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/municipalities/${municipalityCode}/collections/${collectionId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    return this.request(options);
  }

  public async getWatsonDiscoverySearchesForCollection({
    collectionId,
    municipalityCode,
    periodStart,
    periodEnd,
  }: {
    collectionId?: string;
    municipalityCode: number | string;
    periodStart?: Date;
    periodEnd?: Date;
  }): Promise<IWatsonDiscoverySearch[]> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/municipalities/${municipalityCode}/searches`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      params: {
        collectionId,
        periodStart,
        periodEnd,
      },
    };
    return this.request(options);
  }

  public async getWatsonDiscoverySearchStats({
    collectionId,
    municipalityCode,
    periodStart,
    periodEnd,
  }: {
    collectionId?: string;
    municipalityCode: number | string;
    periodStart?: Date;
    periodEnd?: Date;
  }): Promise<IWatsonDiscoverySearchStat[]> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/municipalities/${municipalityCode}/stats/searches`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      params: {
        collectionId,
        periodStart,
        periodEnd,
      },
    };
    return this.request(options);
  }

  public async getWatsonDiscoveryUrlStats({
    collectionId,
    municipalityCode,
    periodStart,
    periodEnd,
  }: {
    collectionId?: string;
    municipalityCode: number | string;
    periodStart?: Date;
    periodEnd?: Date;
  }): Promise<IWatsonDiscoveryUrlStat[]> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/municipalities/${municipalityCode}/stats/urls`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      params: {
        collectionId,
        periodStart,
        periodEnd,
      },
    };
    return this.request(options);
  }

  public async obfuscateWatsonDiscoverySearch(oldQuery: string, newQuery: string): Promise<void> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/searches/obfuscate`,
      method: "PUT",
      data: {
        oldQuery,
        newQuery,
      },
      headers: {
        Accept: "application/json",
      },
    };
    return this.request(options);
  }

  public async createCollectionForMuni(municipalityCode: string | number, collectionId: string): Promise<any> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/municipalities/${municipalityCode}/collections`,
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      data: {
        collectionId,
      },
    };
    return this.request(options);
  }

  public async detachCollectionFromMuni(municipalityCode: string | number, collectionId: number): Promise<void> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/municipalities/${municipalityCode}/collections/${collectionId}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
      },
    };
    return this.request(options);
  }

  public async getUrlsForMunicipality(municipalityId: number): Promise<Array<IWatsonDiscoveryStartUrl>> {
    const options: AxiosRequestConfig = {
      url: `/api/watson-discovery/crawler/${municipalityId}/urls`,
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    }
    return this.request(options)
  }

  public async saveUrl(startUrl: IWatsonDiscoveryStartUrl): Promise<any> {
    let options: AxiosRequestConfig;
    if (startUrl.id === -1) {
        options = {
          url: `/api/watson-discovery/crawler/url`,
          method: "POST",
          headers: {
            Accept: "application/json"
          },
          data: startUrl
        }
      } else {
        options = {
          url: `/api/watson-discovery/crawler/url/${startUrl.id}`,
          method: "PUT",
          headers: {
            Accept: "application/json"
          },
          data: startUrl
        }
      }
      return this.request(options);
    }

    public async deleteUrl(id: number): Promise<any> {
      if (id && id !== -1) {
          const options: AxiosRequestConfig = {
            url: `/api/watson-discovery/crawler/url/${id}`,
            method: "DELETE",
            headers: {
              Accept: "application/json"
            }
          }
          return this.request(options);
        }
      }
}

export interface GetAllDialogsQuery {
  limit?: number;
  skip?: number;
  isDetailed?: boolean;
  searchText?: string;
  sortBy?: number;
  municipalityCode?: number;
  hasCallback?: number;
  fromSender?: number;
  timeRangeEnd?: Date;
  timeRangeStart?: Date;
  subject?: string;
}

export interface GetAllRatingsQuery {
  limit?: number;
  skip?: number;
  isDetailed?: boolean;
  searchText?: string;
  sortBy?: number;
  municipalityCode?: number;
  timeRangeEnd?: Date;
  timeRangeStart?: Date;
  scope?: "all" | "inScope" | "outOfScope";
}

export interface PresentationConfig {
  isFeedbackEnabled: boolean;
  feedbackDelay: number;
  presentationMode: PresentationModeEnum;
  presentationMessages: string[];
}
