import {AxiosRequestConfig} from "axios";
import {browserHistory} from "../App";
import {BaseAPIService} from "./BaseAPIService";
import {PageState} from "../helpers/PageState";

export class AuthService extends BaseAPIService {

    /**
     * Signs in.
     * @param username
     * @param password
     */
    public async signIn(username: string, password: string): Promise<any> {
        const options: AxiosRequestConfig = {
            url: "/api/auth/login",
            method: "POST",
            data: {
                username: username,
                password: password
            }
        };

        const res = await this.request(options);
        localStorage.setItem("accessToken", res.accessToken);
        AuthService.cleanPageState();
    }

    /**
     * Signs off and redirects to sign-in.
     */
    public async signOff(): Promise<any> {
        browserHistory.push('/sign-in');
        localStorage.removeItem("accessToken");
        localStorage.removeItem("profile");
        AuthService.cleanPageState();
    }

    /**
     * Changes current password with ldap.
     * @param newPass
     */
    public async changePassword(newPass: string): Promise<any> {
        const options: AxiosRequestConfig = {
          url: "/api/auth/account",
          method: "POST",
          data: {
                password: newPass
          }
        };
        return await this.request(options);
    }

    /**
     * Changes current password with ldap.
     */
    public async getUserProfile(): Promise<any> {
        const options: AxiosRequestConfig = {
            url: "/api/auth/profile ",
            method: "GET"
        };
        return await this.request(options);
    }

    protected static cleanPageState(): void {
        sessionStorage.removeItem(PageState.pageStatePrefix + "dialog_list");
        sessionStorage.removeItem(PageState.pageStatePrefix + "feedback_list");
        sessionStorage.removeItem(PageState.pageStatePrefix + "stats");
    }

}