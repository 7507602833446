import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { Avatar, Card, CardContent, Grid, Theme, Typography } from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhoneOutlined';

const styles: any = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

export type CallbacksCountProps = {
  count: number,
  classes: any
};

export class CallbacksCount extends Component<CallbacksCountProps, any>{

  render() {
    return (
      <div className={this.props.classes.root}>
        <Card className={clsx(this.props.classes.root)}>
          <CardContent>
            <Grid container justify="space-between" wrap="nowrap">
              <Grid item>
                <Typography
                  className={this.props.classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2" >
                  ANTAL CALLBACKS
                </Typography>
                <Typography variant="h3">{this.props.count}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={this.props.classes.avatar}>
                  <ContactPhoneIcon className={this.props.classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(CallbacksCount);
