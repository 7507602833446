import React, {Component} from 'react';
import InfoModal from "../InfoModal";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from "@material-ui/core/IconButton";

export type InfoSvgButtonState = { isOpen: boolean };

class InfoSvgButton extends Component<infoSvgButtonProps, InfoSvgButtonState> {

    constructor(props:infoSvgButtonProps) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    toggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };


    render() {
        return (
            <span>
                <IconButton size={"small"} onClick={this.toggleModal} aria-label="info"><InfoIcon/></IconButton>
                <InfoModal className={"context-info-modal"} title={this.props.title} content={this.props.content} isOpen={this.state.isOpen} handleClose={this.toggleModal}/>
            </span>
        );
    }
}

type infoSvgButtonProps = {
    title: string,
    content: any
}

export default InfoSvgButton;