import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Hidden, IconButton, Toolbar} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuRounded';
import InputIcon from '@material-ui/icons/InputRounded';
import HomeIcon from '@material-ui/icons/HomeRounded';
import {AuthService} from "../../../../service/AuthService";

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = (props: Topbarprops) => {
  const { className, onSidebarOpen, ...rest } = props;

  const service: AuthService = new AuthService();

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)} >
      <Toolbar>
        <Hidden only={['sm', 'xs', 'md' ]}>
          <RouterLink to="/dashboard">
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}>
            <HomeIcon style={{ color: "white" }} />
          </IconButton>
          </RouterLink >
        </Hidden>
        <Hidden only={['lg', 'xl' ]}>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.flexGrow} />
          <IconButton
              onClick={service.signOff}
              className={classes.signOutButton}
              color="inherit">
            <InputIcon style={{ color: "white" }} />
          </IconButton>
      </Toolbar>
    </AppBar>
  );
};

type Topbarprops = {
  className?: string,
  onSidebarOpen: any,
};

export default Topbar;
