import { Card, Typography } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import React, { useLayoutEffect, useRef, useState } from "react";
import styles from "./MetricCard.module.scss";
import { Skeleton } from "@material-ui/lab";
import { Line } from "react-chartjs-2";

export interface MetricCardProps {
  value?: string | number;
  text?: string;
  icon: SvgIconComponent;
  data?: { x: any; y: number }[];
}

export function MetricCard({ text, value, icon: Icon, data }: MetricCardProps) {
  const skeleton = value === undefined || value === null;
  const chartContainer = useRef<HTMLDivElement>(null);
  const [dim, setDim] = useState<{ width: number; height: number }>();

  useLayoutEffect(() => {
    setDim(
      chartContainer.current
        ? {
            width: chartContainer.current.clientWidth,
            height: chartContainer.current.clientHeight,
          }
        : undefined
    );
  }, []);

  return (
    <Card className={styles.card}>
      <div style={{ zIndex: 1 }}>
        {skeleton ? (
          <Skeleton variant="text" height={40} width={84} />
        ) : (
          <Typography variant="h1">{value}</Typography>
        )}
        <Typography variant="overline">{text}</Typography>
      </div>

      <div
        className={styles.icon}
        style={{ backgroundColor: "rgba(2, 113, 143, 0.1)", zIndex: 1 }}
      >
        <Icon color="primary" />
      </div>

      {data && (
        <div className={styles.chart} ref={chartContainer}>
          {dim && (
            <Line
              options={{
                responsive: false,
                plugins: {
                  title: {
                    display: false,
                  },
                },
                legend: {
                  display: false,
                },
                tootltips: {
                  enabled: false,
                },
                layout: {
                  padding: {
                    top: 8,
                    left: 0,
                    bottom: 0,
                  },
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        display: false,
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        display: false,
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
              }}
              width={dim.width + 8}
              height={dim.height - 16}
              data={{
                labels: data.map((d) => d.x),
                datasets: [
                  {
                    fill: true,
                    label: "some label",
                    data: data.map((d) => d.y),
                    borderColor: "rgba(2, 113, 143, 0.5)",
                    backgroundColor: "rgba(2, 113, 143, 0.1)",
                    borderWidth: 1,
                    pointRadius: 0,
                  },
                ],
              }}
            />
          )}
        </div>
      )}
    </Card>
  );
}
