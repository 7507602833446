export class PageState {

    public static readonly pageStatePrefix: string = "pagestate_";

    public static save(page: string, state: object) {
        sessionStorage.setItem(PageState.pageStatePrefix + page, JSON.stringify(state));
    }

    public static hasState(page: string): boolean {
        return page !== undefined && page !== "" && sessionStorage.getItem(PageState.pageStatePrefix + page) !== null;
    }

    public static get(page: string) {
        const state = sessionStorage.getItem(PageState.pageStatePrefix + page);
        if (state) {
            try {
                return JSON.parse(state);
            }
            catch (e) {
                return undefined;
            }
        }
        return undefined;
    }

}
