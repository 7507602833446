import React, { Component } from 'react';
import { Theme } from '@material-ui/core/styles';
import { SearchInput } from '../../../../components';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { Checkbox, Grid, IconButton, Tooltip } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import RefreshIcon from '@material-ui/icons/Refresh';
import { Log } from "../../../../common/log";

const styles = ((theme: Theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

export type MunicipalityToolbarProps = { onSearch: any, classes: any; };

export type MunicipalityToolbarState = { searchText: string, sortNameAsc: boolean, isFilterEnabled: boolean; lastSearch: string; };

class MunicipalityToolbar extends Component<MunicipalityToolbarProps, MunicipalityToolbarState> {

  constructor(props: MunicipalityToolbarProps) {
    super(props);

    this.state = {
      searchText: "",
      lastSearch: "",
      sortNameAsc: true,
      isFilterEnabled: false
    };
  }

  search(query: string, asc: boolean) {
    this.setState({ ...this.state, lastSearch: query });
    this.props.onSearch(query, asc);
  }

  keyPress(e: any) {
    if (e.keyCode === 13) {
      Log.log('Search value', e.target.value);
      this.search(e.target.value, this.state.sortNameAsc);
    }
  }

  maybeSearch(query: string) {
    if (this.state.lastSearch === query) return;
    this.search(query, this.state.sortNameAsc);
  }

  handleRefreshClick() {
    this.search(this.state.searchText, this.state.sortNameAsc);
  }

  handleSearchChange(event: any) {
    this.setState({ searchText: event.target.value });
  }

  handleFilterEnabledChange(event: any) {
    this.setState({ isFilterEnabled: event.target.checked });
  }

  handleNameSortChange(event: any) {
    Log.log(event.target.value);
    this.setState({ sortNameAsc: event.target.value !== "name_desc" }, () => {
      this.handleRefreshClick();
    });
  }

  renderSortFilter() {
    if (!this.state.isFilterEnabled)
      return;

    return (
      <Grid item xl={2} lg={3} md={3} sm={6} xs={12}>
        <TextField fullWidth margin="dense"
          label="Sorter efter"
          select
          SelectProps={{ native: true }}
          inputProps={{ 'aria-label': 'Without label' }}
          placeholder=""
          onChange={this.handleNameSortChange.bind(this)}
          value={this.state.sortNameAsc ? "name_asc" : "name_desc"}>
          <option key={1} value="name_asc">Navn (Stigende)</option>
          <option key={2} value="name_desc">Navn (Faldende)</option>
        </TextField>
      </Grid >
    );
  }

  render() {
    return (
      <div>
        <div className={this.props.classes.row}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Kommuner</Typography>
          </Breadcrumbs>
          <span className={this.props.classes.spacer} />
        </div>
        <Grid container spacing={4}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className={this.props.classes.row}>
              <SearchInput
                onChange={this.handleSearchChange.bind(this)}
                onKeyDown={this.keyPress.bind(this)}
                className={this.props.classes.searchInput}
                placeholder="Søg med kommunens navn"
                onBlur={(event) => {
                  this.maybeSearch(event.target.value);
                }}
                content={
                  <div>
                    <Tooltip title="Genindlæs">
                      <IconButton color="primary" aria-label="refresh" onClick={this.handleRefreshClick.bind(this)}>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Vis filter">
                      <Checkbox icon={<FilterList />} checkedIcon={<FilterList />}
                        onChange={this.handleFilterEnabledChange.bind(this)} />
                    </Tooltip>
                  </div>
                }
              />
            </div>
          </Grid>
          {this.renderSortFilter()}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(MunicipalityToolbar);
