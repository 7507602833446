import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import "moment/locale/da";
import {Divider, Theme} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {Sanitize} from "../../../../../../../../helpers/utils";

const styles = ((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    cardHeader: {
        backgroundColor: theme.palette.white
    },
    cardContent: {
        backgroundColor: theme.palette.white,
        height: "50vh",
        marginTop: "5px",
        marginBottom: "5px"
    },
    iconCustomAnswer: {
        fontSize: 80,
        opacity: 0.8,
        color: theme.palette.text.primary
    },
}));

export type SbTextCardProps = {
    tag: any,
    classes: any
};

export type SbTextCardState = {

};

class SbTextCard extends Component<SbTextCardProps, SbTextCardState> {

    constructor(props: SbTextCardProps) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <Card className={this.props.classes.card}>
                <CardHeader className={this.props.classes.cardHeader} title={
                    <span>Tekst fra Selvbetjening.nu</span>
                }
                action={
                     this.props.tag.correctedValue && this.props.tag.correctedValue !== ""  ?
                            <Typography variant="body2">Inaktiv</Typography>
                            :
                            <Typography variant="body2" color="primary">Aktiv</Typography>
                }
                />
                <Divider/>
                <CardContent className={this.props.classes.cardContent} style={{ overflowY: "auto" }}>
                    <Typography
                        style={{ whiteSpace: "pre-wrap" }}
                        variant="body1"
                        dangerouslySetInnerHTML={{__html: Sanitize(this.props.tag.tagValue) || ""}} />
                </CardContent>
            </Card>
        );
    }

}

export default withStyles(styles)(SbTextCard)
