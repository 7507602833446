import palette from '../../../../theme/palette';

const pal: any = palette;

export const options = {
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: pal.divider,
    backgroundColor: 'rgb(255, 255, 255)',
    titleFontColor: pal.text.primary,
    bodyFontColor: pal.text.secondary,
    footerFontColor: pal.text.secondary
  }
};

/*
const data = {
  datasets: [
    {
      data: [63, 15, 22],
      backgroundColor: [
        theme.palette.primary.main,
        theme.palette.error.main,
        theme.palette.warning.main
      ],
      borderWidth: 8,
      borderColor: theme.palette.white,
      hoverBorderColor: theme.palette.white
    }
  ],
  labels: ['Desktop', 'Tablet', 'Mobile']
};
*/

/*
const devices = [
    {
        title: 'Desktop',
        value: '63',
        icon: <LaptopMacIcon />,
        color: pal.primary.main
    },
    {
        title: 'Tablet',
        value: '15',
        icon: <TabletMacIcon />,
        color: pal.error.main
    },
    {
        title: 'Mobile',
        value: '23',
        icon: <PhoneIphoneIcon />,
        color: pal.warning.main
    }
];

 */

/*
export const deviceTypes = {

}


export const colorArray = [
  'rgb(71, 255, 255)',

 

  
  //'rgb(71, 139, 255)',
  'rgb(71, 90, 255)',
  //'rgb(151, 71, 255)',
  //'rgb(200, 71, 255)',

  'rgb(255, 71, 212)',
 

  'rgb(255, 71, 71)',
  'rgb(56, 142, 60)',
  'rgb(255, 120, 71)',
  'rgb(255, 169, 71)', 
  'rgb(255, 218, 71)'
]
*/

