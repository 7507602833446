import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Log } from "../../../../common/log";
import { APIService } from "../../../../service/APIService";

export interface IFrontDeskDetailsModal {
    isOpen: boolean;
    onClose: () => void;
}

export const FrontDeskDetailsModal: React.FC<IFrontDeskDetailsModal> = (
    props
) => {
    const [open, setOpen] = useState(props.isOpen);
    const [municipalities, setMunicipalities] = useState<any[]>([]);

    const [frontDeskMunicipalities, setFrontDeskMunicipalities] = useState<{ id: string, name: string; }[] | null>(null);
    const [frontDeskMunicipalityId, setFrontDeskMunicipalityId] = useState("");

    const [municipalityId, setMunicipalityId] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState("");

    const service = useMemo(() => new APIService(), []);

    useEffect(() => {
        setOpen(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        let isSub = true;
        service.getMunicipalities(100, 0, true, false)
            .then((res) => isSub && setMunicipalities(res))
            .catch((err) => Log.error(`Failed loading municipalities ${err}`));
        return () => {
            isSub = false;
        };
    }, [service]);

    const handleClose = () => {
        setOpen(false);
        setMunicipalityId("");
        setApiKey("");
        setIsSubmitting(false);
        setFrontDeskMunicipalities(null);
        setFrontDeskMunicipalityId("");
        props.onClose();
    };

    const fetchFrontDeskMunicipalities = async () => {
        if (!isFormValid()) return;
        setIsSubmitting(true);
        setError("");
        try {
            const munis = await service.getFrontDeskMunicipalities(apiKey);
            setFrontDeskMunicipalities(munis);
        } catch (e) {
            handleServiceError(e);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmit = async () => {
        if (!isFormValid()) return;
        setError("");
        setIsSubmitting(true);
        try {
            await service.createFrontDeskIntegration({
                apiKey,
                municipalityId: parseInt(municipalityId),
                frontDeskId: parseInt(frontDeskMunicipalityId),
            });
            handleClose();
        } catch (e) {
            handleServiceError(e);
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleServiceError = (e: any) => {
        Log.error(`Failed creating front desk integration ${e}`);
        // Display the error
        const axiosError = e as AxiosError;
        if (axiosError.isAxiosError && axiosError.response && axiosError.response.data.message) {
            setError(axiosError.response.data.message);
        } else {
            setError(`Kunne ikke oprette interation. Prøv venligst igen senere. Fejl: ${e}`);
        }
    };

    const isFormValid = () => {
        if (!frontDeskMunicipalities)
            return !!municipalityId && !!apiKey;
        return !!municipalityId && !!apiKey && !!frontDeskMunicipalityId;
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ fontSize: "1.25rem", fontWeight: 500 }}
            >
                Opret ny Front Desk integration
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Når der oprettes en ny integration til Front Desk skal der angives kommune samt en API-nøgle til den Front Desk instans der skal anvendes for kommunen.
                </DialogContentText>
                <TextField
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    margin="dense"
                    onChange={(event) => setMunicipalityId(event.target.value)}
                    placeholder=""
                    required
                    select
                    SelectProps={{ native: true }}
                    value={municipalityId}
                    disabled={!!frontDeskMunicipalities}
                    variant="outlined">
                    <option disabled key={0} value={""}>Vælg en kommune</option>
                    {municipalities.map((municipality: any) => (
                        <option key={municipality.id} value={municipality.id}>
                            {municipality.name}
                        </option>
                    ))}
                </TextField>

                <TextField required fullWidth label="API-nøgle" variant="outlined" style={{ marginTop: "8px" }} onChange={(event) => setApiKey(event.target.value)} disabled={!!frontDeskMunicipalities} />

                {frontDeskMunicipalities && (
                    <>
                        <Typography style={{ marginTop: "24px", fontWeight: "bold" }} variant="body1">Vælg nu en instans fra Front Desk at koble til</Typography>
                        <TextField
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            style={{ marginTop: "8px" }}
                            required
                            select
                            SelectProps={{ native: true }}
                            onChange={(event) => setFrontDeskMunicipalityId(event.target.value)}
                            value={frontDeskMunicipalityId}
                        >
                            <option disabled key={0} value={""}>Vælg front desk kommune</option>
                            {frontDeskMunicipalities.map((muni) => (<option key={muni.id} value={muni.id}>{muni.name}</option>))}
                        </TextField>
                    </>
                )}

                {error && <Alert style={{ marginTop: "16px" }} severity="error">{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="secondary" onClick={() => handleClose()} disabled={isSubmitting}>Annuller</Button>
                {!frontDeskMunicipalities && <Button color="primary" onClick={() => fetchFrontDeskMunicipalities()} disabled={isSubmitting || !isFormValid()}>Fortsæt</Button>}
                {frontDeskMunicipalities && <Button autoFocus color="primary" onClick={() => handleSubmit()} disabled={isSubmitting || !isFormValid()}>Opret integration</Button>}
            </DialogActions>
        </Dialog>
    );
};
