import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Button, Card, CardContent, Divider, Grid, InputAdornment, Theme, Typography,} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = ((theme: Theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  okBtn: {
    color: theme.palette.error.main
  }
}));

export type MunicipalitySelectionProps = {
  municipalities: any[],
  onModeChange: (municipalityCodes: number[], duration: number) => any,
  classes: any
};

export type MunicipalitySelectionState = {
  isConfirmModalOpen: boolean,
  municipality: any, values: any
};

class MunicipalitySelection extends Component<MunicipalitySelectionProps, MunicipalitySelectionState> {

  constructor(props: MunicipalitySelectionProps) {
    super(props);

    this.state = {
      isConfirmModalOpen: false,
      values: {
        duration: 5,
        municipality: 0
      },
      municipality: {
      }
    };
  }

  getDurationHelpText() {

    if(this.state.values.duration <= 0 || this.state.values.duration > 10080) {
      return "Perioden skal være minimum 1 min og maks 1 uge (10080 min)";
    }

    return "";
  }

  handleDurationChange(event: any) {
    this.setState({ ...this.state, values: {
        ...this.state.values, [event.target.name]: event.target.value
      }
    });
  }

  handleMunicipalityChange(event: any) {

    this.setState({
      ...this.state.values, values: {
        ...this.state.values, [event.target.name]: event.target.value
      }
    });
  }

  handleSave() {
    this.setState({ ...this.state, isConfirmModalOpen: true });
  }

  handleConfirmOk() {
    this.setState({ ...this.state, isConfirmModalOpen: false });

    const codes: number[] = [];

    if(parseInt(this.state.values.municipality) === 0) {
      return;
    }

    if (parseInt(this.state.values.municipality) === -1) {
      for(let item of this.props.municipalities)
        codes.push(item.code)
    }
    else
      codes.push(parseInt(this.state.values.municipality));

    this.props.onModeChange(codes, parseInt(this.state.values.duration));
  }

  handleConfirmModalClose() {
    this.setState({ ...this.state, isConfirmModalOpen: false });
  }


  render() {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={4} >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography
                className={this.props.classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2">
                TILGÆNGELIGHED
              </Typography>
            </Grid>
            <Grid item lg={10} md={10} xl={10} xs={10}>
              <Typography variant="body1">
                Herfra kan tilgængeligheden af chatbotten ændres for én eller flere kommuner. Når en kommune sættes i tilstanden <i>inaktiv</i> vil chatbotten ikke fremgå på kommunens hjemmeside.
                Der skal i denne forbindelse angives en periode hvori chatbotten vil være utilgængelig. Når den angivne periode er udløbet, vil chatbotten igen være tilgængelig på kommunens hjemmeside.

              </Typography>
            </Grid>
            <Grid item lg={10}  md={10} xl={10} xs={10}>
              <Grid container>
                <Grid item lg={3} md={6} xl={3} xs={12}>
                  <TextField name="municipality"
                    style={{ minWidth: "300px" }}
                    label="Kommune"
                    margin="dense"
                    onChange={this.handleMunicipalityChange.bind(this)}
                    placeholder=""
                    required
                    select
                    SelectProps={{native: true}}
                    value={this.state.values.municipality}
                    variant="outlined" >
                    <option disabled key={0} value={0}>Vælg</option>
                    <option key={-1} value={-1}>Alle</option>
                    {this.props.municipalities.map((municipality: any) => (
                      <option  key={municipality.id} value={municipality.code}>
                        {municipality.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={10}  md={10} xl={10} xs={10}>
              <Grid container>
                <Grid item lg={3} md={6} xl={3} xs={12}>
                  <TextField required style={{ minWidth: "300px" }}
                             name="duration"
                             className={this.props.classes.textField}
                             label="Varighed"
                             InputProps={{
                               startAdornment: <InputAdornment position="start">Min</InputAdornment>,
                             }}
                             InputLabelProps={{
                               shrink: true,
                             }}
                             type="number"
                             value={this.state.values.duration}
                             variant="outlined"
                             onChange={this.handleDurationChange.bind(this)}
                             helperText={this.getDurationHelpText()}
                             error={this.getDurationHelpText() === "" ? false : true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider/>
        <DialogActions>
          <Button color="primary" variant="outlined"
                  onClick={this.handleSave.bind(this)}
                  disabled={this.state.values.municipality === 0 || this.props.municipalities.length === 0
                  || this.state.values.duration < 1 || this.state.values.duration > 10080}>
            Sæt inaktiv
          </Button>
        </DialogActions>
        <Dialog
            open={this.state.isConfirmModalOpen}
            onClose={this.handleConfirmModalClose.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Bekræft drift ændring"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på du ønsker at sætte den/de valgte kommuner i <b>inaktiv</b> tilstand. Dette medfører at chatbot'en ikke vises for de valgte kommuner?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleConfirmModalClose.bind(this)} color="primary" autoFocus>
              Annuller
            </Button>
            <Button className={this.props.classes.okBtn} onClick={this.handleConfirmOk.bind(this)} color="inherit">
              Sæt inaktiv
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

export default withStyles(styles)(MunicipalitySelection);

