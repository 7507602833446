import {FilterXSS, IFilterXSSOptions} from "xss";

export function getInitials(name = ''): string {
  return name.replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map(v => v && v[0].toUpperCase())
      .join('');
}

export function getSanitizeOptions(): IFilterXSSOptions {
  return {
    whiteList: {
      a: ['href', 'target'],
      b: [],
      i: [],
      u: [],
      ul: [],
      ol: [],
      li: [],
      br: []
    }
  };
}

const XSS = new FilterXSS(getSanitizeOptions());

export function Sanitize(domNode: any) {
  return XSS.process(domNode);
}

export function getTextForElement(title: string|undefined, description: string|undefined): string {

  if(title && description && title !== "" && description !== "") {
    return `${title} - ${description}`;
  }
  else if(title && (!description || description === "")){
    return title;
  }
  else {
    return description || ""
  }

}