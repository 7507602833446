import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import {config} from "../../../../config/Config";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = (props: any) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1">
        &copy;{' '}DDH - 2020
      </Typography>
      <Typography variant="body2">
        Administrationsværktøj til vedligeholdelse af DDH chatbot.
      </Typography>
      <Typography variant="caption">
        Miljø: { config.isDev ? "Udvikling" : ( config.isTest ? "Test" : "Produktion") }
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
