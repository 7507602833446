import React, {Component} from 'react'
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "@material-ui/core";
import {DownIcon} from "../../../../../../../assets/icons/DownIcon";

export type ExpandItemProps = {
    classes?: any,
    label?: string,
    value?: string,
};

export type ExpandItemState = {};

export class ExpandItem extends Component<ExpandItemProps, ExpandItemState> {

    render() {
        return (
            <ExpansionPanel className="botBlobExpand">
                <ExpansionPanelSummary
                    expandIcon={<DownIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header">

                    <p className="MuiExpansionPanelSummary-content">{this.props.label}</p>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className="botBlobExpandDetail">
                    <p>{this.props.value}</p>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}