import React, { Component } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from "react-perfect-scrollbar";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";
import Typography from "@material-ui/core/Typography";
import InfoSvgButton from "../../../../../../components/InfoButton";
import { User } from "../../../../../../helpers/profile";

const styles = ((theme: Theme) => ({
    root: {},
    alertHeader: {
        fontSize: "1.25rem",
        fontWeight: 500
    },
    deleteCol: {
        width: "5%"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    deleteConfirmBtn: {
        color: theme.palette.error.main
    }
}));

export type AssistantContextProps = {
    isLoading: boolean,
    onDelete: (context: any) => Promise<any>,
    onCreate: (context: any) => Promise<any>,
    onEdit: (context: any, savedContext: any) => Promise<any>,
    classes: any,
    contexts: any[];
};

export type AssistantContextState = { deleteModalOpen: boolean, createModalOpen: boolean, isEdit: boolean, selectedContext: any, values: any, infoModalOpen: boolean, page: number, rowsPerPage: number; };

class AssistantContext extends Component<AssistantContextProps, AssistantContextState> {

    constructor(props: AssistantContextProps) {
        super(props);

        this.state = {
            selectedContext: {},
            deleteModalOpen: false,
            createModalOpen: false,
            isEdit: false,
            values: {
                contextType: "string",
            },
            infoModalOpen: false,
            page: 0,
            rowsPerPage: 10
        };
    }


    handleChange(event: any): void {
        this.setState({
            values: { ...this.state.values, [event.target.name]: event.target.value }
        });
    }

    setEditing(editValue: boolean): void {
        this.setState({ isEdit: editValue });
    }

    handleDeleteModalOpen(context: any): void {
        this.setState({ selectedContext: context, deleteModalOpen: true });
    }

    handleDeleteModalClose(): void {
        this.setState({ deleteModalOpen: false });
    }

    async handleDelete() {
        this.handleDeleteModalClose();
        await this.props.onDelete(this.state.selectedContext);
    }

    async handleCreate() {
        this.handleCreateModalOpenClose(false);
        await this.props.onCreate({
            key: this.state.values.contextKey,
            type: this.state.values.contextType,
            value: this.state.values.contextValue,

        });
    }

    handleEdit(context: any) {
        this.setState({ selectedContext: context, values: { contextType: context.type, contextKey: context.key, contextValue: context.value } });
        this.handleEditContext();
    }

    handleEditContext(): void {
        this.setEditing(true);
        this.handleCreateModalOpenClose(true);
    }

    async handleEditCreate() {
        this.handleCreateModalOpenClose(false);
        await this.props.onEdit(this.state.selectedContext, {
            key: this.state.values.contextKey,
            type: this.state.values.contextType,
            value: this.state.values.contextValue,
        });
    }



    toggleInfoModal = (): void => {
        this.setState({
            infoModalOpen: !this.state.infoModalOpen
        });
    };

    handleCreateModalOpenClose(isOpen: boolean): void {
        this.setState({ createModalOpen: isOpen });
    }

    getContextTypeName(type: string) {

        switch (type) {

            case "number":
                return "Tal";
            case "bool":
                return "Binær";
            case "object":
                return "Kompleks";
            default:
                return "Tekst";

        }

    }

    setPage(page: number) {
        this.setState({ page: page });
    }

    handleRowsPerPageChange(event: any) {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    }

    getContextValueTextField() {

        if (this.state.values.contextType === "bool") {
            return (
                <TextField required fullWidth
                    name="contextValue"
                    className={this.props.classes.textField}
                    select
                    rows={4}
                    value={this.state.isEdit ? this.state.values.contextValue : null}
                    label="Værdi i Watson Assistant"
                    helperText={"Kontekst varaiablens værdi i Watson Assistant ($<Navn>)"}
                    onChange={this.handleChange.bind(this)}
                    variant="outlined">
                    <MenuItem key="true" value="true">
                        Sand
                    </MenuItem>
                    <MenuItem key="false" value="false">
                        Falsk
                    </MenuItem>
                </TextField>
            );
        } else if (this.state.values.contextType === "number") {
            return (
                <TextField required fullWidth
                    name="contextValue"
                    className={this.props.classes.textField}
                    label="Værdi i Watson Assistant"
                    value={this.state.isEdit ? this.state.values.contextValue : null}
                    onChange={this.handleChange.bind(this)}
                    helperText={"Kontekst varaiablens værdi i Watson Assistant ($<Navn>)"}
                    type="number"
                    rows={4}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />);
        }

        return (<TextField required fullWidth
            name="contextValue"
            className={this.props.classes.textField}
            label="Værdi i Watson Assistant"
            value={this.state.isEdit ? this.state.values.contextValue : null}
            helperText={"Kontekst varaiablens værdi i Watson Assistant ($<Navn>)"}
            multiline
            rows={4}
            onChange={this.handleChange.bind(this)}
            variant="outlined"
        />);
    }

    render(): any {
        return (
            <Card>
                <CardHeader
                    subheader="Vedligehold kontekst variabler"
                    title={<span>Kontekst variabler <InfoSvgButton title={"Om kontekst variabler"} content={<Typography>Kontekst variabler giver dig mulighed for at bestemme hvilke værdier der gemmes i chatbotten og bruges under dialogen. Ved at opdatere kontekst variablerne på denne side, vil de blive tilgængelige til brug i Watson Assistant. Du kan læse mere om kontekst variabler i Watson Assistant ved at <a href={"https://cloud.ibm.com/docs/assistant?topic=assistant-dialog-runtime"} target="_blank" rel="noopener noreferrer">klikke på dette link.</a></Typography>} /></span>}
                    action={User.isAdmin() &&
                        <Button color="primary" size="small" variant="outlined"
                            onClick={() => { this.handleCreateModalOpenClose(true); this.setEditing(false); }}>
                            Opret kontekst variabel
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                    <LoadingIndicator isLoading={this.props.isLoading} />
                    {
                        !this.props.isLoading &&
                        <PerfectScrollbar>
                            <div className={this.props.classes.inner}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tag Navn</TableCell>
                                            <TableCell>WA Værdi</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell className={this.props.classes.deleteCol}></TableCell>
                                            <TableCell className={this.props.classes.editCol}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.contexts.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map(context => (
                                            <TableRow
                                                className={this.props.classes.tableRow}
                                                hover
                                                key={context.id}>
                                                <TableCell>{context.key}</TableCell>
                                                <TableCell>{context.value}</TableCell>
                                                <TableCell>{this.getContextTypeName(context.type)}</TableCell>
                                                <TableCell>
                                                    {
                                                        User.isGlobalAdmin() &&
                                                        <IconButton aria-label="rediger"
                                                            onClick={() => this.handleEdit(context)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        User.isGlobalAdmin() &&
                                                        <IconButton aria-label="slet"
                                                            onClick={() => this.handleDeleteModalOpen(context)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    }
                </CardContent>
                <CardActions>
                    <TablePagination
                        labelRowsPerPage="Rækker pr. side"
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from > count ? count : from}-${to} af ${count}`
                        }
                        component="div"
                        count={this.props.contexts.length || 0}
                        onChangePage={(event, page) => this.setPage(page)}
                        onChangeRowsPerPage={this.handleRowsPerPageChange.bind(this)}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                    />
                </CardActions>
                <Dialog
                    open={this.state.deleteModalOpen}
                    onClose={() => this.handleDeleteModalClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={this.props.classes.alertHeader}>Bekræft sletning af kontekst variabel</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på du ønsker at slette denne kontekst variabel
                            '{this.state.selectedContext.key}'
                            permanent? Vær opmærksom på at variablen ikke længere vil være tilgængelig i Watson
                            Assistant.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteModalClose.bind(this)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} className={this.props.classes.deleteConfirmBtn}>
                            Slet
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.createModalOpen}
                    onClose={() => this.handleCreateModalOpenClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className={this.props.classes.alertHeader} id="alert-dialog-title">Opret ny kontekst variabel</DialogTitle>
                    <DialogContent>
                        <TextField required fullWidth
                            name="contextKey"
                            id="outlined-multiline-flexible"
                            className={this.props.classes.textField}
                            label="Navn"
                            onChange={this.handleChange.bind(this)}
                            variant="outlined"
                            value={this.state.isEdit ? this.state.values.contextKey : null}
                        />
                        <TextField required fullWidth
                            name="contextType"
                            id="outlined-select-flexible"
                            select
                            value={this.state.values.contextType}
                            className={this.props.classes.textField}
                            label="Typen af kontekst variablen"
                            onChange={this.handleChange.bind(this)}
                            variant="outlined">
                            <MenuItem key="string" value="string">
                                Tekst
                            </MenuItem>
                            <MenuItem key="number" value="number">
                                Tal
                            </MenuItem>
                            <MenuItem key="bool" value="bool">
                                Binær
                            </MenuItem>
                        </TextField>
                        {
                            this.getContextValueTextField()
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleCreateModalOpenClose(false)} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.state.isEdit ? this.handleEditCreate.bind(this) : this.handleCreate.bind(this)} color="primary">
                            Gem
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        );
    }

}

export default withStyles(styles)(AssistantContext)

