import React from "react";
import { Breadcrumbs, Typography, Grid, Box } from "@material-ui/core";
import { User } from "../../../../helpers/profile";
import { Link as RouterLink } from "react-router-dom";
import { FrontDeskTable } from "./FrontDeskTable";

export const FrontDeskIntegrationDetails: React.FC = () => {
  return <Box padding={3}>
    <Box height="42px" display="flex" alignItems="center" marginTop={1}>
      <Breadcrumbs aria-label="breadcrumb">
        <RouterLink color="inherit" to="/integration" >
          <Typography color="textPrimary">Integrationer</Typography>
        </RouterLink>
        <Typography color="textPrimary">Front Desk</Typography>
      </Breadcrumbs>
      <span />
    </Box>
    <Grid container spacing={4}>
      {
        User.isGlobalAdmin() &&
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <FrontDeskTable />
        </Grid>
      }
    </Grid>
  </Box>
}
