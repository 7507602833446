import React, {Component} from 'react';
import {withStyles} from '@material-ui/styles';
import {Card, CardContent, Divider, Theme, Typography,} from '@material-ui/core';
import {config} from "../../../../../../config/Config";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";

const styles = ((theme: Theme) => ({
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        marginLeft: 'auto',
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    progress: {
        marginTop: theme.spacing(2)
    },
    uploadButton: {
        marginRight: theme.spacing(2)
    }
}));

export type MunicipalityProfileProps = { isLoading: boolean, municipality: any, classes: any };

export type MunicipalityProfileState = {};

class MunicipalityProfile extends Component<MunicipalityProfileProps, MunicipalityProfileState> {

    constructor(props: MunicipalityProfileProps) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Card>
                <div>
                    <CardContent>
                      <LoadingIndicator isLoading={this.props.isLoading}/>
                      {
                        !this.props.isLoading &&
                        <div className={this.props.classes.details}>
                            <div>
                                <Typography
                                    gutterBottom
                                    variant="h2">
                                    {this.props.municipality.name}
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    Kode: {this.props.municipality.code}
                                </Typography>
                            </div>
                            <img className={this.props.classes.avatar}
                                 alt="Logo"
                                 src={`${config.chatbot.scheme}://${config.chatbot.host}/images/avatar/customization/${this.props.municipality.code}.svg`}
                            />
                        </div>
                      }
                    </CardContent>
                  { !this.props.isLoading &&
                    <CardContent>
                        <Typography color="textSecondary" variant="body2">
                          {this.props.municipality.description}
                        </Typography>
                    </CardContent>
                  }
                </div>
                <Divider/>
            </Card>
        );
    }

}

export default withStyles(styles)(MunicipalityProfile)
