import Grid from "@material-ui/core/Grid";
import React, {Component} from "react";
import {Sanitize} from "../../../../../../../helpers/utils";
import {LinkIcon} from "../../../../../../../assets/icons/LinkIcon";

export type LinkBlobProps = {
    classes?: any,
    text?: string;
    source?: string
};

export type LinkBlobState = {  };

export default class LinkBotBlob extends Component<LinkBlobProps,LinkBlobState>{

    render() {
        return(
            <Grid container className="botBlob" direction="row" >
                <Grid  item xs={10}>
                    <a href={this.props.source} target={"_blank"} rel="noopener noreferrer">
                        <div className="botBlobLink" dangerouslySetInnerHTML={{__html: Sanitize(this.props.text) || ""}} ></div>
                    </a>
                </Grid>
                <Grid item xs={2} className="iconAlign">
                    <a href={this.props.source} target={"_blank"} rel="noopener noreferrer">
                        <LinkIcon/>
                    </a>
                </Grid>
            </Grid>
        );
    }
}