import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IFrontDeskQueue } from "./IFrontDeskQueue";

type FormErrors = {
  [key in keyof IFrontDeskQueue]: string | undefined;
};

export const FrontDeskQueueModal: React.FC<{
  queue: IFrontDeskQueue | undefined;
  onClose: () => void;
  onUpdateQueue: (
    queue: IFrontDeskQueue,
    changes: Partial<IFrontDeskQueue>
  ) => Promise<void>;
}> = ({ queue: defaultQueue, onClose, onUpdateQueue }) => {
  const [queue, setQueue] = useState(defaultQueue);
  const [errors, setErrors] = useState<FormErrors | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setQueue(defaultQueue);
  }, [defaultQueue]);

  const handleClose = () => {
    setQueue(undefined);
    setErrors(undefined);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    if (!queue) return;
    const errors = {} as FormErrors;
    if (!queue.displayName) {
      errors.displayName = "Du skal angive et navn til visning";
    }
    if (!queue.type) {
      errors.type = "Du skal angive køens type";
    }
    if (!queue.lookAhead) {
      errors.lookAhead = "Du skal angive et antal dage";
    } else if (
      isNaN(parseInt(queue.lookAhead + "")) ||
      parseInt(queue.lookAhead + "") <= 0
    ) {
      errors.lookAhead = "Du skal skrive et heltal større end 0";
    } else {
      queue.lookAhead = parseInt(queue.lookAhead + "");
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    await updateQueue(queue);
  };

  const updateQueue = async (values: Partial<IFrontDeskQueue>) => {
    // Do update
    setIsSubmitting(true);
    try {
      await onUpdateQueue(defaultQueue!, values);
      handleClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={!!queue}
      onClose={handleClose}
      onExited={() => onClose()}
      aria-labelledby="queue-dialog"
      aria-describedby="queue-dialog"
    >
      {queue && (
        <>
          <DialogTitle id="queue-dialog">
            Kø-indstillinger for <strong>{queue.name}</strong>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Rediger indstillinger for køen <strong>{queue.name}</strong>{" "}
              herunder. Du kan ændre navn til visning, køens type, vælge antal
              dage som der må kigges frem i køen samt aktivere køen.
            </DialogContentText>
            <TextField
              style={{ marginTop: "24px" }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(event) =>
                setQueue({
                  ...queue!,
                  displayName: event.target.value,
                })
              }
              label="Navn til visning"
              placeholder="Angiv navn til visning i Muni"
              required
              value={queue.displayName || ""}
              variant="outlined"
              error={!!errors?.displayName}
              helperText={errors?.displayName}
            />
            <TextField
              style={{ marginTop: "24px" }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(event) =>
                setQueue({
                  ...queue!,
                  type: event.target.value,
                })
              }
              label="Køens type"
              placeholder="Angiv køens type"
              required
              value={queue.type || ""}
              variant="outlined"
              error={!!errors?.type}
              helperText={errors?.type}
            />
            <TextField
              style={{ marginTop: "24px" }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              onChange={(event) =>
                setQueue({
                  ...queue!,
                  lookAhead: event.target.value as unknown as number,
                })
              }
              label="Antal dage at vise tider frem i tiden"
              placeholder="Angiv hvor mange dage der skal kigges frem i tiden"
              required
              value={queue.lookAhead || ""}
              variant="outlined"
              error={!!errors?.lookAhead}
              helperText={errors?.lookAhead}
            />
            <TextField
              style={{ marginTop: "24px" }}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={3}
              rowsMax={6}
              fullWidth
              onChange={(event) => setQueue({
                ...queue!,
                message: event.target.value,
              })}
              label="Afsluttende besked"
              placeholder="Angiv en afsluttende besked, som kan vises til borgeren, specifikt for denne kø"
              value={queue.message}
              variant="outlined"
            />
            <FormControl component="fieldset" style={{ marginTop: "24px" }}>
              <FormLabel component="legend">Vil du aktivere køen?</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={queue.isActive}
                    onChange={(event) =>
                      setQueue({
                        ...queue!,
                        isActive: event.target.checked,
                      })
                    }
                    name="isActive"
                    color="primary"
                  />
                }
                label="Er køen aktiv?"
              />
              <FormHelperText>
                Køen aktiveres først når du gemmer
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={handleClose}
              autoFocus
              disabled={isSubmitting}
            >
              Annuller
            </Button>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Gem ændringer
              {isSubmitting && (
                <CircularProgress
                  size="1.5rem"
                  color="inherit"
                  style={{ marginLeft: "8px" }}
                />
              )}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
