import React, {Component} from "react";

export type TimestampBotBlobProps = {
    classes?: any,
    timestamp: any
};

export type TimestampBotBlobState = {  };


export default class TimestampBotBlob extends Component<TimestampBotBlobProps, TimestampBotBlobState> {

    render(){
        return(
            <div className="timestamp">{this.props.timestamp ? this.props.timestamp : ""}</div>
        );
    }

}